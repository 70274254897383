import Vue from 'vue'

import { mutationTypes } from './constants'
import {
  deepMergeReplace,
  convertArrToObject,
  getUniqueArrayElements
} from '../../utils'

const {
  SET_COMPANIES,
  CLEAR_COMPANIES,
  SET_COMPANIES_FETCHING,
  SET_COMPANIES_POSTING
} = mutationTypes

export default {
  /**
   * @name SET_COMPANIES
   * Add companies to store by overwriting old data with new.
   * Will not remove old companies
   *
   * @param {Object} state Vuex Store Props
   * @param {Object} payload
   * @param {Object|Array} payload.data Company or array of companies
   *
   * @returns {Void}
   */
  [SET_COMPANIES](state, { data }) {
    if (typeof data !== 'object')
      throw Error('Data must be a company or an array of companies!')
    const companies = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(companies)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  /**
   * @name CLEAR_COMPANIES
   * Clear all companies
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_COMPANIES](state) {
    Vue.set(state, 'data', {})
    Vue.set(state, 'ids', [])
  },

  /**
   * @name SET_COMPANIES_FETCHING
   * Set fetching status of companies
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_COMPANIES_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_COMPANIES_POSTING
   * Set posting status of companies
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_COMPANIES_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

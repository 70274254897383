export default {
  settings: {
    company: 'Företag',
    user: 'Min användare',
    document: 'Dokument',
    members: 'Medlemmar',
    membersForm: 'Medlem',
    notifications: 'Notiser',
    integrations: 'Integrationer',
    billing: 'Abonnemang',
    language: 'Språk',
    sms: 'SMS-avsändare',
    subdomain: 'Subdomän',
    emailDomain: 'E-postdomän',
    websitesSocialMedia: 'Sociala medier',
    certificates: 'Certifikat',
    theme: 'Utseende',
    companyPresentation: 'Presentation',
    tabPhotos: 'Inställningar - Foton',
    photosVideos: 'Mina foton',
    templates: 'Inställningar - Mallar',
    templateEditor: 'Textmall',
    termForm: 'Redigera - Villkor',
    termList: 'Mina villkor'
  }
}

import { createLink, linkExists } from './head'

export const loadFont = (family, isPreview = false) => {
  if (family && !linkExists(family, isPreview)) createLink(family, isPreview)
}

// Will go through document data and load fontFamily for all selectors/css objects etc
export const loadFontFamiliesNested = (obj = {}) => {
  if (typeof obj !== 'object' || !obj) return

  for (const key in obj) {
    if (key === 'fontFamily') loadFont(obj[key])
    else if (typeof obj[key] === 'object') loadFontFamiliesNested(obj[key])
  }
}

<template>
  <div :class="mainClasses" class="c-tabs">
    <nav :class="navClasses" class="tabs">
      <ul>
        <li
          v-for="(tabItem, index) in tabItems"
          v-show="tabItem.visible"
          :key="index"
          :class="{
            'is-active': activeTab === index,
            'is-disabled': tabItem.disabled
          }"
        >
          <CSlotComponent
            v-if="tabItem.$slots.header"
            :component="tabItem"
            name="header"
            tag="a"
            @click.native="tabClick(index)"
          />
          <a v-else @click="tabClick(index)">
            <CIcon v-if="tabItem.icon" :size="size" :type="tabItem.icon" />
            <span>{{ tabItem.label }}</span>
          </a>
        </li>
      </ul>
    </nav>
    <section
      :class="{ 'is-transitioning': isTransitioning }"
      class="tab-content"
    >
      <slot />
    </section>
  </div>
</template>

<script>
import CSlotComponent from '@cling/components/ui/utils/CSlotComponent'

export default {
  name: 'CTabs',
  components: {
    CSlotComponent
  },
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    expanded: Boolean,
    type: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    position: {
      type: String,
      validator(value) {
        return ['left', 'right', 'center'].indexOf(value) > -1
      },
      default: null
    },
    animated: {
      type: Boolean,
      default: true
    },
    destroyOnHide: {
      type: Boolean,
      default: false
    },
    vertical: Boolean,
    multiline: Boolean
  },
  data() {
    return {
      activeTab: 0,
      defaultSlots: [],
      contentHeight: 0,
      isTransitioning: false,
      _isTabs: true // Used internally by TabItem
    }
  },
  computed: {
    mainClasses() {
      return {
        'is-fullwidth': this.expanded,
        'is-vertical': this.vertical,
        'is-multiline': this.multiline,
        [`is-${this.position}`]: this.position && this.vertical
      }
    },
    navClasses() {
      return [
        this.type,
        {
          [`is-${this.size}`]: Boolean(this.size),
          [`is-${this.position}`]: this.position && !this.vertical,
          'is-fullwidth': this.expanded,
          'is-toggle-rounded is-toggle': this.type === 'is-toggle-rounded'
        }
      ]
    },
    tabItems() {
      return this.defaultSlots
        .filter(
          vnode =>
            vnode.componentInstance &&
            vnode.componentInstance.$data &&
            vnode.componentInstance.$data._isTabItem
        )
        .map(vnode => vnode.componentInstance)
    }
  },
  watch: {
    /**
     * When v-model is changed set the new active tab.
     */
    value(value) {
      const index = this.getIndexByValue(value, value)
      this.changeTab(index)
    },
    /**
     * When tab-items are updated, set active one.
     */
    tabItems() {
      if (this.activeTab < this.tabItems.length) {
        let previous = this.activeTab
        this.tabItems.map((tab, idx) => {
          if (tab.isActive) {
            previous = idx
            if (previous < this.tabItems.length) {
              this.tabItems[previous].isActive = false
            }
          }
        })
        this.tabItems[this.activeTab].isActive = true
      } else if (this.activeTab > 0) {
        this.changeTab(this.activeTab - 1)
      }
    }
  },
  mounted() {
    this.activeTab = this.getIndexByValue(this.value || 0)
    if (this.activeTab < this.tabItems.length) {
      this.tabItems[this.activeTab].isActive = true
    }
    this.refreshSlots()
  },
  methods: {
    /**
     * Change the active tab and emit change event.
     */
    changeTab(newIndex) {
      if (this.activeTab === newIndex || this.tabItems[newIndex] === undefined)
        return
      if (this.activeTab < this.tabItems.length) {
        this.tabItems[this.activeTab].deactivate(this.activeTab, newIndex)
      }
      this.tabItems[newIndex].activate(this.activeTab, newIndex)
      this.activeTab = newIndex
      this.$emit('change', this.getValueByIndex(newIndex))
    },
    /**
     * Tab click listener, emit input event and change active tab.
     */
    tabClick(index) {
      if (this.activeTab === index) return
      this.$emit('input', this.getValueByIndex(index))
      this.changeTab(index)
    },
    refreshSlots() {
      this.defaultSlots = this.$slots.default || []
    },
    getIndexByValue(value) {
      const index = this.tabItems
        .map(t =>
          t.$options.propsData ? t.$options.propsData.value : undefined
        )
        .indexOf(value)
      return index >= 0 ? index : value
    },
    getValueByIndex(index) {
      const { propsData } = this.tabItems[index].$options
      return propsData && propsData.value ? propsData.value : index
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@cling/styles/theme/utilities/_all.sass';

.c-tabs {
  .tabs {
    margin-bottom: 0;
    flex-shrink: 0;
    li {
      &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
  .tab-content {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    /* padding: calc(1 * var(--rem)); */
    .tab-item {
      flex-shrink: 0;
      flex-basis: auto;
    }
    &.is-transitioning {
      overflow: hidden;
    }
  }
  &:not(:last-child) {
    margin-bottom: calc(1.5 * var(--rem));
  }
  &.is-fullwidth {
    width: 100%;
  }
  &.is-vertical {
    display: flex;
    flex-direction: row;

    > .tabs {
      ul {
        flex-direction: column;
        border-bottom-color: transparent;
        li {
          width: 100%;
          a {
            justify-content: left;
          }
        }
      }
      /* &.is-boxed {
          li {
            a {
              border-bottom-color: transparent !important;
              border-right-color: $tabs-border-bottom-color !important;
              border-radius: $tabs-boxed-link-radius 0 0 $tabs-boxed-link-radius;
            }

            &.is-active {
              a {
                border-bottom-color: $tabs-border-bottom-color !important;
                border-right-color: transparent !important;
              }
            }
          }
        }
        &.is-toggle {
          li {
            + li {
              margin-left: 0;
            }
            &:first-child {
              a {
                border-radius: $tabs-toggle-link-radius $tabs-toggle-link-radius 0 0;
              }
            }
            &:last-child {
              a {
                border-radius: 0 0 $tabs-toggle-link-radius $tabs-toggle-link-radius;
              }
            }
          }
        } */
      &.is-fullwidth {
        li {
          a {
            height: 100%;
          }
        }
      }
    }
    > .tab-content {
      flex-grow: 1;
    }

    &.is-right {
      flex-direction: row-reverse;

      > .tabs {
        ul {
          a {
            flex-direction: row-reverse;
            .icon:first-child {
              margin-right: 0;
              margin-left: 0.5em;
            }
          }
        }

        /* &.is-boxed {
            li {
              a {
                border-bottom-color: transparent !important;
                border-right-color: transparent !important;
                border-left-color: $tabs-border-bottom-color !important;
                border-radius: 0 $tabs-boxed-link-radius $tabs-boxed-link-radius 0;
              }

              &.is-active {
                a {
                  border-bottom-color: $tabs-border-bottom-color !important;
                  border-right-color: $tabs-border-bottom-color !important;
                  border-left-color: transparent !important;
                }
              }
            }
          } */
      }
    }
  }
  &.is-multiline {
    > .tabs {
      ul {
        flex-wrap: wrap;
        flex-shrink: 1;
      }
    }
  }
}
</style>

import { handleStateByKey } from '@cling/store/utils/'

import { getField } from 'vuex-map-fields'

import document2Getters from './document2/getters'

// ? If you don't want _uniqueId and need to compare, use this function
// const copyAndRemoveUniq = (obj) => {
//   const copy = Object.assign({}, obj);

//   Object.values(copy).filter((el) => {
//     if (Array.isArray(el)) {
//       return el.every(subEl => typeof subEl === 'object');
//     }
//     return false;
//   }).forEach((arr) => {
//     arr.forEach(el => delete el._uniqueId);
//   });
//   return copy;
// };

export default {
  getField,
  ...document2Getters,
  hasChanged: state => formKey =>
    JSON.stringify(state[formKey]) !== JSON.stringify(state[`_old_${formKey}`]),
  getFormByKey: state => key => {
    const keys = key.split('.')
    let root = state
    const prop = keys.pop() // always last part of keys
    if (keys.length > 0) {
      root = keys.reduce((result, currentValue) => {
        const res = result[currentValue]
        return res
      }, state)
    }
    return root[prop]
  },
  getArrayElement: state => (key, uniqueId) => {
    const { root, prop } = handleStateByKey(state, key)
    const array = root[prop]
    if (!Array.isArray(array)) {
      throw new Error(`${prop} is not an array`)
    }
    const result = array.find(obj => obj.uniqueId === uniqueId)
    return result
  }
}

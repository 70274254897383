import {
  convertArrToObject,
  deepMergeReplace,
  getUniqueArrayElements
} from '@cling/store/utils'

import Vue from 'vue'

import { mutationTypes } from './constants'

const {
  CLEAR_END_CUSTOMER,
  SET_ENDCUSTOMERS,
  DELETE_ENDCUSTOMER,
  SET_ENDCUSTOMER_FETCHING,
  SET_ENDCUSTOMER_POSTING
} = mutationTypes

export default {
  // Set endCustomers
  // Should only be called after any normalization has been executed from action
  // endCustomers: Array with objects
  // doClearOld: Optional bool if old data should be removed before set
  [SET_ENDCUSTOMERS](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a company user or an array of company users!')
    }
    const items = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(items)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },
  // Delete one
  [DELETE_ENDCUSTOMER](state, id) {
    Vue.delete(state.data, id)
    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_END_CUSTOMER
   * Clear all end customers
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_END_CUSTOMER](state) {
    Vue.set(state, 'data', {})
    Vue.set(state, 'ids', [])
  },

  /**
   * @name SET_ENDCUSTOMER_FETCHING
   * Set fetching status of endCustomer
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_ENDCUSTOMER_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_ENDCUSTOMER_POSTING
   * Set posting status of endCustomer
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_ENDCUSTOMER_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

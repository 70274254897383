import { documentTemplate } from '@cling/api'
import { viewContext } from '@cling/globalState'
import lang from '@cling/language'
import eventBus from '@cling/services/eventBus'
import { global } from '@cling/store/action-types'
import { filterForbiddenProperties } from '@cling/utils'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'templates'

const {
  DO_LOAD_TEMPLATE,
  DO_LOAD_TEMPLATES,
  LOAD_TEMPLATE,
  LOAD_TEMPLATES,
  DO_UPDATE_TEMPLATE,
  DO_CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  REMOVE_TEMPLATE,
  DELETE_TEMPLATE,
  PATCH_TEMPLATE,
  HIDE_TEMPLATE,
  DUPLICATE_PROTECTED_TEMPLATE
} = actionTypes

const {
  SET_TEMPLATE_POSTING,
  SET_TEMPLATE_FETCHING,
  SET_TEMPLATES,
  DELETE_MANY_TEMPLATES
} = mutationTypes

const actions = {
  /**
   * @name DO_LOAD_TEMPLATE
   * Get one template from the API and add to store
   * @param {Object} Vuex object
   * @returns {Promise<>} Promise that resolves or throws an error
   */
  async [DO_LOAD_TEMPLATE]({ commit }, { id, emit = false, params = {} }) {
    try {
      commit(SET_TEMPLATE_FETCHING, true)
      const { data } = await documentTemplate.get(id, params)
      if (emit) eventBus.trigger('template:watch', data)
      commit(SET_TEMPLATES, { data: [data] })
    } finally {
      commit(SET_TEMPLATE_FETCHING, false)
    }
  },

  /**
   * @name DO_LOAD_TEMPLATES
   * Get templates from the API and add to store
   * @param {Object} Vuex object
   * @returns {Promise<>} Promise that resolves or throws an error
   */
  async [DO_LOAD_TEMPLATES]({ commit }, params = {}) {
    try {
      commit(SET_TEMPLATE_FETCHING, true)
      const { data } = await documentTemplate.get(null, params)
      const { items } = data
      commit(SET_TEMPLATES, { data: items })
    } finally {
      commit(SET_TEMPLATE_FETCHING, false)
    }
  },

  async [LOAD_TEMPLATES]({ dispatch }, params = {}) {
    try {
      await dispatch(DO_LOAD_TEMPLATES, params)
    } catch (err) {
      this.handleError(err, {
        object: 'template',
        fallbackCode: 'template.getMany',
        action: `${moduleName}/${LOAD_TEMPLATES}`
      })
    }
  },

  /**
   * @name LOAD_TEMPLATE
   * Get one template by id
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {String} obj.id id
   */
  async [LOAD_TEMPLATE]({ dispatch }, { id, emit }) {
    try {
      await dispatch(DO_LOAD_TEMPLATE, { id, emit })
    } catch (err) {
      this.handleError(err, {
        object: 'template',
        fallbackCode: 'template.getOne',
        action: `${moduleName}/${LOAD_TEMPLATE}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name DO_CREATE_TEMPLATE
   * Create one template
   * @param {Object} body data
   * @returns {Promise<String>} Promise that resolves with template id
   */
  async [DO_CREATE_TEMPLATE](
    { commit, dispatch },
    { body, showMessage = true }
  ) {
    try {
      commit(SET_TEMPLATE_POSTING, true)
      const { data } = await documentTemplate.post(body)
      const { id } = data
      await dispatch(DO_LOAD_TEMPLATE, { id })

      if (showMessage) {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('createdThing', { thing: lang.t('template') })
        })
      }
      return id
    } finally {
      commit(SET_TEMPLATE_POSTING, false)
    }
  },

  /**
   * @name DO_UPDATE_TEMPLATE
   *  Update one template by id
   * @param {String} id id of the template
   * @param {Object} body data
   * @returns {Promise<String>} Promise that resolves with template id
   */
  async [DO_UPDATE_TEMPLATE]({ dispatch, commit }, { id, body }) {
    try {
      commit(SET_TEMPLATE_POSTING, true)
      await documentTemplate.put(id, body)
      await dispatch(DO_LOAD_TEMPLATE, { id })

      if (viewContext.value !== 'widget') {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('updatedThing', { thing: lang.t('template') })
        })
      }
      return id
    } finally {
      commit(SET_TEMPLATE_POSTING, false)
    }
  },
  /**
   * @name UPDATE_TEMPLATE
   *  Update one template by id
   * @param {String} id id of the template
   * @param {Object} body data
   * @returns {Promise<String|null>} Promise that resolves with template id or null
   */
  async [UPDATE_TEMPLATE]({ dispatch }, { id, body }) {
    try {
      switch (body.onModify) {
        case 'update':
          await dispatch(DO_UPDATE_TEMPLATE, { id, body })
          return id
        case 'create':
          return dispatch(DUPLICATE_PROTECTED_TEMPLATE, {
            body,
            doHide: body.doConsumeDocumentQuota // do not hide 'free' templates
          })
        default:
          throw new Error('Invalid onModify value')
      }
    } catch (err) {
      this.handleError(err, {
        object: 'template',
        objectId: id,
        fallbackCode: 'template.put',
        action: `${moduleName}/${UPDATE_TEMPLATE}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name REMOVE_TEMPLATE
   * DELETE or HIDE template depending on onModify value
   * @param {String} id id of the template
   * @returns {Promise<String|null>} Promise that resolves with template id or null
   */
  async [REMOVE_TEMPLATE]({ dispatch }, { template }) {
    try {
      const { id } = template
      switch (template.onModify) {
        case 'update':
          return dispatch(DELETE_TEMPLATE, { id })
        case 'create':
          await dispatch(HIDE_TEMPLATE, { id })
          return id
        default:
          throw new Error('Invalid onModify value')
      }
    } catch (err) {
      this.handleError(err, {
        object: 'template',
        objectId: template?.id,
        fallbackCode: 'template.remove',
        action: `${moduleName}/${REMOVE_TEMPLATE}`
      })
      return null
    }
  },

  /**
   * @name DELETE_TEMPLATE
   * Destroy one template by id
   * @param {String} id id of the template
   * @returns {Promise<String|null>} Promise that resolves with template id or null
   */
  async [DELETE_TEMPLATE]({ commit }, { id }) {
    try {
      commit(SET_TEMPLATE_POSTING, true)
      await documentTemplate.delete(id)
      commit(DELETE_MANY_TEMPLATES, id)

      if (viewContext.value !== 'widget') {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('removedThing', { thing: lang.t('template') }),
          actions: {
            undo: () => {
              this.dispatch(`templates/${PATCH_TEMPLATE}`, { id })
            }
          }
        })
      }
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'templates',
        objectId: id,
        fallbackCode: 'documentTemplate.delete',
        action: `${moduleName}/${DELETE_TEMPLATE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_TEMPLATE_POSTING, false)
    }
  },

  /**
   * @name PATCH_TEMPLATE
   * Patch one template by id
   * @param {String} id id of the template
   * @returns {Promise<String|null>} Promise that resolves with template id or null
   */
  async [PATCH_TEMPLATE]({ commit, dispatch }, { id }) {
    try {
      commit(SET_TEMPLATE_POSTING, true)
      await documentTemplate.patch(id)
      await dispatch(DO_LOAD_TEMPLATE, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'templates',
        objectId: id,
        fallbackCode: 'documentTemplate.patch',
        action: `${moduleName}/${PATCH_TEMPLATE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_TEMPLATE_POSTING, false)
    }
  },

  async [HIDE_TEMPLATE]({ rootGetters }, { id }) {
    if (!id) return
    const hiddenIds =
      rootGetters['settings/getCompanySetting']('templateHiddenIds') || []
    hiddenIds.push(id)
    await this.dispatch(global.UPDATE_SETTINGS, {
      key: 'company',
      settings: { templateHiddenIds: hiddenIds }
    })
  },

  async [DUPLICATE_PROTECTED_TEMPLATE]({ commit, dispatch }, { body, doHide }) {
    try {
      commit(SET_TEMPLATE_POSTING, true)

      const newBody = filterForbiddenProperties(body, [
        '_id',
        'id',
        'companyId'
      ])
      const id = await dispatch(DO_CREATE_TEMPLATE, { body: newBody })

      // Hide OLD id
      if (doHide) await dispatch(HIDE_TEMPLATE, { id: body.id })
      return id
    } finally {
      commit(SET_TEMPLATE_POSTING, false)
    }
  }
}

export default actions

<template>
  <NodeViewWrapper
    :class="[
      {
        'texteditor-image-view-inline': display && display !== 'block',
        'outline-primary-300 outline outline-2': isSelected
      },
      `texteditor-image-view-${display}`
    ]"
    class="texteditor-image-view"
    as="span"
    draggable="true"
    data-drag-handle
  >
    <img
      :src="src"
      :class="{ selected: isSelected }"
      :style="style"
      @click="selectImage"
    />
    <div v-if="isSelected" class="image-menu top-2 rounded-md bg-gray-800">
      <CButton
        v-for="(option, i) in displayOptions"
        :key="i"
        :class="{ '!text-primary-300': display === option.value }"
        :data-texteditor-tooltip="option.tooltip"
        type="none"
        size="small"
        @click="changeDisplay(option.value)"
      >
        <svg
          viewBox="0 0 24 24"
          width="21"
          height="21"
          stroke="currentColor"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          v-html="option.icon"
        ></svg>
      </CButton>
      <div
        class="bg-gray-500"
        style="width: 1px; height: 1.5em; margin: 0 2px"
      />
      <CButton
        :data-texteditor-tooltip="$t('_common:remove')"
        type="none"
        size="small"
        @click="remove"
      >
        <CIcon :size="18" type="trash" />
      </CButton>
    </div>
  </NodeViewWrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import get from 'lodash/get'

export default {
  name: 'TextEditorImageView',
  components: {
    NodeViewWrapper
  },
  props: nodeViewProps, // https://tiptap.dev/guide/node-views/vue#all-available-props
  data() {
    return {
      displayOptions: [
        {
          value: 'left',
          icon: '<rect x="1" y="5" width="11" height="15" rx="0" ry="2"></rect><line x1="16" y1="7" x2="22" y2="7"></line><line x1="16" y1="12" x2="22" y2="12"></line><line x1="16" y1="17" x2="22" y2="17"></line>',
          tooltip: this.$t('_common:align', { thing: this.$t('_common:left') })
        },
        {
          value: 'block',
          icon: '<rect x="2" y="7" width="19" height="11" rx="0" ry="2"></rect><line x1="2" y1="3" y2="3" x2="21"></line><line x1="2" y1="22" x2="21" y2="22"></line>',
          tooltip: this.$t('_common:center', {
            thing: this.$t('_common:image')
          }).capitalize()
        },
        {
          value: 'right',
          icon: '<rect x="11" y="5" width="11" height="15" rx="0" ry="2"></rect><line x1="0" y1="7" y2="7" x2="6"></line><line x1="0" y1="12" x2="6" y2="12"></line><line x1="0" y1="17" x2="6" y2="17"></line>',
          tooltip: this.$t('_common:align', { thing: this.$t('_common:right') })
        }
      ]
    }
  },
  computed: {
    // Selected flag exclusively for edit view
    isSelected() {
      return this.selected && this.editor.isEditable
    },
    src() {
      const url = get(this, 'node.attrs.src', '')
      return this.extension?.options?.resolveImgSrc(url) || url
    },
    display() {
      return get(this, 'node.attrs.display', 'block')
    },
    style() {
      return get(this, 'node.attrs.style', '')
    }
  },
  methods: {
    selectImage() {
      if (!this.editor.isEditable) this.openPreview()
      else this.editor.chain().focus().setNodeSelection(this.getPos()).run()
    },
    remove() {
      this.deleteNode(this.getPos())
    },
    changeDisplay(val) {
      this.updateAttributes({ display: val })
      this.selectImage()
    },
    async openPreview() {
      const modal = () => import('@cling/components/FilePreviewModal.vue')
      const { src, title } = this.node?.attrs || {}
      this.$modal.show(
        modal,
        {
          index: 0, // Translate src index to img list index
          list: [{ url: src, name: title }]
        },
        {
          adaptive: true,
          height: '100%',
          width: '100%',
          classes: '', // Don't remove. In place to not apply default modal class
          transition: ''
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.texteditor-image-view {
  position: relative;
  cursor: pointer;
  line-height: 0;
  margin: 1em 0;
  max-width: 100%;
  user-select: none;
  display: inline-block;
  float: none;
  vertical-align: baseline;
}

.image-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 0 7px;
  > button {
    background: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
    color: rgba($white, 0.7);
    width: 35px;
    height: 42px;
    border-radius: 0.35em;
    &:hover {
      color: $white;
    }
  }
}

$inlineWidth: 50%;
$rightMargin: calc(1 * var(--rem)) 0 calc(1 * var(--rem)) calc(2 * var(--rem));
$leftMargin: calc(1 * var(--rem)) calc(2 * var(--rem)) calc(1 * var(--rem)) 0;

.quill-format img {
  &[data-display='right'] {
    float: right;
    margin: $rightMargin;
    max-width: $inlineWidth;
    border-radius: 2px;
  }
  &[data-display='left'] {
    float: left;
    margin: $leftMargin;
    max-width: $inlineWidth;
    border-radius: 2px;
  }
  &[data-display='block'] {
    display: block;
  }
}

.texteditor-image-view {
  &-inline#{&} {
    max-width: $inlineWidth;
    margin: 0;
    img {
      display: inline-block;
      vertical-align: baseline;
      max-width: 100%;
      border-radius: 2px;
      margin: 0;
      max-height: initial;
    }
    // Clearfix
    &::after {
      content: '';
      display: block;
      height: 0;
      clear: both;
    }
  }
  &-block#{&} {
    display: block;
  }
  &-left#{&} {
    float: left;
    margin: $leftMargin;
  }
  &-right#{&} {
    float: right;
    margin: $rightMargin;
  }
}

.is-mobile .texteditor-image-view-left,
.is-mobile .texteditor-image-view-right,
.is-mobile .quill-format img[data-display='right'],
.is-mobile .quill-format img[data-display='left'] {
  width: 100%;
  float: none;
  display: block;
  margin: calc(2 * var(--rem)) 0;
}

// Media screen is used to avoid applying this in print pdf (media print)
@media screen and (max-width: 600px) {
  .quill-format img[data-display='right'],
  .quill-format img[data-display='left'] {
    width: 100%;
    float: none;
    display: block;
  }
}

.quill-format p::after {
  content: '';
  clear: both;
  display: block;
}
</style>

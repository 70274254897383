import config from '@cling/config'
import features from '@cling/services/features'
import permissions from '@cling/services/permissions'
import { brands, payingAccountTypes } from '@cling/static'
import webStorage from '@cling/utils/webStorage'

import jwtDecode from 'jwt-decode'
import get from 'lodash/get'

export default {
  userPosition: state => state.user.position,
  isLoggingIn: state => state.user.isLoggingIn,
  isSuperUser: () => () => {
    // Return function as no reactivity to state
    const token = webStorage.getItem('token')
    if (!token) return false
    const { employee } = jwtDecode(token)
    return !!employee
  },
  isRegistering: state => state.user.isRegistering,
  isResettingPassword: state => state.user.isResettingPassword,
  isUserLoaded: ({ user }) => (user.userId && user.companyId && true) || false,
  company: ({ user }, getters, rootState, rootGetters) =>
    rootGetters['companies/companyById'](user.companyId),
  companyAccount: ({ user }, getters, rootState, rootGetters) =>
    (rootGetters['companies/companyById'](user.companyId) || {})
      .CompanyAccount || null,
  companyAccountType: (_, getters) =>
    (getters.companyAccount || {}).accountType || null, // TODO
  accountConfigName: (_, getters) =>
    getters.companyAccount?.accountConfigurationKeyName || null,
  companyRegion: (_, getters) =>
    (getters.company || {}).region || brands[config.brand].defaultRegion,
  subscription: (_, getters) => {
    const { companyAccount = {} } = getters
    if (!companyAccount) return {}
    const {
      freePremiumDays = null,
      accountType = null,
      maxProjectManagers = null,
      freeDocumentsLeft = null,
      paymentMethod,
      billingCycleInterval,
      billingCycleCount,
      showCalloutConvertStripe,
      hasExceededQuota,
      StripeSubscriptions,
      mrr,
      hasFailedPayments,
      allowFailedPaymentToConvertStripe,
      freeBankIdLeft,
      freeSmsLeft
    } = companyAccount

    const initialFreePremiumDays = get(
      companyAccount,
      'AccountConfiguration.data.defaultNewCompany.freePremiumDays',
      14
    )
    const stripeStatuses = [
      ...new Set([...StripeSubscriptions.map(i => i.status)])
    ]
    const isStripeLegacy = StripeSubscriptions.some(s =>
      (s.stripeItems || []).some(i => i.stripePriceStripeId.startsWith('plan_'))
    )
    const isInvoiceLegacy =
      payingAccountTypes.includes(accountType) &&
      showCalloutConvertStripe &&
      paymentMethod === 'invoice' &&
      get(companyAccount, 'documentQuota.newCount') === 1000 &&
      !get(companyAccount, 'premiumEndAt')

    const result = {
      type: accountType,
      trialDaysLeft: freePremiumDays, // Remaining trial days with full access
      maxProjectManagers,
      freeDocumentsLeft,
      paymentMethod,
      billingCycleInterval,
      billingCycleCount,
      showCalloutConvertStripe:
        payingAccountTypes.includes(accountType) &&
        showCalloutConvertStripe &&
        paymentMethod === 'invoice',
      stripeStatuses,
      isPaying: payingAccountTypes.includes(accountType), // Boolean if the account is a paying company
      hasExceededQuota, // if the user has used "free" resources and should upgrade their account
      isStripeLegacy,
      isInvoiceLegacy,
      mrr,
      hasFailedPayments,
      allowFailedPaymentToConvertStripe,
      freeBankIdLeft,
      freeSmsLeft,
      initialFreePremiumDays
    }

    return result
  },
  user: ({ user }, getters, rootState, rootGetters) =>
    rootGetters['companyUsers/referenceById'](user.userId),
  feature: () => features.checkFeature.bind(features),
  can: () => permissions.checkPermission.bind(permissions)
}

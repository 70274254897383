<template>
  <div v-if="modals.length" class="modal-root-overlay">
    <div class="stack-list" @click.self="onClose">
      <template v-for="(modal, i) in visibleModals">
        <component
          :is="modal.name"
          v-if="modal.name"
          :key="modal._uniqueId"
          :is-modal="true"
          v-bind="modal.params"
          :style="getStyle(i)"
          :class="[{ 'is-behind': visibleModals.length - 1 !== i }]"
          class="stack-item"
          @close="onClose"
          @id-change="
            $event =>
              i === visibleModals.length - 1 ? onIdChange($event, modal) : null
          "
        />
        <!-- idChange events are ONLY allowed from the last modal -->
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ModalView',
  components: {
    Project: () => import('@/views/account/project/Project.vue'),
    Document: () => import('@/views/account/document/Document.vue')
  },
  computed: {
    ...mapState('application', {
      canCloseModal: state => state.canCloseModal
    }),
    ...mapGetters({
      modals: 'application/modals'
    }),
    visibleModals() {
      return this.modals.slice(-2)
    }
  },
  watch: {
    modals(v, oldV) {
      if (!v.length) return
      const { href } = this.$router.resolve(v[v.length - 1])
      if (v.length === oldV.length) window.history.replaceState({}, '', href)
      else window.history.pushState({}, '', href)
    }
  },
  methods: {
    onClose() {
      if (!this.canCloseModal) return
      this.$store.dispatch('application/POP_MODAL')
      window.history.replaceState({}, '', this.$route.fullPath)
    },
    onIdChange(newId, modal) {
      const newModal = modal
      newModal.params.id = newId
      const { href: newHref } = this.$router.resolve(newModal)

      const newModals = [...this.modals.slice(0, -1), newModal]
      this.$store.commit('application/SET_MODALS', newModals)
      window.history.replaceState({}, '', newHref)
    },
    getStyle(i) {
      const length = this.visibleModals.length
      const index = i + 1 // Begins with count 1
      const style = {
        zIndex: 1000 + index
      }

      if (length === index) return style

      return {
        ...style,
        opacity: 0.75,
        transform: `translate3d(0%, 0%, ${-index * 10}px)`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-root-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.stack-list {
  perspective: 100px;
  perspective-origin: 50% -10%;
  position: relative;
  width: 100%;
  height: 100%;
  /* display: flex; */
  display: grid;
  grid-template-columns: 100vw 1fr;
  grid-template-rows: 100vh 1fr;
}

.stack-item {
  /* contain: layout; Disabled intentionally - Causing render issues and drag drop not working properly with it */
  margin: auto;
  backface-visibility: hidden;
  opacity: 1;
  transition:
    transform 200ms ease,
    opacity 200ms;
  grid-area: 1 / 1 / 2 / 2;
  &.is-behind ::v-deep * {
    opacity: 0;
  }
}
</style>

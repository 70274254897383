import config from '@cling/config'
import { brands } from '@cling/static'

export default {
  pandaDoc: 'PandaDoc',
  docuSign: 'DocuSign',
  qwilr: 'Qwilr',
  proposify: 'Proposify',
  helloSign: 'HelloSign',
  getAccept: 'GetAccept',
  ...(brands[config.brand].defaultRegion === 'SE'
    ? { oneflow: 'Oneflow', scrive: 'Scrive' }
    : { betterProposals: 'Better Proposals' }),
  other: '_common:other',
  none: '_common:none'
}

import { handleError } from '@cling/services/error'
import { addUniqueId } from '@cling/utils'

import Vue from 'vue'

const state = Vue.observable({
  messages: [],
  actions: {}
})

const addMessage = message => state.messages.push(addUniqueId(message))

export const clearMessage = index => {
  const message = state.messages[index]
  if (state.actions[message?._uniqueId]) delete state.actions[message._uniqueId]
  state.messages.splice(index, 1)
}

export const triggerMessageAction = ({ index, type }) => {
  try {
    const predefinedActions = {
      cancel: () => clearMessage(index)
    }

    const message = state.messages[index]
    if (!message || !message._uniqueId)
      throw Error('message not found, failed to trigger action')

    const actions = state.actions[message._uniqueId]
    if (!actions)
      throw Error('message has no actions, failed to trigger action')

    const action = actions[type]
    if (!action) throw Error(`message has no action of type ${type}`)

    let actionFunc = action

    if (typeof action === 'string') {
      actionFunc = predefinedActions[action]
      if (!actionFunc) throw Error(`No predefined action of type ${action}`)
    }

    if (typeof actionFunc !== 'function')
      throw Error('Unable to extract function from message action')

    return actionFunc(message)
  } catch (err) {
    return handleError(err)
  }
}

export const showMessage = payload => {
  const {
    type,
    displayType = 'notification',
    time = 5000,
    title,
    message,
    closeButton,
    omit = ''
  } = payload

  const messageAction = {}
  const actions = {}

  const ignoreMessage = str =>
    str.split(',').some(entry => {
      const [key = 'foo', value = 'bar'] = entry.split(':')
      return Vue.prototype[key] === value
    })
  if (ignoreMessage(omit)) return

  // Support actions provided in different ways
  if (payload.actions) {
    Object.keys(payload.actions).forEach(key => {
      const action = payload.actions[key]
      if (typeof action === 'object' && !Array.isArray(action)) {
        if (!action.fn && !action.callback)
          throw Error(`No callback function provided for action ${key}`)
        messageAction[key] = action.fn || action.callback
        delete action.fn
        delete action.callback
        actions[key] = action
      } else if (typeof action === 'string') {
        messageAction[key] = action
        actions[key] = {}
      } else if (typeof action === 'function') {
        messageAction[key] = action
        actions[key] = {}
      } else {
        throw Error(`Action, ${key} , must be one of: object|string|function`)
      }
    })
  }

  addMessage({
    actions,
    title,
    message,
    type,
    displayType,
    time,
    closeButton
  })

  const msg = state.messages.slice(-1)[0]
  state.actions[msg._uniqueId] = messageAction
}

export const snackMessage = (type, message) => showMessage({ type, message })

export const messageState = state

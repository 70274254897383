import clingapi from '@cling/api'

import { actionTypes, mutationTypes } from '../constants'

const { LOAD_EVENTS_FOR_PROJECT } = actionTypes

const { SET_EVENTS } = mutationTypes

export default {
  // Load all events for a specific project
  async [LOAD_EVENTS_FOR_PROJECT]({ commit }, { id }) {
    const { data: events } = await clingapi.get(`/project/${id}/event`)
    if (!events || events.length <= 0) {
      return false
    }
    commit(SET_EVENTS, {
      events
    })
    return true
  }
}

import { GOOGLE_FONTS_CSS_URL, PREVIEW_ATTRIBUTE_NAME } from './constants'

const getLinkId = family => `font-${family.replace(/\s+/g, '-').toLowerCase()}`

export const linkExists = (family, isPreview = false) => {
  const link = document.getElementById(getLinkId(family))
  return (
    link !== null &&
    link.getAttribute(PREVIEW_ATTRIBUTE_NAME) === isPreview.toString()
  )
}

export const createLink = (family, isPreview = false) => {
  const head = document.head || document.getElementsByTagName('head')[0]
  const link = document.createElement('link')
  link.id = getLinkId(family)
  link.setAttribute('rel', 'stylesheet')

  link.setAttribute(PREVIEW_ATTRIBUTE_NAME, isPreview.toString())
  let url = `${GOOGLE_FONTS_CSS_URL}?family=${family.replace(/\s/gi, '+')}`

  if (isPreview)
    url += `&text=${family}` // only load certain characters for preview
  else
    url +=
      ':100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic' // load all variants for active fonts
  // else url += `:${variants.join(',')}`; // load all variants for active fonts

  url += '&display=swap'

  link.setAttribute('href', url)

  head.appendChild(link)
  return link
}

export const removePreviewLinks = query => {
  const head = document.head || document.getElementsByTagName('head')[0]
  const _query = query || `link[${PREVIEW_ATTRIBUTE_NAME}="true"]`

  for (const el of Array.from(head.querySelectorAll(_query))) {
    el.remove()
  }
}

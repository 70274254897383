<template>
  <transition name="fade">
    <article
      v-if="legacy"
      :class="[`is-${type}`, `is-${size}`]"
      class="message"
    >
      <section :style="{ padding }" class="message-body">
        <header v-if="title" class="message-header">
          {{ title }}
        </header>
        <div class="media">
          <div class="media-content">
            <slot />
          </div>
        </div>
      </section>
      <CButton
        v-if="closable"
        :type="type"
        size="small"
        pattern="secondary"
        circle
        icon="x"
        style="
          right: calc(0.5 * var(--rem));
          position: absolute;
          top: calc(0.5 * var(--rem));
        "
        @click="$emit('close')"
      />
    </article>
    <div v-else class="flex rounded-md border border-gray-200 bg-gray-50 p-4">
      <div v-if="icon" class="-mt-px mr-3">
        <CIcon :type="icon" size="16" />
      </div>
      <div>
        <div>
          <slot name="title">
            <div class="font-medium">{{ title }}</div>
          </slot>
        </div>
        <div class="text-gray-800">
          <slot>
            <div class="mb-2 text-gray-500">{{ text }}</div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CButton from '@cling/components/ui/Button'

export default {
  name: 'CCallout',
  components: {
    CButton
  },
  props: {
    title: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    padding: {
      type: String,
      default: null
    },
    closable: {
      type: Boolean,
      default: false
    },
    legacy: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    }
  }
}
</script>
<style scoped lang="scss">
.message,
.notification {
  position: relative;
  .media {
    @apply text-gray-700;
    padding-top: 0;
    border: 0;
  }
  &.is-normal {
    padding: 0;
    font-size: 16px;
  }
  &.is-small {
    .message-body {
      padding: 1em;
      font-size: 14px;
    }
  }
  .message-header {
    font-weight: 600;
    padding-bottom: 0.25em;
  }
}
</style>

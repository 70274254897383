import lang from '@cling/language'

const ClientForm = () => import('@/views/account/clients/ClientForm.vue')

export default [
  {
    path: 'client',
    components: {
      content: {
        template: '<router-view />'
      }
    },
    children: [
      {
        name: 'clients',
        path: 'list',
        component: () => import('@/views/account/clients/ClientsList.vue'),
        meta: {
          navbar: {
            type: 'default',
            title: lang.t('client_plural', { postProcess: 'capitalize' }),
            classList: 'flat'
          },
          body: {
            background: 'white'
          }
        }
      },
      {
        path: 'new',
        name: 'clientFormNew',
        component: ClientForm,
        meta: {
          navbar: {
            title: lang.t('create', { thing: lang.t('client') }),
            type: 'simple'
          },
          body: {
            background: 'white',
            fullSize: true
          }
        }
      },
      // IMPORTANT NOTE: Needs to below as [:id] acts like a wildcard
      {
        name: 'client',
        path: ':id',
        component: () => import('@/views/account/clients/Client.vue'),
        meta: {
          navbar: {
            type: 'default',
            title: lang.t('client_plural', { postProcess: 'capitalize' }),
            classList: 'flat'
          }
        }
      },
      {
        path: ':id/edit',
        name: 'clientFormEdit',
        component: ClientForm,
        meta: {
          navbar: {
            title: lang.t('edit', { thing: lang.t('client') }),
            type: 'simple'
          },
          body: {
            background: 'white',
            fullSize: true
          }
        }
      }
    ]
  }
]

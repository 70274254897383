export const actionTypes = {
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  LOAD_UNREAD_NOTIFICATIONS: 'LOAD_UNREAD_NOTIFICATIONS',
  LOAD_NOTIFICATION: 'LOAD_NOTIFICATION',
  SET_READ_FOR_PROJECT: 'SET_READ_FOR_PROJECT',
  SET_NOTIFICATION_READ: 'SET_NOTIFICATION_READ',
  SET_ALL_NOTIFICATION_READ: 'SET_ALL_NOTIFICATION_READ',
  DO_CREATE_NOTIFICATION: 'DO_CREATE_NOTIFICATION'
}

export const mutationTypes = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  DELETE_MANY_NOTIFICATIONS: 'DELETE_MANY_NOTIFICATIONS',
  SET_NOTIFICATIONS_FETCHED_AT: 'SET_NOTIFICATIONS_FETCHED_AT',
  SET_NOTIFICATIONS_FETCHING: 'SET_NOTIFICATIONS_FETCHING',
  SET_NOTIFICATIONS_POSTING: 'SET_NOTIFICATIONS_POSTING'
}

import config from '@cling/config'
import { getType } from '@cling/utils'
import { isBefore } from '@cling/utils/date'

class Features {
  /**
   * Schema to define features and the keys
   * Each schema entry consists of rules:
   * accountTypes: Optional array if the company requires any specific accountType
   * featureKey: Optional string which key to map to on the companyFeatures model from backend
   * companySetting: Optional string to read from companySetting boolean (|| to use or)
   * companyUserSettingFn: Optional string to read from companySetting boolean
   * region: Optional string which company.region that is required
   * brand: Optional which brand that must be enabled
   */
  static schema = {
    personalMessage: {
      // accountTypes: ['trial', 'base', 'pro'],
    },
    unlimitedCertificates: {
      // accountTypes: ['trial', 'base', 'pro'],
    },
    companyAboutUs: {
      // accountTypes: ['trial', 'base', 'pro'],
    },
    winProfile: {
      // accountTypes: ['trial', 'base', 'pro'],
    },
    templates: {
      // accountTypes: ['trial', 'base', 'pro'],
    },
    task: {
      featureKey: 'task'
    },
    reverseVat: {
      featureKey: 'reverseVat' // company must have reverseVat enabled as feature
    },
    ataSetting: {
      featureKey: 'ataSetting'
    },
    propertyDesignation: {
      companySetting: 'showRot||showGreenRot' // Anyone one must be active
    },
    // Allows input for openAcc for: openAccManagementHourArticle, openAccWorkHourArticle,
    // openAccExtraEntrepreneurArticle, openAccExtraOtherPercentageArticle
    openAccConstruction: {
      featureKey: 'openAccConstruction'
    },
    docChapterIsBinding: {
      companySetting: 'showIsBinding'
    },
    projectSettingHideAllArticles: {
      companySetting: 'allowHideAllArticles'
    },
    docAtaDateDelay: {
      companySetting: 'docAtaShowDateDelay'
    },
    docAtaNotIncluded: {
      companySetting: 'docAtaShowNotIncluded'
    },
    docAtaPayment: {
      companySetting: 'docAtaShowPayment'
    },
    sms: {
      featureKey: 'sms'
    },
    smsSenderName: {
      featureKey: 'smsSenderName'
    },
    regionSE: {
      region: 'SE'
    },
    bankId: {
      region: 'SE',
      companySetting: 'enableBankId'
    },
    templateGallery: {
      companyUserSettingFn: {
        name: 'defaultLanguage', // user setting name
        fn: value => value === 'sv' // fn to evaluate setting value -> boolean
      }
    },
    clientReview: {
      brand: 'cling'
    },
    upgradeSignatureBankId: {
      companySetting: 'upgradeSignatureBankId'
    },
    // Created before release of Dynamic Documents 2 (only read node layout)
    preDynDoc2: {
      createdBefore: '2020-11-05T00:00:00.000Z'
    },
    emailDomain: {
      featureKey: 'emailDomain'
    },
    subdomain: {
      featureKey: 'subdomain'
    },
    stats: {
      featureKey: 'stats'
    },
    api: {
      featureKey: 'api'
    },
    fortnox: {
      featureKey: 'fortnox'
    },
    inviteCreators: {
      accountTypes: ['base', 'pro']
    },
    docBlock_header: { featureKey: 'docBlock_header' },
    docBlock_parties: { featureKey: 'docBlock_parties' },
    docBlock_packageGroup: { featureKey: 'docBlock_packageGroup' },
    docBlock_titleAndText2: { featureKey: 'docBlock_titleAndText2' },
    docBlock_coverBlock: { featureKey: 'docBlock_coverBlock' },
    docBlock_videoPlayer: { featureKey: 'docBlock_videoPlayer' },
    docBlock_attachments2: { featureKey: 'docBlock_attachments2' },
    docBlock_pdf: { featureKey: 'docBlock_pdf' },
    docBlock_embed: { featureKey: 'docBlock_embed' },
    docBlock_custom: { featureKey: 'docBlock_custom' },
    docBlock_terms: { featureKey: 'docBlock_terms' },
    docBlock_presentation: { featureKey: 'docBlock_presentation' },
    useDocTemplate: { featureKey: 'useDocTemplate' },
    createDocTemplate: { featureKey: 'createDocTemplate' }
  }

  init({ store }) {
    this.store = store
  }

  /**
   * Get all valid features from schema
   */
  getValidFeatures() {
    return Object.keys(Features.schema)
  }

  /**
   * Check if the authenticated user has a accountType that is included in the param
   * @param {String[]} accountTypes Array of accountTypes
   * @returns {Boolean} True if valid account
   */
  _isValidAccountType(accountTypes) {
    if (!accountTypes || getType(accountTypes) !== 'array')
      throw new Error('Invalid param accountTypes')
    const { accountType } =
      this.store.getters['application/companyAccount'] || {}

    return accountTypes.includes(accountType)
  }

  /**
   * Check if the authenticated user has a feature enabled on CompanyFeatures
   * @param {String} featureKey Key of the feature on CompanyFeatures
   * @returns {Boolean} True if feature is enabled on CompanyFeatures
   */
  _isValidFeatureKey(featureKey) {
    if (!featureKey || getType(featureKey) !== 'string')
      throw new Error('Invalid param featureKey')
    const { CompanyFeatures } = this.store.getters['application/company'] || {}

    // No features was found
    if (!CompanyFeatures) {
      return false
    }

    const hasFeature = CompanyFeatures[featureKey]
    return hasFeature
  }

  /**
   * Check if the authenticated user has a companySetting that is true
   * @param {String} settingKey Key of the CompanySetting
   * @returns {Boolean} True if the setting is true
   */
  _isValidCompanySetting(settingKey) {
    if (!settingKey || getType(settingKey) !== 'string')
      throw new Error('Invalid param settingKey')
    const settingsOrKeys = settingKey.split('||') // Optional to separate by || to use or

    return settingsOrKeys.some(key => {
      const value = this.store?.getters['settings/getCompanySetting'](key)
      return typeof value === 'boolean' && value
    })
  }

  /**
   * Check if the authenticated user has a companyUserSetting that matches fn
   * @param {Object} obj
   * @param {String} obj.name name of the user setting
   * @param {Function} obj.fn Function to evaluate the setting value
   * @returns {Boolean} True if valid
   */
  _isValidCompanyUserSettingFn({ name, fn }) {
    if (!name || getType(name) !== 'string')
      throw new Error('Invalid param name')
    if (!fn || getType(fn) !== 'function') throw new Error('Invalid param fn')
    const value = this.store.getters['settings/getCompanyUserSetting'](name)
    return !!fn(value)
  }

  /**
   * Check if the authenticated user has a valid region
   * @param {String} region The required region
   * @returns {Boolean} True if allowed region
   */
  _isValidRegion(requiredRegion) {
    if (!requiredRegion || getType(requiredRegion) !== 'string')
      throw new Error('Invalid param requiredRegion')
    const { region } = this.store.getters['application/company'] || {}
    if (!region) return false

    return requiredRegion === region
  }

  /**
   * Check if the current brand is valid
   * @param {String} brand The required brand
   * @returns {Boolean} True if allowed
   */
  _isValidBrand(requiredBrand) {
    if (!requiredBrand || getType(requiredBrand) !== 'string')
      throw new Error('Invalid param requiredBrand')
    if (!config?.brand) return false
    const { brand } = config.brand

    return requiredBrand === brand
  }

  _isCreatedBefore(dateString) {
    if (!dateString || getType(dateString) !== 'string')
      throw new Error('Invalid param feature dateString')
    const { createdAt } = this.store.getters['application/company'] || {}
    if (!createdAt) return false

    return isBefore(createdAt, dateString)
  }

  /**
   * The internal function used to evaluate if a specific feature is available or not
   * Note that as default a Boolean is returned, but returnType can be specified as array.
   * In that case the length of the array must be checked to decide if the feature should be enabled
   *
   * @param {String} feature
   * @param {Object} options Optional options object
   * @param {String} options.returnType Optional string of which returnType to use, defaults to boolean. Support 'boolean', or 'array'
   *
   * @returns {Boolean|String[]} Depending on the returnType:
   *                             boolean: True if the feature is enabled, or false.
   *                             array: Empty array if feature is enabled, or array with the invalid rule keys.
   */
  _evaluateFeature(feature, { returnType = 'boolean' } = {}) {
    const rules = Features.schema[feature]
    if (!rules || getType(rules) !== 'object')
      throw Error(`No rules for feature '${feature}' has been defined!`)
    if (!['boolean', 'array'].includes(returnType))
      throw Error('Invalid param returnType')

    // Prepare array to store invalid keys if returnType is 'array'
    const invalidKeys = []

    // AccountType: If the feature requires any specific CompanyAccount.accountType
    if (rules.accountTypes && !this._isValidAccountType(rules.accountTypes)) {
      invalidKeys.push('accountTypes')
      if (returnType === 'boolean') return false
    }

    // FeatureKey: If the feature requires any specific CompanyFeature to be enabled from backend
    if (rules.featureKey && !this._isValidFeatureKey(rules.featureKey)) {
      invalidKeys.push('featureKey')
      if (returnType === 'boolean') return false
    }

    // CompanySetting: If the feature requires any specific CompanySetting to be enabled from backend
    if (
      rules.companySetting &&
      !this._isValidCompanySetting(rules.companySetting)
    ) {
      invalidKeys.push('companySetting')
      if (returnType === 'boolean') return false
    }
    // companyUserSettingFn: If the feature requires any specific CompanyUserSetting
    if (
      rules.companyUserSettingFn &&
      !this._isValidCompanyUserSettingFn(rules.companyUserSettingFn)
    ) {
      invalidKeys.push('companyUserSettingFn')
      if (returnType === 'boolean') return false
    }

    // Region: If the feature requires any specific company.region
    if (rules.region && !this._isValidRegion(rules.region)) {
      invalidKeys.push('region')
      if (returnType === 'boolean') return false
    }

    // Brand: If the feature requires any specific brand
    if (rules.brand && !this._isValidBrand(rules.brand)) {
      invalidKeys.push('brand')
      if (returnType === 'boolean') return false
    }

    if (rules.createdBefore && !this._isCreatedBefore(rules.createdBefore)) {
      invalidKeys.push('preDynDoc2')
      if (returnType === 'boolean') return false
    }

    if (returnType === 'array') {
      return invalidKeys
    }
    return true
  }

  /**
   * Check if user has feature
   *
   * @param {String} feature
   *
   * @returns {Boolean} True if the feature is enabled
   */
  checkFeature(feature) {
    return this._evaluateFeature(feature)
  }

  /**
   * Check if a feature could be accessed if user would upgrade accountType
   *
   * @param {String} feature
   * @returns {Boolean} True if the feature currently is disabled only due to missing accountType, otherwise false
   */
  checkFeatureUpgrade(feature) {
    const invalidKeys = this._evaluateFeature(feature, { returnType: 'array' })
    // If accountType is the one and only key in invalidKeys, then upgrade would enable this feature
    return invalidKeys.length === 1 && invalidKeys.includes('accountTypes')
  }
}

export default new Features()

<template>
  <div class="events-wrapper" style="padding-bottom: 8rem">
    <EventItem
      v-for="(event, i) in eventItems"
      :key="i"
      :title="event.title"
      :template="event.template"
      :content="event.content"
      :created-at="event.timestamp"
      :icon="event.icon"
      :color="event.color"
      :is-future="event.isFuture"
      :loading="event.loading"
    />
  </div>
</template>

<script>
import { EventItem } from '@cling/components/timeline'
import { sortCompareFunction } from '@cling/utils'
import { mapGetters } from 'vuex'

export default {
  i18nOptions: {
    namespaces: 'projectEvents',
    messages: {
      en: {
        byName: 'By {{- name}}',
        toEmail: 'To: {{email}}',
        withBankId: '{{content}} with BankID',
        createdTitle: 'Project created',
        viewedTitle: '<div>Client viewed {{- link}}</div>',
        viewedPrefix1: '{{name}} has viewed document',
        viewedPrefix2: 'Has been viewed',
        acceptedTitle: '<div>{{- link}} was accepted!</div>',
        deniedTitle: '<div>{{- link}} was denied</div>',
        sentTitle: '<div>{{prefix}} was sent</div>',
        sentTitlePropertyDesignation:
          '<div>Request for property designation was sent</div>',
        statusChangedTitle: '<div>{{- link}} was now {{text}}!</div>\n',
        requestChangeTitle:
          '<div>{{- link}} was manually marked as {{text}}</div>',
        mailDeliveredTitle: 'client received email ',
        mailOpenedTitle: 'client viewed email',
        mailOpenedPrefix1: 'Email viewed',
        mailOpenedPrefix2: '{{email}} viewed email',
        mailDroppedTitle: 'Could not send email',
        smsDroppedTitle: 'Could not send SMS',
        scheduledTitle: '<div>{{- link}} will be sent{{types}}</div>',
        expiredTitle: '<div>{{- link}} has expired</div>',
        expiredDescription: 'The expiration date has passed'
      },
      sv: {
        byName: 'Av {{- name}}',
        toEmail: 'Till: {{email}}',
        withBankId: '{{content}} med BankID',
        createdTitle: 'Projekt skapat',
        viewedTitle: '<div>Kunden tittade på {{- link}}</div>',
        viewedPrefix1: '{{name}} har visat dokumentet',
        viewedPrefix2: 'Har visats',
        acceptedTitle: '<div>{{- link}} godkändes!</div>',
        deniedTitle: '<div>{{- link}} nekades</div>',
        sentTitle: '<div>{{prefix}} skickades</div>',
        sentTitlePropertyDesignation:
          '<div>Begäran om fastighetsbeteckning skickades</div>',
        statusChangedTitle: '<div>{{- link}} blev nu {{text}}!</div>',
        requestChangeTitle:
          '<div>{{- link}} markerades manuellt som {{text}}</div>',
        mailDeliveredTitle: 'Kunden tog emot mailet',
        mailOpenedTitle: 'Kunden tittade på mailet',
        mailOpenedPrefix1: 'Mailet har visats',
        mailOpenedPrefix2: '{{email}} har visat mailet',
        mailDroppedTitle: 'E-post kunde inte skickas',
        smsDroppedTitle: 'SMS kunde inte skickas',
        scheduledTitle: '<div>{{- link}} kommer att skickas{{types}}</div>',
        expiredTitle: '<div>{{- link}} förföll</div>',
        expiredDescription: 'Giltighetstiden har passerats'
      }
    }
  },
  name: 'ProjectEvents',
  components: {
    EventItem
  },
  props: {
    project: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      userById: 'companyUsers/byId'
    }),
    projectCreatedAt() {
      return this.project ? this.project.getProperty('createdAt') : ''
    },
    documents() {
      return this.project ? this.project.documents : null
    },
    documentEvents() {
      const result = []
      if (!this.documents) return result
      this.documents.forEach(doc =>
        result.push(
          ...doc.events.map(event => ({
            ...event,
            docName: doc.name,
            id: doc.id,
            ...(event.code === 'statusChanged' &&
              [''].includes(event.data.status) && {
                nrOfRequiredAnswers: doc.clients.reduce(
                  (res, client) =>
                    ['signee', 'approver'].includes(client.documentRole)
                      ? res + 1
                      : res,
                  0
                )
              })
          }))
        )
      )
      return result
    },
    // TODO: project status change etc, when supported backend
    scheduledDocuments() {
      const result = []
      if (!this.documents || !this.documents.length) return result
      this.documents.forEach(doc => {
        const { scheduledFirstDelivery } = doc
        if (
          scheduledFirstDelivery &&
          scheduledFirstDelivery.status === 'active'
        )
          result.push(doc)
      })
      return result
    },
    eventItems() {
      const arr = []
      const eventsData = this.documentEvents
      if (!(eventsData && eventsData.length > 1) && this.loading) {
        return [
          { loading: true, isFuture: true },
          { loading: true, isFuture: true }
        ]
      }
      if (!eventsData.find(event => event.code === 'created')) {
        eventsData.splice(0, 0, {
          code: 'created',
          createdAt: this.projectCreatedAt
        })
      }
      if (!this.project) return arr

      eventsData.forEach(item => {
        let { data = {} } = item
        if (data === null) data = {} // Convert null to empty object to support destructuring

        const {
          client = {},
          clients,
          email,
          status,
          counter,
          sendSource,
          companyUserId
        } = data
        const creatorName = this.getUsername(this.project.creatorId)
        const link = this.getDocPreviewLink({ id: item.id })

        // switch (item.code) {
        const mapObject = {
          created: () => ({
            title: this.$t('createdTitle'),
            content: creatorName
              ? this.$t('byName', { name: creatorName })
              : null,
            timestamp: item.createdAt,
            borderColor: 'orange',
            icon: 'folder',
            color: 'purple'
          }),
          viewed: () => {
            // Counter only used for old migrated documents
            const count = counter || item.children.length + 1
            const prefix = client.name
              ? this.$t('viewedPrefix1', { name: client.name })
              : this.$t('viewedPrefix2')
            return {
              template: this.$t('viewedTitle', {
                link: this.getDocPreviewLink({ id: item.id, capitalize: false })
              }),
              content: `${prefix} ${
                count ? this.$tc('_common:times', { count }) : ''
              }`,
              timestamp: item.createdAt,
              icon: 'eye',
              color: 'blue'
            }
          },
          accepted: () => {
            const result = {
              template: this.$t('acceptedTitle', { link }),
              content: client.name
                ? this.$t('byName', { name: client.name })
                : null,
              icon: 'check',
              color: 'green'
            }
            // Adds bankId as method if answered with bankId
            if (
              result.content &&
              client.answerMethod &&
              client.answerMethod.accept === 'bankId'
            ) {
              result.content = this.$t('withBankId', {
                content: result.content
              })
            }
            return result
          },
          denied: () => ({
            template: this.$t('deniedTitle', { link }),
            content: client.name
              ? this.$t('byName', { name: client.name })
              : null,
            timestamp: item.createdAt,
            icon: 'x',
            color: 'red'
          }),
          sent: () => {
            let reminder = ''
            reminder = `${
              sendSource === 'reminder'
                ? this.$t('_common:reminder')
                : this.$t('_common:theDocument')
            } `.capitalize()

            return {
              template:
                sendSource !== 'propertyDesignation'
                  ? this.$t('sentTitle', { prefix: reminder })
                  : this.$t('sentTitlePropertyDesignation'),
              timestamp: item.createdAt,
              icon: reminder ? 'bell' : 'send',
              color: 'teal',
              content: (() => {
                let content = ''
                if (clients && clients.length) {
                  content = clients.reduce((res, cl) => {
                    let deliveryString = ''
                    if (cl.email) deliveryString += `${cl.email}`
                    if (cl.cellphone) {
                      if (cl.email)
                        deliveryString += ` ${this.$t('_common:and')} `
                      deliveryString += cl.cellphone
                    }
                    if (!deliveryString) return res

                    if (!res)
                      res += this.$t('toEmail', { email: deliveryString })
                    else res += `<div>${deliveryString}</div>`
                    return res
                  }, '')
                }
                return content
              })()
            }
          },
          statusChanged: () => {
            if (!['accepted', 'denied'].includes(status)) return null
            const statusText = this.$t(`_common:status.${status}`).toLowerCase()
            // const statusText = status === 'accepted' ? 'godkänt' : 'nekat';
            return {
              template: this.$t('statusChangedTitle', {
                link,
                text: statusText
              }),
              timestamp: item.createdAt,
              icon: status === 'accepted' ? 'check' : 'x',
              color: status === 'accepted' ? 'green' : 'red'
            }
          },
          requestChange: () => {
            const username = this.getUsername(companyUserId)
            const statusText = this.$t(`_common:status.${status}`).toLowerCase()
            return {
              template: this.$t('requestChangeTitle', {
                link,
                text: statusText
              }),
              content: username
                ? this.$t('byName', { name: username })
                : username,
              timestamp: item.createdAt,
              icon: 'tool',
              color: 'gray'
            }
          },
          mailDelivered: () => {
            let receiverEmail = email ? this.$t('toEmail', { email }) : null
            // If email doesn't exist in upper scope (migrated docs) fetch from clients
            if (!email && clients && clients.length) {
              const { email: clientEmail } = clients.find(cl => cl.email) || {}
              if (clientEmail)
                receiverEmail = this.$t('toEmail', { email: clientEmail })
            }
            return {
              title: this.$t('mailDeliveredTitle'),
              content: receiverEmail,
              timestamp: item.createdAt,
              icon: 'inbox',
              color: 'teal'
            }
          },
          mailOpened: () => {
            const count = counter || item.children.length + 1
            let prefix = this.$t('mailOpenedPrefix1')
            if (email) prefix = this.$t('mailOpenedPrefix2', { email })
            else if (clients && clients.length) {
              // Fetch email from clients if it doesn't exist in upper scope (migrated docs)
              const { email: clientEmail } = clients.find(c => c.email) || {}
              if (clientEmail)
                prefix = this.$t('mailOpenedPrefix2', { email: clientEmail })
            }

            return {
              title: this.$t('mailOpenedTitle'),
              content: `${prefix} ${
                count ? this.$tc('_common:times', { count }) : ''
              }`,
              timestamp: item.createdAt,
              icon: 'email',
              color: 'teal'
            }
          },
          mailDropped: () => ({
            title: this.$t('mailDroppedTitle'),
            content: email ? this.$t('toEmail', { email }) : null,
            timestamp: item.createdAt,
            icon: 'alert-triangle',
            color: 'orange'
          }),
          smsDropped: () => ({
            title: this.$t('smsDroppedTitle'),
            content: item.data
              ? this.$t('toEmail', { email: item.data })
              : null,
            timestamp: item.createdAt,
            icon: 'alert-triangle',
            color: 'orange'
          }),
          expired: () => ({
            template: this.$t('expiredTitle', { link }),
            content: this.$t('expiredDescription'),
            timestamp: item.createdAt,
            icon: 'x',
            color: 'red'
          })
        }

        if (!mapObject[item.code]) return
        arr.push(mapObject[item.code]())
      })

      // Adds scheduled documents and types of deliveries
      if (this.scheduledDocuments.length) {
        this.scheduledDocuments.forEach(doc => {
          const { scheduledFirstDelivery } = doc
          let deliveryTypes = ''
          scheduledFirstDelivery.deliveryTypes.forEach(type => {
            // TODO
            if (!deliveryTypes) {
              deliveryTypes = ` ${this.$t('_common:via')} ${type}`
            } else {
              deliveryTypes = `${deliveryTypes} ${this.$t(
                '_common:and'
              )} ${type}`
            }
          })
          arr.push({
            template: this.$t('scheduledTitle', {
              link: this.getDocPreviewLink({ id: doc.id }),
              types: deliveryTypes
            }),
            content: this.$formatDate(scheduledFirstDelivery.sendAt),
            isFuture: true,
            icon: 'calendar',
            color: 'gray'
          })
        })
      }

      arr.sort((a, b) => sortCompareFunction(a.timestamp, b.timestamp))

      return arr
    }
  },
  methods: {
    // Generate router link template for document preview
    // ! OBS: Make sure any actual user input is escaped before using as arg in to this method to avoid XSS vulnerabilities!
    getDocPreviewLink({ id, capitalize = true } = {}) {
      return `
        <router-link style="color: inherit;" :to="{ name: 'document', params: { id: '${id}' } }">
          ${
            capitalize
              ? this.$t('_common:theDocument').capitalize()
              : this.$t('_common:theDocument')
          }
        </router-link>
      `
    },
    getUsername(id) {
      const user = this.userById(id)
      return user ? user.fullName : null
    }
  }
}
</script>

import Template from '@cling/models/template'

export default {
  byId: state => id => {
    if (!state.data[id]) return null
    return new Template(state.data[id])
  },
  idList: state => state.ids,
  isPosting: state => state.isPosting,
  isFetching: state => state.isFetching,
  hiddenTemplateIds: (state, getters, rootState, rootGetters) =>
    rootGetters['settings/getCompanySetting']('templateHiddenIds') || [],
  // Get available not starred templates
  availableTemplates: (state, getters) => {
    const { idList: allTemplateIds, hiddenTemplateIds } = getters
    const ids = allTemplateIds.filter(id => !hiddenTemplateIds.includes(id))
    return ids.map(id => new Template(state.data[id]))
  },
  hiddenTemplates: (state, getters) => {
    const { idList: allTemplateIds, hiddenTemplateIds } = getters
    const ids = hiddenTemplateIds.filter(id => allTemplateIds.includes(id))
    return ids.map(id => new Template(state.data[id]))
  }
}

<template>
  <div
    class="flex max-h-[300px] w-64 flex-col overflow-hidden rounded-lg border border-solid border-slate-200 bg-white text-base text-[#262626] shadow-lg"
  >
    <template v-if="items.length">
      <div class="px-4 py-3 text-sm font-semibold">
        {{ $t('referenceTooltipTitle') }}
      </div>
      <div class="overflow-auto">
        <div
          v-for="(item, index) in items"
          ref="mention"
          :key="item.id + index"
          :class="{
            'bg-[hsl(var(--primary-color-500)/0.2)]': navigatedIndex === index,
            'mb-3': index == items.length - 1
          }"
          class="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap px-4 py-1 hover:bg-[hsl(var(--primary-color-500)/0.1)]"
          @mousedown.prevent
          @click="selectItem(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </template>
    <div v-else class="px-4 py-2 opacity-50">
      {{ $t('_common:noResults') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextEditorMentionList',
  i18nOptions: {
    namespaces: 'TextEditorMentionList',
    messages: {
      en: {
        referenceTooltipTitle: 'Reference info from...'
      },
      sv: {
        referenceTooltipTitle: 'Referera till info från...'
      }
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    command: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      navigatedIndex: 0
    }
  },
  watch: {
    items() {
      this.navigatedIndex = 0
    }
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },
    upHandler() {
      this.navigatedIndex =
        (this.navigatedIndex + this.items.length - 1) % this.items.length
      this.scrollToHighlightedMention()
    },
    downHandler() {
      this.navigatedIndex = (this.navigatedIndex + 1) % this.items.length
      this.scrollToHighlightedMention()
    },
    scrollToHighlightedMention() {
      if (!this.$refs.mention || !this.$refs.mention[this.navigatedIndex])
        return
      this.$refs.mention[this.navigatedIndex].scrollIntoView({
        block: 'nearest'
      })
    },
    enterHandler() {
      const item = this.items[this.navigatedIndex]
      if (item) this.selectItem(item)
    },
    selectItem(item) {
      this.command(item)
    }
  }
}
</script>

import {
  deleteEndCustomer,
  getEndCustomer,
  getEndCustomers,
  patchEndCustomer,
  postEndCustomer,
  putEndCustomer
} from '@cling/api'
import { viewContext } from '@cling/globalState'
import lang from '@cling/language'
import globalMutationTypes from '@cling/store/mutation-types'
import { filterAllowedProperties } from '@cling/utils'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'endCustomers'

const {
  SET_ENDCUSTOMER_FETCHING,
  SET_ENDCUSTOMER_POSTING,
  CLEAR_END_CUSTOMER
} = mutationTypes

const {
  LOAD_ENDCUSTOMERS,
  LOAD_ENDCUSTOMER,
  UPDATE_ENDCUSTOMER,
  CREATE_ENDCUSTOMER,
  DELETE_ENDCUSTOMER,
  PATCH_ENDCUSTOMER,
  FORM_NEW_ENDCUSTOMER,
  FORM_EDIT_ENDCUSTOMER,
  FORM_SUBMIT_ENDCUSTOMER
} = actionTypes

export default {
  /**
   * @name LOAD_ENDCUSTOMERS
   *  Load all endCustomers
   * @param {Object} Vuex object
   *
   * @returns {Promise<Number>} Resolves with length of resulting endCustomers if successful, otherwise false
   */
  async [LOAD_ENDCUSTOMERS]({ commit }, { options = {} } = {}) {
    try {
      commit(SET_ENDCUSTOMER_FETCHING, true)

      const params = {
        ...(typeof options.start === 'number' && { _start: options.start }),
        ...(options.end && { _end: options.end }),
        ...(options.sort && { _sort: options.sort }),
        ...(options.order && { _order: options.order })
      }
      if (options.searchQuery.trim()) {
        // backend throws error if string starts with whitespace
        const query = encodeURIComponent(options.searchQuery)
        params._where = `or(name*=${query},email*=${query},cellphone*=${query})`
      }

      if (options.clearOld) commit(CLEAR_END_CUSTOMER)
      const { data } = await getEndCustomers(params)
      commit(mutationTypes.SET_ENDCUSTOMERS, { data })

      return data.length
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        fallbackCode: 'endCustomer.getAll',
        action: `${moduleName}/${LOAD_ENDCUSTOMERS}`
      })
      return false
    } finally {
      commit(SET_ENDCUSTOMER_FETCHING, false)
    }
  },

  /**
   * @name LOAD_ENDCUSTOMER
   *  Load one endCustomer by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the endCustomer
   */
  async [LOAD_ENDCUSTOMER]({ commit }, { id }) {
    try {
      commit(SET_ENDCUSTOMER_FETCHING, true)
      const { data } = await getEndCustomer(id)
      commit(mutationTypes.SET_ENDCUSTOMERS, { data })
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        objectId: id,
        fallbackCode: 'endCustomer.get',
        action: `${moduleName}/${LOAD_ENDCUSTOMER}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_ENDCUSTOMER_FETCHING, false)
    }
  },

  /**
   * @name CREATE_ENDCUSTOMER
   * Create one
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Object} object.body endCustomer body
   * @returns {Promise<Number>} Promise that resolves with new endCustomer id or null
   */
  async [CREATE_ENDCUSTOMER]({ commit }, { body, showNotification = true }) {
    try {
      commit(SET_ENDCUSTOMER_POSTING, true)
      const { data } = await postEndCustomer(body)
      commit(mutationTypes.SET_ENDCUSTOMERS, { data })

      if (showNotification && viewContext.value !== 'widget') {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('createdThing', { thing: lang.t('client') })
        })
      }

      return data.id
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        fallbackCode: 'endCustomer.post',
        action: `${moduleName}/${CREATE_ENDCUSTOMER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_ENDCUSTOMER_POSTING, false)
    }
  },

  /**
   * @name UPDATE_ENDCUSTOMER
   *  Update one endCustomer by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the endCustomer
   * @param {Object} object.body endCustomer data
   * @returns {Promise<Number>} Promise that resolves with endCustomer id or null
   */
  async [UPDATE_ENDCUSTOMER]({ dispatch, commit }, { id, body }) {
    try {
      commit(SET_ENDCUSTOMER_POSTING, true)
      await putEndCustomer(id, body)
      await dispatch(LOAD_ENDCUSTOMER, {
        id
      })

      if (viewContext.value !== 'widget') {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('updatedThing', { thing: lang.t('client') })
        })
      }
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        objectId: id,
        fallbackCode: 'endCustomer.put',
        action: `${moduleName}/${UPDATE_ENDCUSTOMER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_ENDCUSTOMER_POSTING, false)
    }
  },

  /**
   * @name DELETE_ENDCUSTOMER
   * Destroy one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the endCustomer
   * @returns {Promise<Number>} Promise that resolves with endCustomer id or null
   */
  async [DELETE_ENDCUSTOMER]({ commit }, { id }) {
    try {
      commit(SET_ENDCUSTOMER_POSTING, true)
      await deleteEndCustomer(id)
      commit(mutationTypes.DELETE_ENDCUSTOMER, id)
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', { thing: lang.t('client') }),
        actions: {
          undo: () => {
            this.dispatch(`endCustomers/${PATCH_ENDCUSTOMER}`, { id })
          }
        }
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        objectId: id,
        fallbackCode: 'endCustomer.delete',
        action: `${moduleName}/${DELETE_ENDCUSTOMER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_ENDCUSTOMER_POSTING, false)
    }
  },

  /**
   * @name PATCH_ENDCUSTOMER
   * Patch one by id
   * @param {Number} id Numeric id of the endCustomer
   * @returns {Promise<Number>} Promise that resolves with endCustomer id or null
   */
  async [PATCH_ENDCUSTOMER]({ dispatch, commit }, { id }) {
    try {
      commit(SET_ENDCUSTOMER_POSTING, true)
      await patchEndCustomer(id)
      await dispatch(LOAD_ENDCUSTOMER, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        objectId: id,
        fallbackCode: 'endCustomer.patch',
        action: `${moduleName}/${PATCH_ENDCUSTOMER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_ENDCUSTOMER_POSTING, false)
    }
  },

  /**
   * @name FORM_NEW_ENDCUSTOMER
   *  Prepare new form
   */
  async [FORM_NEW_ENDCUSTOMER]({ getters }) {
    try {
      const formData = {
        id: null,
        customerType: getters.defaultClientType(),
        org_no: null,
        name: null,
        reference: null,
        email: null,
        street: null,
        zip: null,
        city: null,
        cellphone: null,
        cellphoneRegion: null,
        separateWorkAddress: false,
        workStreet: null,
        workZip: null,
        workCity: null,
        reverseVat: false,
        vatNumber: null,
        externalId: null,
        note: null
      }
      this.commit(`forms/${globalMutationTypes.SET_FORM}`, {
        key: 'endCustomer',
        formData
      })
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        action: `${moduleName}/${FORM_NEW_ENDCUSTOMER}`
      })
      return false
    }
  },

  /**
   * @name FORM_EDIT_ENDCUSTOMER
   *  Prepare edit form
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the endCustomer
   * @param {String[]} object.props Optional array of strings which props to load into store
   */

  async [FORM_EDIT_ENDCUSTOMER]({ getters }, { id, props = [] }) {
    const value = filterAllowedProperties(getters.endCustomerById(id), props)
    this.dispatch(`forms/${globalMutationTypes.forms.SET_FORM_VALUE}`, {
      key: 'endCustomer',
      value
    })
  },

  /**
   * @name FORM_SUBMIT_ENDCUSTOMER
   *  Submit form
   * @param {Object} Vuex object
   * @returns {Number|null} Numeric id of the endCustomer or null
   */
  async [FORM_SUBMIT_ENDCUSTOMER](
    { rootGetters, dispatch },
    { showNotification = true } = {}
  ) {
    try {
      const formData = rootGetters['forms/getFormByKey']('endCustomer')
      let { id } = formData
      if (id) {
        await dispatch(UPDATE_ENDCUSTOMER, {
          id: formData.id,
          body: formData
        })
      } else {
        id = await dispatch(CREATE_ENDCUSTOMER, {
          body: formData,
          showNotification
        })
      }
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'endCustomer',
        fallbackCode: 'endCustomer.submit',
        action: `${moduleName}/${FORM_SUBMIT_ENDCUSTOMER}`
      })
      return null
    }
  }
}

import features from '@cling/services/features'
import { getPropertyFromPath } from '@cling/utils'

class Permissions {
  // Schema to define permission scope and permission keys
  static schema = {
    projects: {
      read: 'manageProjects',
      modify: 'manageProjects',
      create: ['manageProjects', 'manageOwnProjects'],
      delete: 'manageProjects',
      readPrice: ['manageProjects', 'showPrices'],
      $scopeKey: 'projects',
      $module: 'projects',
      $id: 'CompanyUserId'
    },
    users: {
      modify: 'manageUsers',
      create: 'manageUsers',
      $scopeKey: 'users',
      $module: 'companyUsers',
      $id: 'id'
    },
    // Important note: As we currently map document permissions to projects there is a manual re-mapping
    // done later in this file, see "Note 1" comment, which must be updated if doc permissions below if changed.
    documents: {
      create: ['manageProjects', 'manageOwnProjects'],
      read: 'manageProjects',
      modify: 'manageProjects',
      delete: 'manageProjects',
      readPrice: ['manageProjects', 'showPrices'],
      $scopeKey: 'projects',
      $module: 'documents2',
      $id: 'companyUserId'
    },
    atas: {
      create: ['manageAtas', 'manageOwnAtas'],
      read: 'manageAtas',
      modify: 'manageAtas',
      delete: 'manageAtas',
      readPrice: ['manageAtas', 'showPrices'],
      $scopeKey: 'atas',
      $module: 'documents',
      $id: 'data.CompanyUserId'
    },
    company: {
      create: 'manageCompany',
      read: 'manageCompany',
      modify: 'manageCompany',
      $scopeKey: 'company',
      $module: 'companies',
      $id: 'id'
    },
    articles: {
      create: ['manageProjects', 'manageOwnProjects'],
      modify: ['manageProjects', 'manageOwnProjects'],
      delete: ['manageProjects', 'manageOwnProjects'],
      readPrice: [
        'manageOwnAtas',
        'manageAtas',
        'manageProjects',
        'manageOwnProjects',
        'showPrices'
      ],
      $scopeKey: 'projects',
      $module: 'articles',
      $id: 'id'
    },
    prices: {
      read: 'showPrices'
    },
    // Document templates
    templates: {
      create: 'createTemplates',
      read: 'readTemplates',
      modify: 'modifyTemplates',
      delete: 'deleteTemplates'
    },
    endCustomers: {
      read: ['readEndCustomers', 'readOwnEndCustomers'],
      modify: 'modifyEndCustomers',
      create: 'createEndCustomers',
      delete: 'deleteEndCustomers',
      $scopeKey: 'endCustomers',
      $module: 'endCustomers',
      $id: 'CompanyUserId',
      $allowNullOwner: true // If endCustomer.CompanyUserId null should be allowed to manage
    },
    fortnox: {
      export: 'manageCompany'
    }
  }

  // Schema to define which permission scope that required any specific features from features service
  // Specify on the format:
  // permissionScope: 'feature.schemaKey'
  static featureSchema = {}

  init({ store }) {
    this.store = store
  }

  /**
   * Get an object by id and scope
   * @param {String|Number} id Object id to get
   * @param {String} scope Scope of object id, ex. projects, atas
   */
  _getObjectById(id, scope) {
    const moduleKey = (Permissions.schema[scope] || {}).$module
    const { data: objects } = this.store.state[moduleKey] || {}

    if (!objects) {
      throw Error(`No module for ${scope} has been defined!`)
    }

    return objects[id]
  }

  /**
   * Get id path from schema. ex data.CompanyUserId
   * @param {String} scope Scope to get id path from
   */
  _getIdPath(scope) {
    return (Permissions.schema[scope] || {}).$id
  }

  /**
   * Get if owner id null should be considered valid or not.
   * Used in scenarios like endCustomer.CompanyUserId: null, should a user be able to edit if null?
   * @param {String} scope Scope to get id path from
   * @returns {Boolean} True if null should be considered valid
   */
  _getAllowNullOwner(scope) {
    return !!(Permissions.schema[scope] || {}).$allowNullOwner
  }

  /**
   * Get scope key from schema. ex projects
   * Its used to convert ex. manageProjects to manageOwnProjects,
   * when "virtual" scope is different.
   * For instance: scope documents will need permission
   * manageProjects or manageOwnProjects, then we need the scopeKey to convert
   * manageProjects to manageOwnProjects.
   * @param {String} scope Scope to get id path from
   */
  _getScopeKey(scope) {
    return (Permissions.schema[scope] || {}).$scopeKey
  }

  /**
   * Get current logged in user
   */
  _getUserId() {
    const { user = {} } = this.store.state.application
    return user.userId
  }

  /**
   * Get all valid scopes from schema
   */
  getValidScopes() {
    return Object.keys(Permissions.schema)
  }

  /**
   * Get all allowed permission for a certain scope
   * @param {String} scope
   * @param {String} permission
   */
  getRequiredCompanyUserPermission(scope, permission) {
    const permissions = Permissions.schema[scope]

    if (!permissions || !permissions[permission]) return []

    return Array.isArray(permissions[permission])
      ? permissions[permission]
      : [permissions[permission]]
  }

  /**
   * Check if the scope has any required feature, and if so if the current user has the feature enabled
   * @param {String} scope
   *
   * @returns {Boolean} Returns true if there is no feature or if the user has the feature enabled, otherwise false
   */
  hasFeature(scope) {
    const feature = Permissions.featureSchema[scope]
    // If this scope does not require any specific feature
    if (!feature) return true

    const hasFeature = features.checkFeature(feature)
    return hasFeature
  }

  /**
   * Check if user has permission in scope
   * An optional id param can be supplied if we also should check user creator of object
   * and can view if owner
   *
   * @param {String} scope
   * @param {String} permission
   * @param {String|Number} id
   */
  checkPermission(scope, permission, id = undefined) {
    const { getters } = this.store
    const { CompanyUserPermission } = getters['application/user'] || {}
    if (!CompanyUserPermission) {
      return false
    }

    // Check if any feature is required
    const hasFeature = this.hasFeature(scope)
    if (!hasFeature) {
      return false
    }

    let allowedPermissions = this.getRequiredCompanyUserPermission(
      scope,
      permission
    )

    if (!allowedPermissions || allowedPermissions.length === 0) {
      // eslint-disable-next-line no-console
      console.warn(
        `Permission ${permission} in scope ${scope} has not been implemented`
      )
      return false
    }

    let hasPermission = allowedPermissions.some(
      x => CompanyUserPermission[x] === true
    )
    // Since the user has global permission, no need to check individual objects.
    if (hasPermission) {
      return true
    }
    // No id provided, no need to continue
    if (!id) {
      return false
    }

    const scopeKey = this._getScopeKey(scope)
    allowedPermissions = allowedPermissions.map(perm => {
      if (perm.includes('Own')) return perm

      return perm.replace(
        `${scopeKey[0].toUpperCase()}${scopeKey.slice(1)}`,
        `Own${scopeKey[0].toUpperCase()}${scopeKey.slice(1)}`
      )
    })

    hasPermission = allowedPermissions.some(
      x => CompanyUserPermission[x] === true
    )

    if (hasPermission) {
      const object = this._getObjectById(id, scope)
      if (!object) return false

      // Note 1: Check for project instead due to that we currently map document permissions to project permissions
      if (scope === 'documents')
        return this.checkPermission('projects', permission, object.projectId)

      const creatorId = getPropertyFromPath(object, this._getIdPath(scope))
      const allowNull = this._getAllowNullOwner(scope)
      return (
        creatorId === this._getUserId() || (allowNull && creatorId === null)
      )
    }

    return false
  }

  /**
   * Get first matched scope from modifiers object
   * @param {String} modifiers Modifiers object from vue directives
   */
  getScope(modifiers = {}) {
    const validScopes = this.getValidScopes()

    const scope = validScopes.find(validScope => modifiers[validScope])

    return scope
  }
}

export default new Permissions()

import { doRefreshToken } from '@cling/api'
import socket from '@cling/api/socket'
import { global } from '@cling/store/action-types'
import mutationTypes from '@cling/store/mutation-types'

// This Vuex plugin is used to trigger a socket communication to ClingAPI when the user is logged in
// and to listen on socket events to reload or invalidate store data when a new version of the data exists

export default store => {
  socket.onConnectionChanged(isConnected => {
    store.commit(
      `application/${mutationTypes.application.SET_SOCKET_CONNECTED}`,
      isConnected
    )
  })

  socket.on('event', ({ type, action, id }) => {
    // We add a small timeout to make sure any cached data is allowed to be cleared, before we fetch any new versions
    // Without the timeout, we probably will fetch the cached data

    // console.log('Event ' + type + ' ' + action);

    if (type === 'document') {
      if (store.getters['documents2/byId'](id)) {
        setTimeout(() => {
          if (action === 'deleted')
            store.commit(
              `documents2/${mutationTypes.documents2.DELETE_MANY_DOCUMENTS2}`,
              id
            )
          else store.dispatch(global.LOAD_DOCUMENT2, { id, emit: true })
        }, 1)
      }
    } else if (type === 'template') {
      if (store.getters['templates/byId'](id)) {
        setTimeout(() => {
          if (action === 'deleted')
            store.commit(
              `templates/${mutationTypes.templates.DELETE_MANY_TEMPLATES}`,
              id
            )
          else store.dispatch(global.LOAD_TEMPLATE, { id, emit: true })
        }, 1)
      }
    } else if (type === 'notification') {
      if (!store.hasModule('notifications')) return
      setTimeout(() => {
        if (action === 'deleted')
          store.commit(
            `notifications/${mutationTypes.notifications.DELETE_MANY_NOTIFICATIONS}`,
            id
          )
        else store.dispatch(global.LOAD_NOTIFICATION, { id })
      }, 1)
    } else if (type === 'project') {
      if (!store.hasModule('projects')) return
      if (store.getters['projects/byId'](id)) {
        setTimeout(() => {
          if (action === 'deleted')
            store.commit(
              `projects/${mutationTypes.projects.DELETE_MANY_PROJECTS}`,
              id
            )
          else store.dispatch(global.LOAD_PROJECT, { id, emit: true })
        }, 1)
      }
    } else if (type === 'companyUser') {
      if (store.getters['companyUsers/byId'](id)) {
        setTimeout(() => {
          if (action === 'deleted')
            store.commit(
              `companyUsers/${mutationTypes.DELETE_COMPANY_USER}`,
              id
            )
          else store.dispatch(global.LOAD_COMPANY_USER, { id })
        }, 1)
      }
    } else if (type === 'companyAccount') {
      setTimeout(() => {
        store.dispatch(global.DO_LOAD_CURRENT_COMPANY, { emit: true })
      }, 1)
    } else if (type === 'companySetting') {
      setTimeout(() => {
        if (action === 'deleted')
          store.commit(`settings/${mutationTypes.settings.CLEAR_SETTINGS}`, {
            key: 'company',
            name: id
          })
        else store.dispatch(global.LOAD_SETTINGS_COMPANY)
      }, 1)
    } else if (type === 'companyUserSetting') {
      setTimeout(() => {
        if (action === 'deleted')
          store.commit(`settings/${mutationTypes.settings.CLEAR_SETTINGS}`, {
            key: 'companyUser',
            name: id
          })
        else store.dispatch(global.LOAD_SETTINGS_COMPANYUSER)
      }, 1)
    } else if (type === 'systemClient' && action === 'refreshToken') {
      setTimeout(() => {
        doRefreshToken()
      }, 1)
    }
  })

  store.subscribe(async mutation => {
    if (!store.getters['application/socketEnabled']) return
    const { type, payload: user } = mutation
    if (type === 'application/SET_USER') {
      if (user && typeof user.userId !== 'undefined') {
        socket.autoReconnect = !!user.userId
        if (user.userId) await socket.connect()
        else if (user.userId === null) {
          // If user logs out we must disconnect the socket
          await socket.disconnect()
        }
      }
    }
  })
}

import Vue from 'vue'

import { mutationTypes } from './constants'
import {
  deepMergeReplace,
  convertArrToObject,
  getUniqueArrayElements
} from '../../utils'

const {
  SET_CERTIFICATES,
  CLEAR_CERTIFICATES,
  SET_CERTIFICATES_FETCHING,
  SET_CERTIFICATES_POSTING
} = mutationTypes

export default {
  /**
   * @name SET_CERTIFICATES
   * Submit new certificates into the store
   * It will...
   *  - Overwrite old certificates
   *  - Add new
   *  - Persist old not included in data
   *
   * @param {Object} state Vuex state
   * @param {Object} payload
   * @param {Object|Object[]} payload.data A certificate or an array of certificates
   */
  [SET_CERTIFICATES](state, { data }) {
    if (typeof data !== 'object')
      throw Error('Data must be a certificate or array of certificates!')
    const certificates = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(certificates)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  /**
   * @name CLEAR_CERTIFICATES
   * Clear all certificates
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_CERTIFICATES](state) {
    Vue.set(state, 'data', {})
    Vue.set(state, 'ids', [])
  },

  /**
   * @name SET_CERTIFICATES_FETCHING
   * Set fetching status of certificates
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_CERTIFICATES_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_CERTIFICATES_POSTING
   * Set posting status of certificates
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_CERTIFICATES_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

export const actionTypes = {
  LOAD_USER_POSITION: 'LOAD_USER_POSITION',
  LOAD_USER: 'LOAD_USER',
  LOGIN_AUTH_TOKEN: 'LOGIN_AUTH_TOKEN',
  LOGIN_USER: 'LOGIN_USER',
  AUTH_3RD_PARTY_USER: 'AUTH_3RD_PARTY_USER',
  SWITCH_CONNECTED_USER: 'SWITCH_CONNECTED_USER',
  REGISTER_USER: 'REGISTER_USER',
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  LOGOUT_USER: 'LOGOUT_USER'
}

export const mutationTypes = {
  SET_USER_LOCATION: 'SET_USER_LOCATION',
  SET_USER: 'SET_USER'
}

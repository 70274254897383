import webStorage from '@cling/utils/webStorage'

import { getBrowserLocale } from './utils'

const _localStorage = {
  lookup(key) {
    let found = null
    const locale = webStorage.getItem(key)
    if (locale) found = locale
    return found
  },
  cacheUserLocale(key, locale) {
    webStorage.setItem(key, locale)
  }
}

class BrowserLocaleLookup {
  constructor(options = {}) {
    this.options = {
      localStorageKey: 'locale',
      ...options
    }
  }

  detect() {
    // Get stored locale if it exists otherwise latest browser locale
    // Cling-user (stored), public docs (not stored)
    const lookup =
      _localStorage.lookup(this.options.localStorageKey) || getBrowserLocale()

    return lookup && typeof lookup === 'string' ? lookup : null
  }

  cacheUserLanguage(locale) {
    _localStorage.cacheUserLocale(this.options.localStorageKey, locale)
  }
}

export default BrowserLocaleLookup

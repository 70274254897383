import * as globals from '@cling/store/actions/constants'
import { actionTypes as application } from '@cling/store/modules/application/constants'
import { actionTypes as articles } from '@cling/store/modules/articles/constants'
import { actionTypes as certificates } from '@cling/store/modules/certificates/constants'
import { actionTypes as companies } from '@cling/store/modules/companies/constants'
import { actionTypes as companyReferenceItems } from '@cling/store/modules/companyReferenceItems/constants'
import { actionTypes as companyReferences } from '@cling/store/modules/companyReferences/constants'
import { actionTypes as companyUsers } from '@cling/store/modules/companyUsers/constants'
import { actionTypes as documents2 } from '@cling/store/modules/documents2/constants'
import { actionTypes as documentTerms } from '@cling/store/modules/documentTerms/constants'
import { actionTypes as endCustomers } from '@cling/store/modules/endCustomers/constants'
import { actionTypes as files } from '@cling/store/modules/files/constants'
import { actionTypes as forms } from '@cling/store/modules/forms/constants'
import { actionTypes as notifications } from '@cling/store/modules/notifications/constants'
import { actionTypes as projects } from '@cling/store/modules/projects/constants'
import { actionTypes as publicDocuments } from '@cling/store/modules/publicDocuments/constants'
import { actionTypes as settings } from '@cling/store/modules/settings/constants'
import { actionTypes as stripe } from '@cling/store/modules/stripe/constants'
import { actionTypes as templateMessages } from '@cling/store/modules/templateMessages/constants'
import { actionTypes as templates } from '@cling/store/modules/templates/constants'
import { actionTypes as unitTypes } from '@cling/store/modules/unitTypes/constants'

// TODO: export global string and include in Vue instance

// Description of all available action types
const actionTypes = {
  ...globals,
  application,
  projects,
  forms,
  documents2,
  files,
  settings,
  articles,
  endCustomers,
  certificates,
  companies,
  companyUsers,
  companyReferences,
  companyReferenceItems,
  templateMessages,
  notifications,
  templates,
  publicDocuments,
  documentTerms,
  unitTypes,
  stripe,
  CREATE_ARTICLE: 'CREATE_ARTICLE',
  CREATE_COMPANY_USER: 'CREATE_COMPANY_USER',
  CREATE_ENDCUSTOMER: 'CREATE_ENDCUSTOMER',
  CREATE_PROJECT: 'CREATE_PROJECT',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  DELETE_COMPANY_USER: 'DELETE_COMPANY_USER',
  DELETE_ENDCUSTOMER: 'DELETE_ENDCUSTOMER',
  DELETE_PROJECT: 'DELETE_PROJECT',
  FORM_CLEAR_FORM: 'FORM_CLEAR_FORM',
  FORM_EDIT_ARTICLE: 'FORM_EDIT_ARTICLE',
  FORM_EDIT_COMPANY: 'FORM_EDIT_COMPANY',
  FORM_EDIT_COMPANY_USER: 'FORM_EDIT_COMPANY_USER',
  FORM_EDIT_COMPANY_BRANDING: 'FORM_EDIT_COMPANY_BRANDING',
  FORM_EDIT_ENDCUSTOMER: 'FORM_EDIT_ENDCUSTOMER',
  FORM_NEW_ARTICLE: 'FORM_NEW_ARTICLE',
  FORM_NEW_COMPANY_USER: 'FORM_NEW_COMPANY_USER',
  FORM_NEW_COMPANY_BRANDING: 'FORM_NEW_COMPANY_BRANDING',
  FORM_NEW_ENDCUSTOMER: 'FORM_NEW_ENDCUSTOMER',
  FORM_NEW_OFFER: 'FORM_NEW_OFFER',
  FORM_SUBMIT_ARTICLE: 'FORM_SUBMIT_ARTICLE',
  FORM_SUBMIT_COMPANY: 'FORM_SUBMIT_COMPANY',
  FORM_SUBMIT_COMPANY_USER: 'FORM_SUBMIT_COMPANY_USER',
  FORM_SUBMIT_COMPANY_BRANDING: 'FORM_SUBMIT_COMPANY_BRANDING',
  FORM_SUBMIT_ENDCUSTOMER: 'FORM_SUBMIT_ENDCUSTOMER',
  FORM_SUBMIT_OFFER: 'FORM_SUBMIT_OFFER',
  LOAD_ARTICLE: 'LOAD_ARTICLE',
  LOAD_ARTICLES: 'LOAD_ARTICLES',
  LOAD_CERTIFICATES: 'LOAD_CERTIFICATES',
  LOAD_COMPANY_USER: 'LOAD_COMPANY_USER',
  LOAD_COMPANY_USERS: 'LOAD_COMPANY_USERS',
  DO_LOAD_CURRENT_COMPANY: 'DO_LOAD_CURRENT_COMPANY',
  LOAD_ENDCUSTOMER: 'LOAD_ENDCUSTOMER',
  LOAD_ENDCUSTOMERS: 'LOAD_ENDCUSTOMERS',
  LOAD_PROJECT: 'LOAD_PROJECT',
  LOAD_PROJECTS: 'LOAD_PROJECTS',
  LOAD_PUBLIC_PROJECT: 'LOAD_PUBLIC_PROJECT',
  PATCH_ARTICLE: 'PATCH_ARTICLE',
  PATCH_COMPANY_USER: 'PATCH_COMPANY_USER',
  SEARCH_ARTICLE: 'SEARCH_ARTICLE',
  SET_ARTICLES: 'SET_ARTICLES',
  SET_FORM: 'SET_FORM',
  SET_PROJECTS: 'SET_PROJECTS',
  SET_READ_FOR_PROJECT: 'SET_READ_FOR_PROJECT',
  SORT: 'SORT',
  UPDATE_ARTICLE: 'UPDATE_ARTICLE',
  UPDATE_COMPANY_USER: 'UPDATE_COMPANY_USER',
  DO_UPDATE_CURRENT_COMPANY: 'DO_UPDATE_CURRENT_COMPANY',
  UPDATE_ENDCUSTOMER: 'UPDATE_ENDCUSTOMER',
  UPDATE_PROJECT: 'UPDATE_PROJECT'
}

export const global = {}

Object.keys(actionTypes).forEach(mod => {
  if (typeof actionTypes[mod] !== 'object') {
    return
  }

  Object.keys(actionTypes[mod]).forEach(key => {
    if (global[key]) {
      // eslint-disable-next-line no-console
      console.warn(
        `Duplicate action constant warning!, ${actionTypes[mod][key]}`
      )
    }
    global[key] = `${mod}/${actionTypes[mod][key]}`
  })
})

Object.keys(globals).forEach(key => {
  if (global[key]) {
    // eslint-disable-next-line no-console
    console.warn(`Duplicate action constant warning!, ${actionTypes[key]}`)
  }
  global[key] = actionTypes[key]
})

export default actionTypes

/**
 * Check if the client has support to use localStorage
 * @returns {Boolean} Returns true if localStorage can be used, otherwise false
 */

export const checkLocalStorageSupport = () => {
  try {
    const storage = window.localStorage
    storage.setItem('test', 'test')
    storage.removeItem('test')
    return true
  } catch (err) {
    return false
  }
}

/**
 * Local storage wrapper that exposes localStorage methods
 * but also a fallback to store data in memory if localStorage was not supported
 */
class WebStorage {
  _memory = {} // Fallback storage in memory if localStorage could not be used
  _isLocalStorageSupport = false
  _prefix = ''

  constructor() {
    this._isLocalStorageSupport = checkLocalStorageSupport()
  }

  setPrefix(value) {
    this._prefix = value
  }

  _getKey(key) {
    return `${this._prefix}${key}`
  }

  getItem(key) {
    if (this._isLocalStorageSupport)
      return localStorage.getItem(this._getKey(key))
    return this._memory[key] || null
  }

  setItem(key, value) {
    if (this._isLocalStorageSupport)
      localStorage.setItem(this._getKey(key), value)
    else this._memory[key] = value
  }

  removeItem(key) {
    if (this._isLocalStorageSupport) localStorage.removeItem(this._getKey(key))
    else delete this._memory[key]
  }

  clear() {
    if (this._isLocalStorageSupport) localStorage.clear()
    else this._memory = {}
  }
}

const Instance = new WebStorage()

export default Instance

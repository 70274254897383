<template>
  <div v-if="$can('documents', 'create')">
    <template v-if="['docspo', 'fortnoxCom'].includes($brand)">
      <CButton
        v-if="$can('documents', 'create')"
        :tooltip="$t('regularTooltip')"
        :to="{ name: 'documentFormSelect', params: { projectId } }"
        :class="{ 'is-left': !compact }"
        :style="!compact && { paddingLeft: '1.25em' }"
        tag="router-link"
        type="primary"
        expanded
        raised
      >
        <CIcon type="paper" size="16" style="padding-right: 1em" />
        {{ $t('inActive') }}
      </CButton>
    </template>
    <CDropdown v-else :mobile-label="$t('mobileLabel')" expanded>
      <template #trigger="{ active }">
        <div>
          <slot :is-active="active">
            <CButton
              :class="{ 'is-left': !compact }"
              :style="!compact && { paddingLeft: '1.25em' }"
              :dropdown-icon="!compact"
              type="primary"
              expanded
              raised
            >
              <CIcon
                v-if="!compact"
                type="paper"
                size="16"
                style="padding-right: 1em"
              />
              {{ active ? $t('active') : $t('inActive') }}
            </CButton>
          </slot>
        </div>
      </template>
      <CDropdownItem
        v-for="doc in documents"
        :key="doc.id"
        :tooltip="$t('ataTooltip')"
        :disabled="!addonTemplateId"
        :to="{
          name: 'documentForm',
          params: {
            addonDocumentSourceId: doc.id,
            templateId: addonTemplateId
          }
        }"
        icon-left="link"
      >
        <div style="line-height: 1.3">
          <div>
            {{ $t('_common:ata').capitalize() }}
          </div>
          <div class="has-text-grey" style="font-size: 13px">
            {{ truncate(doc.name, 20) }}
          </div>
        </div>
      </CDropdownItem>
      <CDropdownItem separator />
      <CDropdownItem
        v-if="$can('documents', 'create')"
        :tooltip="$t('regularTooltip')"
        :to="{ name: 'documentFormSelect', params: { projectId } }"
        icon-left="paper-plus"
      >
        {{ $t('regular') }}
      </CDropdownItem>
    </CDropdown>
  </div>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import { truncate } from '@cling/utils'

import { mapGetters } from 'vuex'

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.createDocumentDropdown'
  },
  name: 'ProjectCreateDocumentDropdown',
  components: {
    CDropdown,
    CDropdownItem
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    documents: {
      type: Array,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getCompanySetting: 'settings/getCompanySetting'
    }),
    addonTemplateId() {
      return this.getCompanySetting('addonTemplateId')
    }
  },
  methods: {
    truncate
  }
}
</script>

import { getUniqueArrayElements } from '@cling/store/utils'

import Vue from 'vue'

import { mutationTypes } from './constants'

const {
  DELETE_MANY_DOCUMENTS2,
  SET_DOCUMENTS2,
  SET_DOCUMENT2_FETCHING,
  SET_DOCUMENT2_POSTING
} = mutationTypes

export default {
  /**
   * @name SET_DOCUMENTS2
   * Add documents2 to store by overwriting old data with new.
   * Will not remove old data
   *
   * @param {Object} state Vuex state
   * @param {Object} payload
   * @param {Object|Array} payload.data document or an array of documents
   */
  [SET_DOCUMENTS2](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a document2 or an array of documents2!')
    }
    const documents2 = Array.isArray(data) ? data : [data]
    const ids = []

    documents2.forEach(itm => {
      if (!itm.id) return
      if (!state.ids.includes(itm.id)) ids.push(itm.id)
      // Update without merge
      Vue.set(state.data, itm.id, itm)
    })
    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  /**
   * @name DELETE_MANY_DOCUMENTS2
   * Deletes many documents2 from the store
   *
   * @param {Object} state Vuex state
   * @param {Number|Number[]} ids One id or array of ids to delete
   */
  [DELETE_MANY_DOCUMENTS2](state, ids) {
    const idArr = Array.isArray(ids) ? ids : [ids]
    idArr.forEach(id => {
      if (!state.data[id]) return
      Vue.delete(state.data, id)

      state.ids = state.ids.filter(x => x !== id)
    })
  },

  /**
   * @name SET_DOCUMENT2_FETCHING
   * Set fetching status
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_DOCUMENT2_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_DOCUMENT2_POSTING
   * Set posting status
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_DOCUMENT2_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

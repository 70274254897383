// Due to date-fns migration from version 1.x -> 2.x
// Date-fns functions no longer support string arguments
// These exposed methods acts as an adaptation/helper for legacy code
// For more info:
// https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#Common-Changes

import {
  addDays as _addDays,
  addMinutes as _addMinutes,
  addMonths as _addMonths,
  addSeconds as _addSeconds,
  addYears as _addYears,
  compareDesc as _compareDesc,
  differenceInCalendarDays as _differenceInCalendarDays,
  differenceInDays as _differenceInDays,
  differenceInMinutes as _differenceInMinutes,
  differenceInSeconds as _differenceInSeconds,
  endOfDay as _endOfDay,
  endOfWeek as _endOfWeek,
  getDate as _getDate,
  getISOWeek as _getISOWeek,
  getMonth as _getMonth,
  getSeconds as _getSeconds,
  getYear as _getYear,
  isAfter as _isAfter,
  isBefore as _isBefore,
  isEqual as _isEqual,
  isFirstDayOfMonth as _isFirstDayOfMonth,
  isFuture as _isFuture,
  isMonday as _isMonday,
  isSameDay as _isSameDay,
  isSameMonth as _isSameMonth,
  isSameWeek as _isSameWeek,
  isSameYear as _isSameYear,
  isToday as _isToday,
  isWeekend as _isWeekend,
  setDate as _setDate,
  setDay as _setDay,
  setHours as _setHours,
  setMinutes as _setMinutes,
  setMonth as _setMonth,
  setSeconds as _setSeconds,
  setYear as _setYear,
  startOfDay as _startOfDay,
  startOfMonth as _startOfMonth,
  subDays as _subDays,
  subHours as _subHours,
  isValid,
  parseISO
} from 'date-fns'

export { isValid, parseISO }

export const isFuture = date => _isFuture(new Date(date))
export const isToday = date => _isToday(new Date(date))
export const isMonday = date => _isMonday(new Date(date))
export const isWeekend = date => _isWeekend(new Date(date))
export const isFirstDayOfMonth = date => _isFirstDayOfMonth(new Date(date))

export const isAfter = (dateLeft, dateRight) =>
  _isAfter(new Date(dateLeft), new Date(dateRight))
export const isBefore = (dateLeft, dateRight) =>
  _isBefore(new Date(dateLeft), new Date(dateRight))
export const isEqual = (dateLeft, dateRight) =>
  _isEqual(new Date(dateLeft), new Date(dateRight))
export const isSameDay = (dateLeft, dateRight) =>
  _isSameDay(new Date(dateLeft), new Date(dateRight))
export const isSameWeek = (dateLeft, dateRight) =>
  _isSameWeek(new Date(dateLeft), new Date(dateRight))
export const isSameMonth = (dateLeft, dateRight) =>
  _isSameMonth(new Date(dateLeft), new Date(dateRight))
export const isSameYear = (dateLeft, dateRight) =>
  _isSameYear(new Date(dateLeft), new Date(dateRight))

export const compareDesc = (dateLeft, dateRight) =>
  _compareDesc(new Date(dateLeft), new Date(dateRight))

export const differenceInSeconds = (dateLeft, dateRight) =>
  _differenceInSeconds(new Date(dateLeft), new Date(dateRight))
export const differenceInMinutes = (dateLeft, dateRight) =>
  _differenceInMinutes(new Date(dateLeft), new Date(dateRight))
export const differenceInDays = (dateLeft, dateRight) =>
  _differenceInDays(new Date(dateLeft), new Date(dateRight))
export const differenceInCalendarDays = (dateLeft, dateRight) =>
  _differenceInCalendarDays(new Date(dateLeft), new Date(dateRight))

export const addSeconds = (date, amount) => _addSeconds(new Date(date), amount)
export const addMinutes = (date, amount) => _addMinutes(new Date(date), amount)
export const addDays = (date, amount) => _addDays(new Date(date), amount)
export const addMonths = (date, amount) => _addMonths(new Date(date), amount)
export const addYears = (date, amount) => _addYears(new Date(date), amount)

export const setDate = (date, dayOfMonth) =>
  _setDate(new Date(date), dayOfMonth)
export const setSeconds = (date, minutes) =>
  _setSeconds(new Date(date), minutes)
export const setMinutes = (date, minutes) =>
  _setMinutes(new Date(date), minutes)
export const setHours = (date, minutes) => _setHours(new Date(date), minutes)
// TODO: necessary to include locale?
export const setDay = (date, day, options) =>
  _setDay(new Date(date), day, options)
export const setMonth = (date, month) => _setMonth(new Date(date), month)
export const setYear = (date, year) => _setYear(new Date(date), year)

export const subHours = (date, amount) => _subHours(new Date(date), amount)
export const subDays = (date, amount) => _subDays(new Date(date), amount)

export const getDate = date => _getDate(new Date(date))
export const getSeconds = date => _getSeconds(new Date(date))
export const getMonth = date => _getMonth(new Date(date))
export const getYear = date => _getYear(new Date(date))
export const getISOWeek = date => _getISOWeek(new Date(date))

export const startOfDay = date => _startOfDay(new Date(date))
export const startOfMonth = date => _startOfMonth(new Date(date))

export const endOfDay = date => _endOfDay(new Date(date))
export const endOfWeek = date => _endOfWeek(new Date(date))

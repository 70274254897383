import lang from '@cling/language'
import { getLatestEventString } from '@cling/utils'

import get from 'lodash/get'

export const projectSchema = {
  id: {
    $default: null,
    $type: Number
  },
  name: {
    $default: '',
    $type: String
  },
  isDemo: {
    $default: false,
    $type: Boolean
  },
  externalId: {
    $default: '',
    $type: Number
  },
  publicId: {
    $default: null,
    $type: String
  },
  projectNumber: {
    $default: null,
    $type: Number
  },
  CompanyId: {
    $default: null,
    $type: 'ref',
    $ref: 'companies'
  },
  CompanyUserId: {
    $default: null,
    $type: 'ref',
    $ref: 'companyUsers'
  },
  ProjectMembers: [
    {
      $type: 'ref',
      $ref: 'companyUsers'
    }
  ],
  ProjectSettings: {
    $default: [],
    $type: Array
  },
  status: {
    $default: 'new',
    $type: String
  },
  createdAt: {
    $default: null,
    $type: Date
  },
  updatedAt: {
    $default: null,
    $type: Date
  },
  deletedAt: {
    $default: null,
    $type: Date
  }
}

export default class Project {
  static schema = projectSchema

  static statuses = {
    new: {
      statusText: 'Utkast',
      statusClass: 'draft'
    },
    sent: {
      statusText: 'Skickad',
      statusClass: 'sent'
    },
    denied: {
      statusText: 'Nekad',
      statusClass: 'denied'
    },
    accepted: {
      statusText: 'Accepterad',
      statusClass: 'accepted'
    },
    signed: {
      statusText: 'Signerad',
      statusClass: 'accepted'
    },
    semiSigned: {
      statusText: 'Delsignerad',
      statusClass: 'accepted'
    },
    active: {
      statusText: 'Pågående',
      statusClass: 'active'
    },
    completed: {
      statusText: 'Klar',
      statusClass: 'completed'
    },
    archived: {
      statusText: 'Arkiverad',
      statusClass: 'archived'
    }
  }

  constructor(project, getters) {
    if (!project) throw Error('First arg. id is required')
    if (!getters) throw Error('Second arg. getters is required')

    this._getters = getters
    this._project = project

    this._documents = [] // Container for documents
  }

  get id() {
    return this._project?.id
  }

  getId() {
    return this.id || null
  }

  getProject() {
    return this._project || null
  }

  get documents() {
    const mainId = get(this._project, 'cachedDocuments.mainDocument.id')

    return this.documentIds?.reduce((acc, id) => {
      const doc = this._getters['documents2/byId'](id)
      if (doc) {
        // Place mainDocument first
        return doc.id === mainId ? [doc, ...acc] : [...acc, doc]
      }
      return acc
    }, [])
  }

  // Mainly for use in document lists
  get cachedDocumentsInfo() {
    const { documents, mainDocument } = this._project.cachedDocuments || {}

    return Object.values(documents || {}).reduce((acc, doc) => {
      // Put "main document" first in the list
      if (doc.id === mainDocument?.id) return [doc, ...acc]
      return [...acc, doc]
    }, [])
  }

  get documentIds() {
    return Object.keys(this._project.cachedDocuments?.documents || {})
  }

  get events() {
    return this._project?.Events || []
  }

  get creatorId() {
    return this._project?.CompanyUser
  }

  get creator() {
    return this._getters['companyUsers/byId'](this.creatorId)
  }

  get name() {
    return this._project.name
  }

  get status() {
    return this._project.status
  }

  get subStatus() {
    const { status, mainDocument } = this
    return ['offer', 'contract'].includes(status) && mainDocument
      ? mainDocument.status
      : null
  }

  // Note: combination of cached doc and project status, including virtual doc status 'scheduled'
  get virtualStatus() {
    const { status, subStatus, firstDeliveryAt } = this

    if (!['offer', 'contract'].includes(status)) return status

    return firstDeliveryAt && subStatus === 'draft' ? 'scheduled' : subStatus
  }

  get firstDeliveryAt() {
    // Use fully loaded document getter if available
    let firstDeliveryAt = this.mainDocument?.scheduledFirstDelivery?.sendAt

    // Fallback to cached doc on project
    if (!firstDeliveryAt) {
      firstDeliveryAt =
        this._project?.cachedDocuments?.mainDocument?.firstDeliveryAt
    }

    return firstDeliveryAt
  }

  get firstDeliveryFormatted() {
    const { firstDeliveryAt } = this
    return firstDeliveryAt ? lang.formatDate(firstDeliveryAt, 'p dd MMM') : null
  }

  get lastViewedAgo() {
    // Use fully loaded document getter if available
    let lastViewedAgo = this.mainDocument?.lastViewedAgo

    // Fallback to cached doc on project
    if (!lastViewedAgo) {
      const updatedAt =
        this._project?.cachedDocuments?.mainDocument?.lastViewedAt

      if (updatedAt)
        lastViewedAgo = getLatestEventString({ code: 'viewed', updatedAt })
    }

    return lastViewedAgo
  }

  get clients() {
    // Get all unique clients from all the documents that exist on the project
    const currentIdList = []
    const result = this.documents.reduce((res, x) => {
      x.clients.forEach(client => {
        if (currentIdList.includes(client.endCustomerId)) return
        currentIdList.push(client.endCustomerId)
        res.push(client)
      })
      return res
    }, [])

    return result
  }

  get mainDocument() {
    return this.documents[0]
  }

  get currency() {
    return get(this, 'mainDocument.currency', null)
  }

  get members() {
    return (
      this._project?.ProjectMembers?.map(id =>
        this._getters['companyUsers/byId'](id)
      ) || []
    )
  }

  get projectNumber() {
    return this._project.projectNumber
  }

  get note() {
    return this._project.ProjectNote
  }

  get files() {
    return this._project.ProjectFiles
  }

  get firstClientName() {
    return this._project.firstClientName
  }

  get firstClientStreet() {
    return this._project.firstClientStreet
  }

  get prices() {
    const result = {
      types: [],
      subTotal: 0,
      taxAmount: 0,
      total: 0,
      maxTotal: 0,
      paidAmount: 0,
      hasDeductions: false,
      currency: this.currency
    }

    if (!this.documents.length) return result

    this.documents.forEach(x => {
      result.types = [...new Set([...result.types, ...x.prices.type])]
      if (result.currency && result.currency !== x.currency) return

      result.subTotal += x.prices.subTotal
      result.taxAmount += x.prices.taxAmount
      result.total += x.prices.total
      result.maxTotal += x.prices.maxTotal

      if (x.prices.region && x.prices.region.houseWorkAmount) {
        result.hasDeductions = true
      }
    })

    return result
  }

  getProperty(path = '') {
    return get(this._project, path, null)
  }
}

import logger from '@cling/services/logger'

import hubspot from './hubspot'
import pipedrive from './pipedrive'

export * from './appAuth'

function install(Vue, { router, store }) {
  try {
    const urlParams = new URLSearchParams(window.location.search)
    const view = urlParams?.get('view')
    if (view === 'pipedrive') Vue.use(pipedrive, { router, store })
  } catch (err) {
    logger.debug('Integration window error: ', err)
  }
  // TODO update hubspot according to pipedrive for performance/reduced complexity
  Vue.use(hubspot, { router, store })
}

export default {
  install
}

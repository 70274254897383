import Vue from 'vue'

import { mutationTypes } from '../constants'

const { SET_EVENTS } = mutationTypes

export default {
  // Set events for the associated projects
  // events: Array with objects
  [SET_EVENTS](state, { events = [] }) {
    events.forEach(event => {
      const { ProjectId: projectId } = event
      if (!projectId) return false
      if (!state.data[projectId]) return false
      // Does the Events array exist on project?
      let newEvents = []
      if (state.data[projectId].Events) {
        newEvents = state.data[projectId].Events
      } else {
        Vue.set(state.data[projectId], 'Events', newEvents)
      }
      // Does the event already exist?
      const index = newEvents.findIndex(e => e.id === event.id)
      if (index !== -1) {
        newEvents[index] = Object.assign(newEvents[index], event)
      } else {
        newEvents.push(event)
      }
      return true
    })
  }
}

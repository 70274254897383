import companyCrmsImport from './companyCrms'
import companyIndustriesImport from './companyIndustries'
import companySizesImport from './companySizes'
import crmExtensionsImport from './crmExtensions'
import reasonForSignupImport from './reasonForSignup'
import signSoftwareImport from './signSoftware'

export const crmExtensions = crmExtensionsImport
export const companyIndustries = companyIndustriesImport
export const companySizes = companySizesImport
export const companyCrms = companyCrmsImport
export const reasonForSignup = reasonForSignupImport
export const signSoftware = signSoftwareImport

// This file helps to export static data

export default {
  crmExtensions,
  companyIndustries,
  companySizes,
  companyCrms,
  reasonForSignup,
  signSoftware
}

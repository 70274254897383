/* eslint-disable no-console */

const isDev = import.meta.env.DEV

const configSchema = {
  environment: {
    format: 'String',
    env: process.env.NODE_ENV
  },
  baseUrl: {
    format: 'String',
    default: 'http://localhost:8080',
    env: import.meta.env.VITE_APP_WEB_BASEURL
  },
  showAdminSettings: {
    format: 'Boolean',
    default: isDev,
    env: import.meta.env.VITE_APP_ADMIN_SETTINGS
  },
  debug: {
    format: 'Boolean',
    default: isDev,
    env: import.meta.env.VITE_APP_DEBUG
  },
  checkAppUpdates: {
    format: 'Boolean',
    default: !isDev,
    env: import.meta.env.VITE_APP_CHECK_APP_UPDATES
  },
  logLevel: {
    format: 'Number',
    default: !isDev ? 1 : 5,
    env: import.meta.env.VITE_APP_LOG_LEVEL
  },
  showSendEmails: {
    format: 'Boolean',
    default: isDev,
    env: import.meta.env.VITE_APP_SHOW_SEND_EMAILS
  },
  api: {
    baseUrl: {
      format: 'String',
      default: 'https://api.dev.cling.se',
      env: import.meta.env.VITE_APP_CLINGAPI_BASEURL
    },
    socket: {
      path: {
        format: 'String',
        default: '/socket.io',
        env: import.meta.env.VITE_APP_CLINGAPI_SOCKET_PATH
      }
    }
  },
  piwik: {
    id: {
      format: 'String',
      default: '9709911e-f71d-4c86-883d-3b4bd3c6df81',
      env: import.meta.env.VITE_APP_PIWIK_APP_ID
    }
  },
  freshChat: {
    token: {
      format: 'String',
      default: '75294d63-74f7-4a0a-964a-5d1efe0623ba', // Fortnox dev
      env: import.meta.env.VITE_APP_FRESHCHAT_TOKEN
    },
    host: {
      format: 'String',
      default: 'https://fortnoxtestkonto.freshchat.com', // Fortnox dev
      env: import.meta.env.VITE_APP_FRESHCHAT_HOST
    },
    widgetUrl: {
      format: 'String',
      default: 'https://fortnoxtestkonto.freshchat.com/js/widget.js', // Fortnox dev
      env: import.meta.env.VITE_APP_FRESHCHAT_WIDGET_URL
    },
    widgetUuid: {
      format: 'String',
      default: '557e7e51-1c01-4e9d-a77b-52b35e55fda7', // Fortnox dev
      env: import.meta.env.VITE_APP_FRESHCHAT_WIDGET
    },
    tagsCommaSeparated: {
      format: 'String',
      default: '', // comma separated tags if multiple (like 'cling,docspo')
      env: import.meta.env.VITE_APP_FRESHCHAT_TAGS
    }
  },
  rollbar: {
    // Maybe we should add an option to enable/disable rollbar?
    environment: {
      format: 'String',
      env: import.meta.env.VITE_APP_ROLLBAR_ENV
    },
    clientToken: {
      format: 'String',
      env: import.meta.env.VITE_APP_ROLLBAR_CLIENT_TOKEN
    }
  },
  google: {
    // Google Fonts api key
    fontsKey: {
      format: 'String',
      default: 'AIzaSyAmNFe07wm9Zc5XkyZC-R7a7RKbP21GRVc',
      env: import.meta.env.VITE_APP_GOOGLE_FONTS_KEY
    },
    signInId: {
      format: 'String',
      default:
        '304554270591-7sp9q4omqih373020mj0mo2ks2bka1gb.apps.googleusercontent.com', // Docspo/Cling staging
      env: import.meta.env.VITE_APP_GOOGLE_SIGN_IN_ID
    }
  },
  facebook: {
    pixelUserId: {
      format: 'String',
      default: '',
      env: import.meta.env.VITE_APP_FACEBOOK_PIXEL_USER_ID
    }
  },
  // TODO: Remove after next release when we have also removed it from any env or configs provided
  segment: {
    writeKey: {
      format: 'String',
      default: '',
      env: import.meta.env.VITE_APP_SEGMENT_WRITE_KEY
    }
  },
  unsplash: {
    id: {
      format: 'String',
      default:
        '141f6cc7099b0dcf9bbc6b447135e5623f5793caabff3413b4c82f7751473e31',
      env: import.meta.env.VITE_APP_UNSPLASH_ID
    }
  },
  pexels: {
    id: {
      format: 'String',
      default: '563492ad6f9170000100000150cc2598932942c583cec92c1f02e9d9',
      env: import.meta.env.VITE_APP_PEXELS_ID
    }
  },
  stripe: {
    publishableKey: {
      format: 'String',
      default: '',
      env: import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY
    }
  },
  brand: {
    format: 'String',
    default: 'cling',
    env: import.meta.env.VITE_APP_BRAND
  },
  otherBrandUrl: {
    format: 'String',
    default: '',
    env: import.meta.env.VITE_APP_OTHER_BRAND_URL
  },
  clientId: {
    format: 'String',
    default: '4888cbb193eda211f4b8fc77ce362becf8e8f3147743193c55ce3ea334807a84', // Staging
    env: import.meta.env.VITE_APP_CLIENT_ID
  }
}

/**
 * External config can be loaded by injecting json in a script element in index.html
 * The injected json must be loaded prior to the main application
 */
let externalConfigEl = document.getElementById('config')
let externalConfig = {}

if (externalConfigEl) {
  try {
    externalConfig = JSON.parse(externalConfigEl.innerText)

    const parent = externalConfigEl.parentNode
    parent?.removeChild(externalConfigEl)
    externalConfigEl = null
  } catch (err) {
    console.error(err)
    console.error('Failed to load external config')
  }
}

const isObject = val =>
  typeof val !== 'undefined' &&
  typeof val === 'object' &&
  !Array.isArray(val) &&
  val !== null

const getProperty = (obj, path) =>
  path.reduce((acc, key) => {
    if (typeof acc === 'undefined') return acc
    return acc[key]
  }, obj)

function createConfig(rootSchema) {
  const propOpts = ['format', 'default', 'env']
  const validFormats = ['string', 'number', 'boolean']

  function parseSchema(schema, path) {
    const isProperty =
      isObject(schema) && Object.keys(schema).some(k => propOpts.includes(k))

    if (isProperty) {
      const format = schema.format ? schema.format.toLowerCase() : 'string'
      let val

      if (typeof schema.default !== 'undefined') {
        val = schema.default
      }
      // TODO: Won't work in Vite. Vite replaces strings statically.
      // TODO: https://vitejs.dev/guide/env-and-mode.html#production-replacement
      // if (typeof import.meta.env[schema.env || '$'] !== 'undefined') {
      //   val = import.meta.env[schema.env];
      // }
      if (typeof schema.env !== 'undefined') {
        val = schema.env
      }
      if (typeof getProperty(externalConfig, path) !== 'undefined') {
        val = getProperty(externalConfig, path)
      }

      if (typeof val === 'undefined') {
        return undefined
      }

      if (!validFormats.includes(format)) {
        throw new Error(`${format} is not a valid config format!`)
      }
      if (typeof format === 'undefined') {
        console.warn(`Configuration ${path.join('.')} is not defined`)
        return undefined
      }
      if (format === 'string') {
        return val.toString()
      }
      if (format === 'number') {
        return parseFloat(val)
      }
      if (format === 'boolean') {
        if (val.toString().toLowerCase() === 'false') {
          return false
        }
        return true
      }

      return null
    }

    return Object.entries(schema).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        throw new Error('Arrays are not supported in configuration schema')
      }

      const currentPath = [...path, key]

      if (isObject(value)) {
        acc[key] = parseSchema(value, currentPath)
      }

      return acc
    }, {})
  }

  return parseSchema(rootSchema, [])
}

const config = createConfig(configSchema)

if (config.debug) {
  console.log(config)
}

export default config

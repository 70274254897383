// Supported CRMs for company.crm enum
// Should be in sync for both landing, backend and frontend.
export default [
  'hubspot',
  'salesforce',
  'pipedrive',
  'close',
  'upsales',
  'copper',
  'other',
  'none'
]

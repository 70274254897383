import config from '@cling/config'

import get from 'lodash/get'

import freshChat from '@/services/freshChat'

export default {
  install(Vue, { store } = {}) {
    let serviceType = null
    let serviceRef
    let serviceInitParams

    if (get(config, 'freshChat.token')) {
      serviceType = 'freshChat'
      serviceRef = freshChat
      serviceInitParams = {
        token: config.freshChat.token,
        host: config.freshChat.host,
        widgetUuid: config.freshChat.widgetUuid,
        widgetUrl: config.freshChat.widgetUrl,
        tags: config.freshChat.tagsCommaSeparated.split(',')
      }
    }

    serviceRef.init({
      store,
      ...serviceInitParams
    })

    const supportObj = Vue.observable({
      serviceType,
      hideIcon: serviceRef.hideIcon.bind(serviceRef),
      syncData: serviceRef.syncData.bind(serviceRef),
      show: serviceRef.show.bind(serviceRef),
      hide: serviceRef.hide.bind(serviceRef),
      showNewMessage: serviceRef.showNewMessage.bind(serviceRef),
      getIsReady: serviceRef.getIsReady.bind(serviceRef),
      install: serviceRef.install.bind(serviceRef),
      isEnabled: false
    })

    // Global method called from Piwik on chat consent
    window.enableChat = () => {
      supportObj.isEnabled = true
    }

    // Make available in store
    store.$supportChat = supportObj

    // Make available in all vue components
    Vue.prototype.$supportChat = supportObj
  }
}

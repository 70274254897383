import config from '@cling/config'

import eventTracker from '@/services/eventTracker'

export default {
  install(Vue, { store } = {}) {
    eventTracker.init({
      store,
      fbUserPixel: config.facebook.pixelUserId
    })

    const eventObj = Vue.observable({
      instance: eventTracker,
      isSuperUser: eventTracker.isSuperUser.bind(eventTracker),
      installFbUserPixel: eventTracker.installFbUserPixel.bind(eventTracker),
      uninstallFbUserPixel:
        eventTracker.uninstallFbUserPixel.bind(eventTracker),
      trackEvent: eventTracker.trackEvent.bind(eventTracker),
      isEnabled: false,
      get _hasInstalledFbUserPixel() {
        return eventTracker._hasInstalledFbUserPixel
      }
    })

    // Global method called from Piwik on tracker consent
    window.enableTracker = () => {
      eventObj.isEnabled = true
      eventObj.installFbUserPixel()
    }

    // Make available i store
    store.$trackEvent = eventTracker.trackEvent.bind(eventTracker)
    store.$eventTracker = eventObj

    // Make available in all vue components
    Vue.prototype.$trackEvent = eventTracker.trackEvent.bind(eventTracker)
    Vue.prototype.$eventTracker = eventObj
  }
}

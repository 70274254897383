import { unitType } from '@cling/api'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'unitTypes'

const {
  LOAD_UNIT_TYPES,
  LOAD_UNIT_TYPE,
  UPDATE_UNIT_TYPE,
  CREATE_UNIT_TYPE,
  DELETE_UNIT_TYPE
} = actionTypes

const { SET_UNIT_TYPES, SET_UNIT_TYPE_FETCHING, SET_UNIT_TYPE_POSTING } =
  mutationTypes

export default {
  async [LOAD_UNIT_TYPES]({ commit }, params = {}) {
    try {
      commit(SET_UNIT_TYPE_FETCHING, true)
      const { data } = await unitType.get(null, params) // get all
      commit(SET_UNIT_TYPES, { data })
    } catch (err) {
      this.handleError(err, {
        object: 'unitType',
        fallbackCode: 'unitType.get',
        action: `${moduleName}/${LOAD_UNIT_TYPES}`
      })
    } finally {
      commit(SET_UNIT_TYPE_FETCHING, false)
    }
  },

  async [LOAD_UNIT_TYPE]({ commit }, { id, params = {} }) {
    try {
      commit(SET_UNIT_TYPE_FETCHING, true)
      const { data } = await unitType.get(id, params)
      commit(SET_UNIT_TYPES, { data })
    } catch (err) {
      this.handleError(err, {
        object: 'unitType',
        objectId: id,
        fallbackCode: 'unitType.get',
        action: `${moduleName}/${LOAD_UNIT_TYPE}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_UNIT_TYPE_FETCHING, false)
    }
  },

  async [UPDATE_UNIT_TYPE]({ dispatch, commit }, { id, body }) {
    try {
      commit(SET_UNIT_TYPE_POSTING, true)
      await unitType.put(id, body)
      await dispatch(LOAD_UNIT_TYPE, { id })

      return id
    } catch (err) {
      this.handleError(err, {
        object: 'unitType',
        objectId: id,
        fallbackCode: 'unitType.put',
        action: `${moduleName}/${UPDATE_UNIT_TYPE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_UNIT_TYPE_POSTING, false)
    }
  },

  async [CREATE_UNIT_TYPE]({ commit }, { body, showMessage = true }) {
    try {
      commit(SET_UNIT_TYPE_POSTING, true)
      const { data } = await unitType.post(body)
      await commit(SET_UNIT_TYPES, { data })

      return data.id
    } finally {
      commit(SET_UNIT_TYPE_POSTING, false)
    }
  },

  async [DELETE_UNIT_TYPE]({ commit }, { id }) {
    try {
      commit(SET_UNIT_TYPE_POSTING, true)
      await unitType.delete(id)
      commit(mutationTypes.DELETE_UNIT_TYPE, id)

      return id
    } catch (err) {
      this.handleError(err, {
        object: 'unitType',
        objectId: id,
        fallbackCode: 'unitType.delete',
        action: `${moduleName}/${DELETE_UNIT_TYPE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_UNIT_TYPE_POSTING, false)
    }
  }
}

import clingapi from '@cling/api'
import lang from '@cling/language'
import { global } from '@cling/store/action-types'
import mutationTypes from '@cling/store/mutation-types'
import { filterAllowedProperties } from '@cling/utils'

import { actionTypes } from './constants'

const moduleName = 'settings'

const { FORM_RESET_OLD } = global

const {
  DO_LOAD_ALL_SETTINGS,
  LOAD_ALL_SETTINGS,
  DO_LOAD_SETTINGS_COMPANY,
  LOAD_SETTINGS_COMPANY,
  LOAD_SETTINGS_COMPANYUSER,
  UPDATE_SETTINGS,
  DO_UPDATE_SETTINGS,
  FORM_EDIT_COMPANY_SETTINGS,
  DO_LOAD_SETTINGS_COMPANYUSER,
  FORM_SUBMIT_COMPANY_SETTINGS,
  FORM_EDIT_COMPANY_USER_SETTINGS,
  FORM_SUBMIT_COMPANY_USER_SETTINGS
} = actionTypes

const { SET_SETTINGS, SET_SETTINGS_FETCHING, SET_SETTINGS_POSTING } =
  mutationTypes.settings

const { SET_FORM } = mutationTypes.forms
const textSettingNames = ['ataTravel'] // array with name of settings of type text

// array with name of settings that should always be a number
const numericSettingNames = [
  'extraWorkPrice',
  'extraWorkManagementPrice',
  'extraEntrepreneurPercentage',
  'extraOtherPercentage'
]

// Helper to normalize settings
// When updated the function arrayifySettings must be updated accordingly
// settings: Object with key => value
const normalizeSettings = settings => {
  const newSettings = settings
  Object.keys(newSettings).forEach(key => {
    if (textSettingNames.includes(key) && newSettings[key] === '0') {
      newSettings[key] = ''
      // Match boolean if string matches true/false
    } else if (newSettings[key] === 'true') {
      newSettings[key] = true
    } else if (newSettings[key] === 'false') {
      newSettings[key] = false
    } else if (newSettings[key] === 'null') {
      newSettings[key] = null
      // Number?
    } else if (
      typeof newSettings[key] !== 'boolean' &&
      !Number.isNaN(Number(newSettings[key])) &&
      newSettings[key] !== ''
    ) {
      newSettings[key] = Number(newSettings[key])
    }
  })
  return newSettings
}

// Helper to convert settings object to array of setting objects
// used when sending to API.
// When updated the function normalizeSettings must be updated accordingly
// return: Array with setting objects
const arrayifySettings = settingsObj => {
  const settings = []
  Object.keys(settingsObj).forEach(key => {
    let value = settingsObj[key]
    // Convert back value to a string to store in API
    if (settingsObj[key] === true) {
      value = 'true'
    } else if (settingsObj[key] === false) {
      value = 'false'
    }

    // Convert some settings to numbers
    if (numericSettingNames.includes(key)) {
      value = Number(value)
    }

    if (
      textSettingNames.includes(key) &&
      (settingsObj[key] === false || settingsObj[key] === '')
    ) {
      settings.push({ name: key, value: false, type: 'string' })
    } else {
      settings.push({
        name: key,
        value
      })
    }
  })
  return settings
}

export default {
  /**
   * @name DO_LOAD_ALL_SETTINGS
   * Load all current settings for authenticated user
   * @param {Object} Vuex object
   */
  async [DO_LOAD_ALL_SETTINGS]({ dispatch }) {
    await dispatch(DO_LOAD_SETTINGS_COMPANY)
    await dispatch(DO_LOAD_SETTINGS_COMPANYUSER)
  },

  /**
   * @name LOAD_ALL_SETTINGS
   * Load all current settings for authenticated user
   * @param {Object} Vuex object
   */
  async [LOAD_ALL_SETTINGS]({ dispatch }) {
    try {
      await dispatch(DO_LOAD_ALL_SETTINGS)
    } catch (err) {
      this.handleError(err, {
        object: 'settings',
        fallbackCode: 'settings.get',
        action: `${moduleName}/${LOAD_ALL_SETTINGS}`
      })
    }
  },

  /**
   * @name DO_LOAD_SETTINGS_COMPANY
   * Load all companySettings for authenticated user
   * @param {Object} Vuex object
   */
  async [DO_LOAD_SETTINGS_COMPANY]({ commit }) {
    try {
      commit(SET_SETTINGS_FETCHING, true)
      const { data: settings } = await clingapi.get('/companySetting')
      const newSettings = normalizeSettings(settings)
      commit(SET_SETTINGS, { key: 'company', settings: newSettings })

      // If company has setting for ata title, update it
      const { ataTitleKey } = settings
      if (ataTitleKey && ataTitleKey !== 'ata') {
        lang.i18next.addResources(lang.i18next.language, '_common', {
          ata: `$t(${ataTitleKey})`,
          ataThe: `$t(${ataTitleKey}The)`
        })
      }
    } finally {
      commit(SET_SETTINGS_FETCHING, false)
    }
  },

  /**
   * @name LOAD_SETTINGS_COMPANY
   * Load all companySettings for authenticated user
   * @param {Object} Vuex object
   */
  async [LOAD_SETTINGS_COMPANY]({ dispatch }) {
    try {
      await dispatch(DO_LOAD_SETTINGS_COMPANY)
    } catch (err) {
      this.handleError(err, {
        object: 'companySettings',
        fallbackCode: 'settings.get',
        action: `${moduleName}/${LOAD_SETTINGS_COMPANY}`
      })
    }
  },

  /**
   * @name DO_LOAD_SETTINGS_COMPANYUSER
   * Load all companyUserSettings for authenticated user
   * @param {Object} Vuex object
   */
  async [DO_LOAD_SETTINGS_COMPANYUSER]({ dispatch, commit }) {
    try {
      commit(SET_SETTINGS_FETCHING, true)
      const { data: settings } = await clingapi.get('/companyUserSetting')
      const newSettings = normalizeSettings(settings)
      commit(SET_SETTINGS, { key: 'companyUser', settings: newSettings })

      // If the user has a setting to auto trigger purchase event, trigger and clear setting
      const { autoTriggerPurchaseEvent } = newSettings
      if (autoTriggerPurchaseEvent) {
        const { currency, amount, accountType } = autoTriggerPurchaseEvent || {}
        this.$trackEvent?.('purchase', { currency, amount, accountType })
        await dispatch(DO_UPDATE_SETTINGS, {
          key: 'companyUser',
          settings: arrayifySettings({ autoTriggerPurchaseEvent: null })
        })
      }
    } finally {
      commit(SET_SETTINGS_FETCHING, false)
    }
  },
  /**
   * @name LOAD_SETTINGS_COMPANYUSER
   * Load all companyUserSettings for authenticated user
   * @param {Object} Vuex object
   */
  async [LOAD_SETTINGS_COMPANYUSER]({ dispatch }) {
    try {
      await dispatch(DO_LOAD_SETTINGS_COMPANYUSER)
    } catch (err) {
      this.handleError(err, {
        object: 'companyUserSettings',
        fallbackCode: 'settings.get',
        action: `${moduleName}/${LOAD_SETTINGS_COMPANYUSER}`
      })
    }
  },

  /**
   * Update one or many settings
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {String} object.key Settings key, company or companyUsers
   * @param {String|Number|Boolean|Null} object.settings Object of settings to update
   */
  async [UPDATE_SETTINGS]({ dispatch, commit }, { key, settings }) {
    try {
      if (!key) throw new Error('Missing valid parameter key')
      const newSettings = arrayifySettings(settings)
      // Temporary set store with settings while we wait for updates from API.
      commit(SET_SETTINGS, { key, settings })
      // Update API and reload settings
      await dispatch(DO_UPDATE_SETTINGS, { key, settings: newSettings })
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'setting',
        fallbackCode: 'settings.put',
        action: `${moduleName}/${UPDATE_SETTINGS}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name DO_UPDATE_SETTINGS
   * Update settings for authenticated user
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {String} object.key String of the setting type, can match company or companyUser
   * @param {Object[]} object.settings Array of objects
   */
  async [DO_UPDATE_SETTINGS]({ dispatch, commit }, { key, settings }) {
    try {
      commit(SET_SETTINGS_POSTING, true)

      if (key === 'company') {
        await clingapi.post('/companySetting', settings)
        dispatch(DO_LOAD_SETTINGS_COMPANY)
      } else if (key === 'companyUser') {
        await clingapi.post('/companyUserSetting', settings)
        dispatch(DO_LOAD_SETTINGS_COMPANYUSER)
      } else {
        throw new Error(
          `Setting key: '${key}' is not valid, cannot update settings.`
        )
      }
    } finally {
      commit(SET_SETTINGS_POSTING, false)
    }
  },
  /**
   * @name FORM_EDIT_COMPANY_SETTINGS
   * Prepare edit companySettings for authenticated user
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {String[]} object.props Optional array of strings which props to load into store
   */
  async [FORM_EDIT_COMPANY_SETTINGS]({ commit, state }, { props = [] } = {}) {
    try {
      const formData = filterAllowedProperties(state.company, props)
      commit(
        `forms/${SET_FORM}`,
        { key: 'companySettings', formData },
        { root: true }
      )
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'setting',
        fallbackCode: 'setting.editCompany',
        action: `${moduleName}/${FORM_EDIT_COMPANY_SETTINGS}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name FORM_SUBMIT_COMPANY_SETTINGS
   * Submit company settings
   * @param {Object} Vuex object
   */
  async [FORM_SUBMIT_COMPANY_SETTINGS]({ rootGetters, dispatch }) {
    try {
      const settingsData = rootGetters['forms/getFormByKey']('companySettings')
      const settings = arrayifySettings(settingsData)
      await dispatch(DO_UPDATE_SETTINGS, {
        key: 'company',
        settings
      })
      await this.dispatch(FORM_RESET_OLD, 'companySettings')
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'setting',
        fallbackCode: 'setting.submitCompany',
        action: `${moduleName}/${FORM_SUBMIT_COMPANY_SETTINGS}`
      })
      return false
    }
  },
  /**
   * @name FORM_EDIT_COMPANY_USER_SETTINGS
   * Prepare edit companyUserSettings for authenticated user
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {String[]} object.props Optional array of strings which props to load into store
   */
  async [FORM_EDIT_COMPANY_USER_SETTINGS](
    { commit, state },
    { props = [] } = {}
  ) {
    try {
      const formData = filterAllowedProperties(state.companyUser, props)
      commit(
        `forms/${SET_FORM}`,
        { key: 'companyUserSettings', formData },
        { root: true }
      )
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'setting',
        fallbackCode: 'setting.editCompanyUser',
        action: `${moduleName}/${FORM_EDIT_COMPANY_USER_SETTINGS}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name FORM_SUBMIT_COMPANY_USER_SETTINGS
   * Submit companyUser settings
   * @param {Object} Vuex object
   */
  async [FORM_SUBMIT_COMPANY_USER_SETTINGS]({ rootGetters, dispatch }) {
    try {
      const settingsData = rootGetters['forms/getFormByKey'](
        'companyUserSettings'
      )
      const settings = arrayifySettings(settingsData)
      await dispatch(DO_UPDATE_SETTINGS, {
        key: 'companyUser',
        settings
      })
      await this.dispatch(FORM_RESET_OLD, 'companyUserSettings')
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'setting',
        fallbackCode: 'setting.submitCompanyUser',
        action: `${moduleName}/${FORM_SUBMIT_COMPANY_USER_SETTINGS}`
      })
      return false
    }
  }
}

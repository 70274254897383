import { GeneralError } from '@cling/services/error/types'
import { getType, getUniqueArrayElements } from '@cling/utils'

import Vue from 'vue'

export { getUniqueArrayElements } // Re-export as this func was moved to utils

/**
 * Allows you to use dot separated keys to populate the store
 * @param {String} state The current state
 * @param {String} key A dot separated key, e.g. document.id
 * @param {Boolean} doCreatePath Optional boolean if the path should be created if missing, defaults to false
 * @returns {Object} An object with root and prop(is equal to key)
 */
export function handleStateByKey(state, key, doCreatePath = false) {
  // transform "array keys" to dotted
  let keys = key.replace(/\[(\d*)\]/gi, (m, p1) => `.${p1}`)
  // Is key a . separated path?
  keys = keys.split('.')
  let root = state
  const prop = keys.pop() // always last part of keys
  if (keys.length > 0) {
    root = keys.reduce((result, currentValue) => {
      let res = result[currentValue]
      // If res was not found and doCreatePath is true, create an empty object
      if (
        doCreatePath &&
        (getType(res) === 'undefined' || getType(res) === 'null')
      ) {
        Vue.set(result, currentValue, {})
        res = result[currentValue]
      }
      return res
    }, state)
  }
  return { root, prop }
}

/**
 * Merge two objects, will overwrite "old" values with new.
 * Arrays will NOT be merged, "old" arr will be replaced with new
 * @param {Object} old Object to modify
 * @param {Object} updates Object to merge
 * @returns {void}
 */
export function deepMergeReplace(old, updates) {
  function deepMergeReplaceRec(oldDoc, newDoc) {
    Object.keys(newDoc).forEach(key => {
      const oldIsObject =
        oldDoc[key] instanceof Object && !Array.isArray(oldDoc[key])
      const newIsObject =
        newDoc[key] instanceof Object && !Array.isArray(newDoc[key])

      if (oldIsObject && newIsObject) {
        // Lets keep going deeper :)
        deepMergeReplace(oldDoc[key], newDoc[key])
        return
      }

      Vue.set(oldDoc, key, newDoc[key])
    })
  }
  deepMergeReplaceRec(old, updates)
}

/**
 * @typedef {Object} ConvertArrToObjectReturn
 * @property {Number[]} ids Array of converted ids
 * @property {Object} data Object with ids as root keys
 */
/**
 * Takes an array and returns an object with ids as keys.
 * [{ id:10, x: 3 }, { id: 20, x: 4 }] converts to:
 * { 10: { id: 10, x: 3 }, 20: { id: 20, x:4 }}
 *
 * @param {Object[]} arr Array to convert
 * @param {Object[]} keyId Id key that should be used
 * @returns {ConvertArrToObjectReturn} array with only the unique elements
 */
export function convertArrToObject(arr, { keyId = 'id' } = {}) {
  const ids = []
  const values = {}

  arr.forEach(element => {
    values[element[keyId]] = element
    ids.push(element[keyId])
  })

  return { ids, values }
}

/**
 * Helper to redirect and handle next when user submits document
 * @param {Object} object Optional
 * @param {String} object.next String what to do next, can be project or preview
 * @param {Number} object.projectId ProjectId
 * @param {String} object.docType String for documentType
 * @param {Number} object.docId Id of the document
 * @returns {Boolean} True if user was redirected or false
 */
export function handleNext(
  { next = null, projectId, docType, docId } = {},
  router,
  onComplete = undefined
) {
  if (!next || !router) {
    return false
  }

  switch (next) {
    case 'project': {
      if (!projectId) {
        throw new GeneralError(
          `Cannot redirect user on next: '${next}' as no projectId is defined`
        )
      }
      router.push(
        { name: 'project', params: { id: projectId, modal: false } },
        onComplete
      )
      break
    }
    case 'preview': {
      // Document
      if (!projectId) {
        throw new GeneralError(
          `Cannot redirect user on next: '${next}' as no projectId is defined`
        )
      }
      if (!docType) {
        throw new GeneralError(
          `Cannot redirect user on next: '${next}' as no docType is defined`
        )
      }
      if (!docId) {
        throw new GeneralError(
          `Cannot redirect user on next: '${next}' as no docId is defined`
        )
      }
      router.push(
        { name: 'Preview', params: { projectId, docType, docId } },
        onComplete
      )
      break
    }
    default: {
      throw new GeneralError(
        `Cannot redirect user on next: '${next}' as it is not implemented`
      )
    }
  }
  return true
}

export { default as normalize } from './normalize'

export default {
  handleStateByKey
}

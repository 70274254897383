export const actionTypes = {
  LOAD_CERTIFICATES: 'LOAD_CERTIFICATES',
  DO_SET_COMPANY_CERTIFICATES: 'DO_SET_COMPANY_CERTIFICATES',
  SUBMIT_CERTIFICATES: 'SUBMIT_CERTIFICATES'
}

export const mutationTypes = {
  SET_CERTIFICATES: 'SET_CERTIFICATES',
  CLEAR_CERTIFICATES: 'CLEAR_CERTIFICATES',
  SET_CERTIFICATES_FETCHING: 'SET_CERTIFICATES_FETCHING',
  SET_CERTIFICATES_POSTING: 'SET_CERTIFICATES_POSTING'
}

export const initialState = () => ({
  isUploading: false, // Helper boolean to track if any upload is in progress
  article: {},
  document: {},
  document2: {},
  projectNote: {},
  company: {},
  companyBranding: {},
  companyUser: {},
  companySettings: {},
  companyUserSettings: {},
  moduleArticles: [], // array of article objects
  modulePrices: {}, // price object to calculate prices
  file: {},
  companyReference: {},
  companyReferenceItems: [], // array of reference items
  endCustomer: {},
  timeline: {},
  documentTerm: {}
})

export default {
  ...initialState()
}

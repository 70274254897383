/* eslint-disable no-prototype-builtins */

// Helper functions, and a main getDefaults to get default data from a json schema
// From https://github.com/trilogy-group/ignite-chute-opensource-json-schema-defaults

const isObject = item =>
  typeof item === 'object' && item !== null && item.toString() === {}.toString()
const cloneJSON = source => JSON.parse(JSON.stringify(source))

/**
 * returns a result of deep merge of two objects
 *
 * @param {Object} target
 * @param {Object} source
 * @return {Object}
 */
const merge = (target, source) => {
  target = cloneJSON(target)

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (isObject(target[key]) && isObject(source[key])) {
        target[key] = merge(target[key], source[key])
      } else {
        target[key] = source[key]
      }
    }
  }
  return target
}

/**
 * get object by reference. works only with local references that points on
 * definitions object
 *
 * @param {String} path
 * @param {Object} definitions
 * @return {Object}
 */
const getLocalRef = (path, definitions) => {
  path = path.replace(/^#\/definitions\//, '').split('/')

  const find = (currPath, root) => {
    const key = currPath.shift()
    if (!root[key]) {
      return {}
    } else if (!currPath.length) {
      return root[key]
    }
    return find(path, root[key])
  }

  const result = find(path, definitions)

  if (!isObject(result)) {
    return result
  }
  return cloneJSON(result)
}

/**
 * merge list of objects from allOf properties
 * if some of objects contains $ref field extracts this reference and merge it
 *
 * @param {Array} allOfList
 * @param {Object} definitions
 * @return {Object}
 */
const mergeAllOf = (allOfList, definitions) => {
  const { length } = allOfList
  let index = -1
  let result = {}

  while (++index < length) {
    let item = allOfList[index]

    item =
      typeof item.$ref !== 'undefined'
        ? getLocalRef(item.$ref, definitions)
        : item

    result = merge(result, item)
  }

  return result
}

/**
 * returns a object that built with default values from json schema
 *
 * @param {Object} schema
 * @param {Object} definitions
 * @return {Object}
 */
const defaults = (schema, definitions) => {
  if (typeof schema.default !== 'undefined') {
    return schema.default
  } else if (typeof schema.allOf !== 'undefined') {
    const mergedItem = mergeAllOf(schema.allOf, definitions)
    return defaults(mergedItem, definitions)
  } else if (typeof schema.$ref !== 'undefined') {
    const reference = getLocalRef(schema.$ref, definitions)
    return defaults(reference, definitions)
  } else if (schema.type === 'object') {
    if (!schema.properties) {
      return {}
    }

    for (const key in schema.properties) {
      if (schema.properties.hasOwnProperty(key)) {
        schema.properties[key] = defaults(schema.properties[key], definitions)

        if (
          typeof schema.properties[key] === 'undefined' ||
          (isObject(schema.properties[key]) &&
            !Array.isArray(schema.properties[key]) &&
            !Object.keys(schema.properties[key]).length)
        ) {
          delete schema.properties[key]
        }
      }
    }

    return schema.properties
  } else if (schema.type === 'array') {
    if (!schema.items) {
      return []
    }

    // minimum item count
    const ct = schema.minItems || 0
    // tuple-typed arrays
    if (schema.items.constructor === Array) {
      const values = schema.items.map(item => defaults(item, definitions))
      // remove undefined items at the end (unless required by minItems)
      for (let i = values.length - 1; i >= 0; i--) {
        if (typeof values[i] !== 'undefined') {
          break
        }
        if (i + 1 > ct) {
          values.pop()
        }
      }
      return values
    }
    // object-typed arrays
    const value = defaults(schema.items, definitions)
    if (typeof value === 'undefined') {
      return []
    }
    const values = []
    for (let i = 0; i < Math.max(1, ct); i++) {
      values.push(cloneJSON(value))
    }
    return values
  }
}

/**
 * Get default data from a jsonSchema
 *
 * @param {Object} schema
 * @param {Object|undefined} definitions
 * @return {Object}
 */
export const getDefault = (schema, definitions) => {
  if (typeof definitions === 'undefined') {
    definitions = schema.definitions || {}
  } else if (isObject(schema.definitions)) {
    definitions = merge(definitions, schema.definitions)
  }

  return defaults(cloneJSON(schema), definitions)
}

export default {
  getDefault
}

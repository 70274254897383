import { extensionApi } from '@cling/api'
import config from '@cling/config'
import { handleError } from '@cling/services/error'
import logger from '@cling/services/logger'
import webStorage from '@cling/utils/webStorage'

import { crmExtensions } from '@/static'

// Get app install URL
export const redirectAuthUrl = async (app, isPublic = false) => {
  if (app === 'hubspot') app = 'hubspotNative'
  const fn = isPublic ? 'getPublicAuthUrl' : 'getAuthUrl'

  const { data: authUrl } = await extensionApi[fn](app, { brand: config.brand })

  if (!authUrl) return

  // If framed window, message redirect to parent
  if (window.top !== window.self)
    window.parent.postMessage({ action: 'redirect', url: authUrl }, '*')
  else if (window.location) window.location.replace(authUrl)
}

// Router guards designed to direct flow
// from the moment user clicks 'Install' in external App
export const appAuthGuards = {
  beforeAll: (to, from, next) =>
    !crmExtensions[to.params?.app] ? next({ name: 'notFound' }) : next(),
  beforeAuth: async (to, from, next) => {
    const token = webStorage.getItem('token')

    // If existing account -> redirect to external app auth url
    if (token) await redirectAuthUrl(to.params.app)

    // Continue to login / register
    return next()
  },
  beforeInstall: async (to, from, next) => {
    const token = webStorage.getItem('token')
    const appSignOn = !!webStorage.getItem('ext-setup-type')
    // Only continue without token if using "app sign on"
    if (!token && !appSignOn) next({ name: 'AppAuth', params: to.params })

    const { code } = to.query || {}
    if (!code) await redirectAuthUrl(to.params.app, !token)

    // We currently only use brand in state on format 'brand=cling'
    // Split state on : as it is on format 'brand=cling:companyUserId=123'
    try {
      const { state = '' } = to.query || {}
      const stateParts = state.split(':')
      stateParts.forEach(statePart => {
        const [key, value] = statePart.split('=')
        // State brand: Redirect to correct brand
        if (key === 'brand') {
          if (value !== config.brand) {
            const queryString = Object.keys(to.query)
              .map(key => `${key}=${to.query[key]}`)
              .join('&')
            const redirect = `${to.path}${queryString ? `?${queryString}` : ''}`
            if (config.otherBrandUrl) {
              if (config.environment === 'production') {
                window.location = `${config.otherBrandUrl}${redirect}`
                return next(false)
              }

              logger.debug(
                `Brand mismatch! Should have re-directed to '${config.otherBrandUrl}' but only in env production`
              )
            }
          }
        }
      })
    } catch (err) {
      handleError(err, { showMessage: false })
    }
    return next()
  }
}

<script>
import { sortCompareFunction } from '@cling/utils'

export default {
  name: 'SmartList',
  props: {
    defaultSort: {
      type: String,
      default: 'desc',
      validator(value) {
        return ['asc', 'desc'].includes(value)
      }
    },
    filterMethod: {
      type: Function,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    sortMethod: {
      type: Function,
      default: null
    },
    sortKey: {
      type: String,
      default: ''
    },
    query: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      sortBy: this.sortKey,
      sortOrder: 1
    }
  },
  computed: {
    filteredItems() {
      let data = this.items
      if (this.query) {
        data = !this.filterMethod
          ? this.searchedItems
          : this.filterMethod(this.query, this.items)
      }
      if (this.sortBy || this.defaultSort) {
        data = !this.sortMethod
          ? this.sortedItems
          : this.sortMethod(this.sort, this.searchedItems)
      }
      return data
    },
    searchedItems() {
      return this.items.filter(row => {
        if (typeof row === 'object') {
          return Object.keys(row).some(key =>
            String(row[key]).toLowerCase().includes(this.query.toLowerCase())
          )
        }
        return String(row).toLowerCase().includes(this.query.toLowerCase())
      })
    },
    sortedItems() {
      return this.searchedItems.slice().sort((a, b) => {
        if (typeof a === 'object' && typeof b === 'object') {
          a = a[this.sortBy]
          b = b[this.sortBy]
        }
        return (a === b ? 0 : sortCompareFunction(a, b)) * this.sortOrder
      })
    }
  },
  created() {
    if (this.defaultSort) {
      this.sortOrder =
        this.defaultSort === 'asc' ? this.sortOrder : this.sortOrder * -1
    }
  },
  methods: {
    sort(by) {
      this.sortBy = by
      this.sortOrder = this.sortOrder * -1
    }
  },
  render() {
    return this.$scopedSlots.default({
      items: this.filteredItems,
      sortBy: this.sort,
      sort: {
        by: this.sortBy,
        order: this.sortOrder === 1 ? 'asc' : 'desc'
      }
    })
  }
}
</script>

import config from '@cling/config'

export default {
  install(Vue, { store } = {}) {
    const brandObj = Vue.observable({ brand: config.brand })

    // Make available i store
    store.$brand = brandObj.brand // Note: Is set if changed below

    Vue.mixin({
      computed: {
        $brand: {
          get: () => brandObj.brand,
          set: brand => {
            brandObj.brand = brand
            store.$brand = brand // Update store property as well
          }
        }
      }
    })
  }
}

/* eslint-disable no-console */
import config from '@cling/config'

export const logLevels = {
  debug: 4,
  info: 3,
  warn: 2,
  error: 1
}

class Logger {
  setLevel(level) {
    this._level = typeof level === 'number' ? level : logLevels[level]
  }

  debug(...args) {
    if (this._level < 4) return
    console.debug(...args)
  }

  info(...args) {
    if (this._level < 3) return
    console.log(...args)
  }

  warn(...args) {
    if (this._level < 2) return
    console.warn(...args)
  }

  error(...args) {
    if (this._level < 1) return
    console.error(...args)
  }

  event(object) {
    // TODO: save and handle event
    this.debug(object)
    if (typeof object === 'object' && object.error) {
      this.error(object.error)
    }
  }
}

const logger = new Logger()

logger.setLevel(config.logLevel)

export default logger

// Same function used in landing-page
function getUrlUtm() {
  const urlParams = new URLSearchParams(window.location.search)
  const params = {
    utm_source: urlParams.get('utm_source'),
    utm_medium: urlParams.get('utm_medium'),
    utm_campaign: urlParams.get('utm_campaign'),
    utm_content: urlParams.get('utm_content'),
    utm_term: urlParams.get('utm_term')
  }

  const str = Object.keys(params).reduce(
    (res, key) => (params[key] ? `${res}${key}:${params[key]},` : res),
    ''
  )
  return str
}

export default {
  install(Vue, { store } = {}) {
    let utm = getUrlUtm()

    // If no utm was found, use referrer
    if (!utm) utm = document.referrer || null

    function setUtm(value) {
      Vue.prototype.$utm = value
      store.$utm = value
    }

    Vue.prototype.$setUtm = setUtm
    setUtm(utm)
  }
}

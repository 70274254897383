import { document } from '@cling/api'
import config from '@cling/config'

import get from 'lodash/get'

import { filterEmptyProps } from './utils'

import Document from './index'

/** class for company specific document activities */

const removeArticlePrices = articles =>
  articles.map(a => ({ ...a, price: 0, totalAmount: 0, showPrice: false }))

class CompanyDocument extends Document {
  /**
   * @param {Object} obj
   * @param {Object} obj.data The document root
   * @param {Object} obj.documentOptions
   * @param {function} obj.can Function to use permissions
   * @param {Object} obj.getters Vuex getters
   * @param {Boolean} obj.updateSender optional if the sender should be updated, or use from doc.company / doc.companyUser
   */
  constructor({
    data,
    documentOptions = {},
    can,
    getters,
    updateSender = false
  }) {
    if (!getters) throw new Error('Missing param getters')
    let inputData = data
    if (can && !can('prices', 'read') && get(data, 'articles', []).length) {
      inputData = {
        ...data,
        articles: removeArticlePrices(data.articles)
      }
    }
    const {
      senderString: defaultSenderString,
      receiverString: defaultReceiverString,
      // sender settings
      fSkatt,
      insurance,
      // default theme
      offerBrandingBackgroundUrl: background,
      documentThemeBackgroundFilter: backgroundFilter,
      documentShowPriceSummary: showPriceSummary,
      documentThemePriceBackground: priceSummaryBackground,
      documentThemePrimaryColor: headingColor,
      presentationFontFamily: fontFamily, // ? does this still exist as a setting ?
      showClingLogo
    } = getters['settings/allCompanySettings'] || {}
    super(inputData, {
      ...documentOptions,
      props: {
        defaultSenderString,
        defaultReceiverString,
        ...documentOptions.props
      },
      readPrices: can ? can('prices', 'read') : true,
      isPreview: true,
      settingsTheme: filterEmptyProps({
        background,
        backgroundFilter,
        showPriceSummary,
        priceSummaryBackground,
        headingColor,
        fontFamily,
        showClingLogo
      })
    })
    this._documentOptions = documentOptions
    this._can = can
    this._defaultScope = 'documents'
    this._getters = getters

    // Read sender from live if the parameters tells us to, or missing company or companyUser on doc data
    const useLiveSender =
      updateSender ||
      !Object.keys(get(this, '_doc.company', {})).length ||
      !Object.keys(get(this, '_doc.companyUser', {})).length
    const senderCompany = useLiveSender
      ? this._doc.companyId
        ? getters['companies/companyById'](this._doc.companyId)
        : getters['application/company']
      : this._doc.company

    // NOTE: Since senderCompany is a reference to actual store object
    // fSkat and insurance will be added to the store in company
    // which is declared as fine for now, until we eventually move them to the parties block
    if (senderCompany && useLiveSender) {
      senderCompany.fSkatt = fSkatt
      senderCompany.insurance = insurance
    }

    const senderUser = useLiveSender
      ? this._doc.companyUserId
        ? getters['companyUsers/referenceById'](this._doc.companyUserId)
        : getters['application/user']
      : this._doc.companyUser

    this.sender = this._documentOptions.sender || {
      company: senderCompany,
      user: senderUser
    }

    // Custom base viewRoute
    const companyAccount = this._getters['application/companyAccount']
    const customSubdomain = get(companyAccount, 'customViewRoute')
    const hasCustomSubdomain =
      this._getters['application/feature']('subdomain') && customSubdomain

    this._baseDomain = hasCustomSubdomain ? customSubdomain : config.baseUrl
  }
  can(permission, scope = null, id = null) {
    if (!this._can) return true
    if (typeof permission !== 'string')
      throw new Error('Permission must be of type String')
    return this._can(scope || this._defaultScope, permission, id || this.id)
  }
  get companyId() {
    return this._doc.companyId
  }
  get companyUserId() {
    return this._doc.companyUserId
  }

  resetReminders() {
    super.resetReminders()

    let { reminders } = this
    if (!reminders.length) return

    // Set days from companySettings
    const { _getters: getters } = this
    const reminderSettings = getters['settings/reminderSettings']

    const setSendDays = (type, sendDays) =>
      reminders
        .filter(({ type: currentType }) => currentType === type)
        .map(reminder => {
          reminder.sendDays = sendDays
          return true
        })

    if (typeof reminderSettings.notAnswered !== 'undefined')
      setSendDays('reminder', reminderSettings.notAnswered)
    if (typeof reminderSettings.notViewed !== 'undefined')
      setSendDays('viewed', reminderSettings.notViewed)
    if (typeof reminderSettings.expires !== 'undefined')
      setSendDays('expires', reminderSettings.expires)

    // Filter out any reminders that should be disabled
    reminders = reminders.filter(
      ({ type, sendDays }) => type === 'first' || sendDays
    )
    this.reminders = reminders
  }

  /**
   * Clone a companyDocument, returns a identical copy
   * @returns {Document} Returns a exact deep clone of the document
   */
  clone() {
    const newDocument = new CompanyDocument({
      data: JSON.parse(JSON.stringify(this._doc)),
      documentOptions: this._documentOptions,
      can: this._can,
      getters: this._getters
    })
    return newDocument
  }

  /**
   * Validate the current document with template API-side
   * @returns {Promise<Boolean>} Resolves with true if valid, otherwise false
   */
  async isValid() {
    const body = this.getRawData()
    try {
      await document.validate(body)
      return true
    } catch (err) {
      return false
    }
  }

  // Extend getter with doc links
  get clients() {
    return super.clients.map(client => ({
      ...client,
      docLink: `${this._baseDomain}/document/${client.publicDocumentId}`,
      ...(client.customDocLinkRendered // If a custom doc link is provided use that instead
        ? { docLink: client.customDocLinkRendered }
        : {})
    }))
  }

  // Setter also needs forwarding because getter is exposed
  set clients(value) {
    super.clients = value
  }

  get signOptions() {
    let signOptions = get(this, '_doc.data.signOptions') || [] // stored custom available sign methods
    const canUseBankId = this._getters['application/feature']('bankId')

    // Use custom signMethods if provided, remove miss-match on feature level
    if (Array.isArray(signOptions) && signOptions.length) {
      if (signOptions.includes('bankId') && !canUseBankId)
        signOptions = signOptions.filter(method => method !== 'bankId')

      return signOptions
    }

    const methods = ['signature', 'inPerson']
    if (canUseBankId) methods.unshift('bankId')

    return methods
  }
}

export default CompanyDocument

export default {
  fallback: 'An unexpected error occurred',
  1: 'Sorry, something went wrong. Try refresh the page.',
  100: 'You have to set a name for the template',
  101: 'The content of the template can not be empty',
  102: 'Type of template can not be empty',
  201: 'Email can not be empty',
  202: 'You need to enter a correct email',
  203: 'The email address has unsubscribed to receive messages from you, was not able to send email.',
  204: 'Something is wrong with the unsubscribe link, please contact us and we will help you.',
  205: 'Something is wrong with the unsubscribe link, please contact us and we will help you.',
  300: 'Was not able to send SMS. Please check that the phone number is a correct cellphone number and try again. ',
  301: 'You have reached your limit of free proposals, upgrade to premium to continue sending.',
  302: 'You have reached your limit of free proposals, upgrade to premium',
  400: 'The @:ata name can not be empty',
  401: 'Valid until date for the @:ataThe can not be empty',
  500: 'Certificate name can not be empty',
  501: 'Certificate description can not be empty',
  502: 'Certificate website can not be empty',
  503: 'Website for the certificate must be a valid URL',
  601: 'Cant save reminder as active if there is no send date.',
  602: 'Can not save reminder date must be a future date',
  603: 'Can not save reminder ',
  700: 'Valid until date for proposal can not be empty',
  800: 'The project name can not be empty',
  900: 'Cant find file',
  901: 'The file do not belong to the current company',
  1001: 'User email can not be empty',
  1002: 'User email must be a valid email',
  1003: 'User password can not be empty',
  1004: 'User first name can not be empty',
  1005: 'User last name can not be empty',
  1006: 'User phone number can not be empty',
  2001: 'The name for the setting can not be empty',
  2002: 'The value for the setting can not be empty',
  3001: 'Company name can not be empty',
  3002: 'Company email address can not be empty',
  3003: 'Company email must be a valid email',
  3004: 'Company website URL can not be empty',
  3005: 'Company website URL must be a valid URL',
  3006: 'Instagram URL can not be empty',
  3007: 'Instagram URL must be a valid URL',
  3008: 'Facebook URL can not be empty',
  3009: 'Facebook URL must be a correct URL',
  3010: 'Reco URL can not be empty',
  3011: 'Reco URL must be a valid URL',
  3012: 'SMS sender name can be maximum 11 characters long',
  4001: 'The email address is already used',
  4002: 'Your password needs to be at least 8 characters long',
  4003: 'User email can not be empty',
  4004: 'User email must be a valid email.',
  4005: 'User password can not be empty',
  4006: 'Was not able to set new password, the reset link might have expired',
  4007: 'You already have the max amount of project leaders on this account, contact support for more help',
  4009: 'The old password does not match',
  4010: 'Could not find any account',
  5001: 'The product must have a name',
  5002: 'Product price needs to be on the right format',
  5003: 'Vat/Tax for the product needs to be on the right format',
  6001: 'The client needs to have a name',
  7001: "You don't have permission. Try refresh the page or contact an account administrator.",
  7002: "You don't have permission to access this module. Try refresh the page or contact an account administrator.",
  8001: 'Your token has expired. Please try to refresh the page.',
  9001: 'A signing process is already initiated.',
  9002: 'The signing process took to long. Please try again.',
  9003: 'You aborted the signing process. Please try again.',
  9004: 'Signing process was interrupted. Please try again. ',
  9005: 'Your BankID certificate has expired. Contact your bank to renew it.',
  9006: "The BankID application don't seems to be installed on your computer or phone. Please install it and get a BankID from your bank. To install the program goto install.bankid.com",
  9007: 'Social security number is missing. Re-enter it and try again.',
  9008: 'Internal error. Try again.',
  9010: 'Verify that the BankID app is up to date',
  10001: 'You must enter a coupon code',
  10002: 'Coupon code is not valid',
  10003: 'This coupon has already been used',
  11001: 'Method of book keeping must have a valid value',
  12001: 'Due date must be entered',
  12002: 'Delivery date must be entered when there is products as goods ',
  12003:
    'When delivery method is manual it is not possible to choose other methods ',
  12004: 'Can not send invoice both as e-invoice and by mail at the same time',
  12005: 'No delivery method is specified',
  12006: 'A credit invoice must have a negative amount',
  12007: 'Could not create PDF for invoice',
  12008: 'Only invoices with status draft can be attested',
  12009: 'Invoicing partner is not implemented',
  12010: 'Invoice is disputed',
  12011: 'Invoice is not marked as disputed',
  12012: 'Amount is invalid',
  12013: 'Vat/Tax is invalid',
  12014: 'User ID is missing',
  12015: 'Can not create reminder since the invoice status is not valid',
  12016: 'Can not create reminder since delivery method is not valid',
  12017: 'Delivery method is not valid',
  12018: 'Can not create payment reminder since no fee has been specified',
  12019: 'Can not send invoice that has a manual delivery method',
  12020: 'No client has been entered on the invoice',
  12021: 'Persons for deduction has not been entered correctly',
  12022: 'Property designation number is not correct',
  12023: 'Apartment number is not correct',
  12024: 'Housing association organisation number is not correct',
  12025: 'Property designation number is not correct',
  12026:
    'Can not sync settings, first name for invoicing contact person is missing',
  12027:
    'Can not sync settings, last name for invoicing contact person is missing',
  12028: 'Can not sync settings, phone for invoicing contact person is missing',
  12029: 'Can not sync settings, email for invoicing contact person is missing',
  12030: 'The invoice has no products',
  12031: 'Could not sync settings, Company registration number is missing',
  12032: 'Could not sync settings, the company name is missing',
  12033: 'Could not sync settings, the company address is missing',
  12034: 'Could not sync settings, the company is missing a zip code',
  12035: 'Could not sync settings, the company city is missing',
  12036: 'Could not sync settings, no company email added',
  12037: 'Can not sync settings, company is missing next invoice number',
  12038: 'Can not update invoice that is not in status draft',
  12039: 'Payment date not valid',
  12040: 'Payment type not valid',
  12041: 'Can not create PDF for invoice that is not attested',
  12042: 'The clients social security number must be entered to sell invoice',
  12043:
    'The clients company registration number must be entered to sell invoice',
  13001: 'End date must be after start date.',
  14001: 'Cannot update document. The document has signatures.',
  15001: 'API key is not valid',
  16001: 'Your card has insufficient funds',
  16002: 'Your card was declined',
  17001: 'The domain is already in use',
  17002: 'Cannot remove a verified domain please contact support',
  18001: 'You have an old version of a document, reload to fetch last version.',
  18002: 'The document is already answered, reload to fetch last version.',
  18003: 'The document is voided, reload to fetch to last version',
  18004:
    'The document have expired and cannot be answered, reload to fetch last version.',
  ECONNREFUSED: 'No response from server, wait a few seconds and try again.',
  project: {
    get: 'Project could not be loaded',
    post: 'Could not create project',
    demo: 'Could not create example project',
    put: 'Could not update project',
    delete: 'Could not delete project',
    patch: 'Could not undo delete project',
    quickview: 'Could not quick pick project',
    projectLeader: 'Was not able to choose project leader',
    send: 'Project could not be sent',
    member: {
      post: 'Could not add member',
      delete: 'Member could not be removed'
    }
  },
  article: {
    get: 'Could not load product',
    submit: 'Could not save product',
    put: 'Could not update product',
    delete: 'Could not delete product',
    patch: 'Cloud not undo delete product',
    search: 'Could not search product'
  },
  companies: {
    submit: 'Could not save company'
  },
  companyReference: {
    get: 'Could not load reference',
    post: 'Could not create reference',
    put: 'Could not update reference',
    delete: 'Could not remove reference',
    patch: 'Could not undo delete reference'
  },
  companyReferenceItem: {
    get: 'Could not load reference photo',
    post: 'Could not create reference photo',
    put: 'Could not update reference photo',
    delete: 'Could not remove reference photo'
  },
  notification: {
    getAll: 'Could not load all notifications',
    getUnread: 'Could not load unread notifications',
    get: 'Could not load notification',
    setReadForProject:
      'Could not mark all notifications as read for the project',
    setReadForInvoice: 'Could not mark all invoice notifications as read',
    setRead: 'Could not mark notification as read',
    setAllRead: 'Could not mark all notifications as read'
  },
  certificate: {
    get: 'Could not load certificates',
    submit: 'Could not save your certificates'
  },
  endclient: {
    getAll: 'Could not load all clients',
    get: 'Could not load client',
    post: 'Could not save new client',
    put: 'Could not update client',
    delete: 'Could not delete client',
    patch: 'Could not undo delete client',
    submit: 'Could not create client'
  },
  companyUser: {
    getAll: 'Could not load all users',
    get: 'Could not load user',
    post: 'Could not save new user',
    put: 'User could not be updated',
    delete: 'User could not be deleted',
    patch: 'Could not undo delete user'
  },
  file: {
    get: 'File could not be loaded',
    post: 'New file could not be saved',
    put: 'File could not be updated',
    delete: 'File could not be deleted',
    patch: 'Could not undo delete file'
  },
  projectFile: {
    get: 'Project file could not be loaded',
    post: 'Project file could not be saved',
    put: 'Project file could not be updated',
    delete: 'Project file could not be removed'
  },
  projectNote: {
    get: 'Note could not be loaded',
    edit: 'Was not able to edit note',
    submit: 'Was not able to save note'
  },
  propertyDesignation: {
    get: 'Could not load property designation',
    edit: 'Could not edit property designation',
    submit: 'Could not save property designation'
  },
  setting: {
    put: 'Was not able to update setting',
    editCompany: 'Was not able to load company settings',
    editInvoice: 'Was not able to load invoice settings',
    submitCompany: 'Was not able to save company settings',
    editCompanyUser: 'Was not able to load user settings',
    submitCompanyUser: 'Was not able to save user settings',
    submitInvoice: 'Could not save invoice settings'
  },
  templateMessage: {
    getAll: 'Was not able to load templates',
    get: 'Was not able to load template',
    post: 'Was not able to save template',
    put: 'Template could not be updated',
    delete: 'Template could not be deleted',
    patch: 'Was not able to undo delete template',
    new: 'Was not able to prepare new template',
    edit: 'Was not able to prepair edit template',
    submit: 'Was not able to save template'
  },
  document: {
    get: 'Was not able to load document',
    delete: 'Was not able to delete document',
    patch: 'Was not able to undo delete document',
    submit: 'Was not able to save document',
    submitStatus: 'Was not able to change status on document'
  },
  documentTerm: {
    getAll: 'Terms could not be loaded',
    get: 'Terms could not be loaded',
    post: 'Could not save terms',
    put: 'Terms could not be updated',
    delete: 'Terms could not be removed',
    patch: 'Could not undo delete terms',
    new: 'Could not prepare new terms',
    edit: 'Could not prepare editable terms ',
    submit: 'Terms could not be saved'
  },
  unitType: {
    get: 'Units could not be loaded',
    put: 'Unit could not be updated',
    delete: 'Unit could not be removed'
  }
}

import i18n from '@cling/language'
import docLang from '@cling/models/document/i18n'

export function setStyleVariables(obj, el) {
  Object.entries(obj).forEach(([key, val]) => {
    el?.style?.setProperty?.(key, val)
  })
}

export function setTranslation(overrides = {}) {
  Object.keys(overrides).forEach(lng => {
    const lang = overrides[lng]
    Object.keys(lang).forEach(ns => {
      const resources = lang[ns]
      const instance = ns === 'document' ? docLang : i18n
      instance.i18next.addResources(lng, ns, resources)
    })
  })
}

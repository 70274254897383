import {
  actionTypes as projectFilesActions,
  mutationTypes as projectFilesMutations
} from './projectFiles/constants'

export const actionTypes = {
  ...projectFilesActions,
  LOAD_PROJECTS: 'LOAD_PROJECTS',
  LOAD_PROJECT: 'LOAD_PROJECT',
  LOAD_PUBLIC_PROJECT: 'LOAD_PUBLIC_PROJECT',
  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_DEMOPROJECT: 'CREATE_DEMOPROJECT',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  PATCH_PROJECT: 'PATCH_PROJECT',
  FORM_EDIT_PROJECT: 'FORM_EDIT_PROJECT',
  DO_SUBMIT_PROJECT: 'DO_SUBMIT_PROJECT',
  SUBMIT_PROJECT: 'SUBMIT_PROJECT',
  DO_LOAD_PROJECT: 'DO_LOAD_PROJECT',
  DO_LOAD_PROJECTS: 'DO_LOAD_PROJECTS',
  DO_LOAD_PROJECTS_COUNT: 'DO_LOAD_PROJECTS_COUNT',
  LOAD_PROJECTS_FOR_CLIENT: 'LOAD_PROJECTS_FOR_CLIENT',
  LOAD_PROJECTS_FOR_USER: 'LOAD_PROJECTS_FOR_USER',
  FORM_SUBMIT_PROJECT: 'FORM_SUBMIT_PROJECT',
  DO_CREATE_PROJECT_NOTE: 'DO_CREATE_PROJECT_NOTE',
  FORM_EDIT_PROJECT_NOTE: 'FORM_EDIT_PROJECT_NOTE',
  FORM_NEW_PROJECT_NOTE: 'FORM_NEW_PROJECT_NOTE',
  FORM_SUBMIT_PROJECT_NOTE: 'FORM_SUBMIT_PROJECT_NOTE',
  LOAD_PROJECT_NOTE: 'LOAD_PROJECT_NOTE',
  DO_UPDATE_PROJECT_NOTE: 'DO_UPDATE_PROJECT_NOTE',
  LOAD_EVENTS_FOR_PROJECT: 'LOAD_EVENTS_FOR_PROJECT',
  DELETE_PROJECT_MEMBER: 'SUBMIT_PROJECT_MEMBER',
  SUBMIT_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER',
  SET_PROJECT_FILTER: 'SET_PROJECT_FILTER',
  SET_PROJECT_SORT: 'SET_PROJECT_SORT',
  SET_QUICKVIEW_PROJECT: 'SET_QUICKVIEW_PROJECT',
  SET_PROJECT_LEADER: 'SET_PROJECT_LEADER',
  CLEAR_PROJECTS: 'CLEAR_PROJECTS'
}

export const mutationTypes = {
  ...projectFilesMutations,
  SET_PROJECTS: 'SET_PROJECTS',
  SET_PROJECTS_FETCHING: 'SET_PROJECTS_FETCHING',
  SET_PROJECTS_POSTING: 'SET_PROJECTS_POSTING',
  SET_PROJECTS_CAN_FETCH_MORE: 'SET_PROJECTS_CAN_FETCH_MORE',
  SET_PROJECTS_COUNT: 'SET_PROJECTS_COUNT',
  CLEAR_PROJECT_DATA: 'CLEAR_PROJECT_DATA',
  SET_PROJECT_NOTES: 'SET_PROJECT_NOTES',
  SET_PROJECT_NOTES_FETCHING: 'SET_PROJECT_NOTES_FETCHING',
  SET_PROJECT_NOTES_POSTING: 'SET_PROJECT_NOTES_POSTING',
  SET_EVENTS: 'SET_EVENTS',
  DELETE_MANY_PROJECTS: 'DELETE_MANY_PROJECTS',
  ADD_PROJECT_MEMBER: 'ADD_PROJECT_MEMBER',
  DELETE_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER'
}

export default {
  actionTypes,
  mutationTypes
}

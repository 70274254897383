<template>
  <label
    ref="label"
    :class="newClass"
    :disabled="disabled"
    class="switch"
    @click="focus"
    @keydown.prevent.enter="$refs.label.click()"
  >
    <input
      ref="input"
      v-model="computedValue"
      :disabled="disabled"
      :name="name"
      :required="required"
      :value="nativeValue"
      :true-value="trueValue"
      :false-value="falseValue"
      type="checkbox"
      @click.stop
    />
    <span :class="[`is-${type}`]" class="check" />
    <span class="control-label"><slot /></span>
  </label>
</template>

<script>
export default {
  name: 'CSwitch',
  props: {
    value: {
      type: [String, Number, Boolean, Function, Object, Array, Date],
      default: null
    },
    nativeValue: {
      type: [String, Number, Boolean, Function, Object, Array, Date],
      default: null
    },
    disabled: Boolean,
    type: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    required: Boolean,
    size: {
      type: String,
      default: ''
    },
    trueValue: {
      type: [String, Number, Boolean, Function, Object, Array, Date],
      default: true
    },
    falseValue: {
      type: [String, Number, Boolean, Function, Object, Array, Date],
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
    newClass() {
      return [
        `is-${this.size}`,
        {
          'is-disabled': this.disabled,
          'is-rounded': this.rounded
        }
      ]
    }
  },
  watch: {
    /**
     * When v-model change, set internal value.
     */
    value(value) {
      this.newValue = value
    }
  },
  methods: {
    focus() {
      // MacOS FireFox and Safari do not focus when clicked
      this.$refs.input.focus()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@cling/styles/theme/utilities/_all.sass';

$speed-slow: 200ms;
$easing: $easeOutBack;
$switch-width-number: 2.5 !default;
$switch-width: $switch-width-number * 1em !default;
$switch-padding: 0.2em !default;

$colors: (
  'primary': (
    var(--primary-color-500),
    var(--white-color),
    var(--primary-color-100),
    var(--primary-color-600)
  ),
  'secondary': (
    var(--primary-color-500),
    var(--white-color),
    var(--primary-color-100),
    var(--primary-color-600)
  ),
  'success': (
    var(--primary-color-500),
    var(--white-color),
    var(--primary-color-100),
    var(--primary-color-600)
  )
);

.switch {
  @extend %unselectable;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-right: 0.5em;
  & + .switch:last-child {
    margin-right: 0;
  }
  input[type='checkbox'] {
    position: absolute;
    left: 0;
    opacity: 0;
    outline: none;
    z-index: -1;
    + .check {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: $switch-width;
      height: calc(#{$switch-width} / 2 + #{$switch-padding});
      padding: $switch-padding;
      background: $grey-light;
      border-radius: $radius;
      transition:
        background $speed-slow $easing,
        box-shadow $speed-slow $easing;
      &:before {
        content: '';
        display: block;
        border-radius: $radius;
        width: calc((#{$switch-width} - #{$switch-padding} * 2.25) / 2);
        height: calc((#{$switch-width} - #{$switch-padding} * 2.25) / 2);
        background: $background;
        /* box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05), */
        /* 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05); */
        transition: transform $speed-slow $easing;
        will-change: transform;
        transform-origin: left;
      }
    }
    &:checked + .check {
      @apply bg-primary-500;
      @each $name, $pair in $colors {
        $color: nth($pair, 1);
        &.is-#{$name} {
          background: hsl($color / 1);
        }
      }
      &:before {
        transform: translate3d(100%, 0, 0);
      }
    }
    &:focus,
    &:active {
      outline: none;
      + .check {
        box-shadow: 0 0 0 0.15em rgba($grey, 0.15);
      }
      &:checked + .check {
        @apply ring-primary-500 ring-2 ring-opacity-20;
        @each $name, $pair in $colors {
          $color: nth($pair, 1);
          &.is-#{$name} {
            box-shadow: 0 0 0 0.15em hsl($color / 0.15);
          }
        }
      }
    }
  }
  .control-label {
    padding-left: 0.5em;
  }
  &:hover {
    input[type='checkbox'] + .check {
      background: rgba($grey-light, 0.9);
    }
    input[type='checkbox']:checked + .check {
      @apply bg-primary-500;
      @each $name, $pair in $colors {
        $color: nth($pair, 1);
        &.is-#{$name} {
          background: hsl($color / 0.9);
        }
      }
    }
  }
  &.is-rounded {
    input[type='checkbox'] {
      + .check {
        border-radius: $radius-rounded;
        &:before {
          border-radius: $radius-rounded;
        }
      }
    }
  }
  &.is-tiny {
    @include control-tiny;
  }
  &.is-small {
    @include control-small;
    font-size: 12px;
  }
  &.is-normal {
    @include control-normal;
  }
  &.is-medium {
    @include control-medium;
  }
  &.is-large {
    @include control-large;
  }
  &[disabled] {
    @apply text-gray-500;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
</style>

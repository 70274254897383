/* eslint-disable no-console */
import { handleStateByKey } from '@cling/store/utils/'
import { addUniqueId, getType } from '@cling/utils'

import Vue from 'vue'
import { updateField } from 'vuex-map-fields'

import { mutationTypes } from './constants'
import document2Mutations from './document2/mutations'

const {
  SET_FORM,
  CLEAR_FORM,
  SET_FORM_VALUE,
  REMOVE_FORM_VALUE,
  REMOVE_FORM_KEY_VALUE
} = mutationTypes

export default {
  updateField,
  ...document2Mutations,
  // Set form by key
  [SET_FORM](state, { key, formData }) {
    // Copy formData before it is inserted into the store
    if (getType(formData) === 'undefined') {
      console.warn('FormData was undefined!')
      return
    }
    const newFormData = JSON.parse(JSON.stringify(formData))

    const { root, prop } = handleStateByKey(state, key, true)
    if (Array.isArray(newFormData)) {
      Vue.set(root, prop, newFormData)
    } else if (typeof newFormData === 'object' && newFormData !== null) {
      const data = Object.assign({}, newFormData)
      Vue.set(root, prop, data)
    } else {
      Vue.set(root, prop, newFormData)
    }

    // Make sure we are only doing next operation when we are setting whole form
    if (key.split('.').length !== 1) {
      console.warn('Using SET_FORM to set individual values')
      return
    }

    // Create a copy of original values to keep as references for looking for changes
    // Since vuex sorts the object keys we must store it as an object for it to match
    state[`_old_${key}`] = JSON.parse(JSON.stringify(state[key] || {}))
  },
  // Clear form by value
  [CLEAR_FORM](state, { key }) {
    const { root, prop } = handleStateByKey(state, key)
    Vue.set(root, prop, {})
  },

  /**
   * @name REMOVE_FORM_VALUE
   */
  [REMOVE_FORM_VALUE](state, key) {
    const { root, prop } = handleStateByKey(state, key)

    if (Array.isArray(root)) {
      Vue.delete(root, prop)
      return
    }

    if (root[prop] && Array.isArray(root[prop])) {
      Vue.set(root, prop, [])
      return
    }

    if (root[prop] && typeof root[prop] === 'object') {
      Vue.set(root, prop, {})
      return
    }
    root[prop] = undefined
  },

  /**
   * @name REMOVE_FORM_KEY_VALUE
   */
  [REMOVE_FORM_KEY_VALUE](state, key) {
    const { root, prop } = handleStateByKey(state, key)
    if (root && prop in root) delete root[prop]
  },

  /**
   * @name SET_FORM_VALUE
   */
  [SET_FORM_VALUE](state, { key, value }) {
    const { root, prop } = handleStateByKey(state, key, true)
    const isNull = getType(value) === 'null'
    const isEmptyArray = getType(value) === 'array' && !value.length
    // if the last prop is an array we know we need to append the object in the end
    if (
      !isNull &&
      root[prop] &&
      getType(root[prop]) === 'array' &&
      !isEmptyArray
    ) {
      // If the value is an array we know that we want to replace the whole value,
      // so we need to skip this step
      if (getType(value) !== 'array') {
        root[prop].push(addUniqueId(value))
        return
      }
    }

    // If the parent to the last prop is an array,
    // we know that we are replacing the whole arr element
    if (!isNull && getType(root) === 'array' && !isEmptyArray) {
      // Let's add a unique id if there is none, otherwise persist the old
      let newValue = addUniqueId(value)
      if (typeof root[prop] === 'object' && root[prop]._uniqueId) {
        newValue = { ...newValue, _uniqueId: root[prop]._uniqueId }
      }

      Vue.set(root, prop, newValue)
      return
    }

    // In all other cases, just replace the thing
    Vue.set(root, prop, value)
  }
}

import { getDeliveryTypes } from './utils'

export default {
  document: ({ document2 }) => {
    if (!Object.keys(document2).length) return null
    return document2
  },
  // Returns activated delivery types in an object keyVal bool
  deliveryTypes: state => {
    return getDeliveryTypes(state.document2)
  },
  getDocumentAccountDefault:
    (state, getters, rootState, rootGetters) =>
    (defaultName, { template }) => {
      let result

      if (defaultName === 'reminders') {
        result = []

        if (!template) return result

        result.push(...template.reminders)

        const setSendDays = (type, sendDays) =>
          result
            .filter(({ type: currentType }) => currentType === type)
            .forEach(reminder => {
              reminder.sendDays = sendDays
            })

        const settings = rootGetters['settings/reminderSettings']

        if (settings.notAnswered !== undefined)
          setSendDays('reminder', settings.notAnswered)
        if (settings.notViewed !== undefined)
          setSendDays('viewed', settings.notViewed)
        if (settings.expires !== undefined)
          setSendDays('expires', settings.expires)

        // Filter out any reminders that should be disabled
        result = result.filter(
          ({ type, sendDays }) => type === 'first' || sendDays
        )
      }
      return result
    }
}

<template>
  <div
    v-if="$can('prices', 'read')"
    class="info-bar c-flex-row wrap"
    style="
      padding: 0.5em 1em 0 1em;
      background-color: hsl(var(--gray-color-100) / 1);
      border-radius: 0.5em;
    "
  >
    <template v-if="loading || !prices.currency">
      <div v-for="n in 2" :key="n" class="info-bar-item">
        <CSkeleton width="5em" color="hsl(0, 0%, 91%)" class="info-bar-title" />
        <CSkeleton width="9em" color="hsl(0, 0%, 91%)" class="info-bar-value" />
        <CSkeleton hidden class="info-bar-sub-value" height="12px" />
      </div>
    </template>
    <template v-else>
      <div class="info-bar-item">
        <div class="info-bar-title">{{ $t('value').capitalize() }}</div>
        <div class="info-bar-value">
          {{
            priceFormat(prices.subTotal, {
              showZero: true,
              currency: prices.currency
            })
          }}
          {{ $t('vat.exVat', { thing: $t('vat.sharedTitle') }) }}
        </div>
        <div class="info-bar-sub-value">
          {{
            priceFormat(prices.total, {
              showZero: true,
              currency: prices.currency
            })
          }}
          {{ $t('vat.incVat', { thing: $t('vat.sharedTitle') }) }}
          {{ prices.hasDeductions ? ` ${$t('deduction.after')}` : '' }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CSkeleton from '@cling/components/ui/Skeleton'
import { priceFormat } from '@cling/utils'

import { mapGetters } from 'vuex'

export default {
  name: 'ProjectCashSummary',
  components: {
    CSkeleton
  },
  props: {
    prices: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isFortnoxInstalled: 'settings/isFortnoxInstalled'
    })
  },
  methods: {
    priceFormat
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.info-bar {
  font-size: 16px;
  &-item {
    flex: 1;
    min-width: 10rem;
    margin-bottom: 1rem;
  }
  &-title {
    color: $black;
    font-weight: 500;
    font-size: 14px;
  }
  &-sub-value {
    margin-top: 3px;
    font-size: 14px;
    color: $gray700;
    max-width: 90%;
    line-height: 1.2;
  }
}
</style>

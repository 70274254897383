export default {
  companyById: state => id => state.data[id],
  certificateIdListForCurrentCompany: (state, getters, rootState) => () =>
    getters.certificateIdListForCompany(rootState.application.user.companyId),
  certificateIdListForCompany: state => companyId => {
    const certificateIds = state.data[companyId].CompanyCertificates
    if (certificateIds) {
      return certificateIds
    }
    return []
  },
  isFetching: state => state.isFetching,
  isPosting: state => state.isPosting
}

import config from '@cling/config'
import { getDisabledThirdParties } from '@cling/services/disabledServices'

import Rollbar from 'rollbar'

const rollbarClientAccessToken = config.rollbar.clientToken
const rollbar = new Rollbar({
  accessToken: rollbarClientAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  source_map_enabled: true,
  enabled: false,
  ignoredMessages: [
    'The operation is insecure', // Ignore errors related to insecure operations in browsers
    'Script error.', // Ignore bundle errors
    "null is not an object (evaluating 'localStorage.getItem')", // Ignore users who do not have access to localStorage
    "Unexpected token '<'", // Ignore load errors when a new bundle is being replaced
    "expected expression, got '<'",
    'ChunkLoadError',
    'Loading chunk',
    'ResizeObserver loop limit exceeded',
    'Failed to update a ServiceWorker for scope',
    'Object Not Found Matching Id', // Seems to be related to a .NET browser https://github.com/cefsharp/CefSharp/issues/3632
    'newestWorker is null'
  ],
  payload: {
    environment: config.rollbar.environment || config.environment,
    client: {
      javascript: {
        // eslint-disable-next-line
        code_version: __APP_VERSION__, // Git SHA of the deployed code (from webpack plugin)
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  },
  transform: payload => {
    // This transform allows us to upload sourcemaps for domain "dynamichost",
    // and then match it against multiple domains (reuse for both Cling and Docspo)
    const { trace } = payload.body
    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i++) {
        const { filename } = trace.frames[i]
        if (filename) {
          // Be sure that the minified_url when uploading includes 'dynamichost'
          trace.frames[i].filename = trace.frames[i].filename.replace(
            config.baseUrl,
            'https://dynamichost'
          )
        }
      }
    }
  }
})

const canEnable =
  !!rollbarClientAccessToken && !getDisabledThirdParties().includes('rollbar')

// Global method called from Piwik on error feedback consent
window.enableErrorTracker = () => {
  if (canEnable) rollbar.configure({ enabled: true })
}

export default rollbar

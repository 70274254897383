export default {
  // Language codes (ISO619-1)
  language: {
    sv: 'Svenska',
    en: 'Engelska',
    de: 'Tyska',
    fi: 'Finska',
    fr: 'Franska',
    es: 'Spanska',
    nl: 'Nederländska'
  }
}

// This file exposes valid regions according to ISO-3166
// Should be in sync for both landing, backend and frontend.
// https://sv.wikipedia.org/wiki/ISO_3166
// https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.csv
// Calling codes based of:
// https://en.wikipedia.org/wiki/List_of_country_calling_codes
// Currency codes based on ISO 4217, optional preferred currency

export default {
  AD: {
    name: 'Andorra',
    callingCode: '+376',
    currency: 'EUR'
  },
  AE: {
    name: 'United Arab Emirates',
    callingCode: '+971',
    currency: 'AED'
  },
  AF: {
    name: 'Afghanistan',
    callingCode: '+93',
    currency: 'AFN'
  },
  AG: {
    name: 'Antigua and Barbuda',
    callingCode: '+1 268',
    currency: 'XCD'
  },
  AI: {
    name: 'Anguilla',
    callingCode: '+1 264',
    currency: 'XCD'
  },
  AL: {
    name: 'Albania',
    callingCode: '+355',
    currency: 'ALL'
  },
  AM: {
    name: 'Armenia',
    callingCode: '+374',
    currency: 'AMD'
  },
  AO: {
    name: 'Angola',
    callingCode: '+244',
    currency: 'AOA'
  },
  AQ: {
    name: 'Antarctica',
    callingCode: '',
    currency: null
  },
  AR: {
    name: 'Argentina',
    callingCode: '+54',
    currency: 'ARS'
  },
  AS: {
    name: 'American Samoa',
    callingCode: '+1 684',
    currency: 'USD'
  },
  AT: {
    name: 'Austria',
    callingCode: '+43',
    currency: 'EUR'
  },
  AU: {
    name: 'Australia',
    callingCode: '+61',
    currency: 'AUD'
  },
  AW: {
    name: 'Aruba',
    callingCode: '+297',
    currency: 'AWG'
  },
  AX: {
    name: 'Åland Islands',
    callingCode: '+358',
    currency: 'EUR'
  },
  AZ: {
    name: 'Azerbaijan',
    callingCode: '+994',
    currency: 'AZN'
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    callingCode: '+387',
    currency: 'BAM'
  },
  BB: {
    name: 'Barbados',
    callingCode: '+1 246',
    currency: 'BBD'
  },
  BD: {
    name: 'Bangladesh',
    callingCode: '+880',
    currency: 'BDT'
  },
  BE: {
    name: 'Belgium',
    callingCode: '+32',
    currency: 'EUR'
  },
  BF: {
    name: 'Burkina Faso',
    callingCode: '+226',
    currency: 'XOF'
  },
  BG: {
    name: 'Bulgaria',
    callingCode: '+359',
    currency: 'BGN'
  },
  BH: {
    name: 'Bahrain',
    callingCode: '+973',
    currency: 'BHD'
  },
  BI: {
    name: 'Burundi',
    callingCode: '+257',
    currency: 'BIF'
  },
  BJ: {
    name: 'Benin',
    callingCode: '+229',
    currency: 'XOF'
  },
  BL: {
    name: 'Saint Barthélemy',
    callingCode: '+590',
    currency: 'EUR'
  },
  BM: {
    name: 'Bermuda',
    callingCode: '+1 441',
    currency: 'BMD'
  },
  BN: {
    name: 'Brunei Darussalam',
    callingCode: '+673',
    currency: 'BND'
  },
  BO: {
    name: 'Bolivia (Plurinational State of)',
    callingCode: '+591',
    currency: 'BOB'
  },
  BQ: {
    name: 'Bonaire, Sint Eustatius and Saba',
    callingCode: '+599 7',
    currency: 'USD'
  },
  BR: {
    name: 'Brazil',
    callingCode: '+55',
    currency: 'BRL'
  },
  BS: {
    name: 'Bahamas',
    callingCode: '+1 242',
    currency: 'BSD'
  },
  BT: {
    name: 'Bhutan',
    callingCode: '+975',
    currency: 'INR'
  },
  BV: {
    name: 'Bouvet Island',
    callingCode: '',
    currency: 'NOK'
  },
  BW: {
    name: 'Botswana',
    callingCode: '+267',
    currency: 'BWP'
  },
  BY: {
    name: 'Belarus',
    callingCode: '+375',
    currency: 'BYN'
  },
  BZ: {
    name: 'Belize',
    callingCode: '+501',
    currency: 'BZD'
  },
  CA: {
    name: 'Canada',
    callingCode: '+1',
    currency: 'CAD'
  },
  CC: {
    name: 'Cocos (Keeling) Islands',
    callingCode: '+61 89162',
    currency: 'AUD'
  },
  CD: {
    name: 'Congo, Democratic Republic of the',
    callingCode: '+243',
    currency: 'CDF'
  },
  CF: {
    name: 'Central African Republic',
    callingCode: '+236',
    currency: 'XAF'
  },
  CG: {
    name: 'Congo',
    callingCode: '+242',
    currency: 'XAF'
  },
  CH: {
    name: 'Switzerland',
    callingCode: '+41',
    currency: 'CHF'
  },
  CI: {
    name: "Côte d'Ivoire",
    callingCode: '+225',
    currency: 'XOF'
  },
  CK: {
    name: 'Cook Islands',
    callingCode: '+682',
    currency: 'NZD'
  },
  CL: {
    name: 'Chile',
    callingCode: '+56',
    currency: 'CLP'
  },
  CM: {
    name: 'Cameroon',
    callingCode: '+237',
    currency: 'XAF'
  },
  CN: {
    name: 'China',
    callingCode: '+86',
    currency: 'CNY'
  },
  CO: {
    name: 'Colombia',
    callingCode: '+57',
    currency: 'COP'
  },
  CR: {
    name: 'Costa Rica',
    callingCode: '+506',
    currency: 'CRC'
  },
  CU: {
    name: 'Cuba',
    callingCode: '+53',
    currency: 'CUP'
  },
  CV: {
    name: 'Cabo Verde',
    callingCode: '+238',
    currency: 'CVE'
  },
  CW: {
    name: 'Curaçao',
    callingCode: '+599 9',
    currency: 'ANG'
  },
  CX: {
    name: 'Christmas Island',
    callingCode: '+61 89164',
    currency: 'AUD'
  },
  CY: {
    name: 'Cyprus',
    callingCode: '+357',
    currency: 'EUR'
  },
  CZ: {
    name: 'Czechia',
    callingCode: '+420',
    currency: 'CZK'
  },
  DE: {
    name: 'Germany',
    callingCode: '+49',
    currency: 'EUR'
  },
  DJ: {
    name: 'Djibouti',
    callingCode: '+253',
    currency: 'DJF'
  },
  DK: {
    name: 'Denmark',
    callingCode: '+45',
    currency: 'DKK'
  },
  DM: {
    name: 'Dominica',
    callingCode: '+1 767',
    currency: 'XCD'
  },
  DO: {
    name: 'Dominican Republic',
    callingCode: '+1',
    currency: 'DOP'
  },
  DZ: {
    name: 'Algeria',
    callingCode: '+213',
    currency: 'DZD'
  },
  EC: {
    name: 'Ecuador',
    callingCode: '+593',
    currency: 'USD'
  },
  EE: {
    name: 'Estonia',
    callingCode: '+372',
    currency: 'EUR'
  },
  EG: {
    name: 'Egypt',
    callingCode: 'Egypt',
    currency: 'EGP'
  },
  EH: {
    name: 'Western Sahara',
    callingCode: '+212',
    currency: 'MAD'
  },
  ER: {
    name: 'Eritrea',
    callingCode: '+291',
    currency: 'ERN'
  },
  ES: {
    name: 'Spain',
    callingCode: '+34',
    currency: 'EUR'
  },
  ET: {
    name: 'Ethiopia',
    callingCode: '+251',
    currency: 'ETB'
  },
  FI: {
    name: 'Finland',
    callingCode: '+358',
    currency: 'EUR'
  },
  FJ: {
    name: 'Fiji',
    callingCode: '+679',
    currency: 'FJD'
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    callingCode: '+500',
    currency: 'FKP'
  },
  FM: {
    name: 'Micronesia (Federated States of)',
    callingCode: '+691',
    currency: 'USD'
  },
  FO: {
    name: 'Faroe Islands',
    callingCode: '+298',
    currency: 'DKK'
  },
  FR: {
    name: 'France',
    callingCode: '+33',
    currency: 'EUR'
  },
  GA: {
    name: 'Gabon',
    callingCode: '+241',
    currency: 'XAF'
  },
  GB: {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    callingCode: '+44',
    currency: 'GBP'
  },
  GD: {
    name: 'Grenada',
    callingCode: '+1 473',
    currency: 'XCD'
  },
  GE: {
    name: 'Georgia',
    callingCode: 'Georgia',
    currency: 'GEL'
  },
  GF: {
    name: 'French Guiana',
    callingCode: '+594',
    currency: 'EUR'
  },
  GG: {
    name: 'Guernsey',
    callingCode: '+44',
    currency: 'GGP'
  },
  GH: {
    name: 'Ghana',
    callingCode: '+233',
    currency: 'GHS'
  },
  GI: {
    name: 'Gibraltar',
    callingCode: '+350',
    currency: 'GIP'
  },
  GL: {
    name: 'Greenland',
    callingCode: '+299',
    currency: 'DKK'
  },
  GM: {
    name: 'Gambia',
    callingCode: '+220',
    currency: 'GMD'
  },
  GN: {
    name: 'Guinea',
    callingCode: '+224',
    currency: 'GNF'
  },
  GP: {
    name: 'Guadeloupe',
    callingCode: '+590',
    currency: 'EUR'
  },
  GQ: {
    name: 'Equatorial Guinea',
    callingCode: '+240',
    currency: 'XAF'
  },
  GR: {
    name: 'Greece',
    callingCode: '+30',
    currency: 'EUR'
  },
  GS: {
    name: 'South Georgia and the South Sandwich Islands',
    callingCode: '+500',
    currency: 'GBP'
  },
  GT: {
    name: 'Guatemala',
    callingCode: '+502',
    currency: 'GTQ'
  },
  GU: {
    name: 'Guam',
    callingCode: '+1 671',
    currency: 'USD'
  },
  GW: {
    name: 'Guinea-Bissau',
    callingCode: '+245',
    currency: 'XOF'
  },
  GY: {
    name: 'Guyana',
    callingCode: '+592',
    currency: 'GYD'
  },
  HK: {
    name: 'Hong Kong',
    callingCode: '+852',
    currency: 'HKD'
  },
  HM: {
    name: 'Heard Island and McDonald Islands',
    callingCode: '+61',
    currency: 'AUD'
  },
  HN: {
    name: 'Honduras',
    callingCode: '+504',
    currency: 'HNL'
  },
  HR: {
    name: 'Croatia',
    callingCode: '+385 ',
    currency: 'HRK'
  },
  HT: {
    name: 'Haiti',
    callingCode: '+509',
    currency: 'HTG'
  },
  HU: {
    name: 'Hungary',
    callingCode: '+36',
    currency: 'HUF'
  },
  ID: {
    name: 'Indonesia',
    callingCode: '+62',
    currency: 'IDR'
  },
  IE: {
    name: 'Ireland',
    callingCode: '+353',
    currency: 'EUR'
  },
  IL: {
    name: 'Israel',
    callingCode: '+972',
    currency: 'ILS'
  },
  IM: {
    name: 'Isle of Man',
    callingCode: '+44',
    currency: 'GBP'
  },
  IN: {
    name: 'India',
    callingCode: '+91',
    currency: 'INR'
  },
  IO: {
    name: 'British Indian Ocean Territory',
    callingCode: '+246',
    currency: 'USD'
  },
  IQ: {
    name: 'Iraq',
    callingCode: '+964',
    currency: 'IQD'
  },
  IR: {
    name: 'Iran (Islamic Republic of)',
    callingCode: '+98',
    currency: 'IRR'
  },
  IS: {
    name: 'Iceland',
    callingCode: '+354',
    currency: 'ISK'
  },
  IT: {
    name: 'Italy',
    callingCode: '+39',
    currency: 'EUR'
  },
  JE: {
    name: 'Jersey',
    callingCode: '+44 1534',
    currency: 'GBP'
  },
  JM: {
    name: 'Jamaica',
    callingCode: '+1 876',
    currency: 'JMD'
  },
  JO: {
    name: 'Jordan',
    callingCode: '+962',
    currency: 'JOD'
  },
  JP: {
    name: 'Japan',
    callingCode: '+81',
    currency: 'JPY'
  },
  KE: {
    name: 'Kenya',
    callingCode: '+254',
    currency: 'KES'
  },
  KG: {
    name: 'Kyrgyzstan',
    callingCode: '+996',
    currency: 'KGS'
  },
  KH: {
    name: 'Cambodia',
    callingCode: '+855',
    currency: 'KHR'
  },
  KI: {
    name: 'Kiribati',
    callingCode: '+686',
    currency: 'AUD'
  },
  KM: {
    name: 'Comoros',
    callingCode: '+269',
    currency: 'KMF'
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    callingCode: '+1 869',
    currency: 'XCD'
  },
  KP: {
    name: "Korea (Democratic People's Republic of)",
    callingCode: '+850',
    currency: 'KPW'
  },
  KR: {
    name: 'Korea, Republic of',
    callingCode: '+82',
    currency: 'KRW'
  },
  KW: {
    name: 'Kuwait',
    callingCode: '+965',
    currency: 'KWD'
  },
  KY: {
    name: 'Cayman Islands',
    callingCode: '+1 345',
    currency: 'KYD'
  },
  KZ: {
    name: 'Kazakhstan',
    callingCode: '+7',
    currency: 'KZT'
  },
  LA: {
    name: "Lao People's Democratic Republic",
    callingCode: '+856',
    currency: 'LAK'
  },
  LB: {
    name: 'Lebanon',
    callingCode: '+961',
    currency: 'LBP'
  },
  LC: {
    name: 'Saint Lucia',
    callingCode: '+1 758',
    currency: 'XCD'
  },
  LI: {
    name: 'Liechtenstein',
    callingCode: '+423',
    currency: 'CHF'
  },
  LK: {
    name: 'Sri Lanka',
    callingCode: '+94',
    currency: 'LKR'
  },
  LR: {
    name: 'Liberia',
    callingCode: '+231',
    currency: 'LRD'
  },
  LS: {
    name: 'Lesotho',
    callingCode: '+266',
    currency: 'LSL'
  },
  LT: {
    name: 'Lithuania',
    callingCode: '+370',
    currency: 'EUR'
  },
  LU: {
    name: 'Luxembourg',
    callingCode: '+352',
    currency: 'EUR'
  },
  LV: {
    name: 'Latvia',
    callingCode: '+371',
    currency: 'EUR'
  },
  LY: {
    name: 'Libya',
    callingCode: '+218',
    currency: 'LYD'
  },
  MA: {
    name: 'Morocco',
    callingCode: '+212',
    currency: 'MAD'
  },
  MC: {
    name: 'Monaco',
    callingCode: '+377',
    currency: 'EUR'
  },
  MD: {
    name: 'Moldova, Republic of',
    callingCode: '+373',
    currency: 'MDL'
  },
  ME: {
    name: 'Montenegro',
    callingCode: '+382',
    currency: 'EUR'
  },
  MF: {
    name: 'Saint Martin (French part)',
    callingCode: '+590',
    currency: 'EUR'
  },
  MG: {
    name: 'Madagascar',
    callingCode: '+261',
    currency: 'MGA'
  },
  MH: {
    name: 'Marshall Islands',
    callingCode: '+692',
    currency: 'USD'
  },
  MK: {
    name: 'North Macedonia',
    callingCode: '+389',
    currency: 'MKD'
  },
  ML: {
    name: 'Mali',
    callingCode: '+223',
    currency: 'XOF'
  },
  MM: {
    name: 'Myanmar',
    callingCode: '+95',
    currency: 'MMK'
  },
  MN: {
    name: 'Mongolia',
    callingCode: '+976',
    currency: 'MNT'
  },
  MO: {
    name: 'Macao',
    callingCode: '+853',
    currency: 'MOP'
  },
  MP: {
    name: 'Northern Mariana Islands',
    callingCode: '+1 670',
    currency: 'USD'
  },
  MQ: {
    name: 'Martinique',
    callingCode: '+596',
    currency: 'EUR'
  },
  MR: {
    name: 'Mauritania',
    callingCode: '+222',
    currency: 'MRO'
  },
  MS: {
    name: 'Montserrat',
    callingCode: '+1 664',
    currency: 'XCD'
  },
  MT: {
    name: 'Malta',
    callingCode: '+356',
    currency: 'EUR'
  },
  MU: {
    name: 'Mauritius',
    callingCode: '+230',
    currency: 'MUR'
  },
  MV: {
    name: 'Maldives',
    callingCode: '+960',
    currency: 'MVR'
  },
  MW: {
    name: 'Malawi',
    callingCode: '+265',
    currency: 'MWK'
  },
  MX: {
    name: 'Mexico',
    callingCode: '+52',
    currency: 'MXN'
  },
  MY: {
    name: 'Malaysia',
    callingCode: '+60',
    currency: 'MYR'
  },
  MZ: {
    name: 'Mozambique',
    callingCode: '+258',
    currency: 'MZN'
  },
  NA: {
    name: 'Namibia',
    callingCode: '+264',
    currency: 'NAD'
  },
  NC: {
    name: 'New Caledonia',
    callingCode: '+687',
    currency: 'XPF'
  },
  NE: {
    name: 'Niger',
    callingCode: '+227',
    currency: 'XOF'
  },
  NF: {
    name: 'Norfolk Island',
    callingCode: '+672 3',
    currency: 'AUD'
  },
  NG: {
    name: 'Nigeria',
    callingCode: '+234',
    currency: 'NGN'
  },
  NI: {
    name: 'Nicaragua',
    callingCode: '+505',
    currency: 'NIO'
  },
  NL: {
    name: 'Netherlands',
    callingCode: '+31',
    currency: 'EUR'
  },
  NO: {
    name: 'Norway',
    callingCode: '+47',
    currency: 'NOK'
  },
  NP: {
    name: 'Nepal',
    callingCode: '+977',
    currency: 'NPR'
  },
  NR: {
    name: 'Nauru',
    callingCode: '+674',
    currency: 'AUD'
  },
  NU: {
    name: 'Niue',
    callingCode: '+683',
    currency: 'NZD'
  },
  NZ: {
    name: 'New Zealand',
    callingCode: '+64',
    currency: 'NZD'
  },
  OM: {
    name: 'Oman',
    callingCode: '+968',
    currency: 'OMR'
  },
  PA: {
    name: 'Panama',
    callingCode: '+507',
    currency: 'PAB'
  },
  PE: {
    name: 'Peru',
    callingCode: '+51',
    currency: 'PEN'
  },
  PF: {
    name: 'French Polynesia',
    callingCode: '+689',
    currency: 'XPF'
  },
  PG: {
    name: 'Papua New Guinea',
    callingCode: '+675',
    currency: 'PGK'
  },
  PH: {
    name: 'Philippines',
    callingCode: '+63',
    currency: 'PHP'
  },
  PK: {
    name: 'Pakistan',
    callingCode: '+92',
    currency: 'PKR'
  },
  PL: {
    name: 'Poland',
    callingCode: '+48',
    currency: 'PLN'
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    callingCode: '+508',
    currency: 'EUR'
  },
  PN: {
    name: 'Pitcairn',
    callingCode: '+64',
    currency: 'NZD'
  },
  PR: {
    name: 'Puerto Rico',
    callingCode: '+1',
    currency: 'USD'
  },
  PS: {
    name: 'Palestine, State of',
    callingCode: '+970',
    currency: 'JOD'
  },
  PT: {
    name: 'Portugal',
    callingCode: '+351',
    currency: 'EUR'
  },
  PW: {
    name: 'Palau',
    callingCode: '+680',
    currency: 'USD'
  },
  PY: {
    name: 'Paraguay',
    callingCode: '+595',
    currency: 'PYG'
  },
  QA: {
    name: 'Qatar',
    callingCode: '+974',
    currency: 'QAR'
  },
  RE: {
    name: 'Réunion',
    callingCode: '+262',
    currency: 'EUR'
  },
  RO: {
    name: 'Romania',
    callingCode: '+40',
    currency: 'RON'
  },
  RS: {
    name: 'Serbia',
    callingCode: '+381',
    currency: 'RSD'
  },
  RU: {
    name: 'Russian Federation',
    callingCode: '+7',
    currency: 'RUB'
  },
  RW: {
    name: 'Rwanda',
    callingCode: '+250',
    currency: 'RWF'
  },
  SA: {
    name: 'Saudi Arabia',
    callingCode: '+966',
    currency: 'SAR'
  },
  SB: {
    name: 'Solomon Islands',
    callingCode: '+677',
    currency: 'SBD'
  },
  SC: {
    name: 'Seychelles',
    callingCode: '+248',
    currency: 'SCR'
  },
  SD: {
    name: 'Sudan',
    callingCode: '+249',
    currency: 'SDG'
  },
  SE: {
    name: 'Sweden',
    callingCode: '+46',
    currency: 'SEK'
  },
  SG: {
    name: 'Singapore',
    callingCode: '+65',
    currency: 'SGD'
  },
  SH: {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    callingCode: '+290',
    currency: 'SHP'
  },
  SI: {
    name: 'Slovenia',
    callingCode: '+386',
    currency: 'EUR'
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    callingCode: '+47 79',
    currency: 'NOK'
  },
  SK: {
    name: 'Slovakia',
    callingCode: '+421',
    currency: 'EUR'
  },
  SL: {
    name: 'Sierra Leone',
    callingCode: '+232',
    currency: 'SLL'
  },
  SM: {
    name: 'San Marino',
    callingCode: '+378',
    currency: 'EUR'
  },
  SN: {
    name: 'Senegal',
    callingCode: '+221',
    currency: 'XOF'
  },
  SO: {
    name: 'Somalia',
    callingCode: '+252',
    currency: 'SOS'
  },
  SR: {
    name: 'Suriname',
    callingCode: '+597',
    currency: 'SRD'
  },
  SS: {
    name: 'South Sudan',
    callingCode: '+211',
    currency: 'SSP'
  },
  ST: {
    name: 'Sao Tome and Principe',
    callingCode: '+239',
    currency: 'STD'
  },
  SV: {
    name: 'El Salvador',
    callingCode: '+503',
    currency: 'USD'
  },
  SX: {
    name: 'Sint Maarten (Dutch part)',
    callingCode: '+1 721',
    currency: 'ANG'
  },
  SY: {
    name: 'Syrian Arab Republic',
    callingCode: '+963',
    currency: 'SYP'
  },
  SZ: {
    name: 'Eswatini',
    callingCode: '+268',
    currency: 'SZL'
  },
  TC: {
    name: 'Turks and Caicos Islands',
    callingCode: '+1 649',
    currency: 'USD'
  },
  TD: {
    name: 'Chad',
    callingCode: '+235',
    currency: 'XAF'
  },
  TF: {
    name: 'French Southern Territories',
    callingCode: '',
    currency: 'EUR'
  },
  TG: {
    name: 'Togo',
    callingCode: '+228',
    currency: 'XOF'
  },
  TH: {
    name: 'Thailand',
    callingCode: '+66',
    currency: 'THB'
  },
  TJ: {
    name: 'Tajikistan',
    callingCode: '+992',
    currency: 'TJS'
  },
  TK: {
    name: 'Tokelau',
    callingCode: '+690',
    currency: 'NZD'
  },
  TL: {
    name: 'Timor-Leste',
    callingCode: '+670',
    currency: 'USD'
  },
  TM: {
    name: 'Turkmenistan',
    callingCode: '+993',
    currency: 'TMT'
  },
  TN: {
    name: 'Tunisia',
    callingCode: '+216',
    currency: 'TND'
  },
  TO: {
    name: 'Tonga',
    callingCode: '+676',
    currency: 'TOP'
  },
  TR: {
    name: 'Turkey',
    callingCode: '+90',
    currency: 'TRY'
  },
  TT: {
    name: 'Trinidad and Tobago',
    callingCode: '+1 868',
    currency: 'TTD'
  },
  TV: {
    name: 'Tuvalu',
    callingCode: '+688',
    currency: 'AUD'
  },
  TW: {
    name: 'Taiwan, Province of China',
    callingCode: '+886',
    currency: 'TWD'
  },
  TZ: {
    name: 'Tanzania, United Republic of',
    callingCode: '+255',
    currency: 'TZS'
  },
  UA: {
    name: 'Ukraine',
    callingCode: '+380',
    currency: 'UAH'
  },
  UG: {
    name: 'Uganda',
    callingCode: '+256',
    currency: 'UGX'
  },
  UM: {
    name: 'United States Minor Outlying Islands',
    callingCode: '',
    currency: 'USD'
  },
  US: {
    name: 'United States of America',
    callingCode: '+1',
    currency: 'USD'
  },
  UY: {
    name: 'Uruguay',
    callingCode: '+598',
    currency: 'UYU'
  },
  UZ: {
    name: 'Uzbekistan',
    callingCode: '+998',
    currency: 'UZS'
  },
  VA: {
    name: 'Holy See',
    callingCode: '+39',
    currency: 'EUR'
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    callingCode: '+1 784',
    currency: 'XCD'
  },
  VE: {
    name: 'Venezuela (Bolivarian Republic of)',
    callingCode: '+58',
    currency: 'VEF'
  },
  VG: {
    name: 'Virgin Islands (British)',
    callingCode: '',
    currency: 'USD'
  },
  VI: {
    name: 'Virgin Islands (U.S.)',
    callingCode: '+1',
    currency: 'USD'
  },
  VN: {
    name: 'Viet Nam',
    callingCode: '+84',
    currency: 'VND'
  },
  VU: {
    name: 'Vanuatu',
    callingCode: '+678',
    currency: 'VUV'
  },
  WF: {
    name: 'Wallis and Futuna',
    callingCode: '+681',
    currency: 'XPF'
  },
  WS: {
    name: 'Samoa',
    callingCode: '+685',
    currency: 'EUR'
  },
  YE: {
    name: 'Yemen',
    callingCode: '+967',
    currency: 'YER'
  },
  YT: {
    name: 'Mayotte',
    callingCode: '+26',
    currency: 'EUR'
  },
  ZA: {
    name: 'South Africa',
    callingCode: '+27',
    currency: 'ZAR'
  },
  ZM: {
    name: 'Zambia',
    callingCode: '+260',
    currency: 'ZMW'
  },
  ZW: {
    name: 'Zimbabwe',
    callingCode: '+263',
    currency: 'USD'
  }
}

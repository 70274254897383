import { getAPIStatus } from '@cling/api'
import lang from '@cling/language'
import { getType } from '@cling/utils'
import { addSeconds, isFuture } from '@cling/utils/date'

import rollbar from './rollbar'
import * as types from './types'

// Array of error codes not to send to external services such as rollbar
// These codes represents known error codes that we defined backend
const excludeSendExternalForCodes = [9001, 9002, 9003, 9004, 9005, 9006]
// These codes represents http status codes (any internal code must be unknown)
const excludeSendExternalForStatusCodes = [401]

/**
 * Helper to get a user friendly error message from an error
 * @param {Object} err
 * @returns {String|null} Returns a error message or null
 */
function getUserMessage(err) {
  const { payload } = err
  const getCodeTranslation = code =>
    lang.te(`errors:${code}`) && lang.t(`errors:${code}`)

  // Get default message from translation the err.code
  let message = getCodeTranslation(err.code) // || lang.t('errors:fallback');
  // Get translation from payload code
  if (payload.code && getCodeTranslation(payload.code)) {
    message = getCodeTranslation(payload.code)

    // If no payload code was found, try to use payload fallbackCode
  } else if (payload.fallbackCode && getCodeTranslation(payload.fallbackCode)) {
    message = getCodeTranslation(payload.fallbackCode)

    // Get translation from payload string
  } else if (payload.string) {
    message = payload.string
  }

  return message
}
class ErrorHandler {
  constructor() {
    this.handleError.bind(this)
  }

  errors = new Map()

  init({
    logger = console,
    showMessage,
    onNetworkError,
    clearNetworkError
  } = {}) {
    this._logger = logger
    if (typeof showMessage !== 'function')
      throw Error('showMessage must be a function')
    this._showMessage = showMessage
    this._onNetworkError = onNetworkError
    this._clearNetworkError = clearNetworkError
  }

  normalizeError(err) {
    const isCustom = this.getErrorType(err) !== 'Error'

    if (isCustom) {
      return err
    }

    return new types.GeneralError(err)
  }

  _showNotification(message, type) {
    const defaultMessage = `\
      Tyvärr har ett okänt fel inträffat, \
      vi har loggat felet och kommer snarast att åtgärda det.
    `

    this._showMessage({
      type: type || 'error',
      message: message || defaultMessage
    })
  }

  logError(err) {
    if (this._logger) {
      this._logger.error(err)
      this._logger.debug(`${err.name}: ${err.message}`, err.payload)
    } else {
      // eslint-disable-next-line no-console
      console.error(err)
      // eslint-disable-next-line no-console
      console.debug(`${err.name}: ${err.message}`, err.payload)
    }
  }

  getErrorType(err) {
    const isCustom = Object.keys(types).some(x => err instanceof types[x])

    if (!isCustom) return 'Error'

    return err.name
  }

  addCustomData(err, payload) {
    if (getType(payload) !== 'object') {
      return
    }

    const errorType = this.getErrorType(err)

    if (errorType === 'Error') {
      return
    }

    err.add(payload)
  }

  saveError(err) {
    const errorType = this.getErrorType(err)

    if (!this.errors.has(errorType)) {
      this.errors.set(errorType, [])
    }

    const history = this.errors.get(errorType)

    this.errors.set(errorType, {
      ...history,
      [err.message]: {
        count: ((history[err.message] || {}).count || 0) + 1,
        lastError: new Date()
      }
    })
  }

  isDuplicate(err, time = 1) {
    const errorType = this.getErrorType(err)

    const { lastError } = (this.errors.get(errorType) || {})[err.message] || {}

    if (lastError && isFuture(addSeconds(lastError, time))) {
      return true
    }

    return false
  }

  notifyUser(err) {
    if (this.isDuplicate(err) || (err.payload && !err.payload.showMessage))
      return
    const message = getUserMessage(err)
    if (message) this._showNotification(message)
  }

  /**
   * Method to send the error to Rollbar
   * @param {Object} err ErrorObject,
   * @param {Number} time Optional time in seconds to throttle logging to Rollbar for err
   */
  sendRollbarError(err, time = 10) {
    if (this.isDuplicate(err, time)) {
      return
    }
    const { payload = {}, httpStatusCode } = err || {}
    const { code } = payload
    if (code && excludeSendExternalForCodes.includes(code)) return
    if (
      !code &&
      httpStatusCode &&
      excludeSendExternalForStatusCodes.includes(httpStatusCode)
    )
      return

    rollbar.error(err, payload)
  }

  async resolveNetworkError(err) {
    try {
      await getAPIStatus()

      this._clearNetworkError?.()
    } catch (error) {
      if (this.getErrorType(error) !== 'NetworkError') {
        this.handleError(error)
      }

      setTimeout(() => {
        this.resolveNetworkError(err).catch(this.handleError)
      }, 5000)
    }
  }
}

class ErrorInterface extends ErrorHandler {
  handleError(error, payload) {
    const err = this.normalizeError(error)

    this.addCustomData(err, payload)

    this.notifyUser(err)

    this.logError(err)

    this.sendRollbarError(err)

    if (!this.isDuplicate(err) && this.getErrorType(err) === 'NetworkError') {
      this._onNetworkError?.(err)
      this.resolveNetworkError(err).catch(this.handleError)
    }

    this.saveError(err)
  }

  onVueException(err, vm, info) {
    vm.$nextTick(() => {
      this.handleError(err)
    })
  }
}

const Instance = new ErrorInterface()

export { types }

export const handleError = Instance.handleError.bind(Instance)

export default Instance

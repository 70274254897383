import {
  deepMergeReplace,
  getUniqueArrayElements,
  convertArrToObject
} from '@cling/store/utils'
import Vue from 'vue'

import { mutationTypes } from './constants'

const {
  SET_COMPANY_USERS,
  DELETE_COMPANY_USER,
  CLEAR_COMPANY_USERS,
  SET_COMPANY_USERS_FETCHING,
  SET_COMPANY_USERS_POSTING
} = mutationTypes

export default {
  /**
   * @name SET_COMPANY_USERS
   * Add company users to store by overwriting old data with new.
   * Will not remove old company users
   *
   * @param {Object} state Vuex Store Props
   * @param {Object} payload
   * @param {Object|Array} payload.data company user or array of company users
   *
   * @returns {Void}
   */
  [SET_COMPANY_USERS](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a company user or an array of company users!')
    }
    const items = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(items)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },
  // Delete one
  [DELETE_COMPANY_USER](state, id) {
    Vue.delete(state.data, id)
    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_COMPANY_USERS
   * Clear all company users
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_COMPANY_USERS](state) {
    Vue.set(state, 'data', {})
    Vue.set(state, 'ids', [])
  },

  /**
   * @name SET_COMPANY_USERS_FETCHING
   * Set fetching status of company users
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_COMPANY_USERS_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_COMPANY_USERS_POSTING
   * Set posting status of company users
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_COMPANY_USERS_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

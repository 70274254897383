// Supported industries for company.industry enum
// Should be in sync for both landing, backend and frontend.
export default [
  'design',
  'sales',
  'software',
  'consultancy',
  'media',
  'construction',
  'rot',
  'rut',
  'greenRot',
  'event',
  'other'
]

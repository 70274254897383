<!-- Virtual list extended in wrapper designed for fonts dropdown
Individual component in order to leverage component/template boundaries -->

<!-- Advantage: Independent changes in parent template won't re-render virtual list unnecessarily -->

<template>
  <div>
    <VirtualList
      :data-key="'family'"
      :data-sources="items"
      :data-component="FontDropdownItem"
      :estimate-size="37"
      :style="listStyle"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-if="$slots.footer" #footer>
        <slot name="footer"></slot>
      </template>
    </VirtualList>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAttrs } from 'vue'
import VirtualList from 'vue-virtual-scroll-list'

import FontDropdownItem from './FontDropdownItem.vue'

type Props = {
  items: Array<{ family: string }>
}

defineProps<Props>()

const attrs = useAttrs()
const staticStyle = computed(() => attrs.style)

const listStyle = computed(() => ({
  'overflow-y': 'auto',
  height: '250px',
  ...staticStyle
}))
</script>

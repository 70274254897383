import {
  deleteTemplate,
  getAllTemplates,
  getTemplate,
  patchTemplate,
  postTemplate,
  putTemplate
} from '@cling/api'
import lang from '@cling/language'
import { global } from '@cling/store/action-types'
import globalMutationTypes from '@cling/store/mutation-types'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'templateMessages'

const { FORM_RESET_OLD } = global

const {
  LOAD_TEMPLATE_MESSAGES,
  LOAD_TEMPLATE_MESSAGE,
  CREATE_TEMPLATE_MESSAGE,
  UPDATE_TEMPLATE_MESSAGE,
  DELETE_TEMPLATE_MESSAGE,
  PATCH_TEMPLATE_MESSAGE,
  FORM_NEW_TEMPLATE_MESSAGE,
  FORM_EDIT_TEMPLATE_MESSAGE,
  FORM_SUBMIT_TEMPLATE_MESSAGE
} = actionTypes

const {
  SET_TEMPLATE_MESSAGES,
  SET_TEMPLATE_MESSAGES_POSTING,
  SET_TEMPLATE_MESSAGES_FETCHING
} = mutationTypes

export default {
  /**
   * @name LOAD_TEMPLATE_MESSAGES
   *  Load all templateMessages
   * @param {Object} Vuex object
   */
  async [LOAD_TEMPLATE_MESSAGES]({ commit }) {
    try {
      commit(SET_TEMPLATE_MESSAGES_FETCHING, true)
      const { data } = await getAllTemplates()

      commit(SET_TEMPLATE_MESSAGES, { data })

      return true
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        fallbackCode: 'templateMessage.getAll',
        action: `${moduleName}/${LOAD_TEMPLATE_MESSAGES}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_TEMPLATE_MESSAGES_FETCHING, false)
    }
  },

  /**
   * @name LOAD_TEMPLATE_MESSAGE
   *  Load one templateMessage by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the templateMessage
   */
  async [LOAD_TEMPLATE_MESSAGE]({ commit }, { id }) {
    try {
      commit(SET_TEMPLATE_MESSAGES_FETCHING, true)
      const { data } = await getTemplate(id)

      commit(SET_TEMPLATE_MESSAGES, { data })
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        objectId: id,
        fallbackCode: 'templateMessage.get',
        action: `${moduleName}/${LOAD_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_TEMPLATE_MESSAGES_FETCHING, false)
    }
  },

  /**
   * @name CREATE_TEMPLATE_MESSAGE
   *  Create one
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Object} object.body Body of the new templateMessage
   * @returns {Promise<Number|null>} Numeric id of the templateMessage or null
   */
  async [CREATE_TEMPLATE_MESSAGE]({ commit }, { body }) {
    try {
      commit(SET_TEMPLATE_MESSAGES_POSTING, true)

      const { data } = await postTemplate(body)

      commit(SET_TEMPLATE_MESSAGES, { data })

      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('createdThing', { thing: lang.t('template') })
      })
      return data.id
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        fallbackCode: 'templateMessage.post',
        action: `${moduleName}/${CREATE_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_TEMPLATE_MESSAGES_POSTING, false)
    }
  },

  /**
   * @name UPDATE_TEMPLATE_MESSAGE
   *  Update one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @param {Object} object.body data
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [UPDATE_TEMPLATE_MESSAGE]({ dispatch, commit }, { id, body }) {
    try {
      commit(SET_TEMPLATE_MESSAGES_POSTING, true)
      await putTemplate(id, body)
      await dispatch(actionTypes.LOAD_TEMPLATE_MESSAGE, {
        id
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('updatedThing', { thing: lang.t('template') })
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        objectId: id,
        fallbackCode: 'templateMessage.put',
        action: `${moduleName}/${UPDATE_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_TEMPLATE_MESSAGES_POSTING, false)
    }
  },

  /**
   * @name DELETE_TEMPLATE_MESSAGE
   * Destroy one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [DELETE_TEMPLATE_MESSAGE]({ commit }, { id }) {
    try {
      commit(SET_TEMPLATE_MESSAGES_POSTING, true)
      await deleteTemplate(id)
      commit(mutationTypes.DELETE_TEMPLATE_MESSAGE, id)
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', { thing: lang.t('template') }),
        actions: {
          undo: () => {
            this.dispatch(`templateMessages/${PATCH_TEMPLATE_MESSAGE}`, { id })
          }
        }
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        objectId: id,
        fallbackCode: 'templateMessage.delete',
        action: `${moduleName}/${DELETE_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_TEMPLATE_MESSAGES_POSTING, false)
    }
  },

  /**
   * @name PATCH_TEMPLATE_MESSAGE
   * Patch one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [PATCH_TEMPLATE_MESSAGE]({ dispatch, commit }, { id }) {
    try {
      commit(SET_TEMPLATE_MESSAGES_POSTING, true)
      await patchTemplate(id)
      await dispatch(LOAD_TEMPLATE_MESSAGE, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        objectId: id,
        fallbackCode: 'templateMessage.patch',
        action: `${moduleName}/${PATCH_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_TEMPLATE_MESSAGES_POSTING, false)
    }
  },

  /**
   * @name FORM_NEW_TEMPLATE_MESSAGE
   *  Prepare new form
   * @param {Object} Vuex object
   */
  async [FORM_NEW_TEMPLATE_MESSAGE]({ commit }) {
    try {
      const formData = {
        name: '',
        message: ''
      }
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'templateMessage', formData },
        { root: true }
      )
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        fallbackCode: 'templateMessage.new',
        action: `${moduleName}/${FORM_NEW_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name FORM_EDIT_TEMPLATE_MESSAGE
   *  Prepare edit form
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [FORM_EDIT_TEMPLATE_MESSAGE]({ commit, state }, { id }) {
    try {
      const templateMessageData = state.data[id]
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'templateMessage', formData: templateMessageData },
        { root: true }
      )
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        objectId: id,
        fallbackCode: 'templateMessage.edit',
        action: `${moduleName}/${FORM_EDIT_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name FORM_SUBMIT_TEMPLATE_MESSAGE
   *  Submit form
   * @param {Object} Vuex object
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [FORM_SUBMIT_TEMPLATE_MESSAGE]({ rootGetters, dispatch }) {
    try {
      const templateMessageData =
        rootGetters['forms/getFormByKey']('templateMessage')
      let { id } = templateMessageData
      if (id) {
        await dispatch(actionTypes.UPDATE_TEMPLATE_MESSAGE, {
          id: templateMessageData.id,
          body: templateMessageData
        })
      } else {
        id = await dispatch(actionTypes.CREATE_TEMPLATE_MESSAGE, {
          body: templateMessageData
        })
      }
      // Update in store
      dispatch(actionTypes.LOAD_TEMPLATE_MESSAGE, {
        id
      })
      await this.dispatch(FORM_RESET_OLD, 'templateMessage')
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'templateMessage',
        fallbackCode: 'templateMessage.submit',
        action: `${moduleName}/${FORM_SUBMIT_TEMPLATE_MESSAGE}`,
        actionPayload: arguments[1]
      })
      return null
    }
  }
}

<script lang="jsx">
import { formatPhone } from '@cling/utils'

/**
 * Usage:
 * <CPhoneFormat :phone="123" :region="SE">
 *
 * or with slots:
 * <CPhoneFormat :phone="123">
    <template v-slot="{ formattedValue }">
      The formatted phone: {{ formattedValue }}
    </template>
  </CPhoneFormat>
 */

export default {
  name: 'CPhoneFormat',
  props: {
    phone: {
      type: String,
      required: false,
      default: ''
    },
    region: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      formattedPhone: ''
    }
  },
  watch: {
    phone: {
      immediate: true,
      async handler() {
        this.doFormatPhone()
      }
    }
  },
  methods: {
    async doFormatPhone() {
      if (!this.phone) {
        this.formattedPhone = ''
        return
      }
      const formatted = await formatPhone(this.phone, {
        defaultRegion: this.region
      })
      if (!formatted) {
        this.formattedPhone = this.phone // Just show default
        return
      }
      this.formattedPhone = formatted.national
    }
  },
  render() {
    if (!this.$scopedSlots.default) {
      return <span>{this.formattedPhone}</span>
    }

    return this.$scopedSlots.default({
      formattedValue: this.formattedPhone
    })
  }
}
</script>

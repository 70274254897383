import permissions from '@cling/services/permissions'

export default {
  install(Vue, { store } = {}) {
    permissions.init({ store })

    Vue.prototype.$permissions = permissions
    Vue.prototype.$can = permissions.checkPermission.bind(permissions)
  }
}

export class Event {
  constructor() {
    this.events = {}
  }

  on(eventName, fn) {
    this.events[eventName] = this.events[eventName] || []
    this.events[eventName].push(fn)

    return () => this.off(eventName, fn)
  }

  off(eventName, fn) {
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter(
        listener => listener !== fn
      )
    }
  }

  trigger(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(function (fn) {
        fn(data)
      })
    }
  }
}

export default new Event()

<template>
  <div class="l-root">
    <div
      v-tooltip="getNavTooltip(-1)"
      :class="{ disabled: !canBackward }"
      class="l-button"
      style="left: calc(-4 * var(--rem))"
      @click="$emit('go', -1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </div>
    <div
      v-tooltip="getNavTooltip(1)"
      :class="{ disabled: !canForward }"
      class="l-button"
      style="right: calc(-4 * var(--rem))"
      @click="$emit('go', 1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
    <!-- <div v-tooltip="getNavTooltip('close')" class="l-button" @click="$emit('close')">
      <svg viewBox="0 0 24 24" width="25" height="25" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    canForward: {
      type: Boolean,
      default: false
    },
    canBackward: {
      type: Boolean,
      default: false
    }
  },
  created() {
    document.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    this.removeKeyHandler()
  },
  methods: {
    getNavTooltip(type) {
      if (type === -1) {
        return {
          content: `
            <div style='margin-bottom: 0.6em;'>
              ${this.$t('show', { thing: this.$t('previous') })}
            </div>
            <div class='flex items-center justify-center w-full' style='padding-bottom: 0.5em;'>
              <div style='text-align: center; background-color: hsl(0, 0%, 40%); line-height: 1; border-radius: 0.5em; display: inline-flex; align-items: center; justify-content: center; padding: 0.5em;'>
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'><path d='M19 12H6M12 5l-7 7 7 7'></path></svg>
              </div>
            </div>
          `,
          html: true
        }
      }
      if (type === 1) {
        return {
          content: `
            <div style='margin-bottom: 0.6em;'>
              ${this.$t('show', { thing: this.$t('next') })}
            </div>
            <div class='flex items-center justify-center w-full' style='padding-bottom: 0.5em;'>
              <div style='text-align: center; background-color: hsl(0, 0%, 40%); line-height: 1; border-radius: 0.5em; display: inline-flex; align-items: center; justify-content: center; padding: 0.5em;'>
                <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'><path d='M5 12h13M12 5l7 7-7 7'/></svg>
              </div>
            </div>
          `,
          html: true
        }
      }
      if (type === 'close') {
        return {
          content:
            '<div style="margin-bottom: 0.6em;">Stäng</div><div style="text-align: center; opacity: 0.85;"><svg width="30" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="17 0 105.5 72" style="enable-background:new 17 0 105.5 72"><path d="M103.3 70.3H36.2c-9.6 0-17.5-7.9-17.5-17.5V19.2c0-9.6 7.9-17.5 17.5-17.5h67.1c9.6 0 17.5 7.9 17.5 17.5v33.6c0 9.6-7.9 17.5-17.5 17.5z" style="fill:none;stroke:currentColor;stroke-width:3;stroke-miterlimit:10"/><path style="fill:none" d="M17 17.8h105.5V49H17z"/><path fill="currentColor" d="M46.6 38.2c.1 5 3.3 7.1 7 7.1 2.6 0 4.2-.5 5.6-1l.6 2.6c-1.3.6-3.5 1.3-6.8 1.3-6.3 0-10-4.1-10-10.2s3.6-11 9.5-11c6.6 0 8.4 5.8 8.4 9.6 0 .8-.1 1.3-.1 1.7l-14.2-.1zm10.9-2.7c0-2.4-1-6-5.1-6-3.7 0-5.4 3.4-5.7 6h10.8zM65.1 43.9c1.1.7 3 1.5 4.9 1.5 2.7 0 3.9-1.3 3.9-3 0-1.8-1-2.7-3.8-3.7-3.7-1.3-5.4-3.3-5.4-5.8 0-3.3 2.6-6 7-6 2.1 0 3.9.6 5 1.3l-.9 2.7c-.8-.5-2.3-1.2-4.2-1.2-2.2 0-3.4 1.3-3.4 2.8 0 1.7 1.2 2.4 3.9 3.4 3.5 1.3 5.3 3.1 5.3 6.1 0 3.6-2.8 6.1-7.6 6.1-2.2 0-4.3-.5-5.7-1.4l1-2.8zM96.7 46.9c-1 .5-3.1 1.2-5.8 1.2-6.1 0-10.1-4.2-10.1-10.4 0-6.3 4.3-10.8 10.9-10.8 2.2 0 4.1.5 5.1 1l-.8 2.9c-.9-.5-2.3-1-4.3-1-4.7 0-7.2 3.4-7.2 7.7 0 4.7 3 7.6 7.1 7.6 2.1 0 3.5-.5 4.5-1l.6 2.8z"/></svg></div>',
          html: true
        }
      }
      return null
    },
    onKeyUp(e) {
      const focusedEl = document.activeElement
      const isNotInputFocus = !(
        focusedEl.hasAttribute('contenteditable') ||
        ['input', 'textarea'].includes(focusedEl.tagName.toLowerCase())
      )

      if (isNotInputFocus) {
        if (parseInt(e.keyCode, 10) === 37) this.$emit('go', -1)
        if (parseInt(e.keyCode, 10) === 39) this.$emit('go', 1)
      }

      const key = e.key || e.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        const openedModals = this.$modal.getOpenInstances()
        if (e.defaultPrevented || openedModals.length) return
        this.$emit('close')
      }
    },
    removeKeyHandler() {
      document.removeEventListener('keyup', this.onKeyUp)
    }
  },
  render() {
    return this.$scopedSlots.default({
      leftTooltip: this.getNavTooltip(-1),
      rightTooltip: this.getNavTooltip(1),
      closeTooltip: this.getNavTooltip('close')
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.l {
  &-root {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    pointer-events: none;
  }
  &-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
    color: $white;
    opacity: 0.7;
    pointer-events: all;
    svg {
      line-height: 1;
    }
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 1;
    }
    &.disabled {
      pointer-events: none;
      visibility: hidden;
      cursor: default;
    }
  }
}
</style>

export { default as regions } from './regions'
export { default as languages } from './languages'
export { default as locales } from './locales'
export { default as currencies } from './currencies'
export { default as unitTypes } from './unitTypes'

export const accountTypes = ['free', 'trial', 'start', 'base', 'pro']
export const payingAccountTypes = ['start', 'base', 'pro']
export const brands = {
  cling: {
    name: 'Cling',
    domain: 'cling.se',
    defaultRegion: 'SE',
    defaultLanguage: 'sv',
    defaultCurrency: 'SEK',
    termsOfService: {
      version: 'cling_tjansteavtal_sv_2.0',
      url: 'https://api.cling.se/legal/terms'
    },
    privacyPolicy: {
      version: 'cling_integritetsmeddelande_sv_2.0',
      url: 'https://api.cling.se/legal/privacy'
    },
    apiDoc: 'https://developer.cling.se/api/introduction/intro'
  },
  fortnoxSe: {
    name: 'Fortnox Sign',
    domain: 'fortnoxsign.se',
    defaultRegion: 'SE',
    defaultLanguage: 'sv',
    defaultCurrency: 'SEK',
    termsOfService: {
      version: 'cling_tjansteavtal_sv_2.0',
      url: 'https://api.cling.se/legal/terms'
    },
    privacyPolicy: {
      version: 'cling_integritetsmeddelande_sv_2.0',
      url: 'https://api.cling.se/legal/privacy'
    },
    apiDoc: 'https://developer.fortnoxsign.se'
  },
  fortnoxCom: {
    name: 'Fortnox Sign',
    domain: 'fortnoxsign.com',
    defaultRegion: 'US',
    defaultLanguage: 'en',
    defaultCurrency: 'USD',
    termsOfService: {
      version: 'docspo_terms_en_1.2',
      url: 'https://api.docspo.com/legal/terms'
    },
    privacyPolicy: {
      version: 'docspo_privacy_en_1.2',
      url: 'https://api.docspo.com/legal/privacy'
    },
    apiDoc: 'https://developer.fortnoxsign.com'
  },
  docspo: {
    name: 'Docspo',
    domain: 'docspo.com',
    defaultRegion: 'US',
    defaultLanguage: 'en',
    defaultCurrency: 'USD',
    termsOfService: {
      version: 'docspo_terms_en_1.2',
      url: 'https://api.docspo.com/legal/terms'
    },
    privacyPolicy: {
      version: 'docspo_privacy_en_1.2',
      url: 'https://api.docspo.com/legal/privacy'
    },
    apiDoc: 'https://developer.docspo.com'
  }
}

import {
  getCompanyReferenceItems,
  getCompanyReferenceItem,
  postCompanyReferenceItem,
  putCompanyReferenceItem,
  deleteCompanyReferenceItem
} from '@cling/api'
import globalActionTypes, { global } from '@cling/store/action-types'
import globalMutationTypes from '@cling/store/mutation-types'
import { companyReferenceItems as companyReferenceItemListSchema } from '@cling/store/utils/schema'

import { actionTypes, mutationTypes } from './constants'
import { SET_AS_NORMALIZED_DATA } from '../../actions/constants'

const moduleName = 'companyReferenceItems'

const { FORM_RESET_OLD } = global

const {
  LOAD_COMPANY_REFERENCE_ITEMS,
  LOAD_COMPANY_REFERENCE_ITEM,
  CREATE_COMPANY_REFERENCE_ITEM,
  UPDATE_COMPANY_REFERENCE_ITEM,
  DELETE_COMPANY_REFERENCE_ITEM,
  FORM_NEW_COMPANY_REFERENCE_ITEM,
  FORM_SUBMIT_COMPANY_REFERENCE_ITEMS
} = actionTypes

export default {
  /**
   * @name LOAD_COMPANY_REFERENCE_ITEMS
   *  Load all companyReferenceItems
   * @param {Object} Vuex object
   */
  async [LOAD_COMPANY_REFERENCE_ITEMS]({ commit }) {
    try {
      const { data } = await getCompanyReferenceItems()

      commit(mutationTypes.CLEAR_COMPANY_REFERENCE_ITEMS)
      // Nothing to normalize, just stash it
      commit(mutationTypes.SET_COMPANY_REFERENCE_ITEMS, { data })
    } catch (err) {
      this.handleError(err, {
        object: 'companyReferenceItem',
        fallbackCode: 'companyReferenceItem.get',
        action: `${moduleName}/${LOAD_COMPANY_REFERENCE_ITEMS}`
      })
    }
  },

  /**
   * @name LOAD_COMPANY_REFERENCE_ITEM
   *  Load one company reference item by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReferenceItem
   */
  async [LOAD_COMPANY_REFERENCE_ITEM](noop, { id }) {
    try {
      const { data: companyReferenceItem } = await getCompanyReferenceItem(id)

      await this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: [companyReferenceItem],
        schema: companyReferenceItemListSchema
      })
    } catch (err) {
      this.handleError(err, {
        object: 'companyReferenceItem',
        objectId: id,
        fallbackCode: 'companyReferenceItem.get',
        action: `${moduleName}/${LOAD_COMPANY_REFERENCE_ITEM}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name CREATE_COMPANY_REFERENCE_ITEM
   * Create one
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Object} object.body CompanyReferenceItem body
   * @returns {Promise<Number|null>} Promise that resolves with new companyReferenceItem id or null
   */
  async [CREATE_COMPANY_REFERENCE_ITEM]({ commit }, { body }) {
    try {
      const { data } = await postCompanyReferenceItem(body)

      commit(mutationTypes.SET_COMPANY_REFERENCE_ITEMS, { data })
      return data.id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReferenceItem',
        fallbackCode: 'companyReferenceItem.post',
        action: `${moduleName}/${CREATE_COMPANY_REFERENCE_ITEM}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name UPDATE_COMPANY_REFERENCE_ITEM
   *  Update one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReferenceItem
   * @param {Object} object.body companyReferenceItem data
   * @returns {Promise<Number|null>} Promise that resolves with companyReferenceItem id or false
   */
  async [UPDATE_COMPANY_REFERENCE_ITEM]({ dispatch }, { id, body }) {
    try {
      await putCompanyReferenceItem(id, body)
      await dispatch(LOAD_COMPANY_REFERENCE_ITEM, { id })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReferenceItem',
        objectId: id,
        fallbackCode: 'companyReferenceItem.put',
        action: `${moduleName}/${UPDATE_COMPANY_REFERENCE_ITEM}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name DELETE_COMPANY_REFERENCE_ITEM
   * Destroy one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReferenceItem
   * @returns {Promise<Number|null>} Promise that resolves with companyReferenceItem id or null
   */
  async [DELETE_COMPANY_REFERENCE_ITEM]({ commit }, { id }) {
    try {
      await deleteCompanyReferenceItem(id)
      commit(mutationTypes.DELETE_COMPANY_REFERENCE_ITEM, id)
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReferenceItem',
        objectId: id,
        fallbackCode: 'companyReferenceItem.delete',
        action: `${moduleName}/${DELETE_COMPANY_REFERENCE_ITEM}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name FORM_NEW_COMPANY_REFERENCE_ITEM
   *  Prepare new form
   * @param {Object} Vuex object
   * @param {Object} data optional default props for the new item
   * @returns {String|null} uniqueId of the new form item or null
   */
  async [FORM_NEW_COMPANY_REFERENCE_ITEM](noop, data) {
    try {
      const formData = {
        description: '',
        CompanyReferenceId: null,
        ImageId: null,
        ThumbnailId: null,
        ...data
      }
      this.dispatch(`forms/${globalActionTypes.forms.SET_FORM_VALUE}`, {
        key: 'companyReferenceItems',
        value: formData
      })
      return formData.uniqueId
    } catch (err) {
      this.handleError(err, {
        object: 'companyReferenceItem',
        action: `${moduleName}/${FORM_NEW_COMPANY_REFERENCE_ITEM}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name FORM_SUBMIT_COMPANY_REFERENCE_ITEMS
   *  Submit form
   * @param {Object} Vuex object
   */
  async [FORM_SUBMIT_COMPANY_REFERENCE_ITEMS]({ rootGetters, dispatch }) {
    try {
      const companyReferenceItemsData = rootGetters['forms/getFormByKey'](
        'companyReferenceItems'
      )
      // Assign position based on index in the array
      companyReferenceItemsData.forEach((item, index) => {
        this.commit(`forms/${globalMutationTypes.forms.SET_FORM_VALUE}`, {
          key: `companyReferenceItems[${index}]`,
          value: { ...item, position: index }
        })
      })

      const promises = companyReferenceItemsData.map(companyReferenceItem => {
        const { id = undefined } = companyReferenceItem
        const action = id
          ? UPDATE_COMPANY_REFERENCE_ITEM
          : CREATE_COMPANY_REFERENCE_ITEM
        return dispatch(action, {
          id: companyReferenceItem.id,
          body: companyReferenceItem
        })
      })

      await Promise.all(promises)

      await this.dispatch(FORM_RESET_OLD, 'companyReferenceItems')
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companyReferenceItem',
        action: `${moduleName}/${FORM_SUBMIT_COMPANY_REFERENCE_ITEMS}`
      })
      return false
    }
  }
}

import lang from '@cling/language'
import { priceFormat } from '@cling/utils'

import { Liquid } from 'liquidjs'

const disabledTags = ['include', 'tablerow']
const disabledFilters = []

export const liquidEngine = new Liquid({
  // cache: true,
  // globals: {}
})

// Disable tags
const disabledTag = {
  parse(token) {
    throw new Error(`Tag "${token.name}" disabled`)
  }
}
disabledTags.forEach(tagName => liquidEngine.registerTag(tagName, disabledTag))

// Disable filters
function disabledFilter(name) {
  return function () {
    throw new Error(`Filter "${name}" disabled`)
  }
}
disabledFilters.forEach(filterName =>
  liquidEngine.registerFilter(filterName, disabledFilter(filterName))
)

// Add custom filters
liquidEngine.registerFilter('priceFormat', function (v) {
  const docData = this.context.environments
  const { currency } = docData?.data?.prices || docData || {}
  return priceFormat(v, { currency })
})

liquidEngine.registerFilter('formatDate', function (v) {
  return lang.formatDate(v)
})

export default { liquidEngine }

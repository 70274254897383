// Original source
// https://github.com/ianwalter/vuex-reset/blob/master/index.js
//
// Modified version allows for a whitelist array. Which prevents
// the plugin from resetting their state.

import cloneDeep from 'lodash/cloneDeep'

export default function VuexReset(opts = {}) {
  const { trigger, whitelist = [] } = { trigger: 'reset', ...opts }

  // Modules that are not supposed to handled by plugin
  const whitelistModules = ['route', ...whitelist]

  return store => {
    // Extract the initial state from the store so that it can be used to reset
    // the store when a trigger mutation is executed.
    const initialState = cloneDeep(store.state)

    store.subscribe((mutation, state) => {
      if (mutation.type === trigger) {
        const newState = cloneDeep(initialState)

        // Custom code added for being able not to reset Vuex ORM module
        whitelistModules.forEach(moduleName => {
          if (state[moduleName]) {
            newState[moduleName] = cloneDeep(state[moduleName])
          }
        })

        // Reset the entire store state.
        store.replaceState(newState)
      } else {
        // Extract the name of the module and mutation.
        let mod = mutation.type.split('/')
        const mut = mod.pop()

        if (mut === trigger) {
          // Reset the state for the module containing the mutation.
          mod = mod.join('/')
          store.replaceState({
            ...cloneDeep(state),
            [mod]: cloneDeep(initialState[mod])
          })
        }
      }
    })

    store.registerModuleState = (namespace, mod) => {
      store.registerModule(namespace, mod)
      initialState[namespace] = cloneDeep(mod.state)
    }

    store.unregisterModuleState = namespace => {
      store.unregisterModule(namespace)
      delete initialState[namespace]
    }
  }
}

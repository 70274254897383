import { getDocumentContentImage } from '@cling/utils/image'
import Image from '@tiptap/extension-image'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import { Plugin, PluginKey } from 'prosemirror-state'

import ImageView from './components/ImageView.vue'

export default (uploadFn, compressImages = false) =>
  Image.extend({
    addOptions() {
      return {
        inline: true,
        HTMLAttributes: {},
        resolveImgSrc: src =>
          compressImages
            ? getDocumentContentImage(src, { auto: 'compress' })
            : src
      }
    },

    addAttributes() {
      return {
        ...(this.parent ? this.parent() : null),
        src: {
          default: null,
          parseHTML: el => el.getAttribute('src'),
          renderHTML: attrs => ({
            src: this.options?.resolveImgSrc(attrs.src) || attrs.src
          })
        },
        display: {
          default: 'block',
          parseHTML: el => el.getAttribute('data-display'),
          renderHTML: attrs => ({ 'data-display': attrs.display })
        },
        style: {
          default: '',
          parseHTML: el => el.getAttribute('style'),
          renderHTML: attrs => ({ style: attrs.style })
        }
      }
    },

    addNodeView() {
      return VueNodeViewRenderer(ImageView)
    },

    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey('image-handler'),
          props: {
            handlePaste(view, event) {
              const { items } =
                event.clipboardData || event.originalEvent.clipboardData

              for (const item of items) {
                if (item.type.indexOf('image') === 0) {
                  event.preventDefault()
                  const { schema } = view.state

                  const image = item.getAsFile()

                  if (uploadFn) {
                    uploadFn(image).then(src => {
                      const node = schema.nodes.image.create({
                        src
                      })
                      const transaction =
                        view.state.tr.replaceSelectionWith(node)
                      view.dispatch(transaction)
                    })
                  } else {
                    const reader = new FileReader()
                    reader.onload = readerEvent => {
                      const node = schema.nodes.image.create({
                        src: readerEvent.target.result
                      })
                      const transaction =
                        view.state.tr.replaceSelectionWith(node)
                      view.dispatch(transaction)
                    }
                    reader.readAsDataURL(image)
                  }
                }
              }
              return false
            },
            handleDOMEvents: {
              drop(view, event) {
                const hasFiles =
                  event.dataTransfer &&
                  event.dataTransfer.files &&
                  event.dataTransfer.files.length

                if (!hasFiles) return

                const images = Array.from(event.dataTransfer.files).filter(
                  file => /image/i.test(file.type)
                )

                if (images.length === 0) return

                event.preventDefault()

                const { schema } = view.state
                const coordinates = view.posAtCoords({
                  left: event.clientX,
                  top: event.clientY
                })

                images.forEach(async image => {
                  const reader = new FileReader()

                  if (uploadFn) {
                    const node = schema.nodes.image.create({
                      src: await uploadFn(image)
                    })
                    const transaction = view.state.tr.insert(
                      coordinates.pos,
                      node
                    )
                    view.dispatch(transaction)
                  } else {
                    reader.onload = readerEvent => {
                      const node = schema.nodes.image.create({
                        src: readerEvent.target.result
                      })
                      const transaction = view.state.tr.insert(
                        coordinates.pos,
                        node
                      )
                      view.dispatch(transaction)
                    }
                    reader.readAsDataURL(image)
                  }
                })
              }
            }
          }
        })
      ]
    }
  })

<template>
  <div>
    <slot :updated-ago="updatedAgo" />
    <CSkeleton v-if="loading" height="10rem" />
    <BaseTextEditor
      v-else
      v-model="text"
      :disabled="disabled"
      :extensions="{
        image: true
      }"
      :placeholder="$t('placeholder')"
      textarea-style
      filled
      @blur="submit()"
    />
  </div>
</template>

<script>
import CSkeleton from '@cling/components/ui/Skeleton'
import BaseTextEditor from '@cling/components/ui/TextEditor'

export default {
  i18nOptions: {
    namespaces: 'components',
    keyPrefix: 'account.autoSaveNotes'
  },
  name: 'AutoSaveNotes',
  components: {
    BaseTextEditor,
    CSkeleton
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    post: {
      type: Function,
      required: true
    },
    externalUpdatedAt: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updatedAt: null, // For internal use
      isPosting: false,
      text: ''
    }
  },
  computed: {
    updatedAgo() {
      if (this.isPosting) return this.$t('updated.posting')
      else if (this.updatedAt) return this.$t('updated.recent')
      else if (this.externalUpdatedAt)
        return this.$t('updated.ago', {
          time: this.$formatDate(this.externalUpdatedAt, 'p, dd MMM')
        })
      return ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) this.text = val
        else this.updatedAt = null
      }
    }
  },
  methods: {
    async submit() {
      this.isPosting = true
      await this.post(this.text)
      this.updatedAt = new Date().toISOString()
      this.isPosting = false
    }
  }
}
</script>

export const actionTypes = {
  DO_CREATE_TEMPLATE: 'DO_CREATE_TEMPLATE',
  DO_LOAD_TEMPLATE: 'DO_LOAD_TEMPLATE',
  DO_LOAD_TEMPLATES: 'DO_LOAD_TEMPLATES',
  DO_UPDATE_TEMPLATE: 'DO_UPDATE_TEMPLATE',
  LOAD_TEMPLATE: 'LOAD_TEMPLATE',
  LOAD_TEMPLATES: 'LOAD_TEMPLATES',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  PATCH_TEMPLATE: 'PATCH_TEMPLATE',
  HIDE_TEMPLATE: 'HIDE_TEMPLATE',
  DUPLICATE_PROTECTED_TEMPLATE: 'DUPLICATE_PROTECTED_TEMPLATE'
}

export const mutationTypes = {
  SET_TEMPLATES: 'SET_TEMPLATES',
  SET_TEMPLATE_FETCHING: 'SET_TEMPLATE_FETCHING',
  SET_TEMPLATE_POSTING: 'SET_TEMPLATE_POSTING',
  DELETE_MANY_TEMPLATES: 'DELETE_MANY_TEMPLATES'
}

export default {
  actionTypes,
  mutationTypes
}

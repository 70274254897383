import actionTypes from '@cling/store/action-types'

import { actionTypes as newActionTypes, mutationTypes } from '../constants'

const { FORM_DOCUMENT_SET_CLIENT, FORM_DOCUMENT_SUBMIT_CLIENT } = newActionTypes

const { SET_FORM_VALUE, REMOVE_FORM_VALUE } = mutationTypes

export default {
  /**
   * Action to set the endCustomer for document
   * @param {Object} formData Object with the endCustomer object, if null then customer is reset
   */
  async [FORM_DOCUMENT_SET_CLIENT](
    { commit, getters, rootGetters },
    { formData }
  ) {
    // Reset client
    if (!formData) {
      // Set form to null
      commit(SET_FORM_VALUE, {
        key: 'document.ModuleEndCustomer',
        value: null
      })
      // Remove any extraClients
      commit(REMOVE_FORM_VALUE, 'document.ModuleExtraCustomers')
      return true
    }
    // Set new client
    const newClient = Object.assign({}, formData)
    // Reassign the endCustomer.id to the property EndCustomerId to use as a reference to stored customers
    newClient.id = null
    newClient.EndCustomerId = formData.id
    commit(SET_FORM_VALUE, {
      key: 'document.ModuleEndCustomer',
      value: newClient
    })
    const ModuleExtraCustomers = getters.documentField('ModuleExtraCustomers')
    // If the endCustomer is a company create a extraClient from reference
    if (
      newClient.customerType === 'company' &&
      newClient.reference &&
      ModuleExtraCustomers &&
      ModuleExtraCustomers.length === 0
    ) {
      // Create a new extraClient from reference
      commit(SET_FORM_VALUE, {
        key: 'document.ModuleExtraCustomers',
        value: {
          name: newClient.reference
        }
      })
    }

    // TODO: This should probably be somewhere else?
    // Update current vat setting from settings depending on client type
    const priceInputCompany =
      rootGetters['settings/getCompanySetting']('priceInputCompany')
    const priceInputDefault =
      rootGetters['settings/getCompanySetting']('priceInputDefault')
    if (newClient.customerType === 'company' && priceInputCompany) {
      commit(
        'application/updateField',
        { path: 'priceInputVat', value: priceInputCompany },
        { root: true }
      )
    } else if (newClient.customerType === 'individual' && priceInputDefault) {
      commit(
        'application/updateField',
        { path: 'priceInputVat', value: priceInputDefault },
        { root: true }
      )
    }
    return true
  },
  // Submit the endCustomer in the context of client
  async [FORM_DOCUMENT_SUBMIT_CLIENT](
    { commit, dispatch, rootGetters },
    { mode = 'create' } = {}
  ) {
    let newClient = null
    if (mode === 'edit') {
      // Get current client
      const oldClient = rootGetters['forms/documentField']('ModuleEndCustomer')
      const endCustomer = rootGetters['forms/getFormByKey']('endCustomer')
      if (!oldClient) {
        throw new Error('Could not find the old client')
      }
      if (!endCustomer) {
        throw new Error('Could not find the new client')
      }
      // If client changed to company from individual, handle extraClient
      if (
        oldClient.customerType === 'individual' &&
        endCustomer.customerType === 'company'
      ) {
        // Remove all old extraClients
        commit(REMOVE_FORM_VALUE, 'document.ModuleExtraCustomers')

        // Create a new extraClient from reference
        commit(SET_FORM_VALUE, {
          key: 'document.ModuleExtraCustomers',
          value: { name: endCustomer.reference }
        })
        // If company was edited, make sure to update reference if needed
      } else if (endCustomer.customerType === 'company') {
        const extraClients = rootGetters['forms/documentField'](
          'ModuleExtraCustomers'
        )
        // Find first extra client and update name from reference

        if (extraClients && extraClients[0]) {
          commit(SET_FORM_VALUE, {
            key: 'document.ModuleExtraCustomers[0]',
            value: { name: endCustomer.reference }
          })
        } else {
          // Create a new extraClient from reference
          commit(SET_FORM_VALUE, {
            key: 'document.ModuleExtraCustomers',
            value: { name: endCustomer.reference }
          })
        }
      }

      newClient = Object.assign({}, endCustomer, {
        id: endCustomer.EndCustomerId
      })
      if (endCustomer.doUpdateEndCustomer) {
        commit(SET_FORM_VALUE, {
          key: 'endCustomer',
          value: newClient
        })
        await dispatch(
          `endCustomers/${actionTypes.FORM_SUBMIT_ENDCUSTOMER}`,
          {},
          { root: true }
        )
      }
    } else {
      // Submit new endCustomer form
      const endCustomerId = await dispatch(
        `endCustomers/${actionTypes.FORM_SUBMIT_ENDCUSTOMER}`,
        {},
        { root: true }
      )
      // Get the new endCustomer
      const endCustomer =
        rootGetters['endCustomers/endCustomerById'](endCustomerId)

      if (!endCustomer) {
        throw new Error('Could not find the endCustomer')
      }
      newClient = Object.assign({}, endCustomer)
    }
    await dispatch(FORM_DOCUMENT_SET_CLIENT, {
      formData: newClient
    })
    return true
  }
}

import Vue from 'vue'

import { mutationTypes } from './constants'

const {
  SET_SETTINGS,
  CLEAR_SETTINGS,
  SET_SETTINGS_FETCHING,
  SET_SETTINGS_POSTING
} = mutationTypes

export default {
  /**
   * Update settings with new values
   * @param {Object} state Vuex state
   * @param {Object} payload
   * @param {String} payload.key Settings key, company or companyUser
   * @param {Object<name, value>} payload.settings Object with settings to be updated
   */
  [SET_SETTINGS](state, { key, settings = {} }) {
    if (!state[key]) throw Error(`${key} is not a valid key/type`)

    Object.keys(settings).forEach(name => {
      const value = settings[name]
      Vue.set(state[key], name, value)
    })
  },

  /**
   * Clear all settings from store on given type
   * @param {Object} state Vuex state
   * @param {Object} payload
   * @param {String} payload.key companyUser or company
   * @param {String} payload.name Optional name if a specific setting should be cleared
   */
  [CLEAR_SETTINGS](state, { key, name = null }) {
    if (name) Vue.delete(state[key], name)
    else Vue.set(state, key, {})
  },

  /**
   * @name SET_SETTINGS_FETCHING
   * Set fetching status of settings
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_SETTINGS_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_SETTINGS_POSTING
   * Set posting status of settings
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_SETTINGS_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

import {
  deleteProjectFile,
  getProjectFile,
  patchProjectFile,
  postProjectFile,
  putProjectFile
} from '@cling/api'
import lang from '@cling/language'
import { global } from '@cling/store/action-types'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'projects'

const {
  LOAD_PROJECT_FILE,
  CREATE_PROJECT_FILE,
  UPDATE_PROJECT_FILE,
  DELETE_PROJECT_FILE,
  PATCH_PROJECT_FILE
} = actionTypes

export default {
  /**
   * @name LOAD_PROJECT_FILE
   * Load one project file by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id The projectFile.id to load
   */
  async [LOAD_PROJECT_FILE](_, { id }) {
    try {
      const { data } = await getProjectFile(id)

      await this.dispatch(global.SET_AS_NORMALIZED_DATA, {
        data: [data],
        schema: 'projectFiles'
      })

      return true
    } catch (err) {
      this.handleError(err, {
        object: 'projectFile',
        objectId: id,
        fallbackCode: 'projectFile.get',
        action: `${moduleName}/${LOAD_PROJECT_FILE}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name CREATE_PROJECT_FILE
   * Create one project file
   *
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.projectId The projectId
   * @param {Number} object.fileId The id of the file
   *
   * @returns {Promise<string>} Promise that resolves with the projectFile.id or null
   */
  async [CREATE_PROJECT_FILE](_, { projectId, fileId }) {
    try {
      if (typeof projectId === 'undefined')
        throw new Error('Missing parameter projectId')
      if (typeof fileId === 'undefined')
        throw new Error('Missing parameter fileId')

      const { data: projectFile } = await postProjectFile({
        ProjectId: projectId,
        FileId: fileId
      })
      await this.dispatch(global.SET_AS_NORMALIZED_DATA, {
        data: [projectFile],
        schema: 'projectFiles'
      })

      return projectFile.id
    } catch (err) {
      this.handleError(err, {
        object: 'projectFile',
        fallbackCode: 'projectFile.post',
        action: `${moduleName}/${CREATE_PROJECT_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },
  /**
   * @name UPDATE_PROJECT_FILE
   * Update one project file by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id  of the projectFile
   * @param {Object} object.body projectFile data
   * @returns {Promise<string>} Promise that resolves with the projectFile.id or null
   */
  async [UPDATE_PROJECT_FILE]({ dispatch }, { id, body }) {
    try {
      await putProjectFile(id, body)
      await dispatch(LOAD_PROJECT_FILE, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'projectFile',
        objectId: id,
        fallbackCode: 'projectFile.put',
        action: `${moduleName}/${UPDATE_PROJECT_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },
  /**
   * @name DELETE_PROJECT_FILE
   * Delete one project file by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number|String} object.id Numeric id of the project file
   * @returns {Promise<Number>} Promise that resolves with the deleted project file id
   */
  async [DELETE_PROJECT_FILE]({ commit, getters }, { projectId, id }) {
    try {
      const project = getters.byId(projectId)
      if (project) {
        const projectFile = project.files.find(file => file.id === id)
        const { FileId: fileId } = projectFile
        this.dispatch(global.DELETE_FILE, { id: fileId })
      }
      await deleteProjectFile(id)
      commit(mutationTypes.DELETE_PROJECT_FILE, { projectId, id })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', { thing: lang.t('file') }),
        actions: {
          undo: () => {
            this.dispatch(`projects/${PATCH_PROJECT_FILE}`, { id, projectId })
          }
        }
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'projectFile',
        objectId: id,
        fallbackCode: 'projectFile.delete',
        action: `${moduleName}/${DELETE_PROJECT_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },
  /**
   * @name PATCH_PROJECT_FILE
   *  Patch a file by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number|String} object.id Numeric id or public id of the file
   */
  async [PATCH_PROJECT_FILE]({ dispatch, getters }, { projectId, id }) {
    try {
      await patchProjectFile(id)
      await dispatch(LOAD_PROJECT_FILE, { id })
      const project = getters.byId(projectId)
      if (project) {
        const projectFile = project.files.find(file => file.id === id)
        const { FileId: fileId } = projectFile
        await this.dispatch(global.PATCH_FILE, { id: fileId })
      }

      return id
    } catch (err) {
      this.handleError(err, {
        object: 'file',
        objectId: id,
        fallbackCode: 'file.patch',
        action: `${moduleName}/${PATCH_PROJECT_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    }
  }
}

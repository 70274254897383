import axios from 'axios'

// This file is a helper to decide when ClingAPI requests are allowed to be cached,
// and if a socket to ClingAPI is established to allow caching, and invalidating caches on events

const cache = new Map() // Cache of type url -> axios config
export const clearCache = () => cache.clear()

let useCache = false // Boolean if the cache is allowed to be used
export const setUseCache = val => (useCache = val)

// Helper to invalidate cache keys
// The key is the event type and each key has an array of urls that is invalidated.
// <id> is a placeholder that is replaced with the event typeId during invalidation
const events = {
  document: [
    'document', // invalidate all documents if any document is changed
    'document/<id>' // invalidate the specific document url
  ],
  project: ['project', 'project/<id>', 'project/counts'],
  companyUser: ['companyUser', 'companyUser/<id>'],
  templateMessage: ['templateMessage', 'templateMessage/<id>'],
  unitType: ['unitType', 'unitType/<id>']
}

export function invalidateCacheItem({ type, id }) {
  const regExps = (events[type] || []).map(x => {
    const str = x.replace('<id>', id)
    return new RegExp(str)
  })

  const toDelete = []
  cache.forEach((value, key) => {
    const shouldDelete = regExps.some(y => y.test(key))
    if (shouldDelete) toDelete.push(key)
  })

  toDelete.forEach(key => cache.delete(key))
}

/**
 * Save data to cache
 * @param {String} method
 * @param {String} url
 * @param {Object} config Axios config
 * @param {Number} ttl Optional time to live in sec, defaults to infinity.
 */
function setData(method, url, config, ttl = null) {
  if (typeof method === 'undefined')
    throw new Error('Missing valid parameter method')
  if (typeof url === 'undefined') throw new Error('Missing valid parameter url')
  if (typeof config === 'undefined')
    throw new Error('Missing valid parameter config')

  if (typeof method !== 'string')
    throw new Error('Parameter method must be a string')
  if (typeof url !== 'string') throw new Error('Parameter url must be a string')
  if (typeof config !== 'object')
    throw new Error('Parameter config must be a object')

  if (method !== 'get') throw new Error('Only allowed to cache get method')

  let expiresAt
  if (ttl && ttl > 0) {
    expiresAt = new Date(new Date().getTime() + ttl * 1000).getTime()
  }

  cache.set(url, {
    cachedAt: Date.now(),
    ...(expiresAt && { expiresAt }),
    data: {
      ...config,
      request: {}
    }
  })
}

/**
 * Read data from cache
 * @param {String} url
 * @returns {Object|null} Returns axios config if valid match or null
 */
function getData(url) {
  if (typeof url === 'undefined') throw new Error('Missing valid parameter url')
  if (typeof url !== 'string') throw new Error('Parameter url must be a string')

  const { data, cachedAt, expiresAt } = cache.get(url) || {}

  // Max cache ttl is 30 mins
  if (Date.now() > cachedAt + 60000 * 30) {
    cache.delete(url)
    return null
  }

  // Was expiresAt set and is passed?
  if (expiresAt && Date.now() > expiresAt) {
    cache.delete(url)
    return null
  }

  return data
}

export const cacheResolver = async config => {
  const { url, params = {} } = config
  const queryString = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')

  // Create a full url with any params so we cache a complete url
  const fullUrl = `${url}${queryString ? `?${queryString}` : ''}`
  const cachedResponse = getData(fullUrl)

  if (
    useCache &&
    cachedResponse &&
    config.readCache !== false &&
    config.method === 'get'
  ) {
    // console.log(`#### USE CACHE for url: ${fullUrl}`);
    return cachedResponse
  }

  const response = await axios({
    ...config,
    adapter: axios.defaults.adapter // do the request normally
  })

  if (useCache && config.saveCache) {
    // console.log(`#### SAVE CACHE for url: ${fullUrl}`);
    setData(config.method, fullUrl, response, config.cacheTtl || null)
  }

  // Invalidate cache
  if (useCache && config.invalidateCache && config.invalidateCache.type) {
    invalidateCacheItem({
      type: config.invalidateCache.type,
      id: config.invalidateCache.id || null
    })
  }

  return response
}

import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import { getField } from 'vuex-map-fields'

import projectNoteGetters from './projectNotes/getters'
import Project from './schema'

export default {
  getField,
  ...projectNoteGetters,
  byId: (state, ownGetters, rootState, getters) => id => {
    if (!state.data[id]) {
      return null
    }
    return new Project(state.data[id], getters)
  },
  projectIdsByClientId: state => clientId =>
    state.ids.filter(id => {
      const clientEndCustomerIds = get(
        state.data[id],
        'clientEndCustomerIds',
        ''
      )
      if (!clientEndCustomerIds) return false
      return clientEndCustomerIds.split(',').includes(`${clientId}`) // Check for exact match (string) instead of only if string contains (id 12223 contains id 222 etc)
    }),
  projectIdsOwnedByUserId: (state, ownGetters) => memberId =>
    state.ids.filter(id => {
      const project = ownGetters.byId(id)
      if (!project) return false
      const member = project.getProperty('CompanyUserId')
      if (!member) return false
      if (member === memberId) return true
      return false
    }),
  projects: (state, ownGetters, rootState, getters) =>
    state.ids.map(projectId => new Project(state.data[projectId], getters)),
  projectByPublicId: (state, ownGetters, rootState, getters) => publicId => {
    const projectId = state.projectPublicIdToId[publicId]
    if (!projectId) {
      return null
    }
    return new Project(state.data[projectId], getters)
  },
  projectsByStatus:
    (state, { projects }) =>
    status =>
      projects.filter(x => x.status === status),
  filter: (state, ownGetters, rootState, getters) => {
    const settingValue =
      getters['settings/getCompanyUserSetting']('projectListFilter')
    let result = {}
    if (settingValue) {
      result = JSON.parse(settingValue)
      // adhoc adapt old settings which could cause issues to new format
      // will be stored on the correct format if the user interacts with the filter
      if (result?.or?.docStatuses) {
        result.or = {
          and: {
            or: {
              docStatuses: result.or.docStatuses
            },
            status: {
              offer: true
            }
          },
          ...(result.or.status && { status: result.or.status })
        }
      }
    } else
      result = {
        or: {
          and: {
            or: {
              docStatuses: {
                draft: 'contains',
                sent: 'contains',
                accepted: 'contains',
                denied: 'contains',
                expired: 'contains',
                voided: 'contains'
              }
            },
            status: {
              offer: true
            }
          },
          status: {
            active: true,
            completed: true
          }
        },
        status: {
          archived: false
        }
      }

    // If 'sent' is included we always also include voided (as voided does not have a dedicated badge)
    if (get(result, 'or.and.or.docStatuses.sent'))
      set(result, 'or.and.or.docStatuses.voided', 'contains')
    else unset(result, 'or.and.or.docStatuses.voided')

    // virtually ADD and REMOVE main status 'offer' restriction to docStatuses condition
    if (Object.keys(get(result, 'or.and.or.docStatuses', {})).length)
      set(result, 'or.and.status.offer', true)
    else unset(result, 'or.and.status.offer')

    return result
  },
  sort: (state, ownGetters, rootState, getters) => {
    const settingValue =
      getters['settings/getCompanyUserSetting']('projectListSort')
    if (settingValue) return JSON.parse(settingValue)
    return {
      sort: 'updatedAt',
      order: 'DESC'
    }
  },
  isPosting: state => state.isPosting,
  isFetching: state => state.isFetching,
  canFetchMore: state => state.canFetchMore,
  count: state => state.count
}

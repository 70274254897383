type Colors = {
  color: string
  percent: number
}

type GradientOptions = {
  type?: 'linear' | 'radial'
  degree?: number
  colors?: Array<Colors>
}

export const generateGradientStyle = ({
  type = 'linear',
  degree = 0,
  colors = []
}: GradientOptions) => {
  let style = ''
  if (!colors || colors.length < 2) return style
  style =
    type === 'linear' ? `linear-gradient(${degree}deg,` : 'radial-gradient('

  colors.forEach(({ color, percent }, index) => {
    style += `${color} ${percent}%`
    if (index !== colors.length - 1) style += ','
  })

  style += ')'

  return style
}

type HSLA = {
  h: number
  s: number
  l: number
  a: number
}

export const hslObjectToString = (obj: HSLA) => {
  if (!obj) return ''

  const h = Math.round(obj.h * 100) / 100 // Two decimals
  const s = Math.round(obj.s * 100 * 100) / 100
  const l = Math.round(obj.l * 100 * 100) / 100
  const a = Math.round(obj.a * 100 * 100) / 100 / 100

  return `hsla(${h},${s}%,${l}%,${a})`
}

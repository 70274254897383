export default {
  loginUser: {
    wrongCredentials: 'Fel användarnamn eller lösenord'
  },
  propertyDesignationRequest: {
    successMessage: 'Begäran om fastighetsbeteckning skickad!',
    smsTemplate: {
      text: 'Hej{{clientFirstName}}!\nFör att underlätta din ansökan om avdrag så behöver {{company.name}} din fastighetsbeteckning. Klicka här:\n\n{{docLink}}/fastighetsbeteckning'
    },
    emailTemplate: {
      subject: 'Vi behöver din fastighetsbeteckning - {{document.data.name}}',
      title: 'Hej{{clientFirstName}}!',
      content:
        'För att underlätta din ansökan om avdrag så behöver {{company.name}} din fastighetsbeteckning. Klicka på knappen för att ange din fastighetsbeteckning.',
      text: 'Hej{{clientFirstName}}!\nFör att underlätta din ansökan om avdrag så behöver {{company.name}} din fastighetsbeteckning. Klicka på länken för att ange din fastighetsbeteckning.\n\n{{docLink}}/fastighetsbeteckning',
      actionUrl: '{{docLink}}/fastighetsbeteckning'
    }
  },
  updateDocumentDialog: {
    title: 'Byt status och ta bort svar',
    message: 'Dina kunders svar kommer att tas bort.',
    cancel: 'Avbryt',
    submit: 'Ja, fortsätt'
  },
  editDocumentDialog: {
    accepted: {
      title: 'Redigera signerat dokument',
      message:
        'Dokumentet är signerat och låst för ändringar. Om du vill göra ändringar så kan du markera dokumentet som nekat eller skapa en kopia som är går att redigera.',
      submit: 'Skapa kopia'
    },
    hasAnswers: {
      title: 'Redigera delsignerat dokument',
      message:
        'Dokumentet är delvis besvarat och är låst för ändringar. Dina kunders svar kommer att tas bort.',
      submit: 'Ja, fortsätt'
    }
  }
}

import { createArticle } from '@cling/utils'

import { getField } from 'vuex-map-fields'

export default {
  getField,
  articles: state => state.data,
  articleIdList: state => state.ids,
  articleById: state => id => state.data[id],
  isFetching: state => state.isFetching,
  isPosting: state => state.isPosting,
  getNewArticle: (state, getters, rootState, rootGetters) => opts =>
    createArticle({
      vat: rootGetters['settings/defaultVatRate'],
      showPrice: !rootGetters['settings/hidePriceOnNewlyAddedArticles'],
      currency: rootGetters['settings/defaultCurrency'],
      ...opts
    })
}

import config from '@cling/config'
import { brands } from '@cling/static'

// Default validation schemas
export const reminders = {
  type: 'array',
  default: [
    // To schedule a future delivery
    // {
    //   type: 'first',
    //   status: 'new',
    //   sendDays: 0,
    //   sendAt: '2019-10-08T15:00:00.000Z',
    //   deliveryTypes: ['sms'],
    //   tries: 0,
    // },
    {
      type: 'reminder',
      status: 'new',
      sendDays: 2,
      deliveryTypes: ['sms', 'email'],
      views: {
        sms: {
          nodes: [
            {
              nodeId: 1,
              parentId: null,
              itemId: 'smsText',
              itemType: 'smsText',
              value: {
                text: 'data.smsTemplate.reminder.text'
              }
            }
          ]
        },
        email: {
          nodes: [
            {
              nodeId: 1,
              parentId: null,
              itemId: 'emailTemplate',
              itemType: 'emailTemplate',
              value: {
                subject: 'data.emailTemplate.reminder.subject',
                text: 'data.emailTemplate.reminder.text',
                title: 'data.emailTemplate.reminder.title',
                content: 'data.emailTemplate.reminder.content'
              }
            }
          ]
        }
      },
      tries: 0,
      sendDaysOptions: {
        isAfter: true
      }
    },
    {
      type: 'viewed',
      status: 'new',
      sendDays: 5,
      deliveryTypes: ['sms', 'email'],
      views: {
        sms: {
          nodes: [
            {
              nodeId: 1,
              parentId: null,
              itemId: 'smsText',
              itemType: 'smsText',
              value: {
                text: 'data.smsTemplate.viewed.text'
              }
            }
          ]
        },
        email: {
          nodes: [
            {
              nodeId: 1,
              parentId: null,
              itemId: 'emailTemplate',
              itemType: 'emailTemplate',
              value: {
                subject: 'data.emailTemplate.viewed.subject',
                text: 'data.emailTemplate.viewed.text',
                title: 'data.emailTemplate.viewed.title',
                content: 'data.emailTemplate.viewed.content'
              }
            }
          ]
        }
      },
      tries: 0,
      sendDaysOptions: {
        isAfter: true
      }
    },
    {
      type: 'expires',
      status: 'new',
      sendDays: 2,
      deliveryTypes: ['sms', 'email'],
      views: {
        sms: {
          nodes: [
            {
              nodeId: 1,
              parentId: null,
              itemId: 'smsText',
              itemType: 'smsText',
              value: {
                text: 'data.smsTemplate.expires.text'
              }
            }
          ]
        },
        email: {
          nodes: [
            {
              nodeId: 1,
              parentId: null,
              itemId: 'emailTemplate',
              itemType: 'emailTemplate',
              value: {
                subject: 'data.emailTemplate.expires.subject',
                text: 'data.emailTemplate.expires.text',
                title: 'data.emailTemplate.expires.title',
                content: 'data.emailTemplate.expires.content'
              }
            }
          ]
        }
      },
      tries: 0,
      sendDaysOptions: {
        isAfter: false,
        calculateFrom: 'data.expiresAt'
      }
    }
  ],
  items: {
    $ref: `${config.api.baseUrl}/schemas/reminder/1.json`
  }
}

export const smsTemplate = {
  type: 'object',
  required: ['first'],
  properties: {
    first: {
      type: 'object',
      required: ['text'],
      properties: {
        text: {
          type: 'string',
          default:
            'Hej{{clientFirstName}}!\n{{company.name}} har skickat ett dokument till dig som du kan lämna svar på. Läs mer och lämna svar på:\n\n{{docLink}}'
        }
      }
    },
    reminder: {
      type: 'object',
      required: ['text'],
      properties: {
        text: {
          type: 'string',
          default:
            'Påminnelse!\nDu har ett dokument från {{company.name}} som behöver ditt svar. Läs mer och lämna svar på:\n\n{{docLink}}'
        }
      }
    },
    expires: {
      type: 'object',
      required: ['text'],
      properties: {
        text: {
          type: 'string',
          default:
            'Påminnelse!\nDu har ett dokument från {{company.name}} där giltighetstiden snart går ut. Lämna ett svar innan det blir för sent på:\n\n{{docLink}}'
        }
      }
    },
    viewed: {
      type: 'object',
      required: ['text'],
      properties: {
        text: {
          type: 'string',
          default:
            'Påminnelse!\nGlöm inte bort att läsa dokumentet från {{company.name}}. Läs mer och lämna ett svar på:\n\n{{docLink}}'
        }
      }
    }
  }
}

export const emailTemplate = {
  type: 'object',
  required: ['first'],
  properties: {
    first: {
      type: 'object',
      required: ['subject', 'title', 'content'],
      properties: {
        subject: {
          type: 'string',
          default: 'Dokument från {{company.name}} - {{document.data.name}}'
        },
        title: { type: 'string', default: 'Hej{{clientFirstName}}!' },
        content: {
          type: 'string',
          default:
            'Du har fått ett dokument från {{company.name}}. Klicka på knappen för att läsa mer och lämna ett svar.'
        },
        text: {
          type: 'string',
          default:
            'Hej{{clientFirstName}}! {{company.name}} har skickat ett dokument till dig som du kan lämna svar på. Läs mer och lämna svar på:\n\n{{docLink}}'
        }
      }
    },
    viewed: {
      type: 'object',
      required: ['subject', 'title', 'content'],
      properties: {
        subject: {
          type: 'string',
          default: 'Glöm inte bort att läsa dokumentet: {{document.data.name}}'
        },
        title: { type: 'string', default: 'Hej{{clientFirstName}}!' },
        content: {
          type: 'string',
          default:
            'Glöm inte bort att läsa dokumentet från {{company.name}}. Klicka på knappen för att läsa mer och lämna ett svar.'
        },
        text: {
          type: 'string',
          default:
            'Hej{{clientFirstName}}! Du har ett dokument från {{company.name}} som du ännu inte tittat på. Läs mer och lämna svar på:\n\n{{docLink}}'
        }
      }
    },
    reminder: {
      type: 'object',
      required: ['subject', 'title', 'content'],
      properties: {
        subject: {
          type: 'string',
          default:
            'Påminnelse! Lämna svar på dokumentet - {{document.data.name}}'
        },
        title: { type: 'string', default: 'Hej{{clientFirstName}}!' },
        content: {
          type: 'string',
          default:
            'Du har tidigare fått ett dokument från {{company.name}} som saknar ditt svar. Klicka på knappen för att läsa mer och lämna ett svar.'
        },
        text: {
          type: 'string',
          default:
            'Hej{{clientFirstName}}! Du har ett dokument från {{company.name}} som behöver ditt svar. Läs mer och lämna svar på:\n\n{{docLink}}'
        }
      }
    },
    expires: {
      type: 'object',
      required: ['subject', 'title', 'content'],
      properties: {
        subject: {
          type: 'string',
          default:
            'Påminnelse! Dokumentet - {{document.data.name}} - behöver ditt svar'
        },
        title: { type: 'string', default: 'Hej{{clientFirstName}}!' },
        content: {
          type: 'string',
          default:
            'Dokumentet från {{company.name}} löper snart ut, lämna ett svar innan det är för sent. Klicka på knappen för att läsa mer och lämna ett svar.'
        },
        text: {
          type: 'string',
          default:
            'Hej{{clientFirstName}}! Du har ett dokument från {{company.name}} där giltighetstiden snart går ut. Lämna ett svar innan det blir för sent på:\n\n{{docLink}}'
        }
      }
    }
  }
}

// Default views
export const emailView = {
  isEditable: false,
  nodes: [
    {
      itemId: 'emailTemplate',
      itemType: 'emailTemplate',
      value: {
        subject: 'data.emailTemplate.first.subject',
        text: 'data.emailTemplate.first.text',
        title: 'data.emailTemplate.first.title',
        content: 'data.emailTemplate.first.content'
      }
    }
  ]
}

export const smsView = {
  isEditable: false,
  nodes: [
    {
      itemId: 'smsText',
      itemType: 'smsText',
      value: {
        text: 'data.smsTemplate.first.text'
      }
    }
  ]
}

const isRegionSE = brands[config.brand].defaultRegion === 'SE'

export const formSettings = {
  type: 'object',
  default: {
    useVat: isRegionSE,
    vatType: 'vat',
    rounding: {
      enabled: isRegionSE,
      interval: isRegionSE ? 100 : 1,
      show: isRegionSE
    }
  }
}

export const css = {
  $ref: `${config.api.baseUrl}/schemas/css/1.json`,
  default: {
    '[data-typography="title"]': {
      fontFamily: 'Inter',
      fontSize: '56px',
      fontWeight: 800
    },
    h1: {
      fontFamily: 'Inter',
      fontSize: '48px',
      fontWeight: 800
    },
    h2: {
      fontFamily: 'Inter',
      fontSize: '36px',
      fontWeight: 700
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: '21px',
      fontWeight: 600
    },
    blockquote: {
      fontFamily: 'Inter',
      fontSize: '18px',
      borderLeftStyle: 'solid',
      borderLeftWidth: '3px',
      borderColor: 'currentColor'
    },
    p: {
      fontFamily: 'Inter',
      fontSize: '16px'
    }
  }
}

export const sender = { type: 'object', default: {} }
export const name = {
  anyOf: [{ type: 'string' }, { type: 'null' }],
  default: ''
}
export const senderString = { anyOf: [{ type: 'string' }, { type: 'null' }] }
export const receiverString = { anyOf: [{ type: 'string' }, { type: 'null' }] }
export const prices = { $ref: `${config.api.baseUrl}/schemas/price/1.json` }
export const expiresAt = { type: 'string', format: 'date-time' }
export const fields = { type: 'object' }

export default {
  reminders,
  smsTemplate,
  emailTemplate,
  emailView,
  smsView,
  formSettings,
  sender,
  name,
  senderString,
  receiverString,
  prices,
  css,
  expiresAt,
  fields
}

import { getUniqueArrayElements } from '@cling/utils'
import { setCookie, getCookie } from '@cling/utils/cookies'

const disabledThirdPartiesCookieName = 'disabledThirdParties'
const validThirdPartiesCookieValues = [
  'google',
  'facebook',
  'segment',
  'logrocket',
  'rollbar'
] // Valid values to use in the cookies

/**
 * Get which third parties that is disabled
 * @returns {String[]} Always return array of strings
 */
export function getDisabledThirdParties() {
  let disabledThirdParties = []
  try {
    const disabledServicesArray = JSON.parse(
      getCookie(disabledThirdPartiesCookieName)
    )
    if (Array.isArray(disabledServicesArray))
      disabledThirdParties = disabledServicesArray
  } catch (err) {
    // Nothing
  }
  return disabledThirdParties.filter(s =>
    validThirdPartiesCookieValues.includes(s)
  )
}

/**
 * Set which third parties that should be disabled, will replace any existing value
 * @param {String[]} services Array of strings
 */
export function setDisabledThirdParties(services = []) {
  if (!Array.isArray(services))
    throw new Error('Param services must be an array')
  setCookie(
    disabledThirdPartiesCookieName,
    JSON.stringify(
      services.filter(s => validThirdPartiesCookieValues.includes(s))
    )
  )
}

/**
 * Add disabled third parties to any existing ones
 * @param {String[]|String} services Array of strings to add as disabled or a single string
 */
export function addDisableThirdParties(services = []) {
  if (!Array.isArray(services) && typeof services !== 'string')
    throw new Error('Param services must be an array or string')
  const servicesArray = typeof services === 'string' ? [services] : services
  if (!servicesArray.length) return

  const invalidServiceName = servicesArray.find(
    s => !validThirdPartiesCookieValues.includes(s)
  )
  if (invalidServiceName)
    throw new Error(`Service '${invalidServiceName}' is not a valid service`)
  setDisabledThirdParties(
    getUniqueArrayElements([...servicesArray, ...getDisabledThirdParties()])
  )
}

export const actionTypes = {
  LOAD_ENDCUSTOMERS: 'LOAD_ENDCUSTOMERS',
  LOAD_ENDCUSTOMER: 'LOAD_ENDCUSTOMER',
  UPDATE_ENDCUSTOMER: 'UPDATE_ENDCUSTOMER',
  CREATE_ENDCUSTOMER: 'CREATE_ENDCUSTOMER',
  DELETE_ENDCUSTOMER: 'DELETE_ENDCUSTOMER',
  PATCH_ENDCUSTOMER: 'PATCH_ENDCUSTOMER',
  FORM_NEW_ENDCUSTOMER: 'FORM_NEW_ENDCUSTOMER',
  FORM_EDIT_ENDCUSTOMER: 'FORM_EDIT_ENDCUSTOMER',
  FORM_SUBMIT_ENDCUSTOMER: 'FORM_SUBMIT_ENDCUSTOMER'
}

export const mutationTypes = {
  CLEAR_END_CUSTOMER: 'CLEAR_END_CUSTOMER',
  SET_ENDCUSTOMERS: 'SET_ENDCUSTOMERS',
  DELETE_ENDCUSTOMER: 'DELETE_ENDCUSTOMER',
  SET_ENDCUSTOMER_FETCHING: 'SET_ENDCUSTOMER_FETCHING',
  SET_ENDCUSTOMER_POSTING: 'SET_ENDCUSTOMER_POSTING'
}

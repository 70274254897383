import config from '@cling/config'
import lang from '@cling/language'
import logger from '@cling/services/logger'
import { getPermissionTitle } from '@cling/utils'

import get from 'lodash/get'

const emailSupport = message => {
  let href = `mailto:info@${lang.t(`_common:brand.domain`)}?subject=${lang.t(
    `_common:brand.name`
  )} support`
  if (message) href += `&body=${encodeURIComponent(message)}`

  const a = document.createElement('a')
  a.href = href
  a.target = '_blank'
  a.click()

  if (typeof a.remove === 'function') a.remove()
}

class FreshChat {
  _initiated = false
  _installed = false // freshChat script has been loaded
  _token = null // freshChat token
  _host = null // freshChat host
  _widgetUuid = null // freshChat widgetUuid
  _widgetUrl = null // freshChat widgetUrl
  _tags = null // freshChat tags
  _store = null // reference to store
  _isHidden = true // if freshChat icon is visible

  // Local variable to keep track if any data has changed when calling sync
  _lastSyncData = null

  /**
   * Initialize FreshChat instance with params and store
   * @param {Object} object
   * @param {String} object.token FreshChat token
   * @param {String} object.host FreshChat host
   * @param {String} object.widgetUuid FreshChat widgetUuid
   * @param {String} object.widgetUrl FreshChat widgetUrl to load script from
   * @param {String} object.tags FreshChat tags
   * @param {Object} object.store Vuex store
   */
  init({ token, host, widgetUuid, widgetUrl, tags, store } = {}) {
    this._store = store
    this._token = token
    this._host = host
    this._widgetUuid = widgetUuid
    this._widgetUrl = widgetUrl
    this._tags = tags
  }

  getIsReady() {
    return typeof window.fcWidget === 'object'
  }

  /**
   * Install FreshChat script
   * Will inject FreshChat js into document
   */
  install(callback) {
    logger.debug('FreshChat: Install calling')
    if (!this._installed) {
      if (typeof window.fcWidget === 'object') {
        // Nothing
      } else {
        this._loadScript(() => {
          this.syncData()
          if (callback) callback()
        })
      }
      this._installed = true
    }
  }

  /**
   * Method to sync current user to freshChat
   *
   * @returns {Promise}
   */
  async syncData() {
    // Check if is superuser
    const { getters } = this._store || {}
    if (!getters) {
      throw new Error('Could not access store getters')
    }
    const isSuperUser = getters['application/isSuperUser']()
    if (isSuperUser) {
      logger.debug('FreshChat: Is superuser, dont sync.')
      return
    }

    if (!this._token) {
      return
    }

    const companyUserData = await this._getCompanyUserData()
    const companyData = this._getCompanyData()

    const data = {
      ...companyData,
      ...companyUserData
    }

    // If data is the same as last sync, dont call sync
    const newSyncData = JSON.stringify(data)
    if (newSyncData === this._lastSyncData) {
      return
    }

    const setPropFunc = get(window, 'fcWidget.user.setProperties')
    if (setPropFunc) {
      const { success } = await setPropFunc(data)
      if (success) this._lastSyncData = newSyncData
    }
  }

  /**
   * Method to hide FreshChat icon and chat
   */
  hideIcon() {
    this._isHidden = true
    this.hide()
  }

  show() {
    if (this._installed) {
      window.fcWidget?.open()
    } else emailSupport()
  }

  hide() {
    window.fcWidget?.close()
  }

  /**
   * Get authenticated companyuser data in a format expected by FreshChat
   * @returns {Promise<Object>} Resolves with user object
   */
  async _getCompanyUserData() {
    const { getters } = this._store || {}
    if (!getters) {
      throw new Error('Could not access store getters')
    }
    const companyUser = getters['application/user']
    if (!companyUser) {
      return null
    }
    const role = getPermissionTitle(companyUser.CompanyUserPermission)

    const result = {
      externalId: `cling-companyUser-${companyUser.id}`, // Maybe? How to define externalId when we have all envs in the same "app"?
      ...(companyUser.email && { email: companyUser.email }),
      ...(companyUser.firstname && { firstName: companyUser.firstname }),
      ...(companyUser.lastname && { lastName: companyUser.lastname }),
      ...(companyUser.cellphone && {
        phone: companyUser.cellphone
      }),
      role,
      companyUserId: companyUser.id // Maybe?
    }

    return result
  }

  /**
   * Get authenticated company data in a format expected by FreshChat
   * @returns {Object} Company object
   */
  _getCompanyData() {
    const { getters } = this._store || {}
    if (!getters) {
      throw new Error('Could not access store getters')
    }
    const company = getters['application/company']
    if (!company) {
      return null
    }

    const result = {
      companyName: company.name,
      companyId: company.id,
      ...(config.environment === 'production' && {
        adminApp: `https://cling-admin.fnox.se/admin/companies/${company.id}/settings`
      })
    }
    return result
  }

  /**
   * Loads FreshChat js into document
   * @param {Function} callback Optional callback when script was loaded
   */
  _loadScript(callback) {
    if (!this._token)
      throw new Error('Cannot load FreshChat as token is undefined')
    if (!this._host)
      throw new Error('Cannot load FreshChat as host is undefined')
    if (!this._widgetUuid)
      throw new Error('Cannot load FreshChat as widgetUuid is undefined')
    if (!this._widgetUrl)
      throw new Error('Cannot load FreshChat as widgetUrl is undefined')
    if (!this._tags)
      throw new Error('Cannot load FreshChat as tags is undefined')

    const d = document
    const s = d.createElement('script')
    s.src = this._widgetUrl
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
    s.onload = () => {
      window.fcWidget.init({
        token: this._token,
        host: this._host,
        widgetUuid: this._widgetUuid,
        tags: this._tags,
        config: {
          headerProperty: {
            hideChatButton: true
          }
        }
      })
      if (callback) callback()
    }
  }

  showNewMessage(message) {
    window.fcWidget.open({ replyText: message })
    this.show()
  }
}

const Instance = new FreshChat()

// Export method so other can call on syncUserData
export const { syncData } = Instance
export default Instance

/* eslint-disable no-undef */
import {
  documentTemplate,
  extensionApi,
  publicDocument,
  templateGallery
} from '@cling/api'
import config from '@cling/config'
import lang from '@cling/language'
import CompanyDocument from '@cling/models/document/companyDocument'
import docLang from '@cling/models/document/i18n'
import {
  convertToNewClient,
  getDefaultClientObject
} from '@cling/models/document/migrate'
import { setupFormSettings } from '@cling/models/document/utils'

// Temporary
import staticTemplates from '@cling/models/mockdata'
import Template from '@cling/models/template'
import Feature from '@cling/services/features'
import logger from '@cling/services/logger'
import { brands } from '@cling/static'
import { global } from '@cling/store/action-types'
import mutationTypes from '@cling/store/mutation-types'
import { addUniqueId } from '@cling/utils'

import get from 'lodash/get'
import uniqid from 'uniqid'

import { actionTypes as newActionTypes } from './constants'

const {
  FORM_CLEAR_FORM,
  CLEAR_DOCUMENT2_ANSWERS,
  DO_SEND_DOCUMENT2,
  SHOW_MESSAGE,
  LOAD_ENDCUSTOMER,
  UPDATE_TEMPLATE,
  DO_CREATE_TEMPLATE
} = global
const {
  LOAD_DOCUMENT2_FORM,
  LOAD_DOCUMENT2_PUBLIC_FORM,
  LOAD_DUPLICATE_DOCUMENT2_FORM,
  SAVE_DYNDOC_FORM,
  CLEAR_DYNDOC_FORM,
  DO_SAVE_DYNDOC,
  HANDLE_DOCUMENT2_EDIT,
  SAVE_TEMPLATE_FORM,
  LOAD_EXTERNAL_FORM_FIELDS
} = newActionTypes
const { SET_FORM, SET_FORM_VALUE } = mutationTypes.forms

/**
 * Helper to decide if the current data in the store can be used or if a new doc should be initiated
 * Used to support Vuex persist if the users reloads the page or similar.
 *
 * @param {Object} obj
 * @param {Number} obj.id Optional id
 * @param {Number} obj.projectId Optional projectId
 * @param {Object} obj.rootState Vuex rootState
 * @param {Object} obj.state Vuex state
 * @param {Object} obj.rootGetters Vuex rootGetters
 * @param {Function} obj.dispatch Vuex dispatch
 *
 * @returns {Promise<Boolean>} Resolves true if the current data in store should be used, otherwise false or throws error
 */
async function shouldUseExistingStore({
  id = null,
  templateId = null,
  rootState,
  state,
  rootGetters,
  dispatch
}) {
  if (!rootState) throw new Error('Missing parameter rootState')
  if (!state) throw new Error('Missing parameter state')
  if (!rootGetters) throw new Error('Missing parameter rootGetters')
  if (!dispatch) throw new Error('Missing parameter dispatch')
  let useExistingStore = false

  // Check if we should use data in store, or really load new doc form
  const existingDocument = rootGetters['forms/document']
  if (!existingDocument) return false

  // Read data from route
  const {
    name: routeName,
    params: { id: routeId }
  } = rootState.route || {}

  const {
    id: existingId,
    template: { id: existingTemplateId }
  } = existingDocument

  if (['documentForm'].includes(routeName)) {
    // Edit
    if (id) {
      if (existingId && existingId === routeId && existingId === id) {
        // As data in store have id and user is editing, dont reload
        logger.debug(
          'DYNDOC_FORM: Using existing store data existing id match route'
        )
        useExistingStore = true
      }

      // New
    } else if (
      !existingId &&
      !id &&
      ((templateId === 'default' && !existingTemplateId) ||
        templateId === existingTemplateId)
    ) {
      logger.debug('DYNDOC_FORM: Using existing store data as no ids was found')
      useExistingStore = true
    }
  }
  if (useExistingStore)
    logger.debug(
      'DYNDOC_FORM: Dont reload as forms/document2 is already defined'
    )

  return useExistingStore
}

export default {
  /**
   * @name LOAD_DOCUMENT2_FORM
   * Action to prepare the form/document2 with all data needed to create/edit a document
   * @param {Object} Vuex object
   * @param {Object} object Document parameters (optional)
   * @param {Number} object.docId Numeric document id (optional)
   */
  async [LOAD_DOCUMENT2_FORM](
    { commit, rootState, rootGetters, state, dispatch },
    {
      documentId = null,
      templateId = null,
      galleryId = null,
      addonDocumentSourceId = null,
      projectId = null,
      clientId = null,
      formData = null,
      _formData: _formDataParam = null,
      dummyClient = false
    } = {}
  ) {
    const useExistingStore = await shouldUseExistingStore({
      id: documentId,
      templateId,
      rootState,
      state,
      rootGetters,
      dispatch
    })
    if (useExistingStore && !addonDocumentSourceId) return

    let _formData = {
      id: null,
      __tempId: uniqid(),
      companyUserId: this.getters['application/user'].id,
      language:
        this.getters['settings/getCompanyUserSetting']('defaultLanguage') ||
        'en',
      currency:
        this.getters['settings/getCompanyUserSetting']('defaultCurrency'),
      data: {},
      template: null,
      articles: [],
      clients: [],
      ...(_formDataParam && { ..._formDataParam }),
      ...formData
    }

    if (!formData) {
      if (documentId) {
        await this.dispatch(global.DO_LOAD_DOCUMENT2, { id: documentId })
        const document = this.getters['documents2/byId'](documentId)
        _formData = {
          ..._formData,
          ...document.getRawData()
        }
      } else if (templateId) {
        let template = ['default', 'empty'].includes(templateId)
          ? new Template(staticTemplates[templateId](_formData.language))
          : this.getters['templates/byId'](templateId)

        // We want to keep special alias templates outside regular actions to avoid further id logic
        if (
          [
            'offertaSuggestionTemplate',
            'offertaPdfTemplate',
            'onboardingTemplate'
          ].includes(templateId)
        ) {
          const { data } = await documentTemplate.get(templateId)
          template = new Template(data)
        }

        // Get template from store
        if (!template) {
          await this.dispatch(global.LOAD_TEMPLATE, { id: templateId })
          template = this.getters['templates/byId'](templateId)
        }

        if (!template)
          throw new Error(`Could not find template by id: '${templateId}'`)
        _formData.template = {
          ...template._data,
          defaultData: template.defaultData
        }
      } else if (galleryId) {
        const { data } = await templateGallery.get({
          _id: galleryId,
          populate: 'templateId',
          isVisibleApp: true,
          includePurchaseInfo: true
        })

        const tplData = data?.[0]?.templateId
        if (!tplData)
          throw new Error(
            `Could not find template by gallery id: '${galleryId}'`
          )

        const template = new Template(tplData)

        _formData.template = {
          ...template._data,
          defaultData: template.defaultData
        }
      }

      const defaultData = get(_formData, 'template.defaultData', {})

      _formData = {
        ..._formData,
        ...defaultData,
        // Merge input and default clients
        clients: [...(defaultData?.clients || []), ..._formData.clients]
      }

      // Before applying the user accounts default settings we need to make sure the store has it
      if (!Object.keys(rootState.settings.company).length) {
        await this.dispatch(global.LOAD_SETTINGS_COMPANY)
      }

      if (addonDocumentSourceId) {
        await this.dispatch(global.DO_LOAD_DOCUMENT2, {
          id: addonDocumentSourceId
        })
        const sourceDoc = this.getters['documents2/byId'](addonDocumentSourceId)
        const { publicId, clients } = sourceDoc.duplicate()
        _formData = {
          ..._formData,
          projectId: sourceDoc.projectId,
          clients,
          data: {
            ..._formData.data,
            sourceDocument: { id: addonDocumentSourceId, publicId }
          }
        }
      }
    }
    // Only add projectId key to _formData if arg exists!
    if (projectId) _formData.projectId = projectId

    // Add client if creating doc from client page
    if (clientId) {
      await this.dispatch(LOAD_ENDCUSTOMER, { id: clientId })
      const oldClient = rootGetters['endCustomers/endCustomerById'](clientId)
      const answerMethod = get(
        _formData,
        'template.defaultData.data.defaultAnswerMethod'
      )
      const client = convertToNewClient(oldClient, {
        ...(answerMethod && { answerMethod })
      })
      _formData.clients = [..._formData.clients, client]
    }

    if (dummyClient) {
      const deliveryTypes = ['email']
      if (Feature.checkFeature('sms')) deliveryTypes.push('sms')
      _formData.clients = [
        ..._formData.clients,
        {
          name: 'Pernilla P.',
          email: 'pernilla@mail.se',
          cellphone: '073 123 56 78',
          documentRole: 'signee',
          type: 'individual',
          deliveryTypes
        }
      ]
    }

    if (templateId === 'onboardingTemplate') {
      // Initiate the current user as client/recipient during onboarding
      const user = rootGetters['application/user'] || {}
      const company = rootGetters['application/company'] || {}

      const userAsClient = {
        type: 'company',
        email: user.email,
        companyName:
          company.name ||
          (_formData.language === 'sv'
            ? 'Konsulterna AB'
            : 'The consultants Corp.'),
        name:
          user.fullName ||
          (_formData.language === 'sv' ? 'Test Testson' : 'John Doe'),
        ...(user.cellphone ? { cellphone: user.cellphone } : {}),
        ...(company.region && { cellphoneRegion: company.region })
      }

      _formData.clients = [
        ..._formData.clients,
        addUniqueId({ ...getDefaultClientObject(), ...userAsClient }) // uniqueId needed for send order logic
      ]
    }

    // Let final state data be ready to immediately be used in doc constructor, as not to loose its reference
    const defaultAnswerMethod = get(_formData, 'data.defaultAnswerMethod', null)
    if (defaultAnswerMethod) {
      _formData.clients =
        _formData.clients.map(cl =>
          get(cl, 'answerMethod.accept') !== 'inPerson'
            ? { ...cl, answerMethod: defaultAnswerMethod }
            : cl
        ) || []
    }

    let documentFormData = {
      ..._formData,
      data: {
        ..._formData.data,
        ...(!documentId && {
          // TODO - Add key to template to know which path to read it from
          reminders: this.getters['forms/getDocumentAccountDefault'](
            'reminders',
            { template: new Template(_formData.template) }
          )
        })
      }
    }

    documentFormData = setupFormSettings(documentFormData)

    commit(SET_FORM, {
      key: 'document2',
      formData: documentFormData
    })

    // Do not wait for promises to finish as that blocks UI
    dispatch(LOAD_EXTERNAL_FORM_FIELDS)
  },

  /**
   * @name LOAD_DOCUMENT2_PUBLIC_FORM
   * Action to prepare the form/document2 from public
   * @param {Object} Vuex object
   * @param {Object} formData Document parameters (optional)
   */
  async [LOAD_DOCUMENT2_PUBLIC_FORM]({ commit }, { formData = null } = {}) {
    let _formData = {
      id: null,
      language: lang.lang,
      currency: brands[config.brand].defaultCurrency,
      data: {},
      template: null,
      articles: [],
      clients: [],
      ...formData
    }

    if (!formData) {
      if (!_formData.template) {
        const template = new Template(
          staticTemplates.default(_formData.language)
        )
        _formData.template = {
          ...template._data,
          defaultData: template.defaultData
        }
      }
      const defaultData = get(_formData, 'template.defaultData', {})
      _formData = {
        ..._formData,
        ...defaultData
      }
    }

    if (_formData.language === 'sv' && _formData.data.defaultAnswerMethod)
      _formData.data.defaultAnswerMethod.accept = 'bankId'

    // Let final state data be ready to immediately be used in doc constructor, as not to loose its reference
    const defaultAnswerMethod = get(_formData, 'data.defaultAnswerMethod', null)
    if (defaultAnswerMethod) {
      _formData.clients =
        _formData.clients.map(cl =>
          get(cl, 'answerMethod.accept') !== 'inPerson'
            ? { ...cl, answerMethod: defaultAnswerMethod }
            : cl
        ) || []
    }

    commit(SET_FORM, {
      key: 'document2',
      formData: {
        ..._formData,
        data: {
          ..._formData.data
        }
      }
    })
  },

  /**
   * Duplicate a document by id or publicId
   * @param {Object} Vuex
   * @param {Object} obj
   * @param {String} obj.id
   */
  async [LOAD_DUPLICATE_DOCUMENT2_FORM](
    { commit, dispatch },
    {
      id,
      publicDocId,
      projectId = null,
      keepClients = false,
      isPublicForm = false,
      _formData = null
    }
  ) {
    if (!id && !publicDocId) throw new Error('Missing parameter id')

    let document
    if (publicDocId) {
      const { data } = await publicDocument.get(publicDocId, { preview: true })
      document = new CompanyDocument({
        data,
        can: isPublicForm
          ? null
          : permissions.checkPermission.bind(permissions),
        getters: this.getters
      })
    } else {
      // TODO: check if document is already in state
      await this.dispatch(global.DO_LOAD_DOCUMENT2, { id })
      document = this.getters['documents2/byId'](id)
    }

    if (!document) throw new Error('Could not find document to duplicate')

    const duplicate = document.duplicate()
    const { defaultData } = duplicate.template
    const {
      template,
      data,
      articles,
      clients,
      language,
      currency,
      externalReferences
    } = duplicate.getRawData()

    const formData = {
      ...defaultData,
      template,
      articles,
      clients: keepClients ? clients : [],
      language,
      currency,
      externalReferences,
      ..._formData,
      data: { ...defaultData.data, ...data, ..._formData?.data }
    }

    // Only add projectId key to formData if arg exists!
    if (projectId) formData.projectId = projectId

    commit(SET_FORM, {
      key: 'document2',
      formData
    })

    // Do not wait for promises to finish as that blocks UI
    dispatch(LOAD_EXTERNAL_FORM_FIELDS)
  },

  async [SAVE_TEMPLATE_FORM](_, { body, callback }) {
    try {
      if (!body) throw new Error('Missing param body')

      if (body.id) await this.dispatch(UPDATE_TEMPLATE, { id: body.id, body })
      else await this.dispatch(DO_CREATE_TEMPLATE, { body })

      if (typeof callback === 'function') callback()
    } catch (err) {
      this.handleError(err)
    }
  },

  /**
   * @name SAVE_DYNDOC_FORM
   * Action to save a document
   * @param {Object} Vuex object
   * @param {Object} object Document parameters (optional)
   * @param {String} object.next Optional string to decide if user should be redirected after success (project/preview)
   * @param {Boolean} object.doSend Optional if document should be sent, defaults to false
   */
  async [SAVE_DYNDOC_FORM]({ dispatch }, { doSend = false } = {}) {
    try {
      const id = await dispatch(DO_SAVE_DYNDOC)
      const doc = this.getters['documents2/byId'](id)
      const isSenderSign = !!get(doc, 'senderClient')
      if (doSend && !isSenderSign)
        await this.dispatch(DO_SEND_DOCUMENT2, { id })

      return id
    } catch (err) {
      this.handleError(err)
      return null
    }
  },

  async [CLEAR_DYNDOC_FORM]() {
    try {
      await this.dispatch(FORM_CLEAR_FORM, { key: '_old_document2' })
      await this.dispatch(FORM_CLEAR_FORM, { key: 'document2' })
    } catch (err) {
      this.handleError(err)
    }
  },

  /**
   * @name DO_SAVE_DYNDOC
   * Action to save a document
   * @param {Object} Vuex object
   */
  async [DO_SAVE_DYNDOC](
    { rootGetters, getters, commit },
    { showMessage = true } = {}
  ) {
    const { document } = getters
    let { id } = document
    const t = key =>
      docLang.t(`documentTemplateView.${key}`, {
        lng: document.language,
        interpolation: { prefix: '__', suffix: '__' }
      })

    // Note: Translation and name fallback also exists on the document model, but moved here as we no longer have access to the doc instance

    // Make sure all clients have the correct answerMethod
    const defaultAnswerMethod = get(document, 'data.defaultAnswerMethod', null)
    if (defaultAnswerMethod) {
      // Do not update inPerson methods according to default
      document.clients = document.clients.map(cl =>
        get(cl, 'answerMethod.accept') !== 'inPerson'
          ? { ...cl, answerMethod: defaultAnswerMethod }
          : cl
      )
    }
    const body = {
      ...document,
      data: {
        ...document.data,
        name: document.data.name || '',
        smsTemplate: {
          first: {
            text: t('smsTemplate.first.text')
          },
          reminder: {
            text: t('smsTemplate.reminder.text')
          },
          expires: {
            text: t('smsTemplate.expires.text')
          },
          viewed: {
            text: t('smsTemplate.viewed.text')
          }
        },
        emailTemplate: {
          first: {
            subject: t('emailTemplate.first.subject'),
            title: t('emailTemplate.first.title'),
            content: t('emailTemplate.first.content'),
            text: t('emailTemplate.first.text')
          },
          viewed: {
            subject: t('emailTemplate.viewed.subject'),
            title: t('emailTemplate.viewed.title'),
            content: t('emailTemplate.viewed.content'),
            text: t('emailTemplate.viewed.text')
          },
          reminder: {
            subject: t('emailTemplate.reminder.subject'),
            title: t('emailTemplate.reminder.title'),
            content: t('emailTemplate.reminder.content'),
            text: t('emailTemplate.reminder.text')
          },
          expires: {
            subject: t('emailTemplate.expires.subject'),
            title: t('emailTemplate.expires.title'),
            content: t('emailTemplate.expires.content'),
            text: t('emailTemplate.expires.text')
          }
        }
      },
      pdfConfig: {
        locale: docLang.locale,
        timeZone: get(Intl.DateTimeFormat().resolvedOptions(), 'timeZone') || ''
      }
    }

    // Current live company and user is present in the store, don't send it (this is handled backend)
    delete body.company
    delete body.companyUser

    if (id) {
      // If flag asks to clear answers (used for old clients that can upgrade to BankId)
      if (document.clearAnswerBeforePut) {
        await this.dispatch(CLEAR_DOCUMENT2_ANSWERS, { id, doFetch: false })
        await this.dispatch(global.DO_UPDATE_DOCUMENT2, {
          id,
          body: { status: 'draft' },
          showMessage
        }) // Needed to handle upcoming version conflict
      }
      await this.dispatch(global.DO_UPDATE_DOCUMENT2, { id, body, showMessage })
    } else {
      id = await this.dispatch(global.DO_CREATE_DOCUMENT2, { body })
      commit(SET_FORM_VALUE, { key: 'document2.id', value: id })
    }

    // Remove onboarding condition for account overview if still active
    if (rootGetters['settings/showOverviewOnboardingOverlay']) {
      this.dispatch('settings/UPDATE_SETTINGS', {
        key: 'companyUser',
        settings: { showOverviewOnboardingOverlay: false }
      })
    }

    return id
  },

  /**
   * @name HANDLE_DOCUMENT2_EDIT
   * Action returning appropriate response when user wants to edit a document
   * depending on document conditions and user, permissions
   * @param {Object} Vuex object
   * @param {Object} object Document parameters (optional)
   * @param {String} object.documentId Optional docId if document is not populated in the store
   */
  async [HANDLE_DOCUMENT2_EDIT](
    { getters, dispatch },
    { documentId = null } = {}
  ) {
    let document
    if (documentId) {
      await this.dispatch(global.DO_LOAD_DOCUMENT2, { id: documentId })
      document = this.getters['documents2/byId'](documentId)
    } else ({ document } = getters)

    if (!document) throw new Error('Missing document')

    const canEdit = document.id
      ? document.can('modify')
      : document.can('create')
    const { id, status, projectId, hasAnswers } = document

    if (canEdit && (status === 'accepted' || hasAnswers)) {
      const key = status === 'accepted' ? 'accepted' : 'hasAnswers' // Helper to provide the best message
      this.dispatch(SHOW_MESSAGE, {
        displayType: 'dialog',
        type: 'primary',
        title: lang.t(`actions:editDocumentDialog.${key}.title`),
        message: lang.t(`actions:editDocumentDialog.${key}.message`),
        actions: {
          cancel: {
            text: lang.t('_common:cancel'),
            callback: 'cancel'
          },
          submit: {
            text: lang.t(`actions:editDocumentDialog.${key}.submit`),
            callback: async () => {
              if (key === 'accepted') {
                await dispatch(LOAD_DUPLICATE_DOCUMENT2_FORM, {
                  id,
                  projectId,
                  keepClients: true
                })
                this.$router.push({ name: 'documentForm' })
              } else if (key === 'hasAnswers') {
                await this.dispatch(CLEAR_DOCUMENT2_ANSWERS, {
                  id,
                  doFetch: true
                })
                const path = { name: 'documentForm', params: id ? { id } : {} }
                this.$router.push(path)
              }
            }
          }
        }
      })
    } else if (canEdit) {
      const path = { name: 'documentForm', params: id ? { id } : {} }
      return this.$router.push(path)
    }
    return null
  },

  /**
   * @name LOAD_EXTERNAL_FORM_FIELDS
   * Add CRM custom fields to the document if connection exists
   * Note: Not necessary on init, hence added after form is set
   * in order to not block the UI with potentially long request
   */
  async [LOAD_EXTERNAL_FORM_FIELDS]({ rootGetters, getters, commit }) {
    const { document } = getters
    if (document?.externalReferences && document.externalReferences.length) {
      try {
        await Promise.all(
          (document.externalReferences || []).map(
            async ({ service, type, typeId }) => {
              if (type && typeId && service) {
                if (rootGetters['settings/isAuthenticatedCRM'](service)) {
                  const { data: crmFields } = await extensionApi.getFields(
                    service,
                    { type, typeId, populated: true }
                  )

                  commit(SET_FORM_VALUE, {
                    key: 'document2.data.fields',
                    value: { ...get(document, 'data.fields'), ...crmFields }
                  })
                }
              }
            }
          )
        )
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}

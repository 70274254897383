import {
  deepMergeReplace,
  convertArrToObject,
  getUniqueArrayElements
} from '@cling/store/utils/'

import Vue from 'vue'

import { mutationTypes } from './constants'

const {
  SET_COMPANY_REFERENCE_ITEMS,
  DELETE_COMPANY_REFERENCE_ITEM,
  CLEAR_COMPANY_REFERENCE_ITEMS
} = mutationTypes

export default {
  /**
   * @name SET_COMPANY_REFERENCE_ITEMS
   * Add companyReferenceItems to store by overwriting old data with new.
   * Will not remove old companies
   *
   * @param {Object} state Vuex Store Props
   * @param {Object} payload
   * @param {Object|Array} payload.data companyReferenceItems or array of companyReferenceItems
   *
   * @returns {Void}
   */
  [SET_COMPANY_REFERENCE_ITEMS](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error(
        'Data must be a companyReferenceItem or an array of companyReferenceItems!'
      )
    }
    const items = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(items)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  // Delete one
  [DELETE_COMPANY_REFERENCE_ITEM](state, id) {
    Vue.delete(state.data, id)
    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_COMPANY_REFERENCE_ITEMS
   * Clear all companyReferenceItems
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_COMPANY_REFERENCE_ITEMS](state) {
    Vue.set(state, 'data', {})
    Vue.set(state, 'ids', [])
  }
}

<template>
  <div v-if="needRefresh" class="root font-inter">
    <div>
      <div style="font-weight: 500" v-text="$t('title')" />
      <div style="opacity: 0.65; padding-bottom: 1.5em" v-text="$t('text')" />
      <CButton
        type="secondary"
        size="small"
        style="
          font-size: 14px;
          padding: 9px 16px;
          border-radius: 8px;
          height: initial;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
          font-weight: 600;
        "
        @click="updateServiceWorker"
        v-text="$t('action')"
      />
      <CButton
        icon="x"
        circle
        size="small"
        style="position: absolute; right: 0.5em; top: 0.5em"
        @click="closePromptUpdateSW"
      />
    </div>
  </div>
</template>

<script>
import config from '@cling/config'
import webStorage from '@cling/utils/webStorage'

import axios from 'axios'

// https://stackoverflow.com/a/52059759
function isNewerVersion(oldVer, newVer) {
  const oldParts = oldVer.split('.')
  const newParts = newVer.split('.')
  for (var i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i] // parse int
    const b = ~~oldParts[i] // parse int
    if (a > b) return true
    if (a < b) return false
  }
  return false
}

const intervalMS = 5 * 60 * 1000 // 5 minutes

// Fallback value in case the service worker is not registered.
window.reloadApp = () => window.location.reload(true)

export default {
  name: 'UpdatesAvailable',
  data() {
    return {
      updateSW: undefined,
      offlineReady: false,
      needRefresh: false
    }
  },
  i18nOptions: {
    namespaces: 'UpdatesAvailable',
    messages: {
      en: {
        title: 'New $t(_common:brand.name) update available',
        text: 'There is a newer version of $t(_common:brand.name) available. Please update it now.',
        action: 'Update app'
      },
      sv: {
        title: 'En ny $t(_common:brand.name)-version finns tillgänglig',
        text: 'Det finns en nyare version av $t(_common:brand.name) tillgänglig. Var god uppdatera.',
        action: 'Uppdatera app'
      }
    }
  },
  async mounted() {
    try {
      return
    } finally {
      this.evalForceReload()
    }
  },
  methods: {
    // SW methods
    async closePromptUpdateSW() {
      this.offlineReady = false
      this.needRefresh = false
    },
    onOfflineReadyFn() {},
    onNeedRefreshFn() {
      // eslint-disable-next-line no-console
      console.log('onNeedRefreshFn')
    },
    updateServiceWorker() {
      // eslint-disable-next-line no-console
      console.log('reload')
      // this.updateSW && this.updateSW(true)
    },
    handleSWManualUpdates(r) {
      // eslint-disable-next-line no-console
      console.log('Init checking for manual updates')
      // eslint-disable-next-line no-console
      console.log(r)

      r &&
        setInterval(() => {
          // eslint-disable-next-line no-console
          console.log('Checking if updates exist')
          r.update()
        }, intervalMS)
    },
    // Fallback/Safety methods
    async evalForceReload() {
      if (!config.checkAppUpdates) return

      const interval = 5 * 60 * 1000
      const lastReloadAt = parseInt(webStorage.getItem('lastReloadAt'), 10)

      try {
        if (lastReloadAt > Date.now() - interval) return

        const isAppOld = await this.isAppVersionOld('patch')

        if (!isAppOld) return

        webStorage.setItem('lastReloadAt', Date.now())
        this.updateServiceWorker?.(true)
        window.location.reload(true)
      } catch (error) {
        // Don't show any error
      }
    },
    async isAppVersionOld(accuracy = 'minor') {
      // accuracy: <major|minor|patch>
      try {
        const [major = 0, minor = 0, patch = 0] = __APP_VERSION__
          .split('.')
          .map(Number)

        const { data } = await axios({
          method: 'get',
          url: `/appVersion.json?timestamp=${Date.now()}`
        })

        const { version } = JSON.parse(data)

        if (!version) return false // Gracefully fail
        const [liveMajor, liveMinor, livePatch] = version.split('.').map(Number)

        // Special condition
        // 1.10.4 is when we had our previous Vue CLI serviceworker
        // We need to manually uninstall old SWs it if we detect a user on the old version

        if (isNewerVersion('1.10.4', __APP_VERSION__)) {
          if (navigator && navigator.serviceWorker) {
            await navigator.serviceWorker
              .getRegistrations()
              .then(function (registrations) {
                for (let registration of registrations) {
                  registration.unregister()
                }
              })
          }
          return true
        }

        // >= check is used in-case an asset has been cached.
        const isLatestVersion = {
          major: major >= liveMajor,
          minor: major >= liveMajor && minor >= liveMinor,
          patch: major >= liveMajor && minor >= liveMinor && patch >= livePatch
        }

        return !isLatestVersion[accuracy]
      } catch (error) {
        return false // Gracefully fail
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.root {
  position: absolute;
  z-index: 2147483647;
  right: 1rem;
  bottom: 1rem;
  width: 320px;
  max-width: 340px;
  width: calc(100vw - 2rem);
  padding: 1.5em;
  background: hsl(0, 0%, 100%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  border: 1px solid hsl(0, 0%, 85%);
  font-size: 14px;
}
</style>

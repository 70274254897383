export const actionTypes = {
  LOAD_COMPANY_REFERENCES: 'LOAD_COMPANY_REFERENCES',
  LOAD_COMPANY_REFERENCE: 'LOAD_COMPANY_REFERENCE',
  CREATE_COMPANY_REFERENCE: 'CREATE_COMPANY_REFERENCE',
  UPDATE_COMPANY_REFERENCE: 'UPDATE_COMPANY_REFERENCE',
  DELETE_COMPANY_REFERENCE: 'DELETE_COMPANY_REFERENCE',
  PATCH_COMPANY_REFERENCE: 'PATCH_COMPANY_REFERENCE',
  FORM_NEW_COMPANY_REFERENCE: 'FORM_NEW_COMPANY_REFERENCE',
  FORM_EDIT_COMPANY_REFERENCE: 'FORM_EDIT_COMPANY_REFERENCE',
  FORM_SUBMIT_COMPANY_REFERENCE: 'FORM_SUBMIT_COMPANY_REFERENCE'
}

export const mutationTypes = {
  SET_COMPANY_REFERENCES: 'SET_COMPANY_REFERENCES',
  DELETE_COMPANY_REFERENCE: 'DELETE_COMPANY_REFERENCE',
  CLEAR_COMPANY_REFERENCES: 'CLEAR_COMPANY_REFERENCES',
  SET_COMPANY_REFERENCE_FETCHING: 'SET_COMPANY_REFERENCE_FETCHING',
  SET_COMPANY_REFERENCE_POSTING: 'SET_COMPANY_REFERENCE_POSTING'
}

import Vue from 'vue'

import { mutationTypes } from '../constants'

const {
  SET_PROJECT_NOTES,
  SET_PROJECT_NOTES_FETCHING,
  SET_PROJECT_NOTES_POSTING
} = mutationTypes

export default {
  // Set projectNote for the associated projects
  // projectNotes: Array with objects
  [SET_PROJECT_NOTES](state, { projectNotes = [] }) {
    projectNotes.forEach(projectNote => {
      const { ProjectId: projectId } = projectNote
      if (!projectId) return false
      if (!state.data[projectId]) return false
      Vue.set(state.data[projectId], 'ProjectNote', projectNote)
      return true
    })
  },
  /**
   * @name SET_PROJECT_NOTES_FETCHING
   * Set fetching status of project notes
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_PROJECT_NOTES_FETCHING](state, isFetching) {
    state.isFetchingProjectNotes = isFetching
  },

  /**
   * @name SET_PROJECT_NOTES_POSTING
   * Set posting status of project notes
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_PROJECT_NOTES_POSTING](state, isPosting) {
    state.isPostingProjectNotes = isPosting
  }
}

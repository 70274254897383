<template>
  <CDropdownItem
    :value="source"
    :custom-parent="customParent"
    class="truncate-text"
    :style="itemStyle"
  >
    {{ family }}
  </CDropdownItem>
</template>

<script setup lang="ts">
import CDropdownItem from '@cling/components/ui/DropdownItem'

import { getCurrentInstance, onMounted, ref } from 'vue'

import { loadFont } from './load'

type Props = {
  source: { family: string }
}

const props = defineProps<Props>()
const customParent: Record<string, any> | undefined = ref(null)

// Internal helper method to facilitate communication to specific parent
const getCustomParent = (context: any, componentName: string) => {
  let parent = context
  let name = parent.$options.name

  while (parent && (!name || name !== componentName)) {
    parent = parent.$parent
    if (parent) ({ name } = parent.$options)
  }

  return parent || null
}

const family = props.source.family

const itemStyle = ref({
  paddingRight: 'calc(0.5 * var(--rem))',
  fontFamily: `'${family}'`
})

const instance = getCurrentInstance()

if (instance) {
  // Allows for communication through virtual list component layers
  customParent.value = getCustomParent(instance.proxy, 'CDropdown')
}

onMounted(() => {
  loadFont(family, true)
})
</script>

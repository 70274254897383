import config from '@cling/config'
import { brands } from '@cling/static'

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

// Helper to split a b64 string into realData and contentType
// return Obj { realData, contentType }
export const splitb64 = b64string => {
  // Split the base64 string in data and contentType
  const block = b64string.split(';')
  // Get the content type of the image
  const contentType = block[0].split(':')[1] // Example "image/gif"
  // get the real base64 content of the file
  const realData = block[1].split(',')[1] // Example "R0lGODlhPQBEAPeoAJosM...."
  return { realData, contentType }
}

/**
 * Returns unsplash image links with provided params, if not unsplash link, return original
 * For more info on params check link
 * https://unsplash.com/documentation#supported-parameters
 */
export const getUnsplashResized = (inputUrl, params = {}) => {
  if (!inputUrl) return inputUrl
  if (
    !inputUrl.startsWith('https://images.unsplash.com/') ||
    !Object.keys(params || {}).length
  )
    return inputUrl

  const url = new URL(inputUrl)
  const searchParams = new URLSearchParams(url.search)

  const supportedParams = ['h', 'w', 'crop', 'fm', 'q', 'fit', 'dpi']
  Object.keys(params).forEach(key => {
    if (supportedParams.includes(key)) searchParams.set(key, params[key])
  })
  url.search = searchParams.toString()

  return url.toString()
}

/**
 * Returns relative/absolute paths to app images depending on bundle type
 */
export const getAppImage = path => {
  if (typeof path !== 'string')
    throw new Error('Missing or invalid argument path')

  let source = ''

  // eslint-disable-next-line no-undef
  if (__APP_TYPE__ !== 'app') source = config.baseUrl

  return `${source}/img/app${path.startsWith('/') ? path : `/${path}`}`
}

/**
 * Returns modified image url for document content
 * will convert s3 urls to file proxy urls
 * will conditionally add image params
 */
export const getDocumentContentImage = (inputUrl, params = {}) => {
  if (!inputUrl || typeof inputUrl !== 'string') return inputUrl

  const url = new URL(inputUrl)
  const searchParams = new URLSearchParams(url.search)

  // Add image params if provided and not already present
  if (params && typeof params === 'object' && Object.keys(params).length) {
    const availableParams = ['h', 'w', 'auto']

    Object.keys(params)
      .filter(key => availableParams.includes(key))
      .forEach(key => {
        if (!searchParams.has(key)) searchParams.set(key, params[key])
      })
  }

  // if api url, append optionally modified search params and return
  if (inputUrl.startsWith(config.api.baseUrl)) {
    url.search = searchParams.toString()
    return url.toString()
  }

  // check if url is from another of our brands
  const { hostname } = url
  const otherBrandUrl = Object.keys(brands).some(brand =>
    hostname.includes(brands[brand].domain)
  )

  if (otherBrandUrl) {
    url.search = searchParams.toString()
    return url.toString()
  }

  // regex check if it's an s3 url
  const s3UrlRegex = /^https:\/\/[\w.-]+\.s3\.[\w-]+\.amazonaws\.com\/.*/

  if (s3UrlRegex.test(inputUrl)) {
    // convert direct usage of bucket urls, and try to funnel through our file proxy
    searchParams.set('url', inputUrl)
    return `${config.api.baseUrl}/public/fileProxy?${searchParams.toString()}`
  }

  return inputUrl
}

export default {
  b64toBlob,
  splitb64,
  getUnsplashResized,
  getAppImage
}

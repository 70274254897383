/* eslint-disable no-useless-catch */
import { document, publicDocument } from '@cling/api'
import { viewContext } from '@cling/globalState'
import lang from '@cling/language'
import eventBus from '@cling/services/eventBus'
import { global } from '@cling/store/action-types'

import set from 'lodash/set'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'documents2'

const {
  DELETE_DOCUMENT2,
  DO_CREATE_DOCUMENT2,
  DO_LOAD_DOCUMENT2,
  DO_LOAD_DOCUMENTS2,
  DO_UPDATE_DOCUMENT2,
  LOAD_DOCUMENT2,
  LOAD_DOCUMENTS2,
  LOAD_DOCUMENTS2_EXTERNAL,
  PATCH_DOCUMENT2,
  UPDATE_DOCUMENT2,
  UPDATE_DOCUMENT2_STATUS,
  DO_SEND_DOCUMENT2,
  SEND_REMINDER_DOCUMENT2,
  REQUEST_PROPERTY_DOCUMENT2,
  CLEAR_DOCUMENT2_ANSWERS,
  ANSWER_OWN_DOCUMENT2
} = actionTypes

const {
  DELETE_MANY_DOCUMENTS2,
  SET_DOCUMENT2_POSTING,
  SET_DOCUMENT2_FETCHING,
  SET_DOCUMENTS2
} = mutationTypes

const { SHOW_MESSAGE, DO_LOAD_CURRENT_COMPANY } = global

const actions = {
  /**
   * @name DO_LOAD_DOCUMENT2
   * Get one document2 from the API and add to store
   * @param {Object} Vuex object
   * @returns {Promise<>} Promise that resolves or throws an error
   */
  async [DO_LOAD_DOCUMENT2]({ commit }, { id, emit = false }) {
    try {
      commit(SET_DOCUMENT2_FETCHING, true)
      const { data } = await document.get(id)
      if (emit) eventBus.trigger('document:watch', data)
      commit(SET_DOCUMENTS2, { data: [data] })
      return data
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_FETCHING, false)
    }
  },

  /**
   * @name DO_LOAD_DOCUMENTS2
   * Get documents2 from the API and add to store
   * @param {Object} Vuex object
   * @returns {Promise<>} Promise that resolves or throws an error
   */
  async [DO_LOAD_DOCUMENTS2]({ commit }, params = {}) {
    try {
      commit(SET_DOCUMENT2_FETCHING, true)
      const { data } = await document.getAll(params)
      const { items } = data
      commit(SET_DOCUMENTS2, { data: items })
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_FETCHING, false)
    }
  },

  /**
   * @name LOAD_DOCUMENTS2
   * Get many documents2
   * @param {Object} Vuex object
   */
  async [LOAD_DOCUMENTS2]({ dispatch }, params = { isHidden: false }) {
    try {
      await dispatch(DO_LOAD_DOCUMENTS2, params)
    } catch (err) {
      this.handleError(err, {
        object: 'document2',
        fallbackCode: 'document2.getMany',
        action: `${moduleName}/${LOAD_DOCUMENTS2}`
      })
    }
  },

  /**
   * @name LOAD_DOCUMENTS2_EXTERNAL
   * Get many documents2
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {String} obj.service Which service to load for, example hubspot
   * @param {String} obj.type Optional what type of external objects to load for, example company, contact or deal
   * @param {String} obj.typeId Optional id of the external resource
   */
  async [LOAD_DOCUMENTS2_EXTERNAL](
    { dispatch },
    { service, type = null, typeId = null }
  ) {
    if (typeof service === 'undefined') throw new Error('Missing param service')
    await dispatch(LOAD_DOCUMENTS2, {
      isHidden: false,
      'externalReferences.service': service,
      ...(type && { 'externalReferences.type': type }),
      ...(typeId && { 'externalReferences.typeId': typeId })
    })
  },

  /**
   * @name LOAD_DOCUMENT2
   * Get one document2 by id
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {String} obj.id id
   */
  async [LOAD_DOCUMENT2]({ dispatch }, { id, emit }) {
    try {
      const data = await dispatch(DO_LOAD_DOCUMENT2, { id, emit })
      return data
    } catch (err) {
      this.handleError(err, {
        object: 'document2',
        fallbackCode: 'document2.getOne',
        action: `${moduleName}/${LOAD_DOCUMENT2}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name DO_CREATE_DOCUMENT2
   * Create one document2
   * @param {Object} body data
   * @returns {Promise<String>} Promise that resolves with document2 id
   */
  async [DO_CREATE_DOCUMENT2]({ rootGetters, commit, dispatch }, { body }) {
    try {
      commit(SET_DOCUMENT2_POSTING, true)
      const { data } = await document.post(body)
      const { id } = data
      await dispatch(DO_LOAD_DOCUMENT2, { id })

      eventBus.trigger('document:create', data)
      eventBus.trigger('document:save', data)

      if (rootGetters['application/companyAccountType'] === 'free') {
        // As a new document was created, load current company to make sure any free document counts are updated
        await this.dispatch(DO_LOAD_CURRENT_COMPANY)
      }

      return id
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  },

  async [DO_SEND_DOCUMENT2]({ state, commit }, { id }) {
    try {
      commit(SET_DOCUMENT2_POSTING, true)
      await document.send(id)

      eventBus.trigger('document:send', state.data[id])

      if (!['extension', 'widget'].includes(viewContext.value)) {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('sentThing', { thing: lang.t('document') })
        })
      }
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  },

  async [SEND_REMINDER_DOCUMENT2]({ commit }, { id, body = {} }) {
    try {
      const defaultView = {
        views: {
          sms: {
            nodes: [
              {
                nodeId: 1,
                parentId: null,
                itemId: 'smsText',
                itemType: 'smsText',
                value: {
                  text: 'data.smsTemplate.reminder.text'
                }
              }
            ]
          },
          email: {
            nodes: [
              {
                nodeId: 1,
                parentId: null,
                itemId: 'emailTemplate',
                itemType: 'emailTemplate',
                value: {
                  subject: 'data.emailTemplate.reminder.subject',
                  title: 'data.emailTemplate.reminder.title',
                  content: 'data.emailTemplate.reminder.content',
                  text: 'data.emailTemplate.reminder.text'
                }
              }
            ]
          }
        }
      }

      commit(SET_DOCUMENT2_POSTING, true)
      // Spread view as default for now
      await document.send(id, {
        ...defaultView,
        ...body,
        sendSource: 'reminder'
      })

      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('sentThing', { thing: lang.t('reminder') })
      })
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  },

  async [REQUEST_PROPERTY_DOCUMENT2]({ commit }, { id, body = {} }) {
    try {
      const defaultView = {
        views: {
          sms: {
            nodes: [
              {
                nodeId: 1,
                parentId: null,
                itemId: 'smsText',
                itemType: 'smsText',
                value: {
                  text: 'data.smsTemplate.propertyDesignation.text'
                }
              }
            ]
          },
          email: {
            nodes: [
              {
                nodeId: 1,
                parentId: null,
                itemId: 'emailTemplate',
                itemType: 'emailTemplate',
                value: {
                  subject: 'data.emailTemplate.propertyDesignation.subject',
                  title: 'data.emailTemplate.propertyDesignation.title',
                  content: 'data.emailTemplate.propertyDesignation.content',
                  text: 'data.emailTemplate.propertyDesignation.text',
                  actionUrl: 'data.emailTemplate.propertyDesignation.actionUrl'
                }
              }
            ]
          }
        }
      }

      // TODO: When we allow set only specified data on backend, we can update this section
      const { data: doc } = await document.get(id)
      // Hacky way to ignore brackets causing interpolation
      const t = key =>
        lang.t(`propertyDesignationRequest.${key}`, {
          ns: 'actions',
          interpolation: { prefix: '__', suffix: '__' }
        })

      set(doc, 'data.smsTemplate.propertyDesignation', {
        text: t('smsTemplate.text')
      })
      set(doc, 'data.emailTemplate.propertyDesignation', {
        subject: t('emailTemplate.subject'),
        title: t('emailTemplate.title'),
        content: t('emailTemplate.content'),
        text: t('emailTemplate.text'),
        actionUrl: t('emailTemplate.actionUrl')
      })

      const defaultBody = {
        ...defaultView,
        document: doc,
        clients: [doc.clients[0]] // Use first client
      }

      commit(SET_DOCUMENT2_POSTING, true)
      await document.send(id, {
        ...defaultBody,
        ...body,
        sendSource: 'propertyDesignation'
      })

      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('actions:propertyDesignationRequest.successMessage')
      })
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  },

  /**
   * @name DO_UPDATE_DOCUMENT2
   *  Update one document2 by id
   * @param {String} id id of the document2
   * @param {Object} body data
   * @returns {Promise<String>} Promise that resolves with document2 id
   */
  async [DO_UPDATE_DOCUMENT2](
    { state, dispatch, commit },
    { id, body, params = {}, showMessage = true }
  ) {
    try {
      commit(SET_DOCUMENT2_POSTING, true)
      await document.put(id, body, params)
      await dispatch(DO_LOAD_DOCUMENT2, {
        id
      })

      const data = state.data[id]
      eventBus.trigger('document:update', data)
      eventBus.trigger('document:save', data)

      if (showMessage && !['extension', 'widget'].includes(viewContext.value)) {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('updatedThing', { thing: lang.t('document') })
        })
      }
      return id
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  },

  /**
   * @name UPDATE_DOCUMENT2
   *  Update one document2 by id
   * @param {String} id id of the document2
   * @param {Object} body data
   * @returns {Promise<String|null>} Promise that resolves with document2 id or null
   */
  async [UPDATE_DOCUMENT2]({ dispatch }, { id, body, params = {} }) {
    try {
      await dispatch(DO_UPDATE_DOCUMENT2, {
        id,
        body,
        params
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'document2',
        objectId: id,
        fallbackCode: 'document2.put',
        action: `${moduleName}/${UPDATE_DOCUMENT2}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name DELETE_DOCUMENT2
   * Destroy one document2 by id
   * @param {String} id id of the document2
   * @returns {Promise<String|null>} Promise that resolves with document2 id or null
   */
  async [DELETE_DOCUMENT2]({ commit }, { id }) {
    try {
      commit(SET_DOCUMENT2_POSTING, true)
      await document.delete(id)
      commit(DELETE_MANY_DOCUMENTS2, id)

      eventBus.trigger('document:remove', id)

      if (viewContext.value !== 'widget') {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('removedThing', { thing: lang.t('document') }),
          actions: {
            undo: () => {
              this.dispatch(`documents2/${PATCH_DOCUMENT2}`, { id })
            }
          }
        })
      }
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'document2',
        objectId: id,
        fallbackCode: 'document2.delete',
        action: `${moduleName}/${DELETE_DOCUMENT2}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  },

  /**
   * @name PATCH_DOCUMENT2
   * Patch one document2 by id
   * @param {String} id id of the document2
   * @returns {Promise<String|null>} Promise that resolves with document2 id or null
   */
  async [PATCH_DOCUMENT2]({ commit, dispatch }, { id }) {
    try {
      commit(SET_DOCUMENT2_POSTING, true)
      await document.patch(id)
      await dispatch(DO_LOAD_DOCUMENT2, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'document2',
        objectId: id,
        fallbackCode: 'document2.patch',
        action: `${moduleName}/${PATCH_DOCUMENT2}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  },

  /**
   * @name UPDATE_DOCUMENT2_STATUS
   *  Update one document2 status by id
   * @param {String} id id of the document2
   * @param {String} status New status
   * @returns {Promise<String|null>} Promise that resolves with document2 id or null
   */
  async [UPDATE_DOCUMENT2_STATUS]({ dispatch, getters }, { id, status }) {
    try {
      const { hasAnswers } = getters.byId(id) || {}

      // Keep status check in sync with pre save hook on api doc-schema
      if (hasAnswers && !['accepted', 'denied'].includes(status)) {
        await this.dispatch(SHOW_MESSAGE, {
          displayType: 'dialog',
          type: 'warning',
          title: lang.t('actions:updateDocumentDialog.title'),
          message: lang.t('actions:updateDocumentDialog.message'),
          actions: {
            cancel: {
              text: lang.t('actions:updateDocumentDialog.cancel'),
              callback: 'cancel'
            },
            submit: {
              text: lang.t('actions:updateDocumentDialog.submit'),
              callback: async () => {
                await dispatch(CLEAR_DOCUMENT2_ANSWERS, { id, doFetch: false })
                await dispatch(DO_UPDATE_DOCUMENT2, {
                  id,
                  body: { status }
                })
              }
            }
          }
        })
      } else {
        await dispatch(DO_UPDATE_DOCUMENT2, { id, body: { status } })
      }

      return id
    } catch (err) {
      this.handleError(err, {
        object: 'document2',
        objectId: id,
        fallbackCode: 'document2.updateStatus',
        action: `${moduleName}/${UPDATE_DOCUMENT2_STATUS}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name CLEAR_DOCUMENT2_ANSWERS
   * Remove all client answers from the document (to unlock so changes can be made)
   * @param {String} id id of the document2
   * @param {Boolean} doFetch Optional if the document should be fetched after answer has been cleared, defaults to true
   * @returns {Promise<>} Promise that resolves or throws error
   */
  async [CLEAR_DOCUMENT2_ANSWERS]({ dispatch }, { id, doFetch = true }) {
    await document.clearAnswers(id)
    if (doFetch) await dispatch(DO_LOAD_DOCUMENT2, { id })
  },

  /**
   * @name ANSWER_OWN_DOCUMENT2
   * Action to answer document2 as a logged in user/author
   * @param {String} id the document2 id
   * @param {String} publicId the publicDocumentId for the senderClient
   * @param {Object} body the data containing the answer changes to the document2
   * @param {String} checksum doc checksum to answer last version
   *
   * @returns {Promise<Object|undefined>} Promise that resolves with document2 id or undefined
   */
  async [ANSWER_OWN_DOCUMENT2](
    { commit, dispatch },
    { id, publicId, body, checksum }
  ) {
    try {
      commit(SET_DOCUMENT2_POSTING, true)
      const headers = { docChecksum: checksum }
      const { data } = await publicDocument.answer(publicId, body, {}, headers)
      await dispatch(DO_LOAD_DOCUMENT2, { id })
      return data
    } catch (err) {
      throw err
    } finally {
      commit(SET_DOCUMENT2_POSTING, false)
    }
  }
}

export default actions

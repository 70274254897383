export const actionTypes = {
  LOAD_TEMPLATE_MESSAGES: 'LOAD_TEMPLATE_MESSAGES',
  LOAD_TEMPLATE_MESSAGE: 'LOAD_TEMPLATE_MESSAGE',
  CREATE_TEMPLATE_MESSAGE: 'CREATE_TEMPLATE_MESSAGE',
  UPDATE_TEMPLATE_MESSAGE: 'UPDATE_TEMPLATE_MESSAGE',
  DELETE_TEMPLATE_MESSAGE: 'DELETE_TEMPLATE_MESSAGE',
  PATCH_TEMPLATE_MESSAGE: 'PATCH_TEMPLATE_MESSAGE',
  FORM_NEW_TEMPLATE_MESSAGE: 'FORM_NEW_TEMPLATE_MESSAGE',
  FORM_EDIT_TEMPLATE_MESSAGE: 'FORM_EDIT_TEMPLATE_MESSAGE',
  FORM_SUBMIT_TEMPLATE_MESSAGE: 'FORM_SUBMIT_TEMPLATE_MESSAGE'
}

export const mutationTypes = {
  SET_TEMPLATE_MESSAGES: 'SET_TEMPLATE_MESSAGES',
  DELETE_TEMPLATE_MESSAGE: 'DELETE_TEMPLATE_MESSAGE',
  CLEAR_TEMPLATE_MESSAGES: 'CLEAR_TEMPLATE_MESSAGES',
  SET_TEMPLATE_MESSAGES_FETCHING: 'SET_TEMPLATE_MESSAGES_FETCHING',
  SET_TEMPLATE_MESSAGES_POSTING: 'SET_TEMPLATE_MESSAGES_POSTING'
}

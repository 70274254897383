export const actionTypes = {
  LOAD_DOCUMENT_TERMS: 'LOAD_DOCUMENT_TERMS',
  LOAD_DOCUMENT_TERM: 'LOAD_DOCUMENT_TERM',
  CREATE_DOCUMENT_TERM: 'CREATE_DOCUMENT_TERM',
  UPDATE_DOCUMENT_TERM: 'UPDATE_DOCUMENT_TERM',
  DELETE_DOCUMENT_TERM: 'DELETE_DOCUMENT_TERM',
  PATCH_DOCUMENT_TERM: 'PATCH_DOCUMENT_TERM',
  FORM_NEW_DOCUMENT_TERM: 'FORM_NEW_DOCUMENT_TERM',
  FORM_EDIT_DOCUMENT_TERM: 'FORM_EDIT_DOCUMENT_TERM',
  FORM_SUBMIT_DOCUMENT_TERM: 'FORM_SUBMIT_DOCUMENT_TERM'
}

export const mutationTypes = {
  SET_DOCUMENT_TERMS: 'SET_DOCUMENT_TERMS',
  DELETE_DOCUMENT_TERM: 'DELETE_DOCUMENT_TERM',
  CLEAR_DOCUMENT_TERMS: 'CLEAR_DOCUMENT_TERMS',
  SET_DOCUMENT_TERMS_FETCHING: 'SET_DOCUMENT_TERMS_FETCHING',
  SET_DOCUMENT_TERMS_POSTING: 'SET_DOCUMENT_TERMS_POSTING'
}

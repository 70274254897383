import { scrollToElement } from '@cling/utils'

import Vuelidate from 'vuelidate'
import vuelidateErrorExtractor from 'vuelidate-error-extractor'

/**
 * Scrolls to the top most invalid input
 * @param {Number} offset Offset from window top to input top
 */
function scrollToInvalidChild({ offset = 0 } = {}) {
  try {
    if (!this.$cling || !this.$cling.getInvalidInputs) return
    const elements = this.$cling.getInvalidInputs()
    if (!elements.length) return
    const element = this.$cling.getInvalidInputs()[0].$el

    if (!element) return
    scrollToElement(element, { offset })
  } catch (err) {
    this.$error(err)
  }
}

/**
 * Are all child inputs valid
 * @returns {Boolean}
 */
function isFormValid() {
  const result = this._formItems.every(vNode => {
    if (!vNode.$v) {
      this.$log.warn(
        `${vNode._name} is added to validation but has no validator defined`
      )
      return false
    }
    vNode.$v.$touch()
    const isInvalid = vNode.$v.$invalid

    return !isInvalid
  })

  return result
}

/**
 * Get all invalid inputs
 * @returns {vNode[]}
 */
function getInvalidInputs() {
  return this._formItems.filter(vNode => vNode.$v && vNode.$v.$invalid)
}

export default {
  install(Vue) {
    Vue.use(Vuelidate)
    Vue.use(vuelidateErrorExtractor)

    // Add mixin for adding validation to parent
    Vue.mixin({
      // If this element contains options for validation
      // Add references to this element to all parents
      beforeCreate() {
        const { validations } = this.$options

        if (!validations) return

        const addToParent = vNode => {
          if (vNode._formItems) {
            vNode._formItems.push(this)
          }
          if (!vNode.$parent) return

          addToParent(vNode.$parent)
        }

        addToParent(this)
      },
      // Remove all created references before destroy
      beforeDestroy() {
        if (!this.$options.validations) return
        const removeFromParent = vNode => {
          if (vNode._formItems) {
            vNode._formItems = vNode._formItems.filter(x => x !== this)
          }
          if (!vNode.$parent) return

          removeFromParent(vNode.$parent)
        }
        removeFromParent(this)
      },

      // Add custom validation functions to all object
      created() {
        this._formItems = []

        if (!this.$cling) {
          this.$cling = {}
        }

        this.$cling.isFormValid = isFormValid.bind(this)
        this.$cling.getInvalidInputs = getInvalidInputs.bind(this)
        this.$cling.scrollToInvalidInput = scrollToInvalidChild.bind(this)
      }
    })
  }
}

import Vue from 'vue'
import { updateField } from 'vuex-map-fields'

import { mutationTypes } from './constants'
import {
  deepMergeReplace,
  convertArrToObject,
  getUniqueArrayElements
} from '../../utils'

const {
  SET_ARTICLES,
  DELETE_ARTICLE,
  CLEAR_ARTICLES,
  SET_ARTICLES_FETCHING,
  SET_ARTICLES_POSTING
} = mutationTypes

export default {
  updateField,

  /**
   * @name SET_ARTICLES
   * Add articles to store by overwriting old data with new.
   * Will not remove old articles
   *
   * @param {Object} state Vuex Store Props
   * @param {Object} payload
   * @param {Object|Array} payload.data Article or array of articles
   *
   * @returns {Void}
   */
  [SET_ARTICLES](state, { data }) {
    if (typeof data !== 'object')
      throw Error('Data must be an article or array of articles!')
    const articles = Array.isArray(data) ? data : [data]

    const { ids, values } = convertArrToObject(articles)

    deepMergeReplace(state.data, values)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  // Delete one
  [DELETE_ARTICLE](state, id = '') {
    Vue.delete(state.data, id)

    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_ARTICLES
   * Clear all articles
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_ARTICLES](state) {
    Vue.set(state, 'data', {})
    Vue.set(state, 'ids', [])
  },

  /**
   * @name SET_ARTICLES_FETCHING
   * Set fetching status of articles
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_ARTICLES_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_ARTICLES_POSTING
   * Set posting status of articles
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_ARTICLES_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

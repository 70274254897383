import socket from '@cling/api/socket'
import { global } from '@cling/store/action-types'

// This Vuex plugin is used to load notifications if needed when a socket is connected
// Needed if a client is disconnected from the socket due to inactivity, and when the socket is established
// there is a risk that last notifications are not loaded.

export default store => {
  socket.onConnectionChanged(isConnected => {
    if (!store.getters['application/user']) return // No authenticated user
    if (!isConnected) return
    if (store.getters['notifications/isFetching']) return

    // Fetch notifications (but not everyone at once as most users reconnect on socket at the same time after server restart)
    setTimeout(
      () => {
        store.dispatch(global.LOAD_UNREAD_NOTIFICATIONS, { throttleTime: 30 })
      },
      Math.floor(Math.random() * 60000)
    ) // Random between now and 60 sec
  })
}

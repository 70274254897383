export const actionTypes = {
  DO_LOAD_CURRENT_COMPANY: 'DO_LOAD_CURRENT_COMPANY',
  DO_UPDATE_CURRENT_COMPANY: 'DO_UPDATE_CURRENT_COMPANY',
  DO_NORMALIZE_COMPANIES: 'DO_NORMALIZE_COMPANIES',
  FORM_EDIT_COMPANY: 'FORM_EDIT_COMPANY',
  FORM_SUBMIT_COMPANY: 'FORM_SUBMIT_COMPANY',
  FORM_NEW_COMPANY_BRANDING: 'FORM_NEW_COMPANY_BRANDING',
  FORM_EDIT_COMPANY_BRANDING: 'FORM_EDIT_COMPANY_BRANDING',
  FORM_SUBMIT_COMPANY_BRANDING: 'FORM_SUBMIT_COMPANY_BRANDING',
  UPDATE_COMPANY_ACCOUNT_TYPE: 'UPDATE_COMPANY_ACCOUNT_TYPE'
}

export const mutationTypes = {
  SET_COMPANIES: 'SET_COMPANIES',
  CLEAR_COMPANIES: 'CLEAR_COMPANIES',
  SET_COMPANIES_FETCHING: 'SET_COMPANIES_FETCHING',
  SET_COMPANIES_POSTING: 'SET_COMPANIES_POSTING'
}

import log from '@cling/services/logger'

import { normalize } from 'normalizr'

import { SET_AS_NORMALIZED_DATA } from './constants'

import mutationTypes from '../mutation-types'
import { normalize as newNormalize } from '../utils'

const moduleKeys = {
  projectFiles: 'projects',
  certificates: 'certificates',
  companies: 'companies',
  companyUsers: 'companyUsers',
  projects: 'projects',
  companyReferences: 'companyReferences',
  companyReferenceItems: 'companyReferenceItems',
  files: 'files',
  documents: 'documents',
  documentTerms: 'documentTerms'
}

const setActions = {
  projects: mutationTypes.SET_PROJECTS,
  offers: mutationTypes.SET_OFFERS,
  companyUsers: mutationTypes.SET_COMPANY_USERS,
  companies: mutationTypes.companies.SET_COMPANIES,
  events: mutationTypes.SET_EVENTS,
  atas: mutationTypes.SET_ATAS,
  contracts: mutationTypes.SET_CONTRACTS,
  projectNotes: mutationTypes.SET_PROJECT_NOTES,
  projectFiles: mutationTypes.SET_PROJECT_FILES,
  articles: mutationTypes.SET_ARTICLES,
  certificates: mutationTypes.certificates.SET_CERTIFICATES,
  companyReferenceItems:
    mutationTypes.companyReferenceItems.SET_COMPANY_REFERENCE_ITEMS,
  companyReferences: mutationTypes.companyReferences.SET_COMPANY_REFERENCES,
  files: mutationTypes.files.UPDATE_MANY_FILES,
  notifications: mutationTypes.SET_NOTIFICATIONS,
  templateMessages: mutationTypes.SET_TEMPLATE_MESSAGES,
  documentTerms: mutationTypes.documentTerms.SET_DOCUMENT_TERMS
}

export default {
  /**
   * Receives unorganized data, normalize it and set it to
   * each module in the store.
   */
  [SET_AS_NORMALIZED_DATA](store, options) {
    // Set default options
    const opts = {
      includedModules: undefined,
      excludedModules: undefined,
      schema: undefined, // Required
      data: undefined, // Required
      moduleOpts: {}, // options for each module
      ...options
    }

    if (!opts.schema || !opts.data)
      throw Error('Unable to normalize data, missing schema or data')

    // Convert data to array
    const rawData = Array.isArray(opts.data) ? opts.data : [opts.data]

    // Normalize data

    // Temp dual normalize fix
    let data
    if (typeof opts.schema === 'string') {
      // use new normalize
      // eslint-disable-next-line no-extra-semi
      ;({ entities: data } = newNormalize(rawData, opts.schema))
    } else {
      // eslint-disable-next-line no-extra-semi
      ;({ entities: data } = normalize(rawData, opts.schema))
    }

    // Create list of keys
    let entities = Object.keys(data)

    // remove unwanted keys
    entities = entities.filter(
      m =>
        (!opts.includedModules || opts.includedModules.includes(m)) &&
        (!opts.excludedModules || !opts.excludedModules.includes(m))
    )

    // Commit data to each module
    entities.forEach(moduleKey => {
      if (!setActions[moduleKey]) {
        log.warn(`setActions: '${moduleKey}', does not exist`)
        return
      }
      if (!moduleKeys[moduleKey]) {
        log.warn(`moduleKeys: '${moduleKey}', does not exist`)
        return
      }
      const moduleOpts = {
        doClearOld: false,
        ...(opts.moduleOpts[moduleKey] || {})
      }

      this.commit(`${moduleKeys[moduleKey]}/${setActions[moduleKey]}`, {
        data: Object.keys(data[moduleKey]).map(key => data[moduleKey][key]),
        ...moduleOpts
      })
    })
  }
}

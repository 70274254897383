import globalMutationTypes from '@cling/store/mutation-types'
import { addArticleTotalAmount } from '@cling/utils'

import { actionTypes } from '../constants'

const { SET_FORM_VALUE, REMOVE_FORM_VALUE } = globalMutationTypes.forms

export default {
  async [actionTypes.SET_FORM_ARTICLE]({ commit }, { index = null, data }) {
    let keyPath = 'document.ModuleArticles'
    if (index !== null) keyPath = `document.ModuleArticles[${index}]`
    const dataWithTotalAmount = addArticleTotalAmount(data)
    return commit(SET_FORM_VALUE, { key: keyPath, value: dataWithTotalAmount })
  },
  async [actionTypes.REMOVE_FORM_ARTICLE]({ commit }, key) {
    return commit(REMOVE_FORM_VALUE, key)
  }
}

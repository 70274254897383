import features from '@cling/services/features'

export default {
  install(Vue, { store } = {}) {
    features.init({ store })

    Vue.prototype.$features = features
    Vue.prototype.$feature = features.checkFeature.bind(features)
  }
}

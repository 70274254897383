import { clearCache, setUseCache, invalidateCacheItem } from '@cling/api/cache'
import socket from '@cling/api/socket'

export default {
  install() {
    socket.on('event', ({ type, action, id }) => {
      invalidateCacheItem({ type, id })
      if (type === 'systemClient' && action === 'clearCache') clearCache()
      // TODO: Fortnox - When creating the analog version of this section. Remember that it should not reload the page if run in the widget context.
      if (type === 'systemClient' && action === 'reloadApp')
        window.reloadApp(true)
    })

    socket.onConnectionChanged(isConnected => {
      setUseCache(isConnected)
      if (isConnected) return

      clearCache() // If we lose connection to socket we cannot trust cached data
    })
  }
}

import config from '@cling/config'
import lang from '@cling/language'
import { capitalize } from '@cling/utils'
import webStorage from '@cling/utils/webStorage'

import account from '@/router/account'
import shortLink from '@/router/shortLink'
import { appAuthGuards } from '@/services/integrations'

const ForgottenPassword = () => import('@/views/ForgottenPassword.vue')
const Login = () => import('@/views/Login.vue')
const Logout = () => import('@/views/Logout.vue')
const NewPassword = () => import('@/views/NewPassword.vue')
const NotFound = () => import('@/views/NotFound.vue')
const Register = () => import('@/views/Register.vue')
const RegisterDemo = () => import('@/views/RegisterDemo.vue')
const RegisterDeal = () => import('@/views/RegisterDeal.vue')
const RegisterExisting = () => import('@/views/RegisterExisting.vue')
const Unsubscribe = () => import('@/views/Unsubscribe.vue')

function redirectAuthToAccount(to, from, next) {
  // If user is already logged in, send to account
  const token = webStorage.getItem('token')
  if (token) {
    return next({ name: 'account' })
  }
  return next()
}

function logout(to, from, next) {
  webStorage.removeItem('token')
  return next()
}

const routes = {
  '/': { path: '/', redirect: { name: 'Login' } },
  '/sh/:publicId': shortLink, // Handle shortLink routes
  '/a': account,
  '/konto': { redirect: '/a' }, // Supporting old bookmarks + email redirects etc
  '/login': {
    name: 'Login',
    component: Login,
    beforeEnter: redirectAuthToAccount,
    alias: '/loggain'
  },
  '/logout': { name: 'Logout', component: Logout, alias: '/loggaut' },
  '/register/user/:token': {
    name: 'registerExisting',
    component: RegisterExisting,
    alias: '/registrera/anvandare/:token'
  },
  '/register': {
    name: 'register',
    component: Register,
    beforeEnter: redirectAuthToAccount,
    alias: '/registrera'
  },
  '/registreraDemo': {
    name: 'registerDemo',
    component: RegisterDemo,
    beforeEnter: logout
  },
  '/registreraDeal': {
    name: 'registerDeal',
    component: RegisterDeal,
    beforeEnter: logout
  },
  '/reset-password/new/:token': {
    name: 'newPassword',
    component: NewPassword,
    beforeEnter: redirectAuthToAccount,
    alias: '/glomt-losenord/nytt/:token'
  },
  '/reset-password': {
    name: 'forgottenPassword',
    component: ForgottenPassword,
    beforeEnter: redirectAuthToAccount,
    alias: '/glomt-losenord'
  },
  '/unsubscribe': {
    name: 'unsubscribe',
    component: Unsubscribe,
    alias: '/avregistrera'
  },
  '/createDocument': {
    name: 'DocumentFormPublic',
    component: () =>
      import('@/views/account/create/document/DocumentFormPublic.vue'),
    props: { content: true },
    beforeEnter: (to, from, next) => {
      if (webStorage.getItem('token')) {
        return to && to.query && to.query.publicDocumentId
          ? next({ name: 'documentForm', query: to.query })
          : next({ name: 'account' })
      }
      return next()
    },
    meta: {
      title: lang.t('create', { thing: lang.t('document').toLowerCase() }),
      navbar: {
        title: lang.t('create', { thing: lang.t('document').toLowerCase() })
      }
    }
  },
  '/gallery': {
    component: () => import('@/views/templateGallery/index.vue'),
    props: {
      model: true,
      content: true
    },
    meta: {
      body: {
        nativeScroll: true
      }
    }
  },
  '/template/:id': {
    name: 'template',
    props: true,
    component: () => import('@/views/publicTemplate/Index.vue')
  },
  '/document/:id': {
    alias: ['/dokument/:id', '/kund/:id'], // Legacy routes for old documents
    component: () => import('@/views/publicDocument/Index.vue'),
    name: 'publicDocument',
    props: true,
    children: [
      {
        path: 'property-designation',
        alias: 'fastighetsbeteckning',
        name: 'docPropertyDesignation'
      }
    ]
  },
  '/document/:id/pdf': {
    name: 'publicDocumentPdf',
    component: () => import('@/views/publicDocument/Pdf.vue')
  },
  '/verify': {
    name: 'verify',
    component: () => import('@/views/Verify.vue'),
    meta: {
      title: capitalize(
        lang.t('verify', { thing: lang.t('document').toLowerCase() })
      )
    }
  },
  // Install flow external Apps (separate from chrome extension)
  '/auth/:app?': {
    component: { template: '<router-view/>' },
    meta: { fullscreen: true },
    beforeEnter: appAuthGuards.beforeAll,
    children: [
      {
        path: '',
        name: 'AppAuth',
        component: () =>
          import(
            /* webpackChunkName: "integrations" */ '@/views/appAuth/SignOn.vue'
          ),
        props: true,
        meta: { type: 'login' },
        beforeEnter: appAuthGuards.beforeAuth
      },
      {
        path: 'user',
        name: 'AppUserLogin',
        component: () =>
          import(
            /* webpackChunkName: "integrations" */ '@/views/appAuth/SignOn.vue'
          ),
        props: route => ({ app: route.params.app, mode: 'login' }),
        meta: { type: 'login' }
      },
      {
        path: 'callback',
        name: 'AppInstall',
        component: () =>
          import(
            /* webpackChunkName: "integrations" */ '@/views/appAuth/Install.vue'
          ),
        props: true,
        beforeEnter: appAuthGuards.beforeInstall
      }
    ]
  },
  '/partner/connect': {
    name: 'AuthPartnerConnect',
    component: () => import('@/views/partner/PartnerConnect.vue')
  },
  '/me': {
    name: 'clientView',
    component: () => import('@/views/client/Index.vue')
  },
  '/me/login': {
    name: 'clientLogin',
    component: () => import('@/views/client/Login.vue')
  },
  '/purchase/success': {
    name: 'purchaseSuccess',
    component: () => import('@/views/PurchaseSuccess.vue')
  },
  '/404': { name: 'notFound', component: NotFound },
  ...(config.environment === 'development' && {
    '/components': {
      name: 'components',
      component: () => import('@/views/dev/Components.vue')
    },
    '/textEditor': {
      name: 'textEditor',
      component: () => import('@/views/dev/TextEditors.vue')
    }
  }),
  '*': { redirect: '/404' } // Redirect unavailable pages to 404
}

// Convert to route object and export
export default Object.keys(routes).map(route => ({
  ...routes[route],
  path: route
}))

export const actionTypes = {
  SET_FORM_ARTICLE: 'SET_FORM_ARTICLE',
  REMOVE_FORM_ARTICLE: 'REMOVE_FORM_ARTICLE'
}

export const mutationTypes = {}

export default {
  actionTypes,
  mutationTypes
}

import config from '@cling/config'

export const PREVIEW_ATTRIBUTE_NAME = 'data-is-preview'

/**
 * API keys managed on Google Cloud Services
 * Project Id: cling-246410
 * Restricted to Web Fonts Developer API
 * Production key restricted to http-referrer '*.cling.se/*' and '*.docspo.com/*'
 */

export const GOOGLE_FONTS_API_KEY = config.google.fontsKey
export const GOOGLE_FONTS_LIST_URL =
  'https://www.googleapis.com/webfonts/v1/webfonts'
export const GOOGLE_FONTS_CSS_URL = 'https://fonts.googleapis.com/css'

// Helper to map 'variant' values from google fonts
// ref: https://developers.google.com/fonts/docs/developer_api
export const variantsMap = {
  100: { label: 'Thin', value: '100' },
  200: { label: 'Extra Light', value: '200' },
  300: { label: 'Light', value: '300' },
  regular: { label: 'Normal', value: '400' },
  500: { label: 'Medium', value: '500' },
  600: { label: 'Semi Bold', value: '600' },
  700: { label: 'Bold', value: '700' },
  800: { label: 'Extra Bold', value: '800' },
  900: { label: 'Black', value: '900' }
}

import { generateInstance } from '@cling/api/axios'
import { cacheResolver } from '@cling/api/cache'
import appConfig from '@cling/config'
import { buildQueryString } from '@cling/utils'
import webStorage from '@cling/utils/webStorage'

import axios from 'axios'

// This file exports helper function to communicate with the ClingAPI
const authData = {
  withCredentials: true,
  baseUrl: appConfig.api.baseUrl
}

// Create the Cling API instance
export const {
  get,
  post,
  put,
  patch,
  del,
  instance,
  renewToken: doRefreshToken
} = generateInstance({
  withCredentials: authData.withCredentials,
  baseUrl: authData.baseUrl,
  cache: cacheResolver
})

export async function getProjects(filter) {
  const query = buildQueryString(filter)
  return get(`/project${query}`, { saveCache: true })
}
export const postProject = async (body, params = {}) =>
  get('/project', body, { params })
export const getProject = async (id, params = {}) =>
  get(`/project/${id}`, { params, saveCache: true })
export const putProject = async (id, body, params = {}) =>
  put(`/project/${id}`, body, {
    params,
    invalidateCache: { type: 'project', id }
  })
export const patchProject = async id => patch(`/project/${id}`)
export const deleteProject = async (id, params = {}) =>
  del(`/project/${id}`, { params, invalidateCache: { type: 'project', id } })
export const getRawProject = async (params = {}) => get('/project', { params })

export const getSectorDemoProject = async sectorKey =>
  get(`/project/sectorDemo/${sectorKey}`)
export const createDemoProject = async () => get('/project/createDemo')

export const sendDocument = async (
  type,
  id,
  { sendTypes = [], deliveryType = 'first' } = {}
) => {
  let path = `${type}/${id}/send`
  const query = buildQueryString(
    {
      types: sendTypes,
      deliveryType
    },
    { prefix: '' }
  )

  if (query) {
    path += `/${query}`
  }
  return get(path)
}

export const getAPIStatus = () => get('/')

// Auth
export const loginAuthToken = async (authToken, body = {}) =>
  post('/auth/actionlink', { token: authToken, ...body })
export const register = async body => post('/register', body)
export const resetPassword = async email =>
  post('/companyUser/forgottenPassword/request', { username: email })
export const setNewPassword = async (password, token) =>
  post('/companyUser/forgottenPassword/new', {
    password,
    resetPasswordToken: token
  })

// Consent
export const storePublicConsent = async body => post('/public/consent', body)
export const storeConsent = async body => post('/consent', body)
export const getConsents = async (params = {}) => get('/consent', { params })

// ProjectFiles
export const getProjectFile = async id => get(`/projectFile/${id}`)
export const postProjectFile = async body => post('/projectFile', body)
export const putProjectFile = async (id, body) =>
  put(`/projectFile/${id}`, body)
export const deleteProjectFile = async id => del(`/projectFile/${id}`)
export const patchProjectFile = async id => patch(`/projectFile/${id}`)

// ProjectNotes
export const getProjectNote = async (id, params = {}) =>
  get(`/projectNote/${id}`, { params })
export const postProjectNote = async (body, params = {}) =>
  post('/projectNote', body, { params })
export const putProjectNote = async (id, body, params = {}) =>
  put(`/projectNote/${id}`, body, { params })
export const appendProjectNote = async (projectId, text, params = {}) =>
  put(`/project/${projectId}/projectNote/append`, { text }, { params })
export const deleteProjectNote = async (id, params = {}) =>
  del(`/projectNote/${id}`, { params })

export const getProjectCount = async (params = {}) =>
  get('/project/counts', { params, saveCache: true })

// File
export const getFile = async id => get(`/file/${id}`)
export const postFile = async body => post('/file', body)
export const putFile = async (id, body) => put(`/file/${id}`, body)
export const deleteFile = async id => del(`/file/${id}`)
export const patchFile = async id => patch(`/file/${id}`)

// Article
export const getAllArticles = async (params = {}) => get('/article', { params })
export const getArticle = async id => get(`/article/${id}`)
export const postArticle = async body => post('/article', body)
export const putArticle = async (id, body) => put(`/article/${id}`, body)
export const deleteArticle = async id => del(`/article/${id}`)
export const patchArticle = async id => patch(`/article/${id}`)
export const searchArticle = async query =>
  post('/search', {
    type: 'article',
    query
  })

export const unitType = {
  get: async (id, params = {}) =>
    get(id ? `/unitType/${id}` : '/unitType', { params, saveCache: true }),
  post: async (body, params = {}) => post('/unitType', body, { params }),
  put: async (id, body, params = {}) =>
    put(`/unitType/${id}`, body, {
      params,
      invalidateCache: { type: 'unitType', id }
    }),
  delete: async (id, params = {}) =>
    del(`/unitType/${id}`, {
      params,
      invalidateCache: { type: 'unitType', id }
    })
}

// CompanyReference
export const getCompanyReferences = async (params = {}) =>
  get('/companyReference', { params })
export const getCompanyReference = async (id, params = {}) =>
  get(`/companyReference/${id}`, { params })
export const postCompanyReference = async (body, params = {}) =>
  post('/companyReference', body, { params })
export const putCompanyReference = async (id, body, params = {}) =>
  put(`/companyReference/${id}`, body, { params })
export const deleteCompanyReference = async (id, params = {}) =>
  del(`/companyReference/${id}`, { params })
export const patchCompanyReference = async (id, params = {}) =>
  patch(`/companyReference/${id}`, { params })

// CompanyReferenceItem
export const getCompanyReferenceItems = async (params = {}) =>
  get('/companyReferenceItem', { params })
export const getCompanyReferenceItem = async (id, params = {}) =>
  get(`/companyReferenceItem/${id}`, { params })
export const postCompanyReferenceItem = async (body, params = {}) =>
  post('/companyReferenceItem', body, { params })
export const putCompanyReferenceItem = async (id, body, params = {}) =>
  put(`/companyReferenceItem/${id}`, body, { params })
export const deleteCompanyReferenceItem = async (id, params = {}) =>
  del(`/companyReferenceItem/${id}`, { params })

// Certificates
export const getCertificates = async (params = {}) =>
  get('/certificate', { params })
export const postCompanyCertificates = async (body, params = {}) =>
  post('/companyCertificate', body, { params })

// TemplateMessage
export const getAllTemplates = async (params = {}) =>
  get('/templateMessage', { params, saveCache: true })
export const getTemplate = async (id, params = {}) =>
  get(`/templateMessage/${id}`, { params, saveCache: true })
export const postTemplate = async (body, params = {}) =>
  post('/templateMessage', body, { params })
export const putTemplate = async (id, body, params = {}) =>
  put(`/templateMessage/${id}`, body, {
    params,
    invalidateCache: { type: 'templateMessage', id }
  })
export const deleteTemplate = async (id, params = {}) =>
  del(`/templateMessage/${id}`, {
    params,
    invalidateCache: { type: 'templateMessage', id }
  })
export const patchTemplate = async (id, params = {}) =>
  patch(`/templateMessage/${id}`, { params })

// EndCustomer
export const getEndCustomers = async (params = {}) =>
  get('/endCustomer', { params })
export const getEndCustomer = async (id, params = {}) =>
  get(`/endCustomer/${id}`, { params })
export const postEndCustomer = async (body, params = {}) =>
  post('/endCustomer', body, { params })
export const putEndCustomer = async (id, body, params = {}) =>
  put(`/endCustomer/${id}`, body, { params })
export const deleteEndCustomer = async (id, params = {}) =>
  del(`/endCustomer/${id}`, { params })
export const patchEndCustomer = async (id, params = {}) =>
  patch(`/endCustomer/${id}`, { params })
export const searchEndCustomer = async query =>
  post('/search', {
    type: 'endCustomer',
    query
  })

// CompanyUser
export const getCompanyUsers = async (params = {}) =>
  get('/companyUser', { params, saveCache: true })
export const getCompanyUser = async (id, params = {}) =>
  get(`/companyUser/${id}`, { params, saveCache: true })
export const postCompanyUser = async (body, params = {}) =>
  post('/companyUser', body, { params })
export const putCompanyUser = async (id, body, params = {}) =>
  put(`/companyUser/${id}`, body, {
    params,
    invalidateCache: { type: 'companyUser', id }
  })
export const deleteCompanyUser = async (id, params = {}) =>
  del(`/companyUser/${id}`, {
    params,
    invalidateCache: { type: 'companyUser', id }
  })
export const patchCompanyUser = async (id, params = {}) =>
  patch(`/companyUser/${id}`, { params })

export const companyUser = {
  getPublic: async (id, params = {}) =>
    get(`/public/companyUser/${id}`, { params }),
  getConnectedUsers: async (params = {}) =>
    get('/companyUser/self/connectedUsers', { params }),
  getConnectedUserAuth: async (id, params = {}) =>
    get(`/companyUser/self/connectedUsers/${id}/auth`, { params }),
  setConnectedUserDefault: async (id, params = {}) =>
    put(`/companyUser/self/connectedUsers/${id}/setDefaultAuth`, { params }),
  verifyEmail: async (body, params = {}) =>
    post('/public/verifyEmail/companyUser', body, { params })
}

// CompanyFeature (employee route)
export const putCompanyFeature = async (body, params = {}) =>
  put('/companyFeature', body, { params })

// CompanyAccount
export const getAccountOverview = async (params = {}) =>
  get('/companyAccount/overview', { params })
export const putAccountType = async (accountType, body = null, params = {}) =>
  put(`/companyAccount/accountType/${accountType}`, body, { params })
export const getNumberOfManagers = async (params = {}) =>
  get('/companyAccount/getNumberOfManagers', { params })
export const addUserLicenseInvoice = async (params = {}) =>
  post('/companyAccount/addUserLicenseInvoice', {}, { params })
export const verifyPromotionCode = async (code, params = {}) =>
  get(`/promotionCode/${code}/canUse`, { params })
export const getActivePromotion = async (code, params = {}) =>
  get('/promotionCode/inUse', { params })

// Subscription
export const subscription = {
  product: async (body, params = {}) =>
    post('/companyAccount/product', body, { params }),
  cancelAll: async (params = {}) =>
    del('/companyAccount/subscription', { params }),
  getPrices: async (params = {}) => get('/companyAccount/price', { params }),
  getUpcomingInvoices: async (params = {}) =>
    get('/companyAccount/upcomingInvoice', { params }),
  getPaymentHistory: async (params = {}) =>
    get('/companyAccount/paymentHistory', { params }),
  putPaymentMethod: async (body, params = {}) =>
    put('/companyAccount/paymentMethod', body, { params }),
  putCustomer: async (body, params = {}) =>
    put('/companyAccount/customer', body, { params }),
  // getStripePortalURL: async (params = {}) => get('/companyAccount/customer/stripePortalUrl', { params }),
  createCheckoutSession: async (body, params = {}) =>
    post('/companyAccount/create-checkout-session', body, { params })
}

// Token
export const getCompanyUserToken = async (params = {}) =>
  get('/companyUserToken', { params })

// Notifications
export const getAllNotifications = async (params = {}) =>
  get('/notification', {
    params: {
      ...params,
      showAll: true
    }
  })
export const getUnreadNotifications = async (params = {}) =>
  get('/notification', { params })
export const getNotification = async (id, params = {}) =>
  get(`/notification/${id}`, { params })
export const setReadNotificationsForProject = async (projectId, params = {}) =>
  post(`/project/${projectId}/setReadNotifications`, { params })
export const setReadNotification = async (id, params = {}) =>
  put(`/notification/${id}`, { isRead: true }, { params })
export const setReadAllNotifications = async (params = {}) =>
  get('/notification/setAllRead', { params })
export const postNotification = async (body, params = {}) =>
  post('/notification', body, { params })

// ProjectMember
export const postProjectMember = async (projectId, companyUserId) =>
  post(`/project/${projectId}/projectMember/${companyUserId}`, null, {
    invalidateCache: { type: 'project', id: projectId }
  })
export const deleteProjectMember = async (projectId, companyUserId) =>
  del(`/project/${projectId}/projectMember/${companyUserId}`, {
    invalidateCache: { type: 'project', id: projectId }
  })

// Unsubscribe
export const unsubscribeByToken = async (token, params = {}) =>
  post('/unsubscribe', { token }, { params })
export const unsubscribeManually = async (email, type = 'all', params = {}) =>
  post('/unsubscribe', { manual: { email, type } }, { params })

// Sectors
export const getAllSectors = async (params = {}) => get('/sector', { params })
export const getCompanySectors = async (params = {}) =>
  get('/companySector', { params })
export const setCompanySectors = async (sectorIds, params = {}) =>
  post('/companySector', { sectorIds }, { params })

// Timeline
export const getTimelines = async (params = {}) => get('/timeline', { params })
export const getTimeline = async (id, params = {}) =>
  get(`/timeline/${id}`, { params })
export const postTimeline = async (body, params = {}) =>
  post('/timeline', body, { params })
export const putTimeline = async (id, body, params = {}) =>
  put(`/timeline/${id}`, body, { params })
export const deleteTimeline = async (id, params = {}) =>
  del(`/timeline/${id}`, { params })

export const timeline = {
  get: async (id, params = {}) => get(`/timeline/${id}`, { params }),
  getAll: async (params = {}) => get('/timeline', { params }),
  post: async (body, params = {}) => post('/timeline', body, { params }),
  put: async (id, body, params = {}) =>
    put(`/timeline/${id}`, body, { params }),
  delete: async (id, params = {}) => del(`/timeline/${id}`, { params }),
  setPositions: async (newIdList, params = {}) =>
    post('/timeline/setPositions', { ids: newIdList }, { params })
}

export const timelineMember = {
  post: (timelineId, companyUserId) =>
    post(`/timeline/${timelineId}/timelineMember/${companyUserId}`, {}),
  delete: (timelineId, companyUserId) =>
    del(`/timeline/${timelineId}/timelineMember/${companyUserId}`, {})
}

// ShortLinks
export const getShortLink = async (publicId, params = {}) =>
  get(`/shortLink/${publicId}`, { params })

// Dynamic documents
export const document = {
  get: async (id, params = {}) =>
    get(`/document/${id}`, { params, saveCache: true }),
  getAll: async (params = {}) => get('/document', { params, saveCache: true }),
  post: async (body, params = {}) => post('/document', body, { params }),
  send: async (id, body = {}, params = {}) =>
    post(`/document/${id}/send`, body, {
      params,
      invalidateCache: { type: 'document', id }
    }),
  clearAnswers: async (id, body = {}, params = {}) =>
    post(`/document/${id}/clearAnswers`, body, {
      params,
      invalidateCache: { type: 'document', id }
    }),
  put: async (id, body, params = {}) =>
    put(`/document/${id}`, body, {
      params,
      invalidateCache: { type: 'document', id }
    }),
  delete: async (id, params = {}) =>
    del(`/document/${id}`, {
      params,
      invalidateCache: { type: 'document', id }
    }),
  patch: async (id, params = {}) => patch(`/document/${id}`, { params }),
  validate: async (body, params = {}) =>
    post('/documentValidate', body, { params })
}

// Dynamic document templates
export const documentTemplate = {
  get: async (id, params = {}) =>
    get(id ? `/template/${id}` : '/template', { params }),
  post: async (body, params = {}) => post('/template', body, { params }),
  put: async (id, body, params = {}) =>
    put(`/template/${id}`, body, { params }),
  delete: async (id, params = {}) => del(`/template/${id}`, { params }),
  patch: async (id, params = {}) => patch(`/template/${id}`, { params })
}

export const templateGallery = {
  get: async (params = {}) => get('/templateGallery', { params })
}

export const templateStat = {
  get: async (templateId, params = {}) =>
    get(`/public/templateStat/${templateId}`, { params })
}

// Stats
export const documentStats = {
  get: async (params = {}) => get(`/documentStats`, { params })
}

// DocumentTerms
export const documentTerm = {
  get: async (id, params = {}) =>
    get(id ? `/documentTerm/${id}` : '/documentTerm', { params }),
  post: async (body, params = {}) => post('/documentTerm', body, { params }),
  put: async (id, body, params = {}) =>
    put(`/documentTerm/${id}`, body, { params }),
  delete: async (id, params = {}) => del(`/documentTerm/${id}`, { params }),
  patch: async (id, params = {}) => patch(`/documentTerm/${id}`, { params })
}

export const publicDocument = {
  get: async (id, params = {}) => get(`/public/document/${id}`, { params }),
  put: async (id, data, headers = {}) =>
    put(`/public/document/${id}`, data, { headers }),
  answer: async (id, body, params = {}, headers = {}) =>
    post(`/public/document/${id}/answer`, body, { params, headers }),
  feedback: async (id, body, params = {}) =>
    post(`/public/document/${id}/feedback`, body, { params })
}

export const publicTemplate = {
  get: async (id, params = {}) => get(`/public/template/${id}`, { params })
}

export const pdfJob = {
  get: async (id, params = {}) => get(`/public/pdfJob/${id}`, { params }),
  addDocument: async (publicId, params = {}) =>
    get(`/public/document/${publicId}/pdfJob/start`, { params })
}

export const client = {
  getDocuments: async (params = {}) => {
    const Authorization = webStorage.getItem('clientToken')
    return axios.get(`${authData.baseUrl}/client/document`, {
      headers: { Authorization },
      params
    })
  },
  requestMagicLink: async (body, params = {}) =>
    axios.post(`${authData.baseUrl}/client/requestMagicLink`, body, { params })
}

export const publicCompany = {
  get: async (id, params = {}) => get(`/public/company/${id}`, { params }),
  getCertificates: async (id, params = {}) =>
    get(`/public/company/${id}/certificates`, { params }),
  getReferences: async (id, params = {}) =>
    get(`/public/company/${id}/references`, { params })
}

export const publicSignature = {
  get: async (id, params = {}) =>
    get(`${authData.baseUrl}/public/signature/${id}`, { params })
}

export const companyApiKey = {
  get: async (id, params = {}) =>
    get(id ? `/companyApiKey/${id}` : '/companyApiKey', { params }),
  post: async (body, params = {}) => post('/companyApiKey', body, { params }),
  put: async (id, body, params = {}) =>
    put(`/companyApiKey/${id}`, body, { params }),
  delete: async (id, params = {}) => del(`/companyApiKey/${id}`, { params }),
  patch: async (id, params = {}) => patch(`/companyApiKey/${id}`, { params })
}

export const publicPartner = {
  getTrustedUrls: async (partnerName, params = {}) =>
    get(`/public/partner/trustedUrls/${partnerName}`, { params })
}

export const companyPartner = {
  createConnectToken: async (body, params = {}) =>
    post('/companyPartner/connectToken', body, { params })
}

// ? Deprecated for now ?
// Review
// export const review = ({
//   getAll: async (params = {}) => get('/review', { params }),
//   get: async (id, params = {}) => get(`/review/${id}`, { params }),
//   put: async (id, body, params = {}) => put(`/review/${id}`, body, { params }),
//   delete: async (id, params = {}) => del(`/review/${id}`, { params }),
//   patch: async (id, params = {}) => patch(`/review/${id}`, { params }),
// });

// ? Deprecated for now ?
// ReviewRequest
// export const reviewRequest = ({
//   getAll: async (id, params = {}) => get('/reviewRequest', { params }),
//   get: async (id, params = {}) => get(`/reviewRequest/${id}`, { params }),
//   post: async (body, params = {}) => post('/reviewRequest', body, { params }),
//   put: async (id, body, params = {}) => put(`/reviewRequest/${id}`, body, { params }),
//   send: async (id, params = {}) => get(`/reviewRequest/${id}/send`, { params }),
// });

export const voucher = {
  post: async (body, params = {}) => post('/voucher', body, { params }),
  canUse: async (code, params = {}) =>
    get(`/voucher/${code}/canUse`, { params })
}
export const extensionApi = {
  getAuthUrl: async (service, params = {}) =>
    get('/extension/authUrl', { params: { ...params, service } }),
  getPublicAuthUrl: async (service, params = {}) =>
    get('/public/extension/authUrl', { params: { ...params, service } }),
  install: async (service, body, params = {}) =>
    post('/extension/install', body, { params: { ...params, service } }),
  uninstall: async (service, params = {}) =>
    del('/extension/uninstall', { params: { ...params, service } }),
  suggestClients: async (service, params = {}) =>
    get('/extension/suggestClients', {
      params: { ...params, service },
      saveCache: true,
      cacheTtl: 20
    }),
  signupWithCrm: async (service, body, params = {}) =>
    post('/public/extension/register', body, {
      params: { ...params, service }
    }),
  search: async (service, params = {}) =>
    get('/extension/search', {
      params: { ...params, service },
      saveCache: true,
      cacheTtl: 10
    }),
  getFields: async (service, params = {}) =>
    get('/extension/fields', {
      params: { ...params, service },
      saveCache: true,
      cacheTtl: 60
    }),
  getExternalReferenceDetails: async (service, params = {}) =>
    get('/extension/externalReferenceDetails', {
      params: { ...params, service },
      saveCache: true,
      cacheTtl: 20
    }),
  settings: async (service, params = {}) =>
    get('/extension/settings', { params: { ...params, service } })
}

export const emailDomain = {
  getAll: async (id, params = {}) => get('/companyEmailDomain', { params }),
  get: async (id, params = {}) => get(`/companyEmailDomain/${id}`, { params }),
  syncAndGet: async (id, params = {}) =>
    get(`/companyEmailDomain/${id}/sync`, { params }),
  post: async (body, params = {}) =>
    post('/companyEmailDomain', body, { params }),
  put: async (id, body, params = {}) =>
    put(`/companyEmailDomain/${id}`, body, { params }),
  delete: async (id, params = {}) =>
    del(`/companyEmailDomain/${id}`, { params })
}

export const fortnoxApi = {
  getAuthUrl: async (params = {}) =>
    get('/fortnox/authUrl', { params: { ...params } }),
  install: async (body, params = {}) =>
    post('/fortnox/install', body, { params: { ...params } }),
  uninstall: async (params = {}) =>
    del('/fortnox/uninstall', { params: { ...params } }),
  searchCustomers: async (search, params = {}) =>
    get('/fortnox/customer', { params: { ...params, search } }),
  createInvoice: async (body, params = {}) =>
    post('/fortnox/invoice', body, { params }),
  createTaxReduction: async (body, params = {}) =>
    post('/fortnox/taxReduction', body, { params })
}

export const slackRoutes = {
  feedback: async (body, params = {}) =>
    post('/slack/feedback', body, { params }),
  support: async (body, params = {}) => post('/slack/support', body, { params })
}

export default {
  get,
  post,
  put,
  del,
  patch,
  authData, // allow other components to access if needed
  getProjects
}

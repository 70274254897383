import { createPinia, PiniaVuePlugin } from 'pinia'

// TODO: [vue3] No longer needed in Vue 3
export const installPinia = app => {
  app.use(PiniaVuePlugin)
}

const pinia = createPinia()

export default pinia

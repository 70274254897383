<template>
  <v-popover
    ref="popover"
    v-bind="$attrs"
    trigger="manual"
    :open.sync="open"
    :auto-hide="false"
    v-on="$listeners"
  >
    <slot />
    <template #popover>
      <div>
        <slot name="popover" />
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'ManualPopover',
  data() {
    return {
      open: false
    }
  },
  mounted() {
    const button = this.$slots.default?.[0]?.elm
    if (button) {
      button.addEventListener('click', this.onButtonClick)
    }
  },
  beforeDestroy() {
    const button = this.$slots.default?.[0]?.elm
    if (button) {
      button.removeEventListener('click', this.onButtonClick)
    }
    this.removeClickOutsideListener()
  },
  methods: {
    onButtonClick(event) {
      event.preventDefault()
      this.open = !this.open
      if (this.open) {
        this.addClickOutsideListener()
      } else {
        this.removeClickOutsideListener()
      }
    },
    onClickOutside(event) {
      const popover = this.$refs.popover.$el
      if (!this.$el.contains(event.target) && !popover.contains(event.target)) {
        this.open = false
        this.removeClickOutsideListener()
      }
    },
    addClickOutsideListener() {
      setTimeout(() => {
        this.getRootElm().addEventListener('click', this.onClickOutside)
      }, 0)
    },
    removeClickOutsideListener() {
      this.getRootElm().removeEventListener('click', this.onClickOutside)
    },
    getRootElm() {
      const rootNode = this.$el.getRootNode?.()
      const isShadowParent = rootNode?.toString() === '[object ShadowRoot]'
      return isShadowParent ? rootNode : document
    }
  }
}
</script>

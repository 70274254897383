/**
 * Import of: https://github.com/panter/vue-i18next/blob/master/src/i18n.js
 */
import { install, Vue } from './install'

export default class VueI18n {
  constructor(i18next, l10n, opts = {}) {
    const options = {
      bindI18n: 'languageChanged loaded',
      bindL10n: 'localeChanged',
      bindStore: 'added removed',
      loadComponentNamespace: false,
      ...opts
    }

    this._vm = null
    this.i18next = i18next
    this.l10n = l10n
    this.options = options

    const originalChangeLanguage = i18next.changeLanguage
    const originalChangeLocale = l10n.changeLocale

    this.i18next.changeLanguage = (language, force = false) => {
      if (!this.isDirty('language') || force) {
        if (force) this.setDirty('language')
        originalChangeLanguage.call(i18next, language)
      }
    }
    this.l10n.changeLocale = (locale, force = false) => {
      if (!this.isDirty('locale') || force) {
        if (force) this.setDirty('locale')
        originalChangeLocale.call(l10n, locale)
      }
    }

    this.onI18nChanged = this.onI18nChanged.bind(this)
    this.onL10nChanged = this.onL10nChanged.bind(this)

    if (options.bindI18n) {
      this.i18next.on(options.bindI18n, this.onI18nChanged)
    }
    if (options.bindStore && this.i18next.store) {
      this.i18next.store.on(options.bindStore, this.onI18nChanged)
    }
    if (options.bindL10n) {
      this.l10n.on(options.bindL10n, this.onL10nChanged)
    }

    this.resetVM({
      i18nLoadedAt: new Date(),
      lang: this.i18next.language,
      l10nLoadedAt: new Date(),
      locale: this.l10n.locale,
      dateFnsLocale: this.l10n.dateFnsLocale,
      dirty: []
    })
  }

  resetVM(data) {
    const oldVM = this._vm
    const { silent } = Vue.config
    Vue.config.silent = true
    this._vm = new Vue({ data })
    Vue.config.silent = silent
    if (oldVM) {
      Vue.nextTick(() => oldVM.$destroy())
    }
  }

  get i18nLoadedAt() {
    return this._vm.$data.i18nLoadedAt
  }
  set i18nLoadedAt(date) {
    this._vm.$set(this._vm, 'i18nLoadedAt', date)
  }

  get lang() {
    return this._vm.$data.lang
  }
  set lang(lang) {
    this._vm.$set(this._vm, 'lang', lang)
  }

  get l10nLoadedAt() {
    return this._vm.$data.l10nLoadedAt
  }
  set l10nLoadedAt(date) {
    this._vm.$set(this._vm, 'l10nLoadedAt', date)
  }

  // Global app locale
  get locale() {
    return this._vm.$data.locale
  }
  set locale(locale) {
    this._vm.$set(this._vm, 'locale', locale)
  }

  // Date-fns only
  get dateFnsLocale() {
    return this._vm.$data.dateFnsLocale
  }
  set dateFnsLocale(locale) {
    this._vm.$set(this._vm, 'dateFnsLocale', locale)
  }

  get dirty() {
    return this._vm.$data.dirty || []
  }
  set dirty(v) {
    // eslint-disable-next-line no-setter-return
    return this._vm.$set(this._vm, 'dirty', v)
  }
  isDirty(param) {
    return this.dirty.includes(param)
  }
  setDirty(v) {
    if (!this.dirty.includes(v)) this.dirty = [...this.dirty, v]
  }

  // Adding language override ensures reactivity when importing this class in various places
  t(key, options) {
    return this.i18next.t(key, { lng: this.lang, ...options })
  }

  tc(key, options) {
    const { count } = options || {}
    return typeof count === 'number'
      ? `${count} ${this.t(key, options)}`
      : this.t(key, options)
  }

  te(key, options) {
    return this.i18next.exists(key, { lng: this.lang, ...options })
  }

  formatDate(date, formatStr = 'P', locale = this.dateFnsLocale) {
    return this.l10n.formatDate(date, formatStr, locale)
  }

  /**
   * Format distance to now will to print a string like "4 minutes ago"
   * If only date is provided, current language is used to map locale as default for consistency.
   * Current supported fallback languages are sv + en
   * @param {String} date A date string to format
   * @param {Object} options Optional options obj to date-fns
   * @param {Boolean} options.addSuffix If "in" or "ago" string should be added
   * @param {String} locale Optional locale, fallbacks to current language
   * @return {String} Returns a string like "4 minutes ago" or empty string
   */
  formatDistanceToNow(date, options = { addSuffix: true }, localeParam) {
    let locale = localeParam
    if (!locale) locale = this.lang === 'en' ? 'en-US' : this.lang // map from language
    return this.l10n.formatDistanceToNow(date, options, locale)
  }

  onI18nChanged() {
    this.lang = this.i18next.language || 'en'
    this.i18nLoadedAt = new Date()
  }
  onL10nChanged() {
    this.locale = this.l10n.locale || 'en'
    this.dateFnsLocale = this.l10n.dateFnsLocale || 'en-US'
    this.l10nLoadedAt = new Date()
  }
}

VueI18n.install = install
// VueI18n.version = __VERSION__;

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueI18n)
}

import { deepMergeReplace, getUniqueArrayElements } from '@cling/store/utils'

import Vue from 'vue'

import { mutationTypes } from './constants'

const {
  SET_DOCUMENT_TERMS,
  DELETE_DOCUMENT_TERM,
  CLEAR_DOCUMENT_TERMS,
  SET_DOCUMENT_TERMS_FETCHING,
  SET_DOCUMENT_TERMS_POSTING
} = mutationTypes

export default {
  /**
   * Insert document terms into store
   * @param {Object} state
   * @param {Object} obj
   * @param {Object|Object[]} obj.data One document term or array of document terms
   */
  [SET_DOCUMENT_TERMS](state, { data }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a documentTerm or an array of document terms!!')
    }
    const items = Array.isArray(data) ? data : [data]
    const ids = []
    const itemObj = {}

    items.forEach(itm => {
      itemObj[itm.id] = itm
      ids.push(itm.id)
    })

    deepMergeReplace(state.data, itemObj)

    state.ids = getUniqueArrayElements([...ids, ...state.ids])
  },

  // Delete one
  [DELETE_DOCUMENT_TERM](state, id) {
    delete state.data[id]
    const index = state.ids.indexOf(id)
    if (index !== -1) {
      state.ids.splice(index, 1)
    }
  },

  /**
   * @name CLEAR_DOCUMENT_TERMS
   * Clear all document terms
   *
   * @param {Object} state Vuex state
   */
  [CLEAR_DOCUMENT_TERMS](state) {
    Vue.set(state, 'data', {})
    Vue.set(state, 'ids', [])
  },

  /**
   * @name SET_DOCUMENT_TERMS_FETCHING
   * Set fetching status of document terms
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isFetching New state of isFetching
   */
  [SET_DOCUMENT_TERMS_FETCHING](state, isFetching) {
    state.isFetching = isFetching
  },

  /**
   * @name SET_DOCUMENT_TERMS_POSTING
   * Set posting status of document terms
   *
   * @param {Object} state Vuex state
   * @param {Boolean} isPosting New state of isPosting
   */
  [SET_DOCUMENT_TERMS_POSTING](state, isPosting) {
    state.isPosting = isPosting
  }
}

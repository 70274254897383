import Vue from 'vue'

class WindowSize {
  constructor({ defaults = { width: 800, height: 600 }, delay = 50 } = {}) {
    this._width = defaults.width
    this._height = defaults.height
    this._delay = delay
    this._timer = null
    this._initialized = false
    this._handler = this._handleResize.bind(this)
  }

  _handleResize() {
    clearTimeout(this._timer)
    this._timer = setTimeout(() => {
      this.update()
    }, this._delay)
  }

  get width() {
    return this._width
  }

  get height() {
    return this._height
  }

  get initialized() {
    return this._initialized
  }

  update() {
    this._width = window.innerWidth
    this._height = window.innerHeight
  }

  setDelay(delay) {
    this._delay = delay
  }

  init() {
    if (typeof window === 'undefined') {
      return this
    }
    if (this.initialized) {
      return this
    }
    this.update()
    window.addEventListener('resize', this._handler, { passive: true })
    this._initialized = true
    return this
  }

  destroy() {
    if (!this.initialized) {
      return this
    }
    window.removeEventListener('resize', this._handler, { passive: true })
    this._initialized = false
    return this
  }
}

const windowSize = new WindowSize().init()
const vm = new Vue({ data: { windowSize } })

export default {
  computed: {
    windowWidth() {
      return vm.windowSize.width
    },

    windowHeight() {
      return vm.windowSize.height
    },

    mq() {
      const { width } = vm.windowSize
      const breakpoints = {
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536,
        '3xl': Infinity
      }

      let res = 'lg'

      Object.keys(breakpoints)
        .reverse()
        .forEach(key => {
          if (width <= breakpoints[key]) {
            res = key
          }
        })

      return res
    }
  }
}

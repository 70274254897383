<template>
  <SmartList
    v-slot="{ items }"
    :query="searchQuery"
    :items="Object.keys(currencies)"
    :default-sort="'asc'"
  >
    <CDropdown
      v-model="internalCurrency"
      v-bind="{
        position: 'is-bottom-left',
        ...$attrs,
        ...$props
      }"
      :size="$attrs.size"
      :disabled="$attrs.disabled"
      :menu-styling="{
        maxWidth: '300px'
      }"
      :scrollable="true"
      :max-height="200"
      class="control"
      @active-change="isCurrencyDropdownOpen = $event"
    >
      <template #trigger>
        <slot :selected-value="selectedValue">
          <CButton
            :dropdown-icon="true"
            class="is-left"
            raised
            style="width: 100%; text-align: left; min-width: 7em"
          >
            {{ selectedValue }}
          </CButton>
        </slot>
      </template>
      <template #header>
        <CInput
          ref="searchCurrencyInput"
          v-model="searchQuery"
          :placeholder="$t('_common:search')"
          filled
          style="margin-bottom: 0.25em"
        />
      </template>
      <CDropdownItem
        v-for="currency in items"
        :key="currency"
        :value="currency"
        class="truncate-text"
        style="padding-right: 0.5em"
      >
        <div style="margin-right: auto">{{ currency }}</div>
        <div style="opacity: 0.7">
          {{ getCurrencySymbol(currency) }}
        </div>
      </CDropdownItem>
    </CDropdown>
  </SmartList>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import CInput from '@cling/components/ui/Input'
import SmartList from '@cling/components/ui/SmartList'
import { currencies } from '@cling/static'
import { getCurrencySymbol } from '@cling/utils'

export default {
  name: 'CCurrencyDropdown',
  components: {
    CInput,
    CDropdown,
    CDropdownItem,
    SmartList
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currencies,
      isCurrencyDropdownOpen: false,
      searchQuery: ''
    }
  },
  computed: {
    internalCurrency: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    selectedValue() {
      if (!this.internalCurrency) return ''
      return getCurrencySymbol(this.internalCurrency)
    }
  },
  watch: {
    isCurrencyDropdownOpen(value) {
      this.$emit('active-change', value)

      if (value) {
        if (this.$refs.searchCurrencyInput)
          this.$refs.searchCurrencyInput.focus()
      } else this.searchQuery = '' // Clear search when closing the dropdown
    }
  },
  methods: {
    getCurrencySymbol
  }
}
</script>

<style lang="scss" scoped></style>

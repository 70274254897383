<template>
  <div
    style="
      min-height: 300px;
      padding: 2em;
      background-color: white;
      border-radius: 0.5em;
    "
  >
    <transition :css="false" @enter="enter" @leave="leave">
      <div v-if="view === 'start'" :key="1">
        <div class="modal-header" style="margin-bottom: 1.5rem">
          {{ $t('_common:propertyDesignation.propertyDesignation') }}
        </div>
        <div>
          <div
            class="c-flex-row c-align-middle c-align-justify alternative"
            @click="!isRequesting ? sendRequest() : null"
          >
            <div class="alternative-content">
              <div class="alternative-title">{{ $t('alt1Title') }}</div>
              <div class="alternative-text">
                {{ $t('alt1Text') }}
              </div>
            </div>
            <div class="alternative-circle">
              <Spinner v-if="isRequesting" :color="'white'" :size="'15px'" />
              <svg
                v-else
                name="send-icon"
                style="margin-left: 3px"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.08068 8.760855l-1.6054 4.516632c-.53498 1.505126.19155 2.148448 1.62119 1.441295l11.7529-5.813401c1.5628-.773015 1.55553-2.012419-.01057-2.77109L1.7516.27883C.31004-.419513-.37484.219643.21573 1.70345l1.84792 4.642877 7.54216 1.130038c.28937.043355.28865.113756 0 .157004L2.08068 8.760855z"
                  fill="#FFF"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div
            class="c-flex-row c-align-middle c-align-justify alternative"
            @click="showEditModal()"
          >
            <div class="alternative-content">
              <div class="alternative-title">{{ $t('alt2Title') }}</div>
              <div class="alternative-text">{{ $t('alt2Text') }}</div>
            </div>
            <div class="alternative-circle self-color">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  stroke="#FFF"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                >
                  <path
                    d="M9.451256 4.577059l2.828427 2.828427m-9.788227 3.29332l8.626483-8.626483c.570964-.570964 1.49625-.571394 2.074312.006668l1.690256 1.690256c.574647.574647.577482 1.503499.0001 2.08088l-1.841395 1.841395-6.77852 6.77852-4.377074.605838.605838-4.377074z"
                    stroke-width="2.05632"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M2.074074 15.527416l8.888889-.000009M13.037037 15.527416l1.777778-.000009"
                    stroke-width="1.17504"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="view === 'sent'" :key="2">
        <div
          class="c-flex-row c-align-center c-align-middle column sent-request-wrapper"
        >
          <svg
            name="house-icon"
            width="150"
            height="150"
            viewBox="0 0 616 616"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <circle id="acv" cx="308" cy="308" r="308" />
              <linearGradient
                id="ccv"
                x1="73526%"
                y1="28153%"
                x2="73526%"
                y2="8238%"
              >
                <stop stop-color="gray" stop-opacity=".25" offset="0%" />
                <stop stop-color="gray" stop-opacity=".12" offset="54%" />
                <stop stop-color="gray" stop-opacity=".1" offset="100%" />
              </linearGradient>
              <linearGradient
                id="d"
                x1="69892%"
                y1="3935%"
                x2="69892%"
                y2="444%"
              >
                <stop stop-color="gray" stop-opacity=".25" offset="0%" />
                <stop stop-color="gray" stop-opacity=".12" offset="54%" />
                <stop stop-color="gray" stop-opacity=".1" offset="100%" />
              </linearGradient>
              <linearGradient
                id="ecv"
                x1="64537%"
                y1="11585%"
                x2="64537%"
                y2="7425%"
              >
                <stop stop-color="gray" stop-opacity=".25" offset="0%" />
                <stop stop-color="gray" stop-opacity=".12" offset="54%" />
                <stop stop-color="gray" stop-opacity=".1" offset="100%" />
              </linearGradient>
              <linearGradient
                id="fcv"
                x1="82336%"
                y1="11681%"
                x2="82336%"
                y2="7473%"
              >
                <stop stop-color="gray" stop-opacity=".25" offset="0%" />
                <stop stop-color="gray" stop-opacity=".12" offset="54%" />
                <stop stop-color="gray" stop-opacity=".1" offset="100%" />
              </linearGradient>
              <linearGradient
                id="gcv"
                x1="73536%"
                y1="5473%"
                x2="73536%"
                y2="0%"
              >
                <stop stop-color="gray" stop-opacity=".25" offset="0%" />
                <stop stop-color="gray" stop-opacity=".12" offset="54%" />
                <stop stop-color="gray" stop-opacity=".1" offset="100%" />
              </linearGradient>
              <linearGradient
                id="hcv"
                x1="73430%"
                y1="28010%"
                x2="73430%"
                y2="4652%"
              >
                <stop stop-color="gray" stop-opacity=".25" offset="0%" />
                <stop stop-color="gray" stop-opacity=".12" offset="54%" />
                <stop stop-color="gray" stop-opacity=".1" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fill-rule="evenodd">
              <mask id="bcv" fill="#fff"><use xlink:href="#acv" /></mask>
              <use fill="#FFFFFF" xlink:href="#acv" />
              <g
                opacity=".7"
                mask="url(#bcv)"
                fill-rule="nonzero"
                fill="url(#ccv)"
              >
                <path
                  d="M554.94 631.06H70.1l3.34-361.74 138.66-36.56h196.06l143.44 25.9z"
                />
              </g>
              <path
                fill="#E6E8F0"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M548.44 626.16H77l3.36-352.62L212.76 237h197.98l134.34 25.88z"
              />
              <g
                opacity=".7"
                mask="url(#bcv)"
                fill-rule="nonzero"
                fill="url(#dcv)"
              >
                <path d="M222.14 76.88h35.38v69.82h-35.38z" />
              </g>
              <g
                opacity=".7"
                mask="url(#bcv)"
                fill-rule="nonzero"
                fill="url(#ecv)"
              >
                <path
                  d="M222.14 291.96V216.5H68.46l-26.1 75.46v7.74H223.1v-7.74z"
                />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M222.86 291.6H45L70.82 217h152.04z"
              />
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M222.86 291.6H45L70.82 217h152.04z"
              />
              <path
                fill="#03F"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M45 292h178.84v7.66H45z"
              />
              <g
                opacity=".7"
                mask="url(#bcv)"
                fill-rule="nonzero"
                fill="url(#fcv)"
              >
                <path d="M553.16 217.46H399.08v76.32h-.98v7.84h181.22v-7.84z" />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M399 293.58h177.88L551.06 219H399z"
              />
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M399 293.58h177.88L551.06 219H399z"
              />
              <path
                fill="#03F"
                fill-rule="nonzero"
                mask="url(#bcv)"
                transform="rotate(-180 487.42 297.83)"
                d="M398 294h178.84v7.66H398z"
              />
              <path
                fill="#03F"
                fill-rule="nonzero"
                opacity=".5"
                mask="url(#bcv)"
                d="M92 496h96.58v60.24H92z"
              />
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M87 552h108.06v10.52H87z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M87 551h108.06v10.52H87z"
              />
              <g opacity=".1" mask="url(#bcv)" fill-rule="nonzero" fill="#000">
                <path d="M92.08 496.22h96.58v23.9H92.08z" />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                opacity=".5"
                mask="url(#bcv)"
                d="M434 496h96.58v60.24H434z"
              />
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M428 552h108.06v10.52H428z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M428 551h108.06v10.52H428z"
              />
              <g opacity=".1" mask="url(#bcv)" fill-rule="nonzero" fill="#000">
                <path d="M433.5 496.22h96.58v23.9H433.5z" />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                opacity=".5"
                mask="url(#bcv)"
                d="M434 355h96.58v60.24H434z"
              />
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M428 411h108.06v10.52H428z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M428 410h108.06v10.52H428z"
              />
              <g opacity=".1" mask="url(#bcv)" fill-rule="nonzero" fill="#000">
                <path d="M433.5 355.64h96.58v23.9H433.5z" />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                opacity=".5"
                mask="url(#bcv)"
                d="M95 355h96.58v60.24H95z"
              />
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M90 411h108.06v10.52H90z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M90 410h108.06v10.52H90z"
              />
              <g opacity=".1" mask="url(#bcv)" fill-rule="nonzero" fill="#000">
                <path d="M94.96 355.64h96.58v23.9H94.96z" />
              </g>
              <g
                opacity=".7"
                mask="url(#bcv)"
                fill-rule="nonzero"
                fill="url(#gcv)"
              >
                <path
                  d="M428.86 177.46L310.92 86.24 196.74 177.4l-8.96-11.2 123-98.2 126.86 98.12z"
                />
              </g>
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M79 300h127.18v1.44H79zM418 301h128.62v1.44H418z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M223 78h33.48v68.86H223z"
              />
              <g
                opacity=".7"
                mask="url(#bcv)"
                fill-rule="nonzero"
                fill="url(#hcv)"
              >
                <path d="M417.72 628.2H203.5V165.26l108.04-4.22 106.18 4.22z" />
              </g>
              <path
                fill="#FFF"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M415.48 628.18H207V163.4L311.24 85l106.16 81.28z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M285 540h52.6v87.98H285z"
              />
              <path
                fill="#000"
                fill-rule="nonzero"
                opacity=".1"
                mask="url(#bcv)"
                d="M257 461h108.06v10.52H257z"
              />
              <path
                fill="#03F"
                fill-rule="nonzero"
                opacity=".5"
                mask="url(#bcv)"
                d="M263 405h96.58v60.24H263z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M257 460h108.06v10.52H257z"
              />
              <g opacity=".1" mask="url(#bcv)" fill-rule="nonzero" fill="#000">
                <path d="M262.32 405.38h96.58v23.9h-96.58z" />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                opacity=".5"
                mask="url(#bcv)"
                d="M263 287h96.58v60.24H263z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M257 342h108.06v10.52H257z"
              />
              <g opacity=".1" mask="url(#bcv)" fill-rule="nonzero" fill="#000">
                <path d="M262.32 287.28h96.58v23.9h-96.58z" />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                opacity=".5"
                mask="url(#bcv)"
                d="M263 169h96.58v60.24H263z"
              />
              <path
                fill="#535461"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M257 224h108.06v10.52H257z"
              />
              <g opacity=".1" mask="url(#bcv)" fill-rule="nonzero" fill="#000">
                <path d="M262.32 169.16h96.58v23.9h-96.58z" />
              </g>
              <path
                fill="#03F"
                fill-rule="nonzero"
                mask="url(#bcv)"
                d="M429.08 180.48L311.14 89.24 196.96 180.4 188 169.2 311 71l126.86 98.12z"
              />
              <ellipse
                fill="#E0E0E0"
                fill-rule="nonzero"
                mask="url(#bcv)"
                cx="329.82"
                cy="583.82"
                rx="3.82"
                ry="3.82"
              />
            </g>
          </svg>
          <div
            class="sent-request-bottom"
            style="text-align: center; margin-top: 1.5rem"
          >
            <div class="sent-request-title">{{ $t('sentTitle') }}</div>
            <div class="sent-request-sub">{{ $t('sentText') }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Spinner from '@cling/components/ui/Spinner'

import Velocity from 'velocity-animate'
import { mapActions } from 'vuex'
import 'velocity-animate/velocity.ui'

import PropertyDesignationModalEdit from './PropertyDesignationModalEdit.vue'

import { global } from '@/store/action-types'

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.propertyDesignationModal'
  },
  name: 'PropertyDesignationModal',
  components: {
    Spinner
  },
  props: {
    documentId: {
      type: String,
      required: true
    },
    propertyDesignation: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      view: 'start',
      isRequesting: false
    }
  },
  watch: {
    view: {
      handler(value) {
        if (value === 'sent') {
          setTimeout(() => {
            this.$emit('close')
          }, 2500)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      requestPropertyDesignation: global.REQUEST_PROPERTY_DOCUMENT2
    }),
    async sendRequest() {
      if (this.isRequesting) {
        return false
      }
      this.isRequesting = true
      try {
        await this.requestPropertyDesignation({ id: this.documentId })
        this.view = 'sent'
      } finally {
        this.isRequesting = false
      }
      return true
    },
    async showEditModal() {
      this.$emit('close')
      this.$modal.show(
        PropertyDesignationModalEdit,
        {
          documentId: this.documentId,
          propertyDesignation: this.propertyDesignation
        },
        {
          height: 'auto',
          adaptive: true,
          classes: 'overflow-visible primary',
          transition: '',
          width: '90%',
          maxWidth: 350,
          scrollable: true
        }
      )
    },
    leave(el) {
      Velocity(el, 'transition.slideUpOut', {
        delay: 500,
        duration: 500,
        queue: false
      })
    },
    enter(el) {
      el.style.opacity = 0
      Velocity(el, 'slideDown', { queue: false, duration: 200 })
      Velocity(el, 'transition.slideUpIn', {
        delay: 200,
        duration: 200,
        queue: false
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.alternative {
  border: 2px solid $cobalt20;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  margin: 0 -0.5rem;
  width: calc(100% + 1rem);
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    border-color: $cobalt;
  }
  & + .alternative {
    margin-top: 1.5rem;
  }
  &-content {
    flex: 1;
  }
  &-title {
    font-weight: 800;
    font-size: calc(1rem + 1px);
    margin-bottom: 0.2rem;
  }
  &-text {
    color: $cobaltGray800;
    font-size: calc(1rem);
  }
  &-circle {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: $cobalt;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    &.self-color {
      background-color: $teal;
    }
  }
  &.disabled {
    cursor: not-allowed;
    & .alternative-text,
    & .alternative-title {
      color: $cobaltGray500;
    }
    & .alternative-circle {
      background-color: $cobaltGray100;
    }
    &:hover {
      border-color: $cobalt20;
    }
  }
}
.sent-request {
  &-bottom {
    padding-top: 1.5rem;
    text-align: center;
  }
  &-title {
    font-size: calc(1rem + 5px);
    font-weight: 900;
    margin-bottom: 0.2rem;
  }
  &-sub {
    font-size: calc(1rem + 1px);
    color: $cobaltGray700;
  }
}

.modal-header {
  font-size: calc(1rem + 6px);
  font-weight: 700;
  line-height: 1;
}
</style>

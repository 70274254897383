<script lang="jsx">
/**
 * Port of https://github.com/corygibbons/react-file-icon
 */
import tinycolor from 'tinycolor2'
import uniqId from 'uniqid'

import defaultStyles from './defaultStyles'
import glyphs from './glyphs.jsx'

export default {
  name: 'FileIcon',
  props: {
    /** Color of icon background */
    color: {
      type: String,
      default: null
    },
    /** Text to display in label */
    extension: {
      type: String,
      default: null
    },
    /** Displays the corner fold */
    fold: {
      type: Boolean,
      default: true
    },
    /** Color of the corner fold */
    foldColor: {
      type: String,
      default: null
    },
    /** Color of file type icon */
    glyphColor: {
      type: String,
      default: null
    },
    /** Color of page gradient */
    gradientColor: {
      type: String,
      default: 'white'
    },
    /** Opacity of page gradient */
    gradientOpacity: {
      type: Number,
      default: 0
    },
    /** Color of label */
    labelColor: {
      type: String,
      default: null
    },
    /** Color of label text */
    labelTextColor: {
      type: String,
      default: 'white'
    },
    /** Style of label text */
    labelTextStyle: {
      type: Object,
      default: null
    },
    /** Displays the label in all caps */
    labelUppercase: {
      type: Boolean,
      default: false
    },
    /** Corner radius of the file icon */
    radius: {
      type: Number,
      default: 5
    },
    /** Width and height of the file icon */
    size: {
      type: Number,
      default: 34
    },
    /** Type of glyph icon to display */
    type: {
      type: String,
      validator: val =>
        [
          '3d',
          'acrobat',
          'audio',
          'binary',
          'code',
          'code2',
          'compressed',
          'document',
          'drive',
          'font',
          'image',
          'presentation',
          'settings',
          'spreadsheet',
          'vector',
          'video'
        ].includes(val),
      default: null
    },
    mime: {
      type: String,
      default: null
    }
  },
  render(h) {
    const VIEWBOX = {
      WIDTH: 48,
      HEIGHT: 48
    }

    const ICON = {
      WIDTH: 40,
      HEIGHT: VIEWBOX.HEIGHT,
      X_OFFSET: 4
    }

    const FOLD = {
      HEIGHT: 12
    }

    const LABEL_HEIGHT = 14

    const UNIQUE_ID = uniqId()
    const defaultOps = defaultStyles[this.mime] || {}

    const ops = {
      color: this.color || defaultOps.color || 'hsl(210, 16%, 94%)',
      foldColor: this.foldColor || defaultOps.foldColor,
      glyphColor: this.glyphColor || defaultOps.glyphColor,
      labelColor: this.labelColor || defaultOps.labelColor,
      labelUppercase: this.labelUppercase || defaultOps.labelUppercase,
      type: this.type || defaultOps.type,
      extension: this.extension || defaultOps.extension
    }

    return (
      <svg
        viewBox={`0 0 ${VIEWBOX.WIDTH} ${VIEWBOX.HEIGHT}`}
        width={this.size}
        height={this.size}
        style={{ maxWidth: '100%', position: 'relative' }}
      >
        <defs>
          <clipPath id={`pageRadius${UNIQUE_ID}`}>
            <rect
              x={ICON.X_OFFSET}
              y="0"
              rx={this.radius}
              ry={this.radius}
              width={ICON.WIDTH}
              height={ICON.HEIGHT}
            />
          </clipPath>
          <clipPath id="foldCrop">
            <rect
              width={ICON.WIDTH}
              height={FOLD.HEIGHT}
              transform={`rotate(-45 0 ${FOLD.HEIGHT})`}
            />
          </clipPath>
          <linearGradient
            x1="100%"
            y1="0%"
            y2="100%"
            id={`pageGradient${UNIQUE_ID}`}
          >
            <stop
              stop-color={this.gradientColor}
              stop-opacity={this.gradientOpacity}
              offset="0%"
            />
            <stop
              stop-color={this.gradientColor}
              stop-opacity="0"
              offset="66.67%"
            />
          </linearGradient>
        </defs>
        {this.fold ? (
          <g id="file" clip-path={`url(#pageRadius${UNIQUE_ID})`}>
            <path
              d={`M${ICON.X_OFFSET} 0 h ${ICON.WIDTH - FOLD.HEIGHT} L ${
                ICON.WIDTH + ICON.X_OFFSET
              } ${FOLD.HEIGHT} v ${ICON.HEIGHT - FOLD.HEIGHT} H ${
                ICON.X_OFFSET
              } Z`}
              fill={ops.color}
            />
            <path
              d={`M${ICON.X_OFFSET} 0 h ${ICON.WIDTH - FOLD.HEIGHT} L ${
                ICON.WIDTH + ICON.X_OFFSET
              } ${FOLD.HEIGHT} v ${ICON.HEIGHT - FOLD.HEIGHT} H ${
                ICON.X_OFFSET
              } Z`}
              fill={`url(#pageGradient${UNIQUE_ID})`}
            />
          </g>
        ) : (
          <g id="file" clip-path={`url(#pageRadius${UNIQUE_ID})`}>
            <rect
              x={ICON.X_OFFSET}
              y="0"
              width={ICON.WIDTH}
              height={ICON.HEIGHT}
              fill={ops.color}
            />
            <rect
              x={ICON.X_OFFSET}
              y="0"
              width={ICON.WIDTH}
              height={ICON.HEIGHT}
              fill={`url(#pageGradient${UNIQUE_ID})`}
            />
          </g>
        )}

        {this.fold && (
          <g transform={`translate(32 ${FOLD.HEIGHT}) rotate(-90)`}>
            <rect
              width={ICON.WIDTH}
              height={ICON.HEIGHT}
              fill={ops.foldColor || tinycolor(ops.color).darken(10).toString()}
              rx={this.radius}
              ry={this.radius}
              clip-path="url(#foldCrop)"
            />
          </g>
        )}

        {ops.extension && (
          <g id="label">
            <rect
              fill={
                ops.labelColor || tinycolor(ops.color).darken(30).toString()
              }
              x={ICON.X_OFFSET}
              y={ICON.HEIGHT - LABEL_HEIGHT}
              width={ICON.WIDTH}
              height={LABEL_HEIGHT}
              clip-path={`url(#pageRadius${UNIQUE_ID})`}
            />
          </g>
        )}
        {ops.extension && (
          <g id="labelText" transform={`translate(${ICON.X_OFFSET} 34)`}>
            <text
              x={ICON.WIDTH / 2}
              y="10"
              font-size="9"
              fill={this.labelTextColor}
              text-anchor="middle"
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                pointerEvents: 'none',
                textTransform: ops.labelUppercase ? 'uppercase' : 'none',
                userSelect: 'none'
              }}
            >
              {ops.extension}
            </text>
          </g>
        )}

        {ops.type && (
          <g
            transform={`translate(0 ${!ops.extension ? 6 : 0})`}
            fill={ops.glyphColor || tinycolor(ops.color).darken(15).toString()}
          >
            {glyphs[ops.type](h)}
          </g>
        )}
      </svg>
    )
  }
}
</script>

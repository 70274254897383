export const actionTypes = {
  LOAD_PROJECT_FILE: 'LOAD_PROJECT_FILE',
  CREATE_PROJECT_FILE: 'CREATE_PROJECT_FILE',
  UPDATE_PROJECT_FILE: 'UPDATE_PROJECT_FILE',
  DELETE_PROJECT_FILE: 'DELETE_PROJECT_FILE',
  PATCH_PROJECT_FILE: 'PATCH_PROJECT_FILE'
}

export const mutationTypes = {
  SET_PROJECT_FILES: 'SET_PROJECT_FILES',
  DELETE_PROJECT_FILE: 'DELETE_PROJECT_FILE'
}

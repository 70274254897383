import clingapi, {
  createDemoProject,
  deleteProject,
  deleteProjectMember,
  getProject,
  getProjects,
  patchProject,
  postProject,
  postProjectMember,
  putProject,
  getProjectCount
} from '@cling/api'
import lang from '@cling/language'
import eventBus from '@cling/services/eventBus'
import { global } from '@cling/store/action-types'
import { projects as projectListSchema } from '@cling/store/utils/schema'
import { getType } from '@cling/utils'

import differenceBy from 'lodash/differenceBy'
import get from 'lodash/get'

import { actionTypes, mutationTypes } from './constants'
import eventActions from './events/actions'
import projectFilesActions from './projectFiles/actions'
import projectNoteActions from './projectNotes/actions'

import { SET_AS_NORMALIZED_DATA } from '../../actions/constants'

const moduleName = 'projects'

const {
  LOAD_PROJECT,
  LOAD_PROJECTS,
  DO_LOAD_PROJECT,
  DO_LOAD_PROJECTS,
  DO_LOAD_PROJECTS_COUNT,
  LOAD_PROJECTS_FOR_CLIENT,
  LOAD_PROJECTS_FOR_USER,
  LOAD_PUBLIC_PROJECT,
  CREATE_PROJECT,
  CREATE_DEMOPROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  PATCH_PROJECT,
  DO_SUBMIT_PROJECT,
  SUBMIT_PROJECT,
  SUBMIT_PROJECT_MEMBER,
  DELETE_PROJECT_MEMBER,
  SET_PROJECT_LEADER,
  SET_PROJECT_FILTER,
  SET_PROJECT_SORT,
  SET_QUICKVIEW_PROJECT,
  CLEAR_PROJECTS
} = actionTypes

const { UPDATE_VIEW_SETTINGS, DO_UPDATE_SETTINGS, DO_LOAD_CURRENT_COMPANY } =
  global

const {
  CLEAR_PROJECT_DATA,
  SET_PROJECTS_POSTING,
  SET_PROJECTS_FETCHING,
  DELETE_MANY_PROJECTS
} = mutationTypes

const actions = {
  ...projectNoteActions,
  ...eventActions,
  ...projectFilesActions,

  /**
   * @name DO_LOAD_PROJECTS
   * Get projects from API and dispatch it
   * in to the store
   * @param {Object} Vuex object
   * @param {Object} options Optional object
   * @param {Boolean} options.clearOld Boolean if old project should be removed, default false
   * @param {Number} options.start Numeric start
   * @param {Number} options.end Numeric end
   * @param {String} options.sort What field to sort on
   * @param {String} options.order What field to order results on
   * @param {Boolean} options.force If the request is forced, otherwise it will abort if
   *                                a request is already active
   * @param {Object} options.filter A filter object used to construct the query
   * @returns {Promise<>} Promise that resolves or throws an error
   */
  async [DO_LOAD_PROJECTS]({ commit, state, rootState }, options = {}) {
    try {
      // Prepare default options
      const opts = {
        clearOld: false,
        start: options.end ? 0 : undefined,
        end: undefined,
        sort: undefined,
        order: undefined,
        force: false,
        filter: undefined,
        ...options // spread in argument
      }

      const { canFetchMore: stateCanFetchMore } = state
      let canFetchMore = !!stateCanFetchMore
      if (opts.clearOld) {
        canFetchMore = true
      }

      // TODO: Should we store filter data before stopping?

      // If we already are fetching, halt
      if (state.isFetching && !opts.force) return

      // If we use pagination, but cannot get any more, halt
      if (
        getType(opts.start) === 'number' &&
        getType(opts.end) === 'number' &&
        !canFetchMore &&
        !opts.force
      )
        return

      commit(SET_PROJECTS_FETCHING, true)

      const { data: projects } = await getProjects({
        start: opts.start,
        end: opts.end,
        sort: opts.sort,
        order: opts.order,
        filter: opts.filter
      })

      // Get all cached docs on each project and add them to the store
      let cachedDocs = {}
      projects.forEach(({ cachedDocuments }) => {
        if (cachedDocuments) {
          const currObj = Object.keys(cachedDocuments.documents).reduce(
            (res, key) => ({
              ...res,
              [key]: {
                id: key,
                data: {
                  name: cachedDocuments.documents[key].name,
                  clients: [
                    {
                      name: get(
                        cachedDocuments,
                        `documents[${key}].firstClient.name`,
                        ''
                      ),
                      type: get(
                        cachedDocuments,
                        `documents[${key}].firstClient.type`
                      ),
                      addresses: get(
                        cachedDocuments,
                        `documents[${key}].firstClient.addresses`
                      )
                    }
                  ]
                },
                status: cachedDocuments.documents[key].status
              }
            }),
            {}
          )
          cachedDocs = { ...cachedDocs, ...currObj }
        }
      })

      const docStoreList = rootState.documents2.ids
      const notAddedDocIds = differenceBy(Object.keys(cachedDocs), docStoreList)
      this.commit('documents2/SET_DOCUMENTS2', {
        data: notAddedDocIds.map(itm => cachedDocs[itm])
      })

      // Clear old data if applicable
      if (opts.clearOld) {
        commit(CLEAR_PROJECT_DATA)
      }

      // Dispatch to global namespace
      this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: projects,
        schema: 'projects'
      })

      // If we use pagination, handle when there are no more projects to fetch
      const amountRequested =
        getType(opts.start) === 'number' && getType(opts.end) === 'number'
          ? opts.end - opts.start
          : 0
      canFetchMore = !(amountRequested > projects.length)
      // Always update canFetchMore
      commit(mutationTypes.SET_PROJECTS_CAN_FETCH_MORE, canFetchMore)
    } finally {
      commit(SET_PROJECTS_FETCHING, false)
    }
  },

  /**
   * @name DO_LOAD_PROJECTS_COUNT
   * Get total project count for each status fro the API
   * and load it to the store
   *
   * @param {Object} Vuex object
   */
  async [DO_LOAD_PROJECTS_COUNT]({ commit }) {
    try {
      commit(SET_PROJECTS_FETCHING, true)

      const { data } = await getProjectCount()
      commit(mutationTypes.SET_PROJECTS_COUNT, { data })
    } finally {
      commit(SET_PROJECTS_FETCHING, false)
    }
  },

  /**
   * @name LOAD_PROJECTS
   * Get projects from API and dispatch it
   * in to the store and handles eny errors that may occur
   * @param {Object} Vuex object
   * @param {Object} options Optional object as documented in DO_LOAD_PROJECTS
   * @returns {Promise<>} Promise that resolves
   */
  async [LOAD_PROJECTS]({ dispatch }, options = {}) {
    try {
      await dispatch(DO_LOAD_PROJECTS, options)
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        fallbackCode: 'project.get',
        action: `${moduleName}/${LOAD_PROJECTS}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name LOAD_PROJECT
   * Will handle loading of specific projects and also handle
   * errors that may occur
   */
  async [LOAD_PROJECT]({ dispatch }, { id, emit }) {
    try {
      await dispatch(DO_LOAD_PROJECT, { id, emit })
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        objectId: id,
        fallbackCode: 'project.get',
        action: `${moduleName}/${LOAD_PROJECT}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name DO_LOAD_PROJECT
   * Get one project from the API and dispatch it
   * in to the store
   */
  async [DO_LOAD_PROJECT]({ commit }, { id, emit = false }) {
    try {
      commit(SET_PROJECTS_FETCHING, true)

      const projects = []
      const { data } = await getProject(id)
      if (data) {
        projects.push(data)
      }
      if (emit) eventBus.trigger('project:watch', data)

      // Dispatch to global namespace
      this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: projects,
        schema: 'projects' // schema: projectListSchema,
      })

      commit(SET_PROJECTS_FETCHING, false)
      return data
    } catch (err) {
      commit(SET_PROJECTS_FETCHING, false)
      throw err
    }
  },

  /**
   * @name LOAD_PROJECTS_FOR_CLIENT
   * Get projects from the API which is assigned to the client by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the EndCustomer
   */
  async [LOAD_PROJECTS_FOR_CLIENT]({ dispatch }, { id }) {
    try {
      await dispatch(DO_LOAD_PROJECTS, {
        filter: {
          clientEndCustomerIds: {
            [id]: 'contains'
          }
        }
      })
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        fallbackCode: 'project.get',
        action: `${moduleName}/${LOAD_PROJECTS_FOR_CLIENT}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name LOAD_PROJECTS_FOR_USER
   * Get projects from the API which is created by the user
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the CompanyUser
   */
  async [LOAD_PROJECTS_FOR_USER]({ dispatch }, { id }) {
    try {
      await dispatch(DO_LOAD_PROJECTS, {
        filter: {
          CompanyUserId: {
            [id]: true
          }
        }
      })
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        fallbackCode: 'project.get',
        action: `${moduleName}/${LOAD_PROJECTS_FOR_USER}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name LOAD_PUBLIC_PROJECT
   * Get one public project from the API and dispatch it
   * in to the store
   */
  // TODO: should this action also trigger isFetching?
  async [LOAD_PUBLIC_PROJECT](moduleStore, publicId) {
    try {
      const { data } = await clingapi.get(`/project/public/${publicId}`)

      // Dispatch to global namespace
      this.dispatch(SET_AS_NORMALIZED_DATA, {
        data,
        schema: projectListSchema
      })
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        objectId: publicId,
        fallbackCode: 'project.get',
        action: `${moduleName}/${LOAD_PUBLIC_PROJECT}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name CREATE_PROJECT
   * Create one project
   *
   * @returns {Promise<string>} String with project id
   */
  async [CREATE_PROJECT]({ dispatch, commit, rootGetters }, { body }) {
    try {
      commit(SET_PROJECTS_POSTING, true)
      const { data: project } = await postProject(body)
      await dispatch(actionTypes.LOAD_PROJECT, {
        id: project.id
      })

      if (rootGetters['application/companyAccountType'] === 'free') {
        // As a new project was created, load current company to make sure any free project counts are updated
        this.dispatch(DO_LOAD_CURRENT_COMPANY)
      }

      commit(SET_PROJECTS_POSTING, false)
      return Promise.resolve(project.id)
    } catch (err) {
      commit(SET_PROJECTS_POSTING, false)
      return Promise.reject(err)
    }
  },

  /**
   * @name CREATE_DEMOPROJECT
   * Create a demo project
   *
   * @returns {Promise<Integer>} Resolves with projectId if successfully or null
   */
  async [CREATE_DEMOPROJECT]({ dispatch, commit }) {
    try {
      commit(SET_PROJECTS_POSTING, true)
      const { data: project } = await createDemoProject()
      const { id } = project

      // Store the id as current demoProjectId as companySetting
      this.dispatch(DO_UPDATE_SETTINGS, {
        key: 'company',
        settings: [{ name: 'demoProjectId', value: id }]
      })

      await dispatch(actionTypes.LOAD_PROJECT, {
        id
      })
      commit(SET_PROJECTS_POSTING, false)
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        fallbackCode: 'project.demo',
        action: `${moduleName}/${CREATE_DEMOPROJECT}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_PROJECTS_POSTING, false)
    }
  },

  /**
   * @name UPDATE_PROJECT
   * Update one project
   *
   * @returns {Promise<string>} String with project id
   */
  async [UPDATE_PROJECT]({ dispatch, commit }, { id, body }) {
    try {
      commit(SET_PROJECTS_POSTING, true)
      await putProject(id, body)
      await dispatch(actionTypes.LOAD_PROJECT, {
        id
      })
      commit(SET_PROJECTS_POSTING, false)
      return id
    } catch (err) {
      commit(SET_PROJECTS_POSTING, false)
      throw err
    }
  },

  /**
   * @name DELETE_PROJECT
   * Remove one project by id
   */
  async [DELETE_PROJECT]({ commit, dispatch }, { id }) {
    try {
      commit(SET_PROJECTS_POSTING, true)
      await deleteProject(id)
      commit(DELETE_MANY_PROJECTS, id)

      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', { thing: lang.t('project') }),
        actions: {
          undo: () => {
            this.dispatch(`projects/${PATCH_PROJECT}`, { id })
          }
        }
      })
      // Reset quickViewProjectId if user was using quick view
      dispatch(SET_QUICKVIEW_PROJECT, {
        id: null
      })
      commit(SET_PROJECTS_POSTING, false)
      return true
    } catch (err) {
      commit(SET_PROJECTS_POSTING, false)
      this.handleError(err, {
        object: 'project',
        objectId: id,
        fallbackCode: 'project.delete',
        action: `${moduleName}/${DELETE_PROJECT}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name PATCH_PROJECT
   * Patch one project by id
   * @param {Number} id Numeric id of the project
   * @returns {Promise<Number>} Promise that resolves with project id or false
   */
  async [PATCH_PROJECT]({ dispatch }, { id }) {
    try {
      await patchProject(id)
      await dispatch(LOAD_PROJECT, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        objectId: id,
        fallbackCode: 'project.patch',
        action: `${moduleName}/${PATCH_PROJECT}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name SET_QUICKVIEW_PROJECT
   * Set current project id for quickview
   * @param {Number} id Numeric id of the project
   * @returns {Promise<Number>} Promise that resolves with project id or false
   */
  async [SET_QUICKVIEW_PROJECT]({ commit }, { id }) {
    try {
      this.dispatch(UPDATE_VIEW_SETTINGS, {
        view: 'projectList',
        settings: {
          quickViewProjectId: id
        }
      })
      return id
    } catch (err) {
      commit(SET_PROJECTS_POSTING, false)
      this.handleError(err, {
        object: 'project',
        objectId: id,
        fallbackCode: 'project.quickview',
        action: `${moduleName}/${SET_QUICKVIEW_PROJECT}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name SET_PROJECT_LEADER
   * Set user as project leader for a project
   * @param {Number} projectId Numeric id of the project
   * @param {Number} companyuserId Numeric id of the companyUser
   * @returns {Promise<Boolean>} Promise that resolves with true or false
   */
  async [SET_PROJECT_LEADER](
    { dispatch, commit, getters },
    { projectId, companyUserId }
  ) {
    try {
      commit(SET_PROJECTS_POSTING, true)
      // Get current project leader
      const oldProjectLeaderId = getters
        .byId(projectId)
        .getProperty('CompanyUserId')
      // Set new projectLeader
      await dispatch(UPDATE_PROJECT, {
        id: projectId,
        body: {
          CompanyUserId: companyUserId
        }
      })

      // Remove the project leader from a member of the project
      await dispatch(DELETE_PROJECT_MEMBER, {
        projectId,
        companyUserId
      })

      // Add the old project leader as a member
      await dispatch(SUBMIT_PROJECT_MEMBER, {
        projectId,
        companyUserId: oldProjectLeaderId
      })

      commit(SET_PROJECTS_POSTING, false)
      return true
    } catch (err) {
      commit(SET_PROJECTS_POSTING, false)
      this.handleError(err, {
        object: 'project',
        objectId: projectId,
        fallbackCode: 'project.projectLeader',
        action: `${moduleName}/${SET_PROJECT_LEADER}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name DO_SUBMIT_PROJECT
   * Submit project data and return any error
   * @param {Object} Data The project data to submit
   */
  async [DO_SUBMIT_PROJECT]({ dispatch }, { data }) {
    let { id } = data
    if (id) {
      await dispatch(actionTypes.UPDATE_PROJECT, {
        id: data.id,
        body: data
      })
    } else {
      id = await dispatch(actionTypes.CREATE_PROJECT, {
        body: data
      })
    }
    // Update project in store
    await dispatch(actionTypes.LOAD_PROJECT, {
      id
    })
    return id
  },
  /**
   * @name SUBMIT_PROJECT
   * Submit project data and handle any errors locally
   * @param {Object} Data The project data to submit
   */
  async [SUBMIT_PROJECT]({ dispatch }, data) {
    try {
      await dispatch(DO_SUBMIT_PROJECT, data)
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        fallbackCode: 'project.post',
        action: `${moduleName}/${SUBMIT_PROJECT}`,
        actionPayload: arguments[1]
      })
    }
    return true
  },
  /**
   * @name SUBMIT_PROJECT_MEMBER
   * @param {Number} projectId Numeric id of the project
   * @param {Number} companyUserId Numeric id of the companyUser
   */
  async [SUBMIT_PROJECT_MEMBER]({ commit }, { projectId, companyUserId }) {
    try {
      commit(SET_PROJECTS_POSTING, true)
      await postProjectMember(projectId, companyUserId)
      commit(mutationTypes.ADD_PROJECT_MEMBER, {
        projectId,
        companyUserId
      })
      commit(SET_PROJECTS_POSTING, false)
      return true
    } catch (err) {
      commit(SET_PROJECTS_POSTING, false)
      this.handleError(err, {
        object: 'project',
        objectId: projectId,
        fallbackCode: 'project.member.post',
        action: `${moduleName}/${SUBMIT_PROJECT_MEMBER}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },
  /**
   * @name DELETE_PROJECT_MEMBER
   * @param {Number} projectId Numeric id of the project
   * @param {Number} companyUserId Numeric id of the companyUser
   */
  async [DELETE_PROJECT_MEMBER]({ commit }, { projectId, companyUserId }) {
    try {
      commit(SET_PROJECTS_POSTING, true)
      await deleteProjectMember(projectId, companyUserId)
      commit(mutationTypes.DELETE_PROJECT_MEMBER, {
        projectId,
        companyUserId
      })
      commit(SET_PROJECTS_POSTING, false)
      return true
    } catch (err) {
      commit(SET_PROJECTS_POSTING, false)
      this.handleError(err, {
        object: 'project',
        objectId: projectId,
        fallbackCode: 'project.member.delete',
        action: `${moduleName}/${DELETE_PROJECT_MEMBER}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },

  async [SET_PROJECT_FILTER](_, filter) {
    const filterString = JSON.stringify(filter)
    await this.dispatch(DO_UPDATE_SETTINGS, {
      key: 'companyUser',
      settings: [{ name: 'projectListFilter', value: filterString }]
    })
  },

  /**
   *
   * @param {Object} param0 Vuex Object
   * @param {Object} payload
   * @param {String} payload.sort What field to sort on
   * @param {String} payload.order What order to sort projects
   */
  async [SET_PROJECT_SORT](_, payload) {
    const sortString = JSON.stringify(payload)
    await this.dispatch(DO_UPDATE_SETTINGS, {
      key: 'companyUser',
      settings: [{ name: 'projectListSort', value: sortString }]
    })
  },

  [CLEAR_PROJECTS]({ commit }) {
    commit(mutationTypes.CLEAR_PROJECT_DATA)
    commit(mutationTypes.SET_PROJECTS_CAN_FETCH_MORE, true)
  }
}

// export default {}
export default actions

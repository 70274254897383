import de from './de' // Keys + Messages
import en from './en' // Keys + Messages
import es from './es' // Keys + Messages
import fi from './fi' // Keys + Messages
import fr from './fr' // Keys + Messages
import nl from './nl' // Keys + Messages
import sv from './sv' // Keys + Messages

// Contains the supported languages as ISO 639 that is supported on documents
export const docLanguages = ['en', 'sv', 'de', 'fi', 'fr', 'es', 'nl']

export const nativeTranslations = {
  en: 'English',
  sv: 'Svenska',
  fi: 'Suomi',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  nl: 'Nederlands'
}

// Easier to use 1 namespace and abstract away the handling of it
// rather than configuring the library to avoid it completely
export default {
  en: { document: en },
  sv: { document: sv },
  de: { document: de },
  fi: { document: fi },
  fr: { document: fr },
  es: { document: es },
  nl: { document: nl }
}

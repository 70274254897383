import supportedLocales from '@cling/static/dateFnsLocales'

export const getBestMatch = (locale = '') => {
  if (typeof locale !== 'string') return null
  const [l] = locale.split('-')
  return supportedLocales.includes(l) ? l : null
}

/**
 * Get the browser locale BCP-47 (like sv-SE)
 * @returns {String|null} Returns browser preffered locale or null if not found
 */
export const getBrowserLocale = () => {
  if (
    navigator &&
    navigator.languages &&
    Array.isArray(navigator.languages) &&
    navigator.languages[0]
  ) {
    return navigator.languages[0]
  } else if (navigator && navigator.language) {
    return navigator.language
  }
  return null
}

export default {
  getBestMatch,
  getBrowserLocale
}

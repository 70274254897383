export const actionTypes = {
  LOAD_DOCUMENT2_FORM: 'LOAD_DOCUMENT2_FORM',
  LOAD_DOCUMENT2_PUBLIC_FORM: 'LOAD_DOCUMENT2_PUBLIC_FORM',
  LOAD_DUPLICATE_DOCUMENT2_FORM: 'LOAD_DUPLICATE_DOCUMENT2_FORM',
  SAVE_DYNDOC_FORM: 'SAVE_DYNDOC_FORM',
  CLEAR_DYNDOC_FORM: 'CLEAR_DYNDOC_FORM',
  DO_SAVE_DYNDOC: 'DO_SAVE_DYNDOC',
  HANDLE_DOCUMENT2_EDIT: 'HANDLE_DOCUMENT2_EDIT',
  SAVE_TEMPLATE_FORM: 'SAVE_TEMPLATE_FORM',
  LOAD_EXTERNAL_FORM_FIELDS: 'LOAD_EXTERNAL_FORM_FIELDS'
}

export const mutationTypes = {}

export default {
  actionTypes,
  mutationTypes
}

import { schema } from 'normalizr'
// Describe schemas for all data that will be normalized

export const file = new schema.Entity('files')
export const files = new schema.Array(file)
export const projectFile = new schema.Entity('projectFiles', {
  File: file
})
export const projectFiles = new schema.Array(projectFile)
export const companyUser = new schema.Entity('companyUsers')
export const companyUsers = new schema.Array(companyUser)
export const offer = new schema.Entity('offers', {
  Files: [file]
})
export const offers = new schema.Array(offer)
export const ata = new schema.Entity('atas', {
  Files: [file]
})
export const atas = new schema.Array(ata)
export const project = new schema.Entity('projects', {
  // Offer: offer,
  // Atas: [ata],
  CompanyUser: companyUser,
  ProjectMembers: [companyUser],
  ProjectFiles: [projectFile]
})
ata.define({
  Project: project
})
export const projects = new schema.Array(project)
export const article = new schema.Entity('articles')
export const articles = new schema.Array(article)

export const certificate = new schema.Entity('certificates')
export const certificates = new schema.Array(certificate)
export const company = new schema.Entity('companies', {
  CompanyCertificates: [certificate]
})
export const companies = new schema.Array(company)
export const companyReferenceItem = new schema.Entity('companyReferenceItems', {
  Image: file,
  Thumbnail: file
})
export const companyReferenceItems = new schema.Array(companyReferenceItem)
export const companyReference = new schema.Entity('companyReferences', {
  CompanyReferenceItems: [companyReferenceItem]
})
export const companyReferences = new schema.Array(companyReference)

export const contract = new schema.Entity('contracts', {
  Offer: offer
})
export const contracts = new schema.Array(contract)
offer.define({
  Contract: contract
})
export const endCustomer = new schema.Entity('endCustomers')

export const notification = new schema.Entity('notifications', {
  Project: project
})
export const notifications = new schema.Array(notification)

export const propertyDesignation = new schema.Entity('propertyDesignations')
export const propertyDesignations = new schema.Array(propertyDesignation)
export const templateMessage = new schema.Entity('templateMessages')
export const templateMessages = new schema.Array(templateMessage)

<template>
  <div>
    <div v-if="loading && !clients.length" class="client-card">
      <CSkeleton width="45%" color="hsl(0, 0%, 91%)" />
      <CSkeleton :count="2" width="35%" color="hsl(0, 0%, 91%)" />
      <CSkeleton width="20%" color="hsl(0, 0%, 91%)" />
      <CSkeleton width="20%" hidden color="hsl(0, 0%, 91%)" />
    </div>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <div v-for="(client, i) in clients" :key="i" class="client-card">
      <div v-if="client.companyName" class="client-name">
        {{ client.companyName }}
      </div>
      <div class="client-name">{{ client.name }}</div>
      <div v-if="client.reference">{{ client.reference }}</div>
      <div v-if="client.documentRole">
        {{ $t(`_common:documentRole.${client.documentRole}.label`) }}
      </div>
      <component
        :is="client.mapUrl ? 'a' : 'div'"
        v-if="client.street || client.zip || client.city"
        :href="client.mapUrl"
        target="_blank"
        rel="noopener"
        style="color: inherit; display: inline-block"
      >
        <div v-if="client.street">{{ client.street }}</div>
        <div v-if="client.zip || client.city">
          {{ client.zip }} {{ client.city }}
        </div>
      </component>
      <div>
        <a v-if="client.email" :href="`mailto:${client.email}`">{{
          client.email
        }}</a>
      </div>
      <div>
        <a v-if="client.cellphone" :href="`tel:${client.cellphone}`">
          <CPhoneFormat
            :phone="client.cellphone"
            :region="client.cellphoneRegion || documentRegion"
          />
        </a>
      </div>
      <div v-if="showPropDes">
        <div v-if="propertyDesignation" class="prop-des-wrapper">
          <div v-if="propertyDesignation.propertyType === 'condominium'">
            <div>
              {{ $t('condominiumId') }}: {{ propertyDesignation.propertyId }}
            </div>
            <div>
              {{ $t('condominiumOrgNo') }}:
              {{ propertyDesignation.condominiumOrgNo }}
            </div>
          </div>
          <div v-else>
            {{ $t('propertyId') }}: {{ propertyDesignation.propertyId }}
          </div>
        </div>
      </div>
      <CDropdown
        v-if="client.endCustomerId && i === 0 && $can('projects', 'create')"
        position="is-bottom-left"
        style="position: absolute; right: 1em; top: 1em"
      >
        <template #trigger>
          <CButton
            circle
            type="none"
            pattern="secondary"
            icon="more-vertical"
          />
        </template>
        <CDropdownItem
          :to="{ name: 'client', params: { id: client.endCustomerId } }"
        >
          {{ $t('buttonClient') }}
        </CDropdownItem>
        <CDropdownItem v-if="showPropDes" @click="openModalPropertyDesignation">
          {{ $t('buttonPropertyDesignation') }}
        </CDropdownItem>
      </CDropdown>
    </div>
  </div>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import CSkeleton from '@cling/components/ui/Skeleton'
import { getDeviceType } from '@cling/utils'

import get from 'lodash/get'

import PropertyDesignationModal from './PropertyDesignationModal.vue'

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.clients'
  },
  name: 'ProjectClients',
  components: {
    CDropdown,
    CDropdownItem,
    CSkeleton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      get
    }
  },
  computed: {
    clients() {
      if (!this.document) return []
      const { clients } = this.document
      const res = clients.map(client => ({
        ...client,
        street:
          get(client, 'addresses.work.street') ||
          get(client, 'addresses.standard.street'),
        city:
          get(client, 'addresses.work.city') ||
          get(client, 'addresses.standard.city'),
        zip:
          get(client, 'addresses.work.zip') ||
          get(client, 'addresses.standard.zip')
      }))
      // Loop again for map urls
      return res.map(x => ({ ...x, mapUrl: this.getMapsLink(x) }))
    },
    client() {
      return this.clients.length ? this.clients[0] : {}
    },
    extraClients() {
      return this.clients.slice(1)
    },
    isIndividual() {
      return this.client.type === 'individual'
    },
    documentId() {
      return this.document ? this.document.id : null
    },
    propertyDesignation() {
      return get(this.client, 'region.propertyDesignation', null)
    },
    showPropDes() {
      if (this.propertyDesignation) return true
      return (
        this.$feature('propertyDesignation') &&
        this.isIndividual &&
        ['rot', 'greenRot15', 'greenRot20', 'greenRot50'].includes(
          get(this.document, 'prices.region.houseWorkType')
        )
      )
    },
    documentRegion() {
      if (!this.document) return null
      return get(this.document, 'sender.company.region')
    }
  },
  methods: {
    async openModalPropertyDesignation() {
      this.$modal.show(
        PropertyDesignationModal,
        {
          documentId: this.documentId,
          propertyDesignation: this.propertyDesignation
        },
        {
          height: 'auto',
          adaptive: true,
          width: '90%',
          maxWidth: 400,
          scrollable: true
        }
      )
    },
    getMapsLink(client) {
      if (!client.street && !client.city && !client.zip) return null
      let str = ''

      if (client.street) str += ` ${client.street}`
      if (client.city) str += ` ${client.city}`
      if (client.zip) str += ` ${client.zip}`

      str = encodeURI(str.trim())

      if (getDeviceType() === 'ios') {
        return `https://maps.apple.com/?daddr=${str}&dirflg=d`
      }
      return `https://www.google.com/maps/dir/?api=1&destination=${str}`
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.prop-des {
  &-wrapper {
    border-top: 1px solid $cobalt30;
    margin-top: 1rem;
    padding-top: 0.5rem;
    color: $gray800;
    font-weight: 400;
  }
  &-button {
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    padding: 0.5rem 0;
    color: $cobalt;
    display: inline-flex;
    align-items: center;
  }
}
.client {
  &-card {
    padding: 0.75em 1rem;
    border-radius: 0.5em;
    width: 100%;
    background-color: hsl(var(--gray-color-100) / 1);
    position: relative;
    font-size: 16px;
    + .client-card {
      margin-top: 1em;
    }
  }
  &-name {
    color: $black;
    font-weight: 500;
  }
}
</style>

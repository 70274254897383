<template>
  <div :class="[className, staticClass]" :style="[staticStyle]" />
</template>

<script setup lang="ts">
// TODO: currently not used
import { computed, useAttrs } from 'vue'

type Props = {
  round: boolean
  className: string
}

withDefaults(defineProps<Props>(), {
  round: true,
  className: 'vue-content-placeholders-img'
})

const attrs = useAttrs()

const staticStyle = computed(() => attrs.style)
const staticClass = computed(() => attrs.class)
</script>

import {
  deleteCompanyUser,
  getCompanyUser,
  getCompanyUsers,
  patchCompanyUser,
  postCompanyUser,
  putCompanyUser
} from '@cling/api'
import lang from '@cling/language'
import { global } from '@cling/store/action-types'
import globalMutationTypes from '@cling/store/mutation-types'
import { filterAllowedProperties } from '@cling/utils'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'companyUsers'

const {
  LOAD_COMPANY_USERS,
  LOAD_COMPANY_USER,
  DO_NORMALIZE_COMPANY_USERS,
  UPDATE_COMPANY_USER,
  CREATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  PATCH_COMPANY_USER,
  FORM_NEW_COMPANY_USER,
  FORM_EDIT_COMPANY_USER,
  FORM_SUBMIT_COMPANY_USER
} = actionTypes

const { SET_AS_NORMALIZED_DATA, FORM_RESET_OLD, DELETE_FILE } = global

const { SET_COMPANY_USERS_FETCHING, SET_COMPANY_USERS_POSTING } = mutationTypes

export default {
  /**
   * @name DO_NORMALIZE_COMPANY_USERS
   * Normalize company users and insert into store.
   * Used to insert companies from action, but also from API service on refresh token
   *
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {Object[]} obj.companyUsers Array or companyUsers
   *
   * @returns {Promise} Resolves if successfully, or throws error
   */
  async [DO_NORMALIZE_COMPANY_USERS](_, { companyUsers = [] }) {
    if (!companyUsers || !Array.isArray(companyUsers) || !companyUsers.length)
      throw new Error('Missing param companyUsers')
    await this.dispatch(SET_AS_NORMALIZED_DATA, {
      data: companyUsers,
      schema: 'companyUsers'
    })
  },

  /**
   * @name LOAD_COMPANY_USERS
   *  Load all companyUsers for current logged in user company
   * @param {Object} Vuex object
   */
  async [LOAD_COMPANY_USERS]({ dispatch, commit }) {
    try {
      commit(SET_COMPANY_USERS_FETCHING, true)
      const { data } = await getCompanyUsers()
      await dispatch(DO_NORMALIZE_COMPANY_USERS, {
        companyUsers: data
      })
    } catch (err) {
      this.handleError(err, {
        object: 'companyUser',
        fallbackCode: 'companyUser.getAll',
        action: `${moduleName}/${LOAD_COMPANY_USERS}`
      })
    } finally {
      commit(SET_COMPANY_USERS_FETCHING, false)
    }
  },

  /**
   * @name LOAD_COMPANY_USER
   *  Load one companyUser by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyUser
   * @returns {Promise<Number|null>} Promise that resolves with user id or null
   */
  // Load one companyUsers by id
  async [LOAD_COMPANY_USER]({ commit, dispatch, rootGetters }, { id }) {
    try {
      commit(SET_COMPANY_USERS_FETCHING, true)
      const { data: companyUser } = await getCompanyUser(id)

      await dispatch(DO_NORMALIZE_COMPANY_USERS, {
        companyUsers: [companyUser]
      })

      const { id: currentUserId } = rootGetters['application/user'] || {}

      if (id === currentUserId) {
        if (lang.locale !== companyUser.locale) {
          lang.l10n.changeLocale(companyUser.locale)
        }
        if (lang.lang !== companyUser.language) {
          lang.i18next.changeLanguage(companyUser.language) // Update current instance language
          if (location) location.reload() // Forces update on documentTitles + router meta
        }
      }

      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyUser',
        objectId: id,
        fallbackCode: 'companyUser.get',
        action: `${moduleName}/${LOAD_COMPANY_USER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_COMPANY_USERS_FETCHING, false)
    }
  },

  /**
   * @name CREATE_COMPANY_USER
   * Create one
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Object} object.body companyUser body
   * @returns {Promise<Number|null>} Promise that resolves with new companyUser id or null
   */
  async [CREATE_COMPANY_USER]({ commit, dispatch, rootGetters }, { body }) {
    try {
      const { language, locale } = rootGetters['application/user'] || {}

      const _body = {
        // Using current user lang/locale if not provided will give better prediction than sql-model defaults (sv | sv-SE)
        language: language || 'en',
        locale: locale || 'en-US',
        ...body
      }

      commit(SET_COMPANY_USERS_POSTING, true)
      const { data: companyUser } = await postCompanyUser(_body)
      await dispatch(DO_NORMALIZE_COMPANY_USERS, {
        companyUsers: [companyUser]
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('sentThingTo', {
          thing: lang.t('invitation'),
          recipient: companyUser.email
        })
      })
      return companyUser.id
    } catch (err) {
      this.handleError(err, {
        object: 'companyUser',
        fallbackCode: 'companyUser.post',
        action: `${moduleName}/${CREATE_COMPANY_USER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_COMPANY_USERS_POSTING, false)
    }
  },

  /**
   * @name UPDATE_COMPANY_USER
   *  Update one companyUser by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyUser
   * @param {Object} object.body companyUser data
   * @returns {Promise<Number|null>} Promise that resolves with companyUser id or null
   */
  async [UPDATE_COMPANY_USER]({ dispatch, commit, getters }, { id, body }) {
    try {
      commit(SET_COMPANY_USERS_POSTING, true)

      // If AvatarPublicId has changed, remove the old file
      const { AvatarPublicId: newAvatarPublicId } = body
      const { AvatarPublicId: oldAvatarPublicId } = getters.byId(id)

      if (newAvatarPublicId && newAvatarPublicId !== oldAvatarPublicId) {
        this.dispatch(DELETE_FILE, { id: oldAvatarPublicId })
      }

      await putCompanyUser(id, body)
      await dispatch(LOAD_COMPANY_USER, {
        id
      })

      // Sync user on chat
      this.$supportChat?.syncData()

      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('updatedThing', { thing: lang.t('user') })
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyUser',
        objectId: id,
        fallbackCode: 'companyUser.put',
        action: `${moduleName}/${UPDATE_COMPANY_USER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_COMPANY_USERS_POSTING, false)
    }
  },

  /**
   * @name DELETE_COMPANY_USER
   * Destroy one companyUser by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyUser
   * @returns {Promise<Number|null>} Promise that resolves with companyUser id or null
   */
  // Destroy company user
  async [DELETE_COMPANY_USER]({ commit }, { id }) {
    try {
      commit(SET_COMPANY_USERS_POSTING, true)
      await deleteCompanyUser(id)
      commit(mutationTypes.DELETE_COMPANY_USER, id)
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', { thing: lang.t('user') }),
        actions: {
          undo: () => {
            this.dispatch(`${moduleName}/${PATCH_COMPANY_USER}`, { id })
          }
        }
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyUser',
        objectId: id,
        fallbackCode: 'companyUser.delete',
        action: `${moduleName}/${DELETE_COMPANY_USER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_COMPANY_USERS_POSTING, false)
    }
  },

  /**
   * @name PATCH_COMPANY_USER
   * Patch one companyUser by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyUser
   * @returns {Promise<Number|null>} Promise that resolves with companyUser id or null
   */
  async [PATCH_COMPANY_USER]({ dispatch, commit }, { id }) {
    try {
      commit(SET_COMPANY_USERS_POSTING, true)
      await patchCompanyUser(id)
      await dispatch(LOAD_COMPANY_USER, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyUser',
        objectId: id,
        fallbackCode: 'companyUser.patch',
        action: `${moduleName}/${PATCH_COMPANY_USER}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_COMPANY_USERS_POSTING, false)
    }
  },

  /**
   * @name FORM_NEW_COMPANY_USER
   *  Prepare new form
   * @param {Object} Vuex object
   */
  [FORM_NEW_COMPANY_USER]({ commit, rootGetters }) {
    // Suggest new user language from current user
    let language = 'en'
    const user = rootGetters['application/user']
    if (user.language) ({ language } = user)

    const formData = {
      AvatarPublicId: null,
      CompanyId: null,
      CompanyUserPermission: {
        manageCompany: false,
        manageUsers: false,
        manageProjects: false,
        manageOwnProjects: false,
        showPrices: false,
        manageAtas: false,
        manageOwnAtas: false
      },
      cellphone: null,
      email: null,
      finishedGuide: false,
      firstname: null,
      id: null,
      language,
      lastActive: null,
      lastname: null,
      resetPasswordExpires: null,
      resetPasswordToken: null
    }
    return commit(
      `forms/${globalMutationTypes.SET_FORM}`,
      { key: 'companyUser', formData },
      { root: true }
    )
  },

  /**
   * @name FORM_EDIT_COMPANY_USER
   *  Prepare edit form
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyUser
   * @param {String[]} object.props Optional array of strings which props to load into store
   */
  [FORM_EDIT_COMPANY_USER]({ commit, getters }, { id, props = [] }) {
    const formData = filterAllowedProperties(getters.byId(id), props)
    return commit(
      `forms/${globalMutationTypes.SET_FORM}`,
      { key: 'companyUser', formData },
      { root: true }
    )
  },

  /**
   * @name FORM_SUBMIT_COMPANY_USER
   *  Submit form
   * @param {Object} Vuex object
   */
  async [FORM_SUBMIT_COMPANY_USER]({ rootGetters, dispatch }) {
    try {
      const companyUserData = rootGetters['forms/getFormByKey']('companyUser')
      let { id } = companyUserData
      if (id) {
        await dispatch(UPDATE_COMPANY_USER, {
          id: companyUserData.id,
          body: companyUserData
        })
      } else {
        id = await dispatch(CREATE_COMPANY_USER, {
          body: companyUserData
        })
      }
      await this.dispatch(FORM_RESET_OLD, 'companyUser')

      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companyUser',
        action: `${moduleName}/${FORM_SUBMIT_COMPANY_USER}`,
        actionPayload: arguments[1]
      })
      return false
    }
  }
}

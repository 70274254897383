import { timeline } from '@cling/api'

import { defineStore } from 'pinia'
import uniqid from 'uniqid'

function ensureUniqueId(task) {
  if (!task._uniqueId) {
    task._uniqueId = uniqid()
  }
  return task
}

export const useTaskStore = defineStore('task', {
  state: () => ({
    tasks: [],
    tagOptions: [
      null,
      'red',
      'blue',
      'green',
      'teal',
      'orange',
      'purple',
      'yellow',
      'black'
    ]
  }),
  actions: {
    async fetchByProjectId(projectId) {
      const response = await timeline.getAll({ projectId })
      this.tasks = response.data.map(ensureUniqueId)
    },
    async submit({ id, body }) {
      if (body && !id && !body.id) {
        // Create new with custom body
        const response = await timeline.post(body)
        this.tasks.push(ensureUniqueId(response.data))
        return
      }

      const putBody = body || this.tasks.find(task => task.id === id)
      if (putBody) {
        // Response does not have data. Do not save
        await timeline.put(id, { id, ...putBody })
        // Update with custom body or data from store
        const index = this.tasks.findIndex(task => task.id === id)
        if (index !== -1) this.tasks.splice(index, 1, ensureUniqueId(putBody))
      }
    },
    async remove(id) {
      await timeline.delete(id)
      this.tasks = this.tasks.filter(task => task.id !== id)
    },
    async setPositions(newIdList) {
      await timeline.setPositions(newIdList)
      newIdList.forEach((id, index) => {
        const task = this.tasks.find(task => task.id === id)
        if (task) {
          task.index = index
        }
      })
    },
    createTask(data) {
      const newTask = ensureUniqueId({
        id: null,
        name: '',
        description: '',
        isDone: false,
        tag: null,
        startAt: null,
        endAt: null,
        projectId: null,
        position: null,
        index: null,
        comments: [],
        checklists: [],
        TimelineMembers: [],
        createdAt: null,
        updatedAt: null,
        ...data
      })
      return newTask
    }
  },
  getters: {
    getTasksByProjectId: state => projectId => {
      return state.tasks.filter(task => task.projectId === projectId)
    },
    getChecklistItem: () => () => ({
      isChecked: false,
      text: '',
      _id: uniqid()
    }),
    getTaskById: state => id => state.tasks.find(task => task.id === id),
    getTheme: state => task => {
      const obj = {
        red: 'red',
        blue: 'blue',
        green: 'green',
        teal: 'teal',
        orange: 'orange',
        purple: 'purple',
        yellow: 'yellow',
        black: 'text'
      }
      if (!task?.tag) return ''
      return obj[task?.tag]
    }
  }
})

export default useTaskStore

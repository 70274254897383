<template>
  <div
    v-if="active"
    :class="[
      'c-skeleton',
      { 'is-animated': animated, 'is-invisible': hidden },
      staticClass
    ]"
    :style="staticStyle"
  >
    <div
      v-for="i in count"
      :key="i"
      class="c-skeleton-item"
      :class="{ 'is-rounded': rounded }"
      :style="itemStyle"
    />
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import { computed, useAttrs } from 'vue'

type Props = {
  active?: boolean
  animated?: boolean
  width?: number | string
  height?: number | string
  circle?: boolean
  rounded?: boolean
  count?: number
  hidden?: boolean
  color?: string
}

const props = withDefaults(defineProps<Props>(), {
  active: true,
  animated: false,
  width: '',
  height: '',
  circle: false,
  rounded: true,
  count: 1,
  hidden: false,
  color: ''
})

const {
  active,
  animated,
  width,
  height,
  circle,
  rounded,
  count,
  hidden,
  color
} = toRefs(props)

const itemStyle = computed(() => ({
  height:
    height.value === undefined
      ? null
      : isNaN(Number(height.value))
        ? height.value
        : `${height.value}px`,
  width:
    width.value === undefined
      ? null
      : isNaN(Number(width.value))
        ? width.value
        : `${width.value}px`,
  borderRadius: circle.value ? '50%' : null,
  background: color.value ? color.value : null
}))

const attrs = useAttrs()
const staticStyle = computed(() => attrs.style)
const staticClass = computed(() => attrs.class)
</script>

export const actionTypes = {
  DELETE_DOCUMENT2: 'DELETE_DOCUMENT2',
  DO_CREATE_DOCUMENT2: 'DO_CREATE_DOCUMENT2',
  DO_LOAD_DOCUMENT2: 'DO_LOAD_DOCUMENT2',
  DO_LOAD_DOCUMENTS2: 'DO_LOAD_DOCUMENTS2',
  DO_UPDATE_DOCUMENT2: 'DO_UPDATE_DOCUMENT2',
  LOAD_DOCUMENT2: 'LOAD_DOCUMENT2',
  LOAD_DOCUMENTS2: 'LOAD_DOCUMENTS2',
  LOAD_DOCUMENTS2_EXTERNAL: 'LOAD_DOCUMENTS2_EXTERNAL',
  PATCH_DOCUMENT2: 'PATCH_DOCUMENT2',
  UPDATE_DOCUMENT2: 'UPDATE_DOCUMENT2',
  UPDATE_DOCUMENT2_STATUS: 'UPDATE_DOCUMENT2_STATUS',
  DO_SEND_DOCUMENT2: 'DO_SEND_DOCUMENT2',
  SEND_REMINDER_DOCUMENT2: 'SEND_REMINDER_DOCUMENT2',
  REQUEST_PROPERTY_DOCUMENT2: 'REQUEST_PROPERTY_DOCUMENT2',
  CLEAR_DOCUMENT2_ANSWERS: 'CLEAR_DOCUMENT2_ANSWERS',
  ANSWER_OWN_DOCUMENT2: 'ANSWER_OWN_DOCUMENT2'
}

export const mutationTypes = {
  DELETE_MANY_DOCUMENTS2: 'DELETE_MANY_DOCUMENTS2',
  SET_DOCUMENTS2: 'SET_DOCUMENTS2',
  SET_DOCUMENT2_FETCHING: 'SET_DOCUMENT2_FETCHING',
  SET_DOCUMENT2_POSTING: 'SET_DOCUMENT2_POSTING'
}

export default {
  actionTypes,
  mutationTypes
}

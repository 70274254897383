import config from '@cling/config'
import lang from '@cling/language'
import { flattenNodeTree } from '@cling/models/document/nodeUtils'

import {
  css,
  emailTemplate,
  emailView,
  expiresAt,
  fields,
  formSettings,
  name,
  prices,
  receiverString,
  reminders,
  sender,
  senderString,
  smsTemplate,
  smsView
} from './defaultSubSchemas'

const nestedDefaultTemplate = language => ({
  id: null,
  name: 'Default Empty',
  validationSchema: {
    type: 'object',
    required: ['clients', 'data'],
    properties: {
      tags: {
        type: 'array',
        default: [{ name: 'document', type: 'category' }]
      },
      docNumber: {
        type: 'object',
        properties: {
          series: {
            type: 'string',
            default: 'document'
          }
        }
      },
      clients: {
        type: 'array',
        default: []
      },
      data: {
        type: 'object',
        required: ['name'],
        properties: {
          // Temporary
          partiesString: {
            anyOf: [{ type: 'string' }, { type: 'null' }],
            default: lang.t('components:document.chapters.parties.title', {
              lng: language
            })
          },
          'header-kh0loiq7': {
            default: {
              layout: 'vertical',
              baseStyle: {
                textAlign: 'left',
                textColor: '#f0ce84'
              },
              items: [
                {
                  type: 'media',
                  size: 35,
                  media: {
                    type: 'image',
                    resourceUrl:
                      'https://images.unsplash.com/photo-1581660545544-83b8812f9516?ixlib=rb-1.2.1&auto=format&fit=crop&w=1800&q=80'
                  }
                },
                {
                  type: 'content',
                  size: 65,
                  media: {
                    type: 'color',
                    backgroundColor: '#17346e'
                  },
                  value: {
                    preText: '',
                    documentName: '<p data-typography="title"></p>',
                    postText: '<p></p>',
                    showLogo: true,
                    logotype: ''
                  }
                }
              ]
            },
            $ref: `${config.api.baseUrl}/schemas/header/2.json`
          },
          packageGroups: {
            default: {
              kh0lol91: {
                type: '',
                title: lang.t(
                  'components:document.chapters.packageGroup.title',
                  { lng: language }
                ),
                hideGroupPrice: false,
                packages: []
              }
            },
            $ref: `${config.api.baseUrl}/schemas/packageGroups/1.json`
          },
          'title-kh0los53': { anyOf: [{ type: 'string' }, { type: 'null' }] },
          'content-kh0los54': { anyOf: [{ type: 'string' }, { type: 'null' }] },
          defaultAnswerMethod: {
            default: { accept: 'signature', deny: 'button' },
            $ref: `${config.api.baseUrl}/schemas/answer/1.json`
          },
          // Default schemas independent of block-nodes
          name,
          sender,
          senderString,
          receiverString,
          prices,
          css,
          fields,
          expiresAt,
          formSettings,
          reminders,
          smsTemplate,
          emailTemplate
        }
      }
    }
  },
  views: {
    edit: {
      isEditable: true,
      nodes: []
    },
    read: {
      isEditable: true,
      nodes: [
        {
          itemId: 'header-kh0loiq7',
          itemType: 'header',
          value: { header: 'data.header-kh0loiq7' }
        },
        {
          itemId: 'parties',
          itemType: 'section',
          value: { title: 'data.partiesString' },
          children: [
            {
              itemId: 'clients',
              itemType: 'subSection',
              value: { title: 'data.receiverString' },
              children: [
                {
                  itemId: 'clientsList',
                  itemType: 'party',
                  value: { parties: 'clients' }
                }
              ]
            },
            {
              itemId: 'sender',
              itemType: 'subSection',
              value: { title: 'data.senderString' },
              children: [
                {
                  itemId: 'senderList',
                  itemType: 'party',
                  value: { parties: 'data.sender' }
                }
              ]
            }
          ]
        },
        {
          itemId: 'packageGroup-kh0lol91',
          itemType: 'section',
          value: {
            title: 'data.packageGroups.kh0lol91.title'
          },
          children: [
            {
              itemId: 'group',
              itemType: 'selectionGroup',
              value: {
                groupId: 'kh0lol91',
                type: 'data.packageGroups.kh0lol91.type',
                packages: 'data.packageGroups.kh0lol91.packages'
              }
            }
          ]
        },
        {
          itemId: 'titleAndText2',
          itemType: 'section',
          value: { title: 'data.title-kh0los53' },
          children: [
            {
              itemId: 'kh0los52',
              itemType: 'html',
              value: { content: 'data.content-kh0los54' }
            }
          ]
        },
        {
          itemId: 'answer',
          itemType: 'answer',
          value: { defaultAnswerMethod: 'data.defaultAnswerMethod' }
        }
      ]
    },
    email: emailView,
    sms: smsView
  },
  isVisible: true,
  language
})

export const emptyTemplate = language => ({
  id: null,
  name: 'Empty',
  validationSchema: {
    type: 'object',
    required: ['clients', 'data'],
    properties: {
      tags: {
        type: 'array',
        default: [{ name: 'document', type: 'category' }]
      },
      docNumber: {
        type: 'object',
        properties: {
          series: { type: 'string', default: 'document' }
        }
      },
      clients: {
        type: 'array',
        default: []
      },
      data: {
        type: 'object',
        required: ['name'],
        properties: {
          // Temporary
          // Default schemas independent of block-nodes
          name,
          sender,
          senderString,
          receiverString,
          prices,
          css,
          fields,
          expiresAt,
          formSettings,
          reminders,
          smsTemplate,
          emailTemplate
        }
      }
    }
  },
  views: {
    edit: {
      isEditable: true,
      nodes: []
    },
    read: {
      isEditable: true,
      nodes: []
    },
    email: {
      ...emailView,
      nodes: flattenNodeTree(emailView.nodes, { setId: true })
    },
    sms: {
      ...smsView,
      nodes: flattenNodeTree(smsView.nodes, { setId: true })
    }
  },
  isVisible: true,
  language
})

export const defaultTemplate = language => {
  const nestedDefault = nestedDefaultTemplate(language)
  return {
    ...nestedDefault,
    views: Object.keys(nestedDefault.views).reduce(
      (res, view) => ({
        ...res,
        [view]: {
          ...nestedDefault.views[view],
          nodes: flattenNodeTree(nestedDefault.views[view].nodes, {
            setId: true
          })
        }
      }),
      {}
    )
  }
}

export default {
  default: defaultTemplate,
  empty: emptyTemplate
}

import TiptapTable from '@tiptap/extension-table'

export function updateColumns(
  node,
  colgroup,
  table,
  cellMinWidth,
  overrideCol,
  overrideValue
) {
  let totalWidth = 0
  let fixedWidth = true
  let nextDOM = colgroup.firstChild
  const row = node.firstChild

  for (let i = 0, col = 0; i < row.childCount; i += 1) {
    const { colspan, colwidth } = row.child(i).attrs

    for (let j = 0; j < colspan; j += 1, col += 1) {
      const hasWidth =
        overrideCol === col ? overrideValue : colwidth && colwidth[j]
      const cssWidth = hasWidth ? `${hasWidth}px` : ''
      totalWidth += hasWidth || cellMinWidth

      if (!hasWidth) {
        fixedWidth = false
      }

      if (!nextDOM) {
        colgroup.appendChild(document.createElement('col')).style.width =
          cssWidth
      } else {
        if (nextDOM.style.width !== cssWidth) {
          nextDOM.style.width = cssWidth
        }

        nextDOM = nextDOM.nextSibling
      }
    }
  }

  while (nextDOM) {
    const after = nextDOM.nextSibling
    nextDOM.parentNode.removeChild(nextDOM)
    nextDOM = after
  }

  if (fixedWidth) {
    table.style.width = `${totalWidth}px`
    table.style.minWidth = ''
  } else {
    table.style.width = ''
    table.style.minWidth = `${totalWidth}px`
  }

  table.style.setProperty('--borderWidth', node.attrs.borderWidth)
  table.style.setProperty('--borderColor', node.attrs.borderColor)
  table.style.setProperty('--cellPadding', node.attrs.cellPadding)
  table.style.setProperty('--headerBackground', node.attrs.headerBackground)
}

export class TableView {
  constructor(node, cellMinWidth) {
    this.node = node
    this.cellMinWidth = cellMinWidth
    this.dom = document.createElement('div')
    this.dom.className = 'tableWrapper'

    Object.keys(this.node.attrs).forEach(key => {
      this.dom.dataset[key] = this.node.attrs[key]
    })

    this.table = this.dom.appendChild(document.createElement('table'))
    this.colgroup = this.table.appendChild(document.createElement('colgroup'))
    updateColumns(node, this.colgroup, this.table, cellMinWidth)
    this.contentDOM = this.table.appendChild(document.createElement('tbody'))
  }

  update(node) {
    if (node.type !== this.node.type) {
      return false
    }

    this.node = node
    updateColumns(node, this.colgroup, this.table, this.cellMinWidth)

    return true
  }

  ignoreMutation(mutation) {
    return (
      mutation.type === 'attributes' &&
      (mutation.target === this.table ||
        this.colgroup.contains(mutation.target))
    )
  }
}

const EditableTable = TiptapTable.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : null),
      borderColor: {
        default: '#dedee3',
        parseHTML: el => el.getAttribute('data-border-color'),
        renderHTML: attrs => ({ 'data-border-color': attrs.borderColor })
      },
      borderWidth: {
        default: '1px',
        parseHTML: el => el.getAttribute('data-border-width'),
        renderHTML: attrs => ({ 'data-border-width': attrs.borderWidth })
      },
      cellPadding: {
        default: '5px',
        parseHTML: el => el.getAttribute('data-cell-padding'),
        renderHTML: attrs => ({ 'data-cell-padding': attrs.cellPadding })
      },
      headerBackground: {
        default: '#eeeef1',
        parseHTML: el => el.getAttribute('data-header-background'),
        renderHTML: attrs => ({
          'data-header-background': attrs.headerBackground
        })
      }
    }
  }
})

export const ReadableTable = EditableTable.extend({
  addNodeView() {
    return ({ node }) => {
      const dom = document.createElement('div')
      dom.className = 'tableWrapper'

      const table = dom.appendChild(document.createElement('table'))
      const colgroup = table.appendChild(document.createElement('colgroup'))
      updateColumns(node, colgroup, table)
      const contentDOM = table.appendChild(document.createElement('tbody'))

      return {
        dom,
        contentDOM
      }
    }
  },
  addProseMirrorPlugins() {
    return []
  }
})

export const EditTable = EditableTable.configure({
  resizable: true,
  View: TableView
})
export const ReadTable = ReadableTable.configure({
  resizable: true,
  View: TableView
})

export default {
  EditTable: EditableTable.configure({ resizable: true, View: TableView }),
  ReadTable: ReadableTable.configure({ resizable: true, View: TableView })
}

<template>
  <div
    :class="[
      {
        'vue-content-placeholders': true,
        'vue-content-placeholders-is-rounded': rounded,
        'vue-content-placeholders-is-centered': centered,
        'vue-content-placeholders-is-animated': animated
      },
      staticClass
    ]"
    :style="[staticStyle]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue'
type Props = {
  rounded?: boolean
  centered?: boolean
  animated?: boolean
}

withDefaults(defineProps<Props>(), {
  rounded: true,
  centered: false,
  animated: true
})

const attrs = useAttrs()

const staticStyle = computed(() => attrs.style)
const staticClass = computed(() => attrs.class)
</script>

<style lang="scss">
@import '../styles.scss';
</style>

import lang from '@cling/language'

export default {
  name: 'notificationsPage',
  components: {
    content: () => import('@/views/account/NotificationsPage.vue')
  },
  meta: {
    navbar: {
      type: 'default',
      title: lang.t('notification.notification_plural', {
        postProcess: 'capitalize'
      }),
      classList: 'flat'
    },
    body: {
      background: 'white'
    }
  }
}

import articleActions from './articles/actions'
import { actionTypes, mutationTypes } from './constants'
import documentActions from './document/actions'
import document2Actions from './document2/actions'

export default {
  ...documentActions,
  ...articleActions,
  ...document2Actions,
  // Action to clear a form by key
  async [actionTypes.FORM_CLEAR_FORM]({ commit }, { key }) {
    // Clear form
    return commit(mutationTypes.CLEAR_FORM, { key })
  },
  async [actionTypes.SET_FORM]({ commit }, { key, formData }) {
    return commit(mutationTypes.SET_FORM, { key, formData })
  },
  async [actionTypes.SET_FORM_VALUE]({ commit }, { key, value }) {
    // Clear form
    return commit(mutationTypes.SET_FORM_VALUE, { key, value })
  },
  async [actionTypes.REMOVE_FORM_VALUE]({ commit }, key) {
    return commit(mutationTypes.REMOVE_FORM_VALUE, key)
  },
  async [actionTypes.REMOVE_FORM_KEY_VALUE]({ commit }, key) {
    return commit(mutationTypes.REMOVE_FORM_KEY_VALUE, key)
  },
  async [actionTypes.FORM_RESET_OLD]({ commit, rootGetters }, key) {
    const formData = rootGetters['forms/getFormByKey'](key)
    return commit(mutationTypes.SET_FORM, { key, formData })
  }
}

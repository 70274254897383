import Feature from '@cling/services/features'

import get from 'lodash/get'

export function getDeliveryTypes(doc) {
  const res = { email: false, sms: false }

  get(doc, 'clients', []).forEach(client => {
    if (client.deliveryTypes.indexOf('sms') > -1) res.sms = true
    if (client.deliveryTypes.indexOf('email') > -1) res.email = true
  })

  if (res.sms && !Feature.checkFeature('sms')) res.sms = false

  return res
}

import lang from '@cling/language'

const SettingsTemplateEditor = () =>
  import('@/views/account/settings/SettingsTemplateEditor.vue')

export default [
  {
    path: 'library',
    name: 'library',
    components: {
      content: () => import('@/views/account/library/Library.vue')
    },
    redirect: { name: 'templates' },
    children: [
      {
        name: 'templates',
        path: 'templates',
        components: {
          content: () => import('@/views/account/library/Templates.vue')
        },
        meta: {
          documentTitle: lang.t('setting_plural', {
            postProcess: 'capitalize'
          }),
          navbar: {
            type: 'default',
            title: lang.t('setting_plural', { postProcess: 'capitalize' }),
            show: false
          },
          body: {
            fullSize: true
          }
        }
      },
      {
        name: 'articles',
        path: 'products',
        components: {
          content: () =>
            import('@/views/account/library/articles/ArticlesList.vue')
        },
        meta: {
          navbar: {
            title: lang.t('article_plural', { postProcess: 'capitalize' }),
            type: 'default',
            show: false
            // classList: 'flat',
          },
          body: {
            fullSize: true
          }
        }
      },
      // Template settings pages
      {
        path: 'snippets',
        name: 'settingsTemplates',
        documentTitle: lang.t('_common:setting_plural', {
          postProcess: 'capitalize'
        }),
        components: {
          content: () => import('@/views/account/library/Snippets.vue')
        },
        meta: {
          permission: 'company:modify',
          feature: 'templates',
          documentTitle: lang.t('router:settings.templates'),
          navbar: {
            type: 'default',
            title: lang.t('_common:setting_plural', {
              postProcess: 'capitalize'
            }),
            show: false
          },
          body: {
            fullSize: true
          }
        }
      },
      {
        path: 'terms',
        name: 'settingsTermList',
        components: {
          content: () => import('@/views/account/library/Terms.vue')
        },
        meta: {
          level: 2,
          permission: 'company:modify',
          navbar: {
            title: lang.t('router:settings.termList')
          },
          body: {
            fullSize: true
          }
        }
      }
      // routerPageSettings,
    ]
  },
  {
    path: '/',
    components: {
      content: () => import('@/views/account/settings/SettingsBaseLayout.vue')
    },
    children: [
      {
        path: 'templates/snippets/:id?',
        name: 'settingsTemplateEditor',
        components: {
          content: SettingsTemplateEditor
        },
        props: {
          content: true
        },
        meta: {
          level: 2,
          permission: 'company:modify',
          feature: 'templates',
          navbar: {
            title: lang.t('router:settings.templateEditor')
          },
          body: {
            fullSize: true,
            background: 'white'
          }
        }
      },
      {
        path: 'templates/term/:id',
        name: 'settingsTermForm',
        components: {
          content: () => import('@/views/account/settings/SettingsTermForm.vue')
        },
        meta: {
          level: 2,
          permission: 'company:modify',
          navbar: {
            title: lang.t('router:settings.termForm')
          },
          body: {
            fullSize: true,
            background: 'white'
          }
        }
      }
    ]
  }
]

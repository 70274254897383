import Animation from '@cling/components/ui/Animation'
import CButton from '@cling/components/ui/Button'
import CIcon from '@cling/components/ui/Icon'
import CInput from '@cling/components/ui/Input'
import CPhoneFormat from '@cling/components/ui/PhoneFormat'
import i18n from '@cling/language'
import pinia, { installPinia } from '@cling/stores'

import Vue from 'vue'

import App from '@/App.vue'
import ThinLink from '@/components/ThinLink.vue'
import extensions from '@/extensions'
import { createRouter } from '@/router'
import routes from '@/router/routes'
import appIntegrations from '@/services/integrations'
import store from '@/store/index'

import '@/assets/styles/normalize.scss'
import '@/assets/styles/oldBase.scss'
import '@/assets/styles/theme/index.sass'
import '@/assets/styles/tailwind.css'

import '@cling/styles/animations.scss'
import '@cling/styles/base.css' // Needs to be imported after tailwind
import '@cling/styles/modal.scss'
import '@cling/styles/tooltip.scss'

// Add custom String util
String.prototype.capitalize = function (this: string): string {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

Vue.component(CIcon.name!, CIcon)
Vue.component(CButton.name!, CButton)
Vue.component(CInput.name!, CInput)
Vue.component(CPhoneFormat.name, CPhoneFormat)
Vue.component('ThinLink', ThinLink)
Vue.component(Animation.name!, Animation)

Vue.config.ignoredElements = ['zapier-app-directory']

Vue.use(extensions, { store })
// TODO: [vue3] Pinia plugin setup differs in Vue 3
Vue.use(installPinia) // Plugin install will be reworked in Vue 3

const router = createRouter(routes, store)

Vue.use(appIntegrations, { router, store })

// Export and mount the Vue instance
const app = new Vue({
  router,
  store,
  pinia,
  el: '#app',
  i18n,
  render: h => h(App)
}).$mount('#app')

// Assign the Vue instance to the global App property
window.App = app

export default app

import config from '@cling/config'
import { getPermissionTitle } from '@cling/utils'

export default {
  referenceById: state => id => state.data[id],
  byId: state => id => {
    const user = state.data[id]
    if (!user) return null
    const { firstname, lastname } = user
    const complementedUser = {
      ...user,
      fullName: `${firstname || ''} ${lastname || ''}`.trim(),
      role: getPermissionTitle(user.CompanyUserPermission),
      avatarUrl: `${config.api.baseUrl}/file/${user.AvatarPublicId}/download`
    }
    return complementedUser
  },
  all: (state, getters) => {
    const res = {}
    state.ids.forEach(id => {
      res[id] = getters.byId(id)
    })
    return res
  },
  isPosting: state => state.isPosting,
  isFetching: state => state.isFetching
}

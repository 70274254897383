export const actionTypes = {
  LOAD_ARTICLES: 'LOAD_ARTICLES',
  LOAD_ARTICLE: 'LOAD_ARTICLE',
  SET_ARTICLES: 'SET_ARTICLES',
  UPDATE_ARTICLE: 'UPDATE_ARTICLE',
  DO_CREATE_ARTICLE: 'DO_CREATE_ARTICLE',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  PATCH_ARTICLE: 'PATCH_ARTICLE',
  SEARCH_ARTICLE: 'SEARCH_ARTICLE',
  FORM_NEW_ARTICLE: 'FORM_NEW_ARTICLE',
  FORM_EDIT_ARTICLE: 'FORM_EDIT_ARTICLE',
  FORM_SUBMIT_ARTICLE: 'FORM_SUBMIT_ARTICLE'
}

export const mutationTypes = {
  SET_ARTICLES: 'SET_ARTICLES',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  CLEAR_ARTICLES: 'CLEAR_ARTICLES',
  SET_ARTICLES_FETCHING: 'SET_ARTICLES_FETCHING',
  SET_ARTICLES_POSTING: 'SET_ARTICLES_POSTING'
}

export default {
  fallback: 'Ett oväntat fel inträffade',
  1: 'Oops, nåt gick fel! Pröva ladda om sidan.',
  100: 'Du måste ange ett namn på mallen',
  101: 'Innehåll i mallen kan inte vara tomt',
  102: 'Typ av mall kan inte vara tomt',
  201: 'E-post kan inte vara tomt',
  202: 'Du måste ange en korrekt e-postadress',
  203: 'Epost-adressen har avanmält sig ifrån flera utskick ifrån Er, kunde inte skicka epost.',
  204: 'Något är fel med länken för att avanmäla sig, vänligen kontakta oss så löser vi det.',
  205: 'Något är fel med länken för att avanmäla sig, vänligen kontakta oss så löser vi det.',
  300: 'Det gick inte att skicka som SMS, kontrollera att telefonnumret är ett korrekt mobilnummer och försök igen.',
  301: 'Gräns för antal gratis projekt denna månad är uppnådd, uppgradera till premium',
  302: 'Gräns för antal gratis dokument är uppnådd, uppgradera till premium',
  400: 'Namnet på @:ata kan inte vara tomt',
  401: 'Giltighetstiden för @:ataThe kan inte vara tomt',
  500: 'Namnet på certifikatet kan inte vara tomt',
  501: 'Beskrivningen på certifikatet kan inte vara tomt',
  502: 'Webbsidan för certifikatet kan int vara tomt',
  503: 'Webbsidan för certifikatet måste vara en korrekt url',
  601: 'Kan inte spara påminnelse som aktiv om det inte finns något skicka-datum',
  602: 'Kan inte spara påminnelse med ett datum som redan har passerat',
  603: 'Kan inte spara påminnelse för förfallodatum när fakturan saknar förfallodatum',
  700: 'Offertens gilighetstid kan inte vara tomt',
  800: 'Namnet på projektet kan inte vara tomt',
  900: 'Filen går inte att hitta',
  901: 'Filen hör inte till det aktuella företaget',
  1001: 'Epost för anställda kan inte vara tomt',
  1002: 'Epost för anställda måste vara en korrekt epost',
  1003: 'Lösenord för anställda kan inte vara tomt',
  1004: 'Förnamn för anställda kan inte vara tomt',
  1005: 'Efternamn för anställda kan inte vara tomt',
  1006: 'Telefonnummer för anställda kan inte vara tomt',
  2001: 'Namn för inställningen kan inte vara tomt',
  2002: 'Värde för inställningen kan inte vara tomt',
  3001: 'Företagsnamn kan inte vara tomt',
  3002: 'Epost för företaget kan inte vara tomt',
  3003: 'Epost för företaget måste vara en korrekt epost',
  3004: 'Webbsida för företaget kan inte vara tomt',
  3005: 'Webbsida för företaget måste vara en korrekt url',
  3006: 'Instagram URL kan inte vara tomt',
  3007: 'Instagram URL måste vara en korrekt url',
  3008: 'Facebook URL kan inte vara tomt',
  3009: 'Facebook URL måste vara en korrekt url',
  3010: 'Reco URL kan inte vara tomt',
  3011: 'Reco URL måste vara en korrekt url',
  3012: 'Namnet på SMS-avsändare får vara max 11 tecken',
  4001: 'Epost-adressen används redan för en användare',
  4002: 'Lösenordet måste vara minst 8 tecken långt',
  4003: 'Epost för användaren kan inte vara tomt',
  4004: 'Epost för användaren måste vara en korrekt epost',
  4005: 'Lösenord för användaren kan inte vara tomt',
  4006: 'Det gick inte att välja ett nytt lösenord, giltigheten på länken kan ha gått ut',
  4007: 'Du har redan max antal projektledare på ditt konto, kontakta support så hjälper vi dig',
  4009: 'Det gamla lösenordet är inte korrekt',
  4010: 'Hittade inget konto',
  5001: 'Artikeln måste ha ett namn',
  5002: 'Artikelns pris måste vara på korrekt format',
  5003: 'Artikelns moms måste vara på korrekt format',
  6001: 'Kunden måste ha ett namn',
  7001: 'Du har inte behörighet, prova att uppdatera sidan eller kontakta en administratör.',
  7002: 'Du har inte behörighet för modulen, prova att uppdatera sidan eller kontakta en administratör.',
  8001: 'Giltighetstiden för din token har gått ut, prova att ladda om sidan',
  9001: 'En signering pågår redan',
  9002: 'Signeringen tog för lång tid. Gör ett till försök.',
  9003: 'Du avbröt signeringen. Gör ett till försök.',
  9004: 'Signeringen avbröts. Gör ett till försök.',
  9005: 'Ditt BankID certifikat är inte giltigt. Kontakta din bank för att förnya det.',
  9006: 'BankID-programmet verkar inte finnas på din dator eller telefon. Installera det och hämta ett BankID hos din Bank. Installera programmet från install.bankid.com',
  9007: 'Ditt personnummer saknas. Fyll i det och försök igen.',
  9008: 'Internt tekniskt fel. Försök igen.',
  9010: 'Kontrollera att BankID-appen är uppdaterad.',
  10001: 'Du måste ange en kampanjkod',
  10002: 'Kampanjkoden är inte giltig',
  10003: 'Kampanjkoden är redan använd',
  11001: 'Bokföringsmetod måste ha ett giltigt värde',
  12001: 'Förfallodatum måste anges',
  12002: 'Leveransdatum måste anges när det finns artiklar som är varor',
  12003: 'När leveransmetod är manuell kan inte andra metoder också väljas',
  12004: 'Kan inte skicka faktura både som eFaktura och brev samtidigt',
  12005: 'Ingen leveransmetod är vald',
  12006: 'En kreditfaktura måste ha ett negativt totalbelopp',
  12007: 'Kunde inte skapa PDF för faktura',
  12008: 'Bara fakturor med status utkast kan attesteras',
  12009: 'Fakturapartner är inte implementerad',
  12010: 'Fakturan är redan bestriden',
  12011: 'Fakturan är inte markerad som bestriden',
  12012: 'Belopp är inte giltigt',
  12013: 'Moms är inte giltigt',
  12014: 'AnvändarId saknas',
  12015: 'Kan inte skapa påminnelse eftersom fakturastatus inte är giltig',
  12016: 'Kan inte skapa påminnelse eftersom leveransmetod är felaktig',
  12017: 'Leveransmetod är inte giltig',
  12018: 'Kan inte skapa påminnelsefaktura eftersom ingen avgift har angetts',
  12019: 'Kan inte skicka en faktura som har manuell leveransmetod',
  12020: 'Ingen kund har valts för fakturan',
  12021: 'Personer för avdrag har inte angetts korrekt',
  12022: 'Fastighetsbeteckning är inte korrekt',
  12023: 'Lägenhetsnummer är inte korrekt',
  12024: 'Bostadsrättens organisationsnummer är inte korrekt',
  12025: 'Fastighetstyp är inte korrekt',
  12026:
    'Kan inte synka inställningar, förnamn för kontaktperson faktura saknas',
  12027:
    'Kan inte synka inställningar, efternamn för kontaktperson faktura saknas',
  12028:
    'Kan inte synka inställningar, telefon för kontaktperson faktura saknas',
  12029:
    'Kan inte synka inställningar, e-post för kontaktperson faktura saknas',
  12030: 'Fakturan har inga artikelrader',
  12031: 'Kan inte synka inställningar, företaget saknar organisationsnummer',
  12032: 'Kan inte synka inställningar, företaget saknar företagsnamn',
  12033: 'Kan inte synka inställningar, företaget saknar adress',
  12034: 'Kan inte synka inställningar, företaget saknar postnummer',
  12035: 'Kan inte synka inställningar, företaget saknar stad',
  12036: 'Kan inte synka inställningar, företaget saknar e-post',
  12037: 'Kan inte synka inställningar, företaget saknar nästa fakturanummer',
  12038: 'Kan inte uppdatera en faktura som inte är i status utkast',
  12039: 'Betalningsdatum är inte giltigt',
  12040: 'Betalningstyp är inte giltigt',
  12041: 'Kan inte skapa PDF för en faktura som inte är attesterad',
  12042: 'Kundens personnummer måste anges för att kunna sälja fakturan',
  12043: 'Kundens org-nr måste anges för att kunna sälja fakturan',
  13001: 'Slutdatum måste vara efter startdatum',
  14001:
    'Dokumentet kan inte uppdateras eftersom det finns insamlade svar ifrån kund',
  15001: 'API nyckeln är inte giltig',
  16001: 'Otillräckligt saldo på kortet',
  16002: 'Kortet blev nekat',
  17001: 'Domänen används redan',
  17002: 'Kan inte ta bort en verifierad domän, kontakta support',
  18001:
    'Du har en gammal version av ett dokument, ladda om för att hämta senaste versionen.',
  18002:
    'Dokumentet är redan besvarat, ladda om för att hämta senaste versionen.',
  18003: 'Dokumentet är återkallat, ladda om för att hämta senaste versionen.',
  18004:
    'Dokumentets giltighetstid har förfallit, ladda om för att hämta senaste versionen.',
  ECONNREFUSED:
    'Fick inget svar från servern, vänta några sekunder och försök igen.',
  project: {
    get: 'Kunde inte hämta projekt',
    post: 'Kunde inte skapa projekt',
    demo: 'Kunde inte skapa demoprojekt',
    put: 'Kunde inte uppdatera projekt',
    delete: 'Kunde inte ta bort projekt',
    patch: 'Kunde inte ångra ta bort projekt',
    quickview: 'Kunde inte snabbvälja projekt',
    projectLeader: 'Kunde inte välja projektledare',
    send: 'Kunde inte skicka projekt',
    member: {
      post: 'Kunde inte lägga till medlem',
      delete: 'Kunde inte ta bort medlem'
    }
  },
  article: {
    get: 'Kunde inte hämta artikel',
    submit: 'Kunde inte spara artikel',
    put: 'Kunde inte uppdatera artikel',
    delete: 'Kunde inte ta bort artikel',
    patch: 'Kunde inte ångra ta bort artikel',
    search: 'Kunde inte söka efter artikel'
  },
  companies: {
    submit: 'Kunde inte spara företag'
  },
  companyReference: {
    get: 'Kunde inte hämta referens',
    post: 'Kunde inte skapa referens',
    put: 'Kunde inte uppdatera referens',
    delete: 'Kunde inte ta bort referens',
    patch: 'Kunde inte ångra ta bort referens'
  },
  companyReferenceItem: {
    get: 'Kunde inte hämta referensbild',
    post: 'Kunde inte skapa referensbild',
    put: 'Kunde inte uppdatera referensbild',
    delete: 'Kunde inte ta bort referensbild'
  },
  notification: {
    getAll: 'Kunde inte hämta alla notifikationer',
    getUnread: 'Kunde inte hämta olästa notifikationer',
    get: 'Kunde inte hämta notifikation',
    setReadForProject:
      'Kunde inte markera alla notifikationer som lästa för projektet',
    setReadForInvoice:
      'Kunde inte markera alla notifikationer som lästa för fakturan',
    setRead: 'Kunde inte markera notifikation som läst',
    setAllRead: 'Kunde inte markera alla notifikation som lästa'
  },
  certificate: {
    get: 'Kunde inte hämta certifikat',
    submit: 'Kunde inte spara dina certifikat'
  },
  endCustomer: {
    getAll: 'Kunde inte hämta alla kunder',
    get: 'Kunde inte hämta kund',
    post: 'Kunde inte spara ny kund',
    put: 'Kunde inte uppdatera kund',
    delete: 'Kunde inte ta bort kund',
    patch: 'Kunde inte ångra ta bort kund',
    submit: 'Kunde inte posta kund'
  },
  companyUser: {
    getAll: 'Kunde inte hämta alla användare',
    get: 'Kunde inte hämta användare',
    post: 'Kunde inte spara ny användare',
    put: 'Kunde inte uppdatera användare',
    delete: 'Kunde inte ta bort användare',
    patch: 'Kunde inte ångra ta bort användare'
  },
  file: {
    get: 'Kunde inte hämta fil',
    post: 'Kunde inte spara ny fil',
    put: 'Kunde inte uppdatera fil',
    delete: 'Kunde inte ta bort fil',
    patch: 'Kunde inte ångra ta bort fil'
  },
  projectFile: {
    get: 'Kunde inte hämta projektfil',
    post: 'Kunde inte spara ny projektfil',
    put: 'Kunde inte uppdatera projektfil',
    delete: 'Kunde inte ta bort projektfil'
  },
  projectNote: {
    get: 'Kunde inte hämta anteckning',
    edit: 'Kunde inte redigera anteckning',
    submit: 'Kunde inte spara anteckning'
  },
  propertyDesignation: {
    get: 'Kunde inte hämta Fastighetsbeteckning',
    edit: 'Kunde inte redigera Fastighetsbeteckning',
    submit: 'Kunde inte spara Fastighetsbeteckning'
  },
  setting: {
    put: 'Kunde inte uppdatera inställning',
    editCompany: 'Kunde inte läsa in företagsinställning',
    editInvoice: 'Kunde inte läsa in fakturainställningar',
    submitCompany: 'Kunde inte spara företagsinställning',
    editCompanyUser: 'Kunde inte läsa in inställning för användare',
    submitCompanyUser: 'Kunde inte spara inställning för användare',
    submitInvoice: 'Kunde inte spara inställning för faktura'
  },
  templateMessage: {
    getAll: 'Kunde inte hämta mallar',
    get: 'Kunde inte hämta mall',
    post: 'Kunde inte spara mall',
    put: 'Kunde inte uppdatera mall',
    delete: 'Kunde inte ta bort mall',
    patch: 'Kunde inte ångra ta bort mall',
    new: 'Kunde inte förbereda ny mall',
    edit: 'Kunde inte förbereda redigera mall',
    submit: 'Kunde inte spara mall'
  },
  document: {
    get: 'Kunde inte hämta dokument',
    delete: 'Kunde inte ta dokument',
    patch: 'Kunde inte ångra ta bort dokument',
    submit: 'Kunde inte spara dokument',
    submitStatus: 'Kunde inte ändra status på dokument'
  },
  documentTerm: {
    getAll: 'Kunde inte hämta villkor',
    get: 'Kunde inte hämta villkor',
    post: 'Kunde inte spara villkor',
    put: 'Kunde inte uppdatera villkor',
    delete: 'Kunde inte ta bort villkor',
    patch: 'Kunde inte ångra ta bort villkor',
    new: 'Kunde inte förbereda nytt villkor',
    edit: 'Kunde inte förbereda redigera villkor',
    submit: 'Kunde inte spara villkor'
  },
  unitType: {
    get: 'Kunde inte hämta enheter',
    put: 'Kunde inte uppdatera enhet',
    delete: 'Kunde inte ta bort enhet'
  }
}

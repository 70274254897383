export default {
  loginUser: {
    wrongCredentials: 'Invalid username or password'
  },
  propertyDesignationRequest: {
    successMessage: 'Request for property designation has been sent!',
    smsTemplate: {
      text: 'Hello{{clientFirstName}}!\nIn order to facilitate your application for deduction, {{company.name}} needs your property designation. Click here:\n\n{{docLink}}/fastighetsbeteckning'
    },
    emailTemplate: {
      subject: 'We need your property designation - {{document.data.name}}',
      title: 'Hello{{clientFirstName}}!',
      content:
        'In order to facilitate your application for deduction, {{company.name}} needs your property designation. Click on the button to enter your property designation.',
      text: 'Hello{{clientFirstName}}!\nIn order to facilitate your application for deduction, {{company.name}} needs your property designation. Click on the link to enter your property designation.\n\n{{docLink}}/fastighetsbeteckning',
      actionUrl: '{{docLink}}/fastighetsbeteckning'
    }
  },
  updateDocumentDialog: {
    title: 'Change status and remove answer',
    message: "Your clients' answers will be removed.",
    cancel: 'Cancel',
    submit: 'Yes, continue'
  },
  editDocumentDialog: {
    accepted: {
      title: 'Edit signed document',
      message:
        'The document is signed and locked for changes. Create a copy to make changes.',
      submit: 'Create copy'
    },
    hasAnswers: {
      title: 'Edit document with signatures',
      message:
        'The document is locked for changes. Existing signatures will be removed.',
      submit: 'Yes, continue'
    }
  }
}

/**
 * Note
 * This is meant as a temporary hardcoded mixin
 * Could/should be removed when partners have moved to self hosting public documents
 */
import { setStyleVariables, setTranslation } from '@cling/utils/overrides'

const getSubdomain = () => {
  const strings = window.location.host.split('.')
  return strings && strings[0]
}

export const isCustomFeedback = getSubdomain() === 'fortnox'

const theme = {
  '--primary-color-50': '132deg 50% 94%',
  '--primary-color-100': '133deg 60% 87%',
  '--primary-color-200': '137deg 66% 71%',
  '--primary-color-300': '140deg 75% 55%',
  '--primary-color-400': '143deg 85% 39%',
  '--primary-color-500': '146deg 100% 27%',
  '--primary-color-600': '149deg 100% 21%',
  '--primary-color-700': '152deg 100% 17%',
  '--primary-color-800': '155deg 100% 11%',
  '--primary-color-900': '159deg 100% 7%',
  '--gray-color-50': '210deg 20% 98%',
  '--gray-color-100': '220deg 14% 96%',
  '--gray-color-200': '220deg 13% 92%',
  '--gray-color-300': '216deg 12% 85%',
  '--gray-color-400': '218deg 11% 65%',
  '--gray-color-500': '220deg 9% 46%',
  '--gray-color-600': '215deg 14% 34%',
  '--gray-color-700': '217deg 19% 27%',
  '--gray-color-800': '215deg 28% 17%',
  '--gray-color-900': '221deg 39% 11%'
}

const i18n = {
  sv: {
    document: {
      'answerFeedback.start.title':
        'Tyckte du om att använda Fortnox signering för att visa det här dokumentet?',
      'answerModal.signature.start.title': 'Signera dokument'
    }
  },
  en: {
    document: {
      'answerFeedback.start.title':
        'Did you enjoy using Fortnox sign to view this document?',
      'answerModal.signature.start.title': 'Sign document'
    }
  }
}

export default {
  created() {
    // document.body is used in favour of #app as style container because modals plugin is parallel in the SPA
    if (getSubdomain() === 'fortnox') {
      setStyleVariables(theme, document?.body)
      setTranslation(i18n)
    }
  }
}

<template>
  <svg
    :style="{
      width: size,
      height: size,
      lineHeight: size,
      staticStyle
    }"
    :class="[color]"
    class="spinner spinner--circle inline-block"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="path"
      fill="none"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      cx="33"
      cy="33"
      r="30"
    ></circle>
  </svg>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue'

type Props = {
  size?: string
  strokeWidth?: number
  color?: string
}

withDefaults(defineProps<Props>(), {
  size: '40px',
  strokeWidth: 6,
  color: ''
})

const attrs = useAttrs()

const staticStyle = computed(() => attrs.style)
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

$offset: 188.4;
$duration: 1s;
.spinner {
  animation: circle-rotator $duration linear infinite;
  backface-visibility: hidden;
  perspective: 1000;
  * {
    line-height: 0;
    box-sizing: border-box;
  }
}
@keyframes circle-rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 90;
  transform-origin: center;
  animation: circle-colors ($duration * 4) ease-in-out infinite;
}
.white .path {
  animation: circle-white ($duration * 4) ease-in-out infinite;
}
.grey .path {
  animation: circle-dash $duration ease-in-out infinite;
  stroke: hsl(0, 0%, 70%);
}
@keyframes circle-colors {
  0% {
    stroke: hsl(var(--primary-color-500) / 1);
  }
  25% {
    stroke: rgb(247, 0, 255);
  }
  75% {
    stroke: rgb(0, 225, 255);
  }
  100% {
    stroke: hsl(var(--primary-color-500) / 1);
  }
}
@keyframes circle-white {
  0% {
    stroke: $white;
  }
  100% {
    stroke: $white;
  }
}
/* @keyframes circle-dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg) translate3d(0, 0, 0);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg) translate3d(0, 0, 0);
  }
} */
</style>

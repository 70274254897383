export default {
  // Language codes (ISO619-1)
  language: {
    sv: 'Swedish',
    en: 'English',
    fi: 'Finnish',
    de: 'German',
    fr: 'French',
    es: 'Spanish',
    nl: 'Dutch'
  }
}

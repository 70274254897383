class BaseError extends Error {
  isCustom = true
  code = null // Default errorCode to show a translated error to user
  httpStatusCode = null // HTTP status code from response if available

  // Default payload
  payload = {
    code: null, // code to translate error to user
    fallbackCode: null, // code to translate error to user if code was not defined
    string: null, // string used to display to user
    object: null, // object type as string
    objectId: null, // objectId Numeric
    action: null, // Full action path to store
    actionPayload: null, // Action payload
    retries: 0, //  How many retries to call action
    axios: null, // axios response or config
    showMessage: true // Should a message be displayed for the user?
  }

  constructor(err = {}) {
    super(err)

    if (typeof err.stack === 'string') {
      this.stack = err.stack.replace(
        /.*\n/i,
        `${this.constructor.name}: ${err.message}\n`
      )
    }
  }

  add(payload) {
    this.payload = {
      ...this.payload,
      ...payload
    }

    return this
  }

  getPayload() {
    return this.payload
  }

  /**
   * Try to set code and httpStatusCode from axios response if possible
   */
  setErrorCodeFromResponse() {
    const { data, status } = this.getPayload().axios || {}
    if (!data && !status) {
      return
    }
    const { errorCode } = data || {}

    if (errorCode) {
      // this.code = errorCode;
      this.add({ code: errorCode })
    }

    if (status) {
      this.httpStatusCode = status
    }
  }

  /**
   * Try to set message from axios response if possible
   * @returns {Boolean} True if message could be set, or false
   */
  setMessageFromResponse() {
    const { data } = this.getPayload().axios || {}
    if (!data) {
      return false
    }
    const { message } = data || {}
    if (message) {
      this.message = message
      return true
    }
    return false
  }
}

export class NotFoundError extends BaseError {
  constructor(err) {
    super(err)

    this.name = 'NotFoundError'
    this.message = typeof err === 'string' || !err ? err : err.message

    if (typeof err === 'string' || !err) return

    const { response: axios } = err

    this.add({ axios })

    this.setErrorCodeFromResponse()
  }
}

export class ForbiddenError extends BaseError {
  constructor(err) {
    super(err)
    this.name = 'ForbiddenError'
    this.message = typeof err === 'string' || !err ? err : err.message

    if (typeof err === 'string' || !err) return

    const { response: axios } = err

    this.add({ axios })

    this.setErrorCodeFromResponse()
    this.setMessageFromResponse()
  }
}

export class ValidationError extends BaseError {
  constructor(err) {
    super(err)
    this.name = 'ValidationError'
    this.message = typeof err === 'string' || !err ? err : err.message

    if (typeof err === 'string' || !err) return

    const { response: axios } = err

    this.add({ axios })

    this.setErrorCodeFromResponse()
    this.setMessageFromResponse()
  }
}

export class NetworkError extends BaseError {
  constructor(err) {
    super(err)
    this.name = 'NetworkError'
    this.message = typeof err === 'string' || !err ? err : err.message

    if (typeof err === 'string' || !err) return
    const { config: axios } = err

    this.add({ axios }) // set the config obj as axios

    this.code = 'ECONNREFUSED'
  }
}

export class GeneralError extends BaseError {
  constructor(err) {
    super(err)
    this.name = 'GeneralError'
    this.message = typeof err === 'string' || !err ? err : err.message

    if (typeof err === 'string' || !err) return
    const { response: axios } = err

    this.add({ axios })

    this.setErrorCodeFromResponse()
    this.setMessageFromResponse()
  }
}

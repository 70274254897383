<template>
  <div :class="[className, staticClass]" :style="[staticStyle]">
    <div v-for="n in lines" :key="n" :class="`${className}__line`" />
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue'

type Props = {
  lines?: number
  className?: string
}

withDefaults(defineProps<Props>(), {
  lines: 1,
  className: 'vue-content-placeholders-text'
})

const attrs = useAttrs()

const staticStyle = computed(() => attrs.style)
const staticClass = computed(() => attrs.class)
</script>

import { getField } from 'vuex-map-fields'

import userGetters from './user/getters'

export default {
  ...userGetters,
  getField,
  modals: state => state.modals,
  messages: state => state.messages,
  isIncVat: state => state.priceInputVat === 'incVat',
  viewSettings: state => view => state.viewSettings[view],
  showSearch: state => state.viewSettings.search.show,
  error: state => state.error,
  socketEnabled: state => state.socketEnabled,
  isSocketConnected: state => state.isSocketConnected
}

export const actionTypes = {
  LOAD_COMPANY_REFERENCE_ITEMS: 'LOAD_COMPANY_REFERENCE_ITEMS',
  LOAD_COMPANY_REFERENCE_ITEM: 'LOAD_COMPANY_REFERENCE_ITEM',
  SET_COMPANY_REFERENCE_ITEMS: 'SET_COMPANY_REFERENCE_ITEMS',
  CREATE_COMPANY_REFERENCE_ITEM: 'CREATE_COMPANY_REFERENCE_ITEM',
  UPDATE_COMPANY_REFERENCE_ITEM: 'UPDATE_COMPANY_REFERENCE_ITEM',
  DELETE_COMPANY_REFERENCE_ITEM: 'DELETE_COMPANY_REFERENCE_ITEM',
  FORM_NEW_COMPANY_REFERENCE_ITEM: 'FORM_NEW_COMPANY_REFERENCE_ITEM',
  FORM_SUBMIT_COMPANY_REFERENCE_ITEMS: 'FORM_SUBMIT_COMPANY_REFERENCE_ITEMS'
}

export const mutationTypes = {
  SET_COMPANY_REFERENCE_ITEMS: 'SET_COMPANY_REFERENCE_ITEMS',
  DELETE_COMPANY_REFERENCE_ITEM: 'DELETE_COMPANY_REFERENCE_ITEM',
  CLEAR_COMPANY_REFERENCE_ITEMS: 'CLEAR_COMPANY_REFERENCE_ITEMS'
}

<template>
  <a v-bind="attrs" :href="href" @click="handleClick">
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, useAttrs } from 'vue'

type To = {
  name: string
  params?: Record<string, string | number>
}

type Props = {
  to: To
}

const props = defineProps<Props>()

const attrs = useAttrs()
const instance = getCurrentInstance()

const router = instance?.proxy?.$router

const href = computed(() => {
  return router.resolve(props.to).href
})

const handleClick = (e: Event) => {
  e.preventDefault()
  e.stopPropagation()
  router.push(props.to)
}
</script>

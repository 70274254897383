import lang from '@cling/language'

const ProjectList = () =>
  import('@/views/account/overview/project-list/ProjectList.vue')
const ProjectTrashList = () =>
  import('@/views/account/overview/project-list/ProjectTrashList.vue')

let children = {
  '/': {
    name: 'account',
    components: {
      content: ProjectList
    },
    props: {
      content: {
        showFilterSettings: true,
        includeArchived: false
      }
    },
    meta: {
      navbar: {
        type: 'default',
        title: lang.t('overview', { postProcess: 'capitalize' }),
        mobileHideTitle: true
      },
      body: {
        background: 'white'
      }
    }
  },
  archive: {
    name: 'archive',
    components: {
      content: ProjectList
    },
    props: {
      content: {
        showFilterSettings: false,
        includeArchived: true
      }
    },
    meta: {
      navbar: {
        type: 'default',
        title: lang.t('archive', { postProcess: 'capitalize' }),
        mobileHideTitle: true
      },
      body: {
        background: 'white'
      }
    }
  },
  projectTrash: {
    name: 'projectTrash',
    components: {
      content: ProjectTrashList
    },
    meta: {
      navbar: {
        type: 'default',
        mobileHideTitle: true
      },
      body: {
        background: 'white'
      }
    }
  }
}

children = Object.keys(children).map(route => ({
  ...children[route],
  path: route,
  meta: {
    ...children[route].meta
  }
}))

export default {
  alias: ['/', 'oversikt'],
  components: {
    content: () => import('@/views/account/overview/Overview.vue')
  },
  meta: {
    modalView: true
  },
  children
}

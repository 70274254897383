import { showMessage } from '@cling/services/messages'

import uniqid from 'uniqid'

import { actionTypes, mutationTypes } from './constants'
import userActions from './user/actions'

const {
  PUSH_MODAL,
  POP_MODAL,
  SET_CAN_CLOSE_MODAL,
  SHOW_MESSAGE,
  UPDATE_VIEW_SETTINGS,
  RESET_VIEW_SETTINGS,
  SHOW_ERROR,
  RESET_ERROR
} = actionTypes
export default {
  ...userActions,
  [PUSH_MODAL]({ commit, state }, payload) {
    const newModal = {
      ...payload,
      _uniqueId: uniqid()
    }
    commit(mutationTypes.SET_MODALS, [...state.modals, newModal])
  },
  [POP_MODAL]({ commit, state }) {
    commit(mutationTypes.SET_MODALS, state.modals.slice(0, -1))
  },
  [SET_CAN_CLOSE_MODAL]({ commit }, canClose) {
    commit(mutationTypes.SET_CAN_CLOSE_MODAL, canClose)
  },
  /**
   * @name SHOW_MESSAGE
   * Adds a new message to store
   *
   * @param {Object} param0 Vuex action object
   * @param {Object} payload
   * @param {String} payload.type Type of message
   * @param {String} payload.displayType How to display the message (dialog|notification)
   * @param {String} payload.time Time to display the message, only if displayType = message
   * @param {String} payload.title Title to display, only if displayType = dialog
   * @param {String} payload.message Message to display
   * @param {Object} payload.actions
   * @param {Function|Object|String} payload.actions.undo Undo action
   * @param {Function|Object|String} payload.actions.submit Submit action
   * @param {Function|Object|String} payload.actions.cancel Cancel action
   * @returns {String} Message uniqueId
   */
  // TODO - Forwarding as a store action until all uses of it has been updated
  [SHOW_MESSAGE](_, payload) {
    return showMessage(payload)
  },

  /**
   * Update/Set new setting/state for view
   * @param {Object} param0 Vuex store object
   * @param {Object} payload
   * @param {String} payload.view What view to update
   * @param {Object} payload.settings Object of settings to update
   */
  [UPDATE_VIEW_SETTINGS]({ commit }, { view, settings }) {
    commit(mutationTypes.SET_VIEW_SETTINGS, { view, settings })
  },

  /**
   * Reset view settings to default settings
   * @param {Object} param0 Vuex store object
   */
  [RESET_VIEW_SETTINGS]({ commit }) {
    commit(mutationTypes.RESET_VIEW_SETTINGS)
  },

  /**
   * Set global application error
   * @param {Object} param0 Vuex store object
   * @param {String} payload.type True if it should show
   */
  [SHOW_ERROR]({ commit }, error) {
    commit(mutationTypes.SET_ERROR, error)
  },

  /**
   * Remove/reset global application error
   * @param {Object} param0 Vuex store object
   */
  [RESET_ERROR]({ commit }) {
    commit(mutationTypes.RESET_ERROR)
  }
}

import config from '@cling/config'
import logger from '@cling/services/logger'
import webStorage from '@cling/utils/webStorage'

import { io } from 'socket.io-client'

// This file exposes a socket class to communicate with an API
class Socket {
  constructor({ rootUrl, path, logger = console, getTokenFn }) {
    this.rootUrl = rootUrl
    this.path = path
    this.logger = logger
    this.getTokenFn = getTokenFn
  }

  _socket = null
  _isConnected = false
  _autoReconnect = false
  _localAutoReconnect = false // used locally in connect method
  _listeners = []
  _connectionChangedListener = []
  _retryCount = 0 // Track retry count to establish connection

  set autoReconnect(value) {
    this._autoReconnect = !!value
  }

  onConnectionChanged(fn) {
    this._connectionChangedListener.push(fn)
  }

  _onConnectionChanged(isConnected) {
    this._connectionChangedListener.forEach(fn => {
      try {
        fn(isConnected)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
    })
  }

  setConnected(isConnected) {
    if (!isConnected) this._retryCount = this._retryCount + 1

    this.logger.debug(`Socket: isConnected changed to ${isConnected}`)
    const hasChanged = this._isConnected !== isConnected

    this._isConnected = isConnected

    if (hasChanged) {
      this._onConnectionChanged(isConnected)
    }
  }

  _onConnected() {
    const token = this.getTokenFn()
    if (token) this._socket.emit('authenticate', { token })
  }

  on(type, fn) {
    this._listeners.push({ type, fn })

    if (this._socket) {
      this._socket.on(type, fn)
    }
    return () => this.off(type, fn) // Return fn to remove this listener
  }

  off(type, fn) {
    this._listeners.forEach((listener, index) => {
      if (listener.type === type && listener.fn === fn) {
        this._listeners.splice(index, 1)
        this._socket?.off(type, fn)
      }
    })
  }

  async connect() {
    // Delay based on retryCount
    const delay = retryCount =>
      new Promise(resolve => setTimeout(resolve, retryCount * 2 * 1000))
    await delay(this._retryCount)

    if (this._socket) {
      this._localAutoReconnect = false // Disable reconnect temp
      this.disconnect()
      this._localAutoReconnect = true
    }
    const { origin, pathname: path } = new URL(this.rootUrl + this.path)
    this._socket = io(origin, {
      path,
      transports: ['websocket', 'polling'] // Start with websocket (no sticky session needed)
    })

    this._socket.on('connect', this._onConnected.bind(this))

    this._socket.on('event', data => this.logger.debug('Socket: Event:', data))

    this._listeners.forEach(({ type, fn }) => {
      this._socket.on(type, fn)
    })

    this._socket.on('disconnect', () => {
      this.setConnected(false)
      if (this._localAutoReconnect && this._autoReconnect) this.connect() // Try to connect again
    })

    this._socket.on('authenticated', () => {
      this._retryCount = 0 // Reset connection retry count
      this.setConnected(true)
    })

    this._socket.on('unauthorized', () => {
      this.setConnected(false)
    })
  }

  disconnect() {
    if (this._socket) {
      this._socket.close()
      this._socket = null
    }
  }
}

const socket = new Socket({
  rootUrl: config.api.baseUrl,
  path: config.api.socket.path,
  logger,
  getTokenFn: () => webStorage.getItem('token')
})

export default socket

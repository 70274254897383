<template>
  <div
    v-if="editor"
    :class="[
      {
        'outside-wrapper': $slots.footer,
        'is-focus': editor.isFocused
      },
      `uid-${_uid}`
    ]"
  >
    <BubbleMenu
      v-if="editor.isEditable"
      :editor="editor"
      :extensions="enabledExtensions"
    />
    <slot name="label" />
    <!-- eslint-disable vue/valid-v-bind, vue/no-parsing-error -->
    <EditorContent
      :editor="editor"
      :class="[
        classList,
        `is-${size}`,
        {
          'textarea-style': textareaStyle,
          'is-filled': filled,
          'is-focused': editor.isFocused,
          'is-hovered': editor.isFocused,
          'is-static': static,
          'editor-base': !static && !textareaStyle, // TODO - Phase out this class,
          unstyled
        }
      ]"
      :disabled="disabled"
      class="quill-custom editor-deep-selector"
      @mouseover.passive="isHover = true"
      @mouseleave.passive="isHover = false"
    />
    <slot name="footer" />
    <EditorFixedMenu
      v-if="editor.isEditable"
      :editor="editor"
      :extensions="enabledExtensions"
      @upload-image="onUploadImage"
    />
  </div>
</template>

<script>
import { post } from '@cling/api'
import { getRootContext } from '@cling/utils'

import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { delegate } from 'tippy.js'

import BubbleMenu from './components/BubbleMenu.vue'
import EditorFixedMenu from './components/EditorFixedMenu.vue'
import DocumentMention from './DocumentMention'
import Image from './Image'
import { EditTable, ReadTable } from './Table'
import TrailingNode from './TrailingNode'

const postAction = async file => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  const { data } = await post('/file', formData, { timeout: 60000 })
  return data.url
}

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...(this.parent ? this.parent() : null),
      typography: {
        default: null,
        parseHTML: el => el.getAttribute('data-typography'),
        renderHTML: attrs => ({ 'data-typography': attrs.typography })
      }
    }
  },
  addCommands() {
    return {
      setParagraph:
        (attrs = {}) =>
        ({ commands }) =>
          commands.setNode('paragraph', attrs)
    }
  }
})

export default {
  name: 'TextEditor',
  i18nOptions: {
    namespaces: 'TextEditor',
    messages: {
      en: {
        placeholder: 'Type something'
      },
      sv: {
        placeholder: 'Skriv något'
      }
    }
  },
  components: {
    EditorContent,
    BubbleMenu,
    EditorFixedMenu
  },
  inject: {
    compressImages: {
      default: false
    }
  },
  props: {
    // Text
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    // Editor state
    editable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    extensions: {
      type: [Object, String],
      default: () => ({})
    },
    mentions: {
      type: Function,
      default: null
    },
    snippets: {
      type: Boolean,
      default: false
    },
    // Style
    classList: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: null
    },
    unstyled: {
      type: Boolean,
      default: false
    },
    textareaStyle: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    static: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null,
      isHover: false
    }
  },
  computed: {
    enabledExtensions() {
      const extensions = {
        bulletList: true,
        heading: [1, 2, 3],
        bold: true,
        link: true,
        underline: true,
        textAlign: true,
        color: true,
        table: true,

        image: false,
        highlight: false,
        blockquote: false,

        ...this.extensions,
        ...(typeof this.mentions === 'function' && { mentions: this.mentions }),
        snippets: this.snippets
      }

      if (this.extensions === 'all') {
        extensions.image = true
        extensions.highlight = true
        extensions.blockquote = true
      }

      return extensions
    }
  },
  watch: {
    value(value) {
      if (!this.editor) return

      // HTML
      const isSame = this.editor.getHTML() === value

      if (isSame) return

      this.editor.commands.setContent(value, false)
    },
    editable(value) {
      this.setEditable(value)
      // TODO create/destroy tooltips
    }
  },
  created() {
    if (!window.initalizedEditorTooltips) {
      this.createGeneralTooltip()
      window.initalizedEditorTooltips = true
    }
  },
  mounted() {
    this.init()
    this.$nextTick(() => this.createTooltips())
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy()
    this.destroyDynamicTooltips()
  },
  methods: {
    init() {
      const {
        // Nodes
        bulletList,
        blockquote,
        heading,
        image,
        mentions,
        // Marks
        bold,
        highlight,
        italic,
        link,
        underline,
        // Extensions
        color,
        textAlign,
        table
      } = this.enabledExtensions

      const extensions = [
        Placeholder.configure({
          emptyEditorClass: 'is-empty',
          placeholder: this.placeholder || this.$t('placeholder'),
          showOnlyWhenEditable: true
        }),
        StarterKit.configure({
          blockquote,
          bulletList,
          ...(heading && { heading: { levels: heading } }),
          bold,
          italic,

          // TODO enable on api, maybe expose as options in menu
          strike: true,
          code: true,
          codeBlock: true,
          paragraph: false
        }),
        CustomParagraph,
        TrailingNode
      ]

      if (link) extensions.push(Link)
      if (color) extensions.push(...[TextStyle, Color])
      if (highlight) extensions.push(Highlight)
      if (underline) extensions.push(Underline)
      if (textAlign) {
        extensions.push(
          TextAlign.configure({
            types: ['paragraph', 'heading', 'list_item', 'todo_item', 'title'],
            defaultAlignment: ''
          })
        )
      }
      if (table) {
        extensions.push(
          this.editable ? EditTable : ReadTable,
          TableRow,
          TableCell,
          TableHeader
        )
      }
      if (image) extensions.push(Image(postAction, this.compressImages))
      // Adding 'mentions' at the end of the array will cause higher priority than for example 'table'
      if (mentions) extensions.push(DocumentMention.call(this, mentions))

      this.editor = new Editor({
        editable: this.editable,
        content: this.value || '',
        extensions,
        onFocus: ({ event }) => this.$emit('focus', event),
        onBlur: ({ editor, event }) => {
          this.$emit('input', editor.isEmpty ? '' : editor.getHTML())
          this.$emit('blur', event)
        }
      })
    },
    setContent(value) {
      // Ref: https://github.com/ueberdosis/tiptap/blob/ee0816413579c0c02e3d679eb5732f1836122ac7/packages/core/src/commands/setContent.ts#L21
      // Second arg: emitUpdate = true to trigger onUpdate above, and subsequently input emit etc
      this.editor.commands.setContent((this.value || '') + value, true)
    },
    setEditable(editable) {
      if (typeof editable !== 'boolean') throw Error('Invalid param editable')
      if (!this.editor) return
      this.editor.setOptions({ editable })
    },
    focus() {
      return this.setFocus()
    },
    setFocus() {
      if (this.editor && this.editor.isEditable)
        this.editor.commands.focus('end')
    },
    async onUploadImage({ file }) {
      const newFile = file.target.files[0]
      const src = await postAction(newFile)
      if (this.editor) this.editor.chain().focus().setImage({ src }).run()
    },
    // TODO: Could ideally be moved to tiptap extension
    // TODO: Handling create/destroy of tippy instances etc
    createTooltips() {
      const { el, isShadow } = getRootContext(this.$root?.$el)
      const appendTo = isShadow ? el : el.body
      const parent = isShadow ? this.$el : `.uid-${this._uid}`

      this.dynamicTooltips = delegate(parent, {
        target: 'span[data-tooltip]',
        appendTo,
        content(reference) {
          const text = reference.getAttribute('data-tooltip')
          return `
            <div class="tooltip">
              <div class="tooltip-inner">
                ${text}
              </div>
            </div>
          `
        },
        theme: 'light',
        duration: [150, 0],
        delay: [200, 0],
        offset: [0, 7],
        animation: 'shift-away',
        allowHTML: true
      })
    },
    createGeneralTooltip() {
      const { el, isShadow } = getRootContext(this.$root?.$el)
      const appendTo = isShadow ? el : el.body

      delegate('body', {
        // Note: Performance
        // Could prolly register this function on the window to avoid having
        // several listeners running. If so, question is, when should it get destroyed?
        target: '[data-texteditor-tooltip]',
        // appendTo: () => document.body,
        appendTo,
        theme: 'dark',
        allowHTML: true,
        delay: 0,
        duration: 0,
        offset: [0, 4],
        placement: 'top',
        trigger: 'mouseenter',
        content(reference) {
          const text = reference.getAttribute('data-texteditor-tooltip')
          return `
            <div class="tooltip">
              <div class="tooltip-inner" style="background: hsla(0, 0%, 18%, 0.9); border-radius: 5px; font-size: 13px; padding: 2px 6px;">
                ${text}
              </div>
            </div>
          `
        }
      })
    },
    destroyDynamicTooltips() {
      if (!this.dynamicTooltips || !this.dynamicTooltips.length) return
      this.dynamicTooltips.forEach(x => {
        x.destroy()
      })

      if (this.delegatedTippy && this.delegatedTippy.destroy) {
        this.delegatedTippy.destroy()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@cling/styles/main.scss';

.editor-base {
  min-height: calc(7 * var(--rem));
  overflow: visible;
  border-radius: $borderRadiusMain;
  border: 1px solid $gray300;
  cursor: text;
}

.editor-deep-selector {
  &:not(.textarea-style) [contenteditable='true'] {
    min-height: calc(10 * var(--rem));
  }
  &:not(.unstyled) [contenteditable='true'] {
    padding: calc(calc(1 * var(--rem)) - 2px) calc(1 * var(--rem))
      calc(0.5 * var(--rem)) calc(1 * var(--rem));
  }
  // Placeholder text
  &:not(.has-content):not(.textarea-style) {
    p.is-empty:nth-child(1)::before {
      content: attr(data-placeholder);
      float: left;
      color: currentColor;
      opacity: 0.35;
      pointer-events: none;
      height: 0;
      position: absolute;
      top: calc(calc(1 * var(--rem)) - 2px);
      left: calc(1 * var(--rem));
      width: 100%;
    }
  }
  &.is-static [contenteditable='true'] {
    padding: 0;
    min-height: initial;
    & p.is-empty:nth-child(1)::before {
      top: 0;
      left: 0;
      width: 100%;
      color: currentColor;
      mix-blend-mode: difference;
      opacity: 0.5;
    }
  }
}

.outside-wrapper.outside-wrapper.outside-wrapper {
  @extend .editor-base;
  .editor-deep-selector {
    border: none;
    padding: 0;
  }
  &.is-focus {
    border-color: hsl(var(--primary-color-500) / 1);
  }
}
.quill-custom {
  font-size: calc(1 * var(--rem));

  &.unstyled {
    padding: 0;
    border: none;
    &:not(.has-content):not(.textarea-style):not(.unstyled) {
      p.is-empty:nth-child(1)::before {
        left: 0;
        top: 0;
      }
    }
  }

  &.is-large {
    font-size: calc(calc(1 * var(--rem)) + 2px);
  }
  &.is-x-large.is-x-large {
    font-size: calc(calc(1 * var(--rem)) + 4px);
  }
  h1 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    font-weight: 800;
  }
  h2 {
    font-size: 1.35em;
    margin-bottom: 0.5em;
    font-weight: 700;
  }
  h3 {
    font-size: 1.22em;
    margin-bottom: 0.5em;
    font-weight: 700;
  }
  p + h1 {
    margin-top: 1.5em;
  }
  p {
    line-height: 1.57;
    font-size: 1em;
    + p {
      margin-top: 0.5em;
    }
  }
  ul,
  ol {
    padding-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-size: 1em;
    & li::before {
      font-size: 1em;
      margin-right: 0.71em;
      color: $grey600;
    }
  }

  // TODO - Refactor so it shares styles with .quill-format class
  // Add support for decimal numbered lists 1 -> 1.1 -> 1.1.1
  // change only the nested lists
  ol {
    counter-reset: item;
    list-style: decimal;
    & > li {
      counter-increment: item;
    }

    ol > li {
      display: block;
      position: relative;
      margin-left: 1em;
    }

    ol > li:before {
      content: counters(item, '.') ' ';
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-100%, 0);
      padding-right: 0.5em;
    }
  }

  hr {
    height: 1em;
    width: 100%;
    border: none;
    letter-spacing: 1em;
    text-align: center;
    margin: 1em 0 1em 0;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      @apply bg-gray-200;
    }
  }
  a {
    font-size: 1em;
    white-space: pre-wrap;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 500px;
    border-radius: 0.5em;
    margin: 0 auto;
  }

  blockquote {
    border-left: 3px solid currentColor;
    padding-left: 1em;
    margin-bottom: 1em;
    padding-top: 0.25em;
    padding-bottom: 0.4em;
    p {
      margin: 0;
    }
  }
  > *:last-child,
  & > .ProseMirror > *:last-child {
    // Needed for two column support.
    // e.g. When an image has `float: left`
    clear: both;
  }
}

.font-inter .quill-custom {
  @include fontConfig();
  font-size: 16px;
  h1,
  h2,
  h3 {
    font-weight: 700;
  }
}

.quill-custom.plain {
  box-shadow: inset 0 0 0 1px hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 90%);
  &:hover {
    box-shadow: inset 0 0 0 2px $gray300;
  }
  &:focus,
  .is-focus & {
    box-shadow: inset 0 0 0 2px $gray300;
    background-color: transparent;
  }
  &.editor-deep-selector:not(.has-content) p.is-empty:nth-child(1)::before {
    color: rgba($gray600, 0.85);
  }
}
.quill-custom.rounder {
  border-radius: $borderRadiusSecondary;
}

/* Table-specific styling */
$borderColor: hsl(240, 4%, 87%); // TODO - Should be a color setting
$borderWidth: 1px; // TODO - Determine if it should be editable
$headerBgColor: hsl(240, 11%, 95%); // TODO - Should be a color setting
$cellPadding: 3px 5px; // TODO - Determine if it should be editable
$selectedCellBgColor: hsla(205, 100%, 50%, 0.2);
$resizeHandleColor: hsla(205, 100%, 50%, 0.5);

.quill-custom .ProseMirror {
  table {
    --borderWidth: $borderWidth;
    --borderColor: $borderColor;
    --cellPadding: $cellPadding;
    --headerBackground: $headerBgColor;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: var(--borderWidth, $borderWidth) solid
        var(--borderColor, $borderColor);
      padding: var(--cellPadding, $cellPadding);
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      font-weight: inherit;
      text-align: inherit;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: var(--headerBackground, $headerBgColor);
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $selectedCellBgColor;
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 3px;
      background-color: $resizeHandleColor;
      pointer-events: none;
      z-index: 9;
    }
  }
  .tableWrapper {
    overflow-x: auto;
    margin: 1em 0;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}

.quill-custom .mention {
  font-size: calc(1em - 2px);
  border-radius: 0.25em;
  padding: 0 0.25em;
  display: inline-block;
  background-color: hsl(var(--primary-color-50) / 0.9);
  color: hsl(var(--primary-color-900) / 1);
  box-shadow: 0 0 0 1px hsl(var(--primary-color-200) / 0.8);
  cursor: default;
  &.empty {
    box-shadow: 0 0 0 1px #fca5a5;
    color: red;
    background-color: #fef2f2;
    &::before {
      border-radius: 50%;
      vertical-align: sub;
      content: '';
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M12 9v4"></path><path d="M12 16v.01"></path></svg>');
      display: inline-block;
      width: 1.125em;
      height: 1.125em;
      margin-right: 0.25em;
    }
  }
}
</style>

import CompanyDocument from '@cling/models/document/companyDocument'
import permissions from '@cling/services/permissions'

export default {
  byData:
    (state, getters, rootState, rootGetters) =>
    ({ data, documentOptions }) =>
      new CompanyDocument({
        data,
        documentOptions,
        can: permissions.checkPermission.bind(permissions),
        getters: rootGetters
      }),
  byId: (state, getters) => id => {
    const docData = state.data[id]
    return !docData ? null : getters.byData({ data: docData })
  },
  // To get documents that match external references, example service: 'hubspot', type: 'deal', typeId: 'x'
  byExternalReference:
    (state, getters) =>
    ({ service, type = null, typeId = null }) => {
      if (typeof service === 'undefined')
        throw new Error('Missing param service')
      if (typeof service !== 'string')
        throw new Error('Param service must be a string')
      const docIds = state.ids.filter(id => {
        const docData = state.data[id]
        const { externalReferences = [] } = docData
        return externalReferences.some(
          ref =>
            ref.service === service &&
            (!type || ref.type === type) &&
            (!typeId || ref.typeId === typeId)
        )
      })
      return docIds.map(id => getters.byId(id))
    },
  isPosting: state => state.isPosting,
  isFetching: state => state.isFetching
}

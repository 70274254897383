import {
  getAllNotifications,
  getNotification,
  getUnreadNotifications,
  postNotification,
  setReadAllNotifications,
  setReadNotification,
  setReadNotificationsForProject
} from '@cling/api'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'notifications'

const {
  LOAD_NOTIFICATIONS,
  LOAD_UNREAD_NOTIFICATIONS,
  LOAD_NOTIFICATION,
  SET_READ_FOR_PROJECT,
  SET_NOTIFICATION_READ,
  SET_ALL_NOTIFICATION_READ,
  DO_CREATE_NOTIFICATION
} = actionTypes

const {
  CLEAR_NOTIFICATIONS,
  SET_NOTIFICATIONS_FETCHING,
  SET_NOTIFICATIONS_POSTING
} = mutationTypes

export default {
  /**
   * @name LOAD_NOTIFICATIONS
   *  Load all
   * @param {Object} Vuex object
   */
  async [LOAD_NOTIFICATIONS]({ commit }) {
    try {
      commit(SET_NOTIFICATIONS_FETCHING, true)
      const { data } = await getAllNotifications()
      commit(mutationTypes.SET_NOTIFICATIONS, { data })
      commit(mutationTypes.SET_NOTIFICATIONS_FETCHED_AT, Date.now())
    } catch (err) {
      this.handleError(err, {
        object: 'notification',
        fallbackCode: 'notification.getAll',
        action: `${moduleName}/${LOAD_NOTIFICATIONS}`
      })
    } finally {
      commit(SET_NOTIFICATIONS_FETCHING, false)
    }
  },

  /**
   * @name LOAD_UNREAD_NOTIFICATIONS
   *  Load unread
   * @param {Object} Vuex object
   * @param {Object} obj Optional
   * @param {Number} obj.throttleTime Optional time in sec to throttle
   */
  async [LOAD_UNREAD_NOTIFICATIONS](
    { commit, getters },
    { throttleTime = null } = {}
  ) {
    try {
      const { lastFetchedAt } = getters
      if (
        throttleTime &&
        throttleTime > 0 &&
        lastFetchedAt &&
        new Date() - lastFetchedAt < throttleTime * 1000
      ) {
        return
      }

      commit(SET_NOTIFICATIONS_FETCHING, true)
      const { data } = await getUnreadNotifications()
      commit(CLEAR_NOTIFICATIONS)
      commit(mutationTypes.SET_NOTIFICATIONS, { data })
      commit(mutationTypes.SET_NOTIFICATIONS_FETCHED_AT, Date.now())
    } catch (err) {
      this.handleError(err, {
        object: 'notification',
        fallbackCode: 'notification.getUnread',
        action: `${moduleName}/${LOAD_UNREAD_NOTIFICATIONS}`
      })
    } finally {
      commit(SET_NOTIFICATIONS_FETCHING, false)
    }
  },

  /**
   * @name LOAD_NOTIFICATION
   *  Load one notification by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the notification
   */
  async [LOAD_NOTIFICATION]({ commit }, { id }) {
    try {
      commit(SET_NOTIFICATIONS_FETCHING, true)
      const { data } = await getNotification(id)
      commit(mutationTypes.SET_NOTIFICATIONS, { data })
    } catch (err) {
      this.handleError(err, {
        object: 'notification',
        objectId: id,
        fallbackCode: 'notification.get',
        action: `${moduleName}/${LOAD_NOTIFICATION}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_NOTIFICATIONS_FETCHING, false)
    }
  },

  /**
   * @name SET_READ_FOR_PROJECT
   * Set all notifications as read for a project
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the project
   */
  async [SET_READ_FOR_PROJECT]({ commit, dispatch }, { id }) {
    try {
      commit(SET_NOTIFICATIONS_POSTING, true)
      await setReadNotificationsForProject(id)
      // Reload all notifications
      if (!this.getters['application/isSocketConnected'])
        await dispatch(actionTypes.LOAD_UNREAD_NOTIFICATIONS)
    } catch (err) {
      this.handleError(err, {
        object: 'project',
        objectId: id,
        fallbackCode: 'notification.setReadForProject',
        action: `${moduleName}/${SET_READ_FOR_PROJECT}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_NOTIFICATIONS_POSTING, false)
    }
  },

  /**
   * @name SET_NOTIFICATION_READ
   * Set one notifications as read
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the notification
   */
  async [SET_NOTIFICATION_READ]({ commit, dispatch }, { id }) {
    try {
      commit(SET_NOTIFICATIONS_POSTING, true)
      await setReadNotification(id)

      // Reload notification
      if (!this.getters['application/isSocketConnected'])
        await dispatch(actionTypes.LOAD_NOTIFICATION, { id })
    } catch (err) {
      this.handleError(err, {
        object: 'notification',
        objectId: id,
        fallbackCode: 'notification.setRead',
        action: `${moduleName}/${SET_NOTIFICATION_READ}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_NOTIFICATIONS_POSTING, false)
    }
  },

  /**
   * @name SET_ALL_NOTIFICATION_READ
   * Set all notifications as read
   * @param {Object} Vuex object
   */
  // Action to mark all notifications as read
  async [SET_ALL_NOTIFICATION_READ]({ commit, dispatch }) {
    try {
      commit(SET_NOTIFICATIONS_POSTING, true)
      await setReadAllNotifications()
      // Reload all notifications
      if (!this.getters['application/isSocketConnected'])
        await dispatch(actionTypes.LOAD_NOTIFICATIONS)
    } catch (err) {
      this.handleError(err, {
        object: 'notification',
        fallbackCode: 'notification.setAllRead',
        action: `${moduleName}/${SET_ALL_NOTIFICATION_READ}`
      })
    } finally {
      commit(SET_NOTIFICATIONS_POSTING, false)
    }
  },

  /**
   * @name DO_CREATE_NOTIFICATION
   * Create one notification of type text
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {String} obj.text Notification text
   *
   * @returns {Promise} Promise that resolves if successfully
   */
  async [DO_CREATE_NOTIFICATION]({ commit, dispatch }, { text }) {
    try {
      commit(SET_NOTIFICATIONS_POSTING, true)
      const body = {
        code: 'text',
        text
      }
      const { data } = await postNotification(body)
      const { id } = data
      await dispatch(LOAD_NOTIFICATION, { id })
    } finally {
      commit(SET_NOTIFICATIONS_POSTING, false)
    }
  }
}

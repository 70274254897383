export const actionTypes = {
  LOAD_PRICES: 'LOAD_PRICES',
  RESET_STRIPE_FORM: 'RESET_STRIPE_FORM',
  SET_STRIPE_FORM_VALUE: 'SET_STRIPE_FORM_VALUE',
  SET_FORM_PROMOTION: 'SET_FORM_PROMOTION',
  SUBMIT_PROMOTION_CODE: 'SUBMIT_PROMOTION_CODE',
  SUBMIT_PAYMENT_METHOD: 'SUBMIT_PAYMENT_METHOD',
  SUBMIT_FORM_PLAN: 'SUBMIT_FORM_PLAN',
  CONFIRM_PAYMENT: 'CONFIRM_PAYMENT',
  REMOVE_SUBSCRIPTION_ITEM: 'REMOVE_SUBSCRIPTION_ITEM',
  SUBMIT_FORM_PAYMENT: 'SUBMIT_STRIPE_FORM_PAYMENT',
  SUBMIT_FORM_PRODUCTS: 'SUBMIT_STRIPE_FORM_PRODUCTS'
}

export default {
  actionTypes
}

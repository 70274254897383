import errorHandler from '@cling/services/error'

export default {
  install(
    Vue,
    { store, logger, showMessage, onNetworkError, clearNetworkError } = {}
  ) {
    errorHandler.init({
      logger,
      showMessage,
      onNetworkError,
      clearNetworkError
    })

    // Make available i store
    store.handleError = errorHandler.handleError.bind(errorHandler)

    // Make available in all vue components
    Vue.prototype.$error = errorHandler.handleError.bind(errorHandler)

    // Catch uncaught exceptions in vue
    Vue.config.errorHandler = errorHandler.onVueException.bind(errorHandler)
  }
}

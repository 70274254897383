import { filterEmptyProps } from '@cling/models/document/utils'
import Feature from '@cling/services/features'

import get from 'lodash/get'
import uniqid from 'uniqid'

// This file provides methods to convert EndCustomer to ClientSchema
// Any changes here should be synced to API: src/libs/utils/client/index.js

const orgNoToVatNumber = orgNo => {
  if (!orgNo) return null
  let vatNumber = (orgNo || '').replace(/^SE|[-]/g, '').trim()
  if (vatNumber.length === 12) vatNumber = vatNumber.substring(2)
  return `SE${vatNumber}01`
}

export const getDefaultClientObject = () => {
  const deliveryTypes = ['email']
  if (
    Feature.checkFeature('sms') &&
    Feature._isValidCompanySetting('deliveryDefaultSms')
  )
    deliveryTypes.push('sms')
  return {
    region: { country: 'sweden' },
    permissions: {
      write: [
        'clients[*].socialNo',
        'clients[0].region.propertyDesignation',
        'data.rejectReasons',
        'data.packageGroups[*].packages[*].isSelected'
      ]
    },
    answer: null,
    deliveryTypes,
    documentRole: 'signee',
    answerMethod: {
      accept: 'signature',
      deny: 'button'
    },
    signOrder: 0,
    _uniqueId: uniqid()
  }
}

export const convertToNewClient = (old, newStored) => {
  const mappedOldToNew = {
    endCustomerId: old.id,
    type: old.customerType,
    name: old.customerType === 'individual' ? old.name : old.reference,
    socialNo: old.customerType === 'individual' ? old.org_no : null,
    companyName: old.customerType === 'company' ? old.name : null,
    orgNo: old.customerType === 'company' ? old.org_no : null,
    email: old.email,
    cellphone: old.cellphone,
    cellphoneRegion: old.cellphoneRegion,
    addresses: {
      ...(old.street || old.zip || old.city
        ? {
            standard: {
              street: old.street,
              zip: `${old.zip || ''}`,
              city: old.city,
              country: old.country,
              latitude: old.latitude ? `${old.latitude}` : null,
              longitude: old.longitude ? `${old.longitude}` : null
            }
          }
        : {}),
      ...(old.workStreet || old.workZip || old.workCity
        ? {
            work: {
              street: old.workStreet,
              zip: `${old.workZip || ''}`,
              city: old.workCity,
              country: old.country,
              latitude: old.latitude ? `${old.latitude}` : null, // ? Does it belong to standard or work?
              longitude: old.longitude ? `${old.longitude}` : null // ? Does it belong to standard or work?
            }
          }
        : {})
    },
    vatNumber: old.vatNumber,
    region: {
      ...(newStored && newStored.region),
      reverseVat: old.reverseVat && old.customerType === 'company'
    },
    documentRole: get(newStored, 'documentRole', 'signee'),
    signOrder: get(newStored, 'signOrder', 0)
  }

  const defaultObj = getDefaultClientObject()

  const result = {
    ...defaultObj,
    ...newStored,
    ...mappedOldToNew
  }

  return result
}

export const convertToOldClient = (newClient = {}) => {
  const _client = newClient
  const standardAddress = get(_client, 'addresses.standard', {})
  const workAddress = get(_client, 'addresses.work', {})
  const mappedNewToOld = {
    id: _client.endCustomerId,
    EndCustomerId: _client.endCustomerId, // used for invoice clients
    customerType: _client.type || 'individual',
    name: _client.type === 'individual' ? _client.name : _client.companyName,
    email: _client.email,
    cellphone: _client.cellphone,
    cellphoneRegion: _client.cellphoneRegion,
    org_no: _client.type === 'individual' ? _client.socialNo : _client.orgNo,
    street: standardAddress.street,
    zip: standardAddress.zip,
    city: standardAddress.city,
    workStreet: workAddress.street,
    workZip: workAddress.zip,
    workCity: workAddress.city,
    vatNumber: _client.vatNumber || orgNoToVatNumber(_client.orgNo) || '',
    separateWorkAddress: !!Object.keys(workAddress).length,
    reverseVat: get(_client, 'region.reverseVat', false),
    longitude: get(_client, 'addresses[0].longitude', null),
    latitude: get(_client, 'addresses[0].latitude', null),
    reference: _client.type === 'company' ? _client.name : null
  }

  return mappedNewToOld
}

// Header migration
const convertThemeToPriceHeaderFormat = ({
  background: resourceUrl,
  backgroundFilter: overlayColor,
  priceSummaryBackground: backgroundColor,
  showPriceSummary
}) => ({
  resourceUrl,
  overlayColor,
  backgroundColor,
  showPriceSummary
})

export const convertToNewHeader = ({ header = {}, theme = {} }) => {
  const {
    resourceUrl = '',
    title = '',
    backgroundColor = '',
    overlayColor = '',
    showPriceSummary
  } = {
    ...convertThemeToPriceHeaderFormat(theme),
    ...header
  }

  return {
    layout: 'price',
    baseStyle: {
      textColor: '',
      textAlign: 'left'
    },
    items: [
      {
        type: 'content',
        size: 50,
        media: {
          type: 'color',
          backgroundColor
        },
        value: {
          preText: '',
          documentName: title ? `<h1>${title}</h1>` : '',
          postText: '',
          showLogo: true,
          logotype: ''
        }
      },
      {
        type: 'media',
        size: 50,
        media: {
          type: 'image',
          resourceUrl,
          overlay: true,
          overlayColor
        }
      }
    ],
    ...(typeof showPriceSummary === 'boolean' && { showPriceSummary })
  }
}

export const convertPriceHeader = (doc, settingsTheme = {}) => {
  if (!doc || !doc.data) return doc
  // Active header ids
  const headerIds = get(doc, 'template.views.read.nodes', [])
    .filter(node => node.itemType === 'header' && !!node.value.header)
    .map(node => node.value.header.substring(5))

  headerIds.forEach(id => {
    if (doc.data[id] && doc.data[id].layout === 'legacy') {
      // Theme prio logic from doc theme getter
      const mergedTheme = {
        ...settingsTheme,
        ...filterEmptyProps(doc.data.theme)
      }

      doc.data[id] = convertToNewHeader({
        header: {
          ...doc.data[id],
          title: get(doc, 'data.name', '')
        },
        theme: mergedTheme
      })
    }
  })
  return doc
}

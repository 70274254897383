import lang from '@cling/language'

export default {
  components: {
    content: () => import('@/views/account/members/MembersRoot.vue')
  },
  children: [
    // IMPORTANT NOTE: Needs to below as [:id] acts like a wildcard
    {
      name: 'memberPage',
      path: ':id',
      components: {
        content: () => import('@/views/account/members/MemberPage.vue')
      },
      meta: {
        navbar: {
          type: 'default',
          title: lang.t('member', { postProcess: 'capitalize' })
        }
      }
    }
  ]
}

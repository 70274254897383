export default {
  install(Vue, { store } = {}) {
    Vue.mixin({
      computed: {
        $viewSettings() {
          return store.state.application.viewSettings
        }
      }
    })
  }
}

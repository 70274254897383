import lang from '@cling/language'

const routes = [
  {
    path: 'products/product/:id?',
    name: 'article',
    components: {
      content: () => import('@/components/forms/FormArticle.vue')
    },
    props: {
      content: true
    },
    meta: {
      navbar: {
        title: lang.t('article_plural', { postProcess: 'capitalize' }),
        type: 'simple',
        position: 'relative'
      },
      body: {
        background: 'white',
        fullSize: true
      }
    }
  }
]

export default routes

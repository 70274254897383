import config from '@cling/config'
import { viewContext } from '@cling/globalState'
import logger from '@cling/services/logger'
import { showMessage } from '@cling/services/messages'
import { global as globalActionTypes } from '@cling/store/action-types'

import { vOnClickOutside } from '@vueuse/components'
import PortalVue from 'portal-vue'

import brand from '@/extensions/brand'
import errorHandler from '@/extensions/errorHandler'
import eventTracker from '@/extensions/eventTracker'
import features from '@/extensions/features'
import featuresUpgrade from '@/extensions/featuresUpgrade'
import permissions from '@/extensions/permissions'
import piwik from '@/extensions/piwik'
import socket from '@/extensions/socket'
import supportChat from '@/extensions/supportChat'
import theme from '@/extensions/theme'
import thirdParty from '@/extensions/third-party'
import utm from '@/extensions/utm'
import validation from '@/extensions/validation'
import viewSettings from '@/extensions/viewSettings'

function install(Vue, { store }) {
  Vue.use(PortalVue)
  Vue.use(permissions, { store })
  Vue.use(features, { store })
  Vue.use(featuresUpgrade, { store })
  Vue.use(errorHandler, {
    store,
    logger,
    showMessage,
    onNetworkError: () =>
      store.dispatch(globalActionTypes.SHOW_ERROR, { type: 'NetworkError' }),
    clearNetworkError: () => store.dispatch(globalActionTypes.RESET_ERROR)
  })
  Vue.use(supportChat, { store })
  Vue.directive('click-outside', vOnClickOutside)
  Vue.use(thirdParty)
  Vue.use(logger)
  Vue.use(validation)
  Vue.use(eventTracker, { store })
  Vue.use(brand, { store })
  Vue.use(theme, { store })
  Vue.use(viewSettings, { store })
  Vue.use(utm, { store })
  Vue.use(piwik, { store })
  Vue.use(socket)

  // SETTING GLOBAL VIEW
  viewContext.value = 'app'

  Vue.prototype.$constants = {
    actions: globalActionTypes
  }

  // Development Settings
  if (config.debug) {
    Vue.config.productionTip = false
    Vue.config.devtools = true
    Vue.config.performance = true
  }
}

export default {
  install
}

import { normalize, schema } from 'normalizr'

const File = new schema.Entity('files')
const Files = new schema.Array(File)

const CompanyUser = new schema.Entity('companyUsers')
const CompanyUsers = new schema.Array(CompanyUser)
const Company = new schema.Entity('companies')
const Companies = new schema.Array(Company)

const Document = new schema.Entity('documents', {
  data: {
    Files
  }
})
const Documents = new schema.Array(Document)

const ProjectFile = new schema.Entity('projectFiles', {
  File
})
const ProjectFiles = new schema.Array(ProjectFile)

const Project = new schema.Entity('projects', {
  ProjectMembers: CompanyUsers,
  ProjectFiles: [
    {
      File
    }
  ],
  CompanyUser
})
const Projects = new schema.Array(Project)

const CompanyReference = new schema.Entity('companyReference', {
  CompanyReferenceItem: {
    Image: File,
    Thumbnail: File
  }
})
const CompanyReferences = new schema.Array(CompanyReference)

const DocumentTerm = new schema.Entity('documentTerms', {
  File
})
const DocumentTerms = new schema.Array(DocumentTerm)

const schemas = {
  project: Project,
  projects: Projects,
  companyReference: CompanyReference,
  companyReferences: CompanyReferences,
  projectFile: ProjectFile,
  projectFiles: ProjectFiles,
  documents: Documents,
  companyUser: CompanyUser,
  companyUsers: CompanyUsers,
  company: Company,
  companies: Companies,
  documentTerm: DocumentTerm,
  documentTerms: DocumentTerms
}

export default (object, type) => {
  const typeSchema = schemas[type]
  if (!typeSchema) {
    // eslint-disable-next-line no-console
    console.warn(`No schema found for type: '${type}'`)
  }
  return normalize(object, typeSchema)
}

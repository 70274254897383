export default {
  'x-world/x-3dmf': {
    labelColor: '#8D1A11',
    type: '3d'
  },
  '3ds': {
    labelColor: '#5FB9AD',
    type: '3d'
  },
  '3g2': {
    type: 'video'
  },
  'video/3gpp': {
    type: 'video'
  },
  'audio/3gpp': {
    type: 'audio'
  },
  '7zip': {
    type: 'compressed'
  },
  'audio/aac': {
    type: 'audio'
  },
  'application/vnd.audiograph': {
    type: 'video'
  },
  'application/postscript': {
    color: '#423325',
    gradientOpacity: 0,
    labelColor: '#423325',
    labelTextColor: '#FF7F18',
    labelUppercase: true,
    foldColor: '#FF7F18'
  },
  'audio/x-aiff': {
    type: 'audio'
  },
  'video/x-ms-asf': {
    type: 'video'
  },
  asp: {
    type: 'code'
  },
  aspx: {
    type: 'code'
  },
  'video/x-msvideo': {
    type: 'video'
  },
  'image/bmp': {
    type: 'image',
    extension: 'bmp'
  },
  'image/heic': {
    type: 'image',
    extension: 'heic'
  },
  'text/x-c': {
    type: 'code'
  },
  cs: {
    type: 'code'
  },
  'text/css': {
    type: 'code'
  },
  'text/csv': {
    type: 'spreadsheet'
  },
  cue: {
    type: 'document'
  },
  'application/x-msdownload': {
    type: 'settings'
  },
  'application/octet-stream': {
    type: 'drive'
  },
  'application/msword': {
    color: '#2C5898',
    foldColor: '#254A80',
    glyphColor: 'rgba(255,255,255,0.4)',
    labelColor: '#2C5898',
    labelUppercase: true,
    type: 'document',
    extension: 'doc'
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    color: '#2C5898',
    foldColor: '#254A80',
    glyphColor: 'rgba(255,255,255,0.4)',
    labelColor: '#2C5898',
    labelUppercase: true,
    type: 'document',
    extension: 'docx'
  },
  'image/vnd.dwg': {
    type: 'vector'
  },
  dxf: {
    type: 'vector'
  },
  eot: {
    type: 'font'
  },
  flac: {
    type: 'audio'
  },
  flv: {
    type: 'video'
  },
  fnt: {
    type: 'font'
  },
  fodp: {
    type: 'presentation'
  },
  fods: {
    type: 'spreadsheet'
  },
  fodt: {
    type: 'document'
  },
  fon: {
    type: 'font'
  },
  'image/gif': {
    type: 'image',
    color: 'aliceblue',
    extension: 'gif'
  },
  gz: {
    type: 'compressed'
  },
  htm: {
    type: 'code'
  },
  'text/html': {
    type: 'code'
  },
  indd: {
    color: '#4B2B36',
    gradientOpacity: 0,
    labelColor: '#4B2B36',
    labelTextColor: '#FF408C',
    labelUppercase: true,
    foldColor: '#FF408C'
  },
  ini: {
    type: 'settings'
  },
  'text/x-java-source': {
    type: 'code'
  },
  'image/jpeg': {
    type: 'image',
    color: 'aliceblue',
    extension: 'jpeg'
  },
  'image/jpg': {
    type: 'image',
    color: 'aliceblue',
    extension: 'jpeg'
  },
  'text/javascript': {
    labelColor: '#F7DF1E',
    type: 'code',
    extension: 'js'
  },
  'application/json': {
    type: 'code',
    extension: 'json'
  },
  jsx: {
    labelColor: '#00D8FF',
    type: 'code',
    extension: 'jsx'
  },
  m4a: {
    type: 'audio'
  },
  'video/x-m4v': {
    type: 'video'
  },
  max: {
    labelColor: '#5FB9AD',
    type: '3d'
  },
  md: {
    type: 'document'
  },
  mid: {
    type: 'audio'
  },
  mkv: {
    type: 'video'
  },
  'video/quicktime': {
    type: 'video'
  },
  'audio/mpeg': {
    type: 'audio'
  },
  'video/mp4': {
    type: 'video'
  },
  'video/mpeg': {
    type: 'video'
  },
  mpg: {
    type: 'video'
  },
  obj: {
    type: '3d'
  },
  odp: {
    type: 'presentation'
  },
  ods: {
    type: 'spreadsheet'
  },
  odt: {
    type: 'document'
  },
  ogg: {
    type: 'audio'
  },
  ogv: {
    type: 'video'
  },
  otf: {
    type: 'font'
  },
  'application/pdf': {
    labelColor: '#D93831',
    labelUppercase: true,
    type: 'acrobat',
    extension: 'pdf'
  },
  php: {
    labelColor: '#8892BE',
    type: 'code'
  },
  pkg: {
    type: '3d'
  },
  plist: {
    type: 'settings'
  },
  'image/png': {
    type: 'image',
    color: 'aliceblue',
    extension: 'png'
  },
  'application/vnd.ms-powerpoint': {
    color: '#D14423',
    foldColor: '#AB381D',
    glyphColor: 'rgba(255,255,255,0.4)',
    labelColor: '#D14423',
    labelUppercase: true,
    type: 'presentation',
    extension: 'ppt'
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    color: '#D14423',
    foldColor: '#AB381D',
    glyphColor: 'rgba(255,255,255,0.4)',
    labelColor: '#D14423',
    labelUppercase: true,
    type: 'presentation',
    extension: 'pptx'
  },
  pr: {
    type: 'video'
  },
  ps: {
    type: 'vector'
  },
  'image/vnd.adobe.photoshop': {
    color: '#34364E',
    gradientOpacity: 0,
    labelColor: '#34364E',
    labelTextColor: '#31C5F0',
    labelUppercase: true,
    foldColor: '#31C5F0'
  },
  py: {
    labelColor: '#FFDE57',
    type: 'code'
  },
  rar: {
    type: 'compressed'
  },
  rb: {
    labelColor: '#BB271A',
    type: 'code'
  },
  rm: {
    type: 'video'
  },
  rtf: {
    type: 'document'
  },
  scss: {
    labelColor: '#C16A98',
    type: 'code'
  },
  sitx: {
    type: 'compressed'
  },
  'image/svg+xml': {
    type: 'vector'
  },
  swf: {
    type: 'video'
  },
  sys: {
    type: 'settings'
  },
  tar: {
    type: 'compressed'
  },
  tex: {
    type: 'document'
  },
  'image/tiff': {
    type: 'image',
    color: 'aliceblue',
    extension: 'tiff'
  },
  ts: {
    labelColor: '#3478C7',
    type: 'code'
  },
  ttf: {
    type: 'font'
  },
  'text/plain': {
    type: 'document',
    extension: 'txt'
  },
  wav: {
    type: 'audio'
  },
  webm: {
    type: 'video'
  },
  wmv: {
    type: 'video'
  },
  woff: {
    type: 'font'
  },
  wpd: {
    type: 'document'
  },
  wps: {
    type: 'document'
  },
  xlr: {
    type: 'spreadsheet'
  },
  'application/vnd.ms-excel': {
    color: '#1A754C',
    foldColor: '#16613F',
    glyphColor: 'rgba(255,255,255,0.4)',
    labelColor: '#1A754C',
    labelUppercase: true,
    type: 'spreadsheet'
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    color: '#1A754C',
    foldColor: '#16613F',
    glyphColor: 'rgba(255,255,255,0.4)',
    labelColor: '#1A754C',
    labelUppercase: true,
    type: 'spreadsheet'
  },
  yml: {
    type: 'code'
  },
  'application/zip': {
    type: 'compressed'
  },
  zipx: {
    type: 'compressed'
  }
}

// Extension first
// export default {
//   '3dm': {
//     labelColor: '#8D1A11',
//     type: '3d',
//   },
//   '3ds': {
//     labelColor: '#5FB9AD',
//     type: '3d',
//   },
//   '3g2': {
//     type: 'video',
//   },
//   '3gp': {
//     type: 'video',
//   },
//   '7zip': {
//     type: 'compressed',
//   },
//   aac: {
//     type: 'audio',
//   },
//   aep: {
//     type: 'video',
//   },
//   ai: {
//     color: '#423325',
//     gradientOpacity: 0,
//     labelColor: '#423325',
//     labelTextColor: '#FF7F18',
//     labelUppercase: true,
//     foldColor: '#FF7F18',
//   },
//   aif: {
//     type: 'audio',
//   },
//   aiff: {
//     type: 'audio',
//   },
//   asf: {
//     type: 'video',
//   },
//   asp: {
//     type: 'code',
//   },
//   aspx: {
//     type: 'code',
//   },
//   avi: {
//     type: 'video',
//   },
//   bin: {
//     type: 'binary',
//   },
//   bmp: {
//     type: 'image',
//   },
//   c: {
//     type: 'code',
//   },
//   cpp: {
//     type: 'code',
//   },
//   cs: {
//     type: 'code',
//   },
//   css: {
//     type: 'code',
//   },
//   csv: {
//     type: 'spreadsheet',
//   },
//   cue: {
//     type: 'document',
//   },
//   dll: {
//     type: 'settings',
//   },
//   dmg: {
//     type: 'drive',
//   },
//   doc: {
//     color: '#2C5898',
//     foldColor: '#254A80',
//     glyphColor: 'rgba(255,255,255,0.4)',
//     labelColor: '#2C5898',
//     labelUppercase: true,
//     type: 'document',
//   },
//   docx: {
//     color: '#2C5898',
//     foldColor: '#254A80',
//     glyphColor: 'rgba(255,255,255,0.4)',
//     labelColor: '#2C5898',
//     labelUppercase: true,
//     type: 'document',
//   },
//   dwg: {
//     type: 'vector',
//   },
//   dxf: {
//     type: 'vector',
//   },
//   eot: {
//     type: 'font',
//   },
//   eps: {
//     type: 'vector',
//   },
//   exe: {
//     type: 'settings',
//   },
//   flac: {
//     type: 'audio',
//   },
//   flv: {
//     type: 'video',
//   },
//   fnt: {
//     type: 'font',
//   },
//   fodp: {
//     type: 'presentation',
//   },
//   fods: {
//     type: 'spreadsheet',
//   },
//   fodt: {
//     type: 'document',
//   },
//   fon: {
//     type: 'font',
//   },
//   gif: {
//     type: 'image',
//   },
//   gz: {
//     type: 'compressed',
//   },
//   htm: {
//     type: 'code',
//   },
//   html: {
//     type: 'code',
//   },
//   indd: {
//     color: '#4B2B36',
//     gradientOpacity: 0,
//     labelColor: '#4B2B36',
//     labelTextColor: '#FF408C',
//     labelUppercase: true,
//     foldColor: '#FF408C',
//   },
//   ini: {
//     type: 'settings',
//   },
//   java: {
//     type: 'code',
//   },
//   jpeg: {
//     type: 'image',
//   },
//   jpg: {
//     type: 'image',
//   },
//   js: {
//     labelColor: '#F7DF1E',
//     type: 'code',
//   },
//   json: {
//     type: 'code',
//   },
//   jsx: {
//     labelColor: '#00D8FF',
//     type: 'code',
//   },
//   m4a: {
//     type: 'audio',
//   },
//   m4v: {
//     type: 'video',
//   },
//   max: {
//     labelColor: '#5FB9AD',
//     type: '3d',
//   },
//   md: {
//     type: 'document',
//   },
//   mid: {
//     type: 'audio',
//   },
//   mkv: {
//     type: 'video',
//   },
//   mov: {
//     type: 'video',
//   },
//   mp3: {
//     type: 'audio',
//   },
//   mp4: {
//     type: 'video',
//   },
//   mpeg: {
//     type: 'video',
//   },
//   mpg: {
//     type: 'video',
//   },
//   obj: {
//     type: '3d',
//   },
//   odp: {
//     type: 'presentation',
//   },
//   ods: {
//     type: 'spreadsheet',
//   },
//   odt: {
//     type: 'document',
//   },
//   ogg: {
//     type: 'audio',
//   },
//   ogv: {
//     type: 'video',
//   },
//   otf: {
//     type: 'font',
//   },
//   pdf: {
//     labelColor: '#D93831',
//     labelUppercase: true,
//     type: 'acrobat',
//   },
//   php: {
//     labelColor: '#8892BE',
//     type: 'code',
//   },
//   pkg: {
//     type: '3d',
//   },
//   plist: {
//     type: 'settings',
//   },
//   png: {
//     type: 'image',
//   },
//   ppt: {
//     color: '#D14423',
//     foldColor: '#AB381D',
//     glyphColor: 'rgba(255,255,255,0.4)',
//     labelColor: '#D14423',
//     labelUppercase: true,
//     type: 'presentation',
//   },
//   pptx: {
//     color: '#D14423',
//     foldColor: '#AB381D',
//     glyphColor: 'rgba(255,255,255,0.4)',
//     labelColor: '#D14423',
//     labelUppercase: true,
//     type: 'presentation',
//   },
//   pr: {
//     type: 'video',
//   },
//   ps: {
//     type: 'vector',
//   },
//   psd: {
//     color: '#34364E',
//     gradientOpacity: 0,
//     labelColor: '#34364E',
//     labelTextColor: '#31C5F0',
//     labelUppercase: true,
//     foldColor: '#31C5F0',
//   },
//   py: {
//     labelColor: '#FFDE57',
//     type: 'code',
//   },
//   rar: {
//     type: 'compressed',
//   },
//   rb: {
//     labelColor: '#BB271A',
//     type: 'code',
//   },
//   rm: {
//     type: 'video',
//   },
//   rtf: {
//     type: 'document',
//   },
//   scss: {
//     labelColor: '#C16A98',
//     type: 'code',
//   },
//   sitx: {
//     type: 'compressed',
//   },
//   svg: {
//     type: 'vector',
//   },
//   swf: {
//     type: 'video',
//   },
//   sys: {
//     type: 'settings',
//   },
//   tar: {
//     type: 'compressed',
//   },
//   tex: {
//     type: 'document',
//   },
//   tif: {
//     type: 'image',
//   },
//   tiff: {
//     type: 'image',
//   },
//   ts: {
//     labelColor: '#3478C7',
//     type: 'code',
//   },
//   ttf: {
//     type: 'font',
//   },
//   txt: {
//     type: 'document',
//   },
//   wav: {
//     type: 'audio',
//   },
//   webm: {
//     type: 'video',
//   },
//   wmv: {
//     type: 'video',
//   },
//   woff: {
//     type: 'font',
//   },
//   wpd: {
//     type: 'document',
//   },
//   wps: {
//     type: 'document',
//   },
//   xlr: {
//     type: 'spreadsheet',
//   },
//   xls: {
//     color: '#1A754C',
//     foldColor: '#16613F',
//     glyphColor: 'rgba(255,255,255,0.4)',
//     labelColor: '#1A754C',
//     labelUppercase: true,
//     type: 'spreadsheet',
//   },
//   xlsx: {
//     color: '#1A754C',
//     foldColor: '#16613F',
//     glyphColor: 'rgba(255,255,255,0.4)',
//     labelColor: '#1A754C',
//     labelUppercase: true,
//     type: 'spreadsheet',
//   },
//   yml: {
//     type: 'code',
//   },
//   zip: {
//     type: 'compressed',
//   },
//   zipx: {
//     type: 'compressed',
//   },
// };

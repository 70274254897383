import config from '@cling/config'
import lang from '@cling/language'
import ClingCookies from '@cling-se/cookies'
import get from 'lodash/get'

const piwikId = get(config, 'piwik.id')

let isInstalled = false

const hiddenPaths = ['/a/extension', '/partner/connect']

export default {
  install(Vue) {
    const isPdfCapture = /\/document\/?.*\/pdf/.test(
      window?.location?.pathname || ''
    )

    // Allow to hide the cookie warning by query param
    // (Used for example during template screenshot)
    let hideCookieWarning = false
    try {
      const urlParams = new URL(window?.location).searchParams
      hideCookieWarning = urlParams.get('hideCookieWarning') === 'true'

      if (!hideCookieWarning) {
        const view = urlParams?.get('view')
        hideCookieWarning = ['hubspotNative', 'pipedrive'].includes(view)
      }

      if (!hideCookieWarning) {
        hideCookieWarning = hiddenPaths.some(path =>
          window.location.pathname.includes(path)
        )
      }
    } catch (err) {
      // Nothing
    }

    Vue.mixin({
      created() {
        if (piwikId && !isInstalled && !isPdfCapture && !hideCookieWarning) {
          ClingCookies?.init(piwikId, config.brand, lang.lang)
          isInstalled = true
        }
      }
    })
  }
}

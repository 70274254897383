/* eslint-disable no-console */
export default {
  namespaced: true,
  state: {
    isFetching: false,
    isPosting: false,
    ids: [],
    data: {}
  },
  getters: {
    byId: (state, ownGetters, rootState, getters) => id => {
      console.error('THIS GETTER SHOULD BE REMOVED ASAP')
      return null
    },
    byCreatorId:
      (state, ownGetters, rootState, getters) =>
      (creatorId, type = null) => {
        console.error('THIS GETTER SHOULD BE REMOVED ASAP')
        return []
      }
  }
}

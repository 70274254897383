export const actionTypes = {
  LOAD_COMPANY_USERS: 'LOAD_COMPANY_USERS',
  LOAD_COMPANY_USER: 'LOAD_COMPANY_USER',
  DO_NORMALIZE_COMPANY_USERS: 'DO_NORMALIZE_COMPANY_USERS',
  UPDATE_COMPANY_USER: 'UPDATE_COMPANY_USER',
  CREATE_COMPANY_USER: 'CREATE_COMPANY_USER',
  DELETE_COMPANY_USER: 'DELETE_COMPANY_USER',
  PATCH_COMPANY_USER: 'PATCH_COMPANY_USER',
  FORM_NEW_COMPANY_USER: 'FORM_NEW_COMPANY_USER',
  FORM_EDIT_COMPANY_USER: 'FORM_EDIT_COMPANY_USER',
  FORM_SUBMIT_COMPANY_USER: 'FORM_SUBMIT_COMPANY_USER'
}

export const mutationTypes = {
  SET_COMPANY_USERS: 'SET_COMPANY_USERS',
  DELETE_COMPANY_USER: 'DELETE_COMPANY_USER',
  SET_COMPANY_USERS_FETCHING: 'SET_COMPANY_USERS_FETCHING',
  SET_COMPANY_USERS_POSTING: 'SET_COMPANY_USERS_POSTING'
}

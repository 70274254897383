<template>
  <ManualPopover
    popover-inner-class="texteditor-tooltip-dropdown-inner"
    popover-class="no-animation"
    placement="bottom-start"
    @mousedown.native.prevent
  >
    <button :data-texteditor-tooltip="$t('fontStyle')" class="menu-button">
      <svg
        width="20"
        height="20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="1 4 46 44"
      >
        <path
          d="M 35.005859 7 A 2.0002 2.0002 0 0 0 33.132812 8.28125 L 23.132812 34.28125 A 2.000748 2.000748 0 0 0 26.867188 35.71875 L 29.066406 30 L 40.933594 30 L 43.132812 35.71875 A 2.000748 2.000748 0 1 0 46.867188 34.28125 L 36.867188 8.28125 A 2.0002 2.0002 0 0 0 35.005859 7 z M 10.980469 13 A 2.0002 2.0002 0 0 0 9.1425781 14.257812 L 3.2519531 28.982422 A 2.0002 2.0002 0 0 0 3.0253906 29.550781 L 1.1425781 34.257812 A 2.0002148 2.0002148 0 1 0 4.8574219 35.742188 L 6.3535156 32 L 15.646484 32 L 17.142578 35.742188 A 2.0002149 2.0002149 0 1 0 20.857422 34.257812 L 18.982422 29.572266 A 2.0002 2.0002 0 0 0 18.980469 29.566406 A 2.0002 2.0002 0 0 0 18.744141 28.976562 L 12.857422 14.257812 A 2.0002 2.0002 0 0 0 10.980469 13 z M 35 14.574219 L 39.394531 26 L 30.605469 26 L 35 14.574219 z M 11 20.386719 L 14.044922 28 L 7.9550781 28 L 11 20.386719 z"
        />
      </svg>
    </button>
    <template #popover>
      <div class="texteditor-tooltip-dropdown flex w-full flex-col text-left">
        <div
          v-close-popover
          :class="{
            'is-active': editor.isActive('paragraph', { typography: 'title' })
          }"
          class="texteditor-tooltip-dropdown-item"
          @mousedown.prevent
          @click="
            editor.chain().focus().setParagraph({ typography: 'title' }).run()
          "
        >
          {{ $t('title') }}
        </div>
        <div
          v-for="level in [1, 2, 3]"
          :key="`h${level}`"
          v-close-popover
          :class="{ 'is-active': editor.isActive('heading', { level }) }"
          class="texteditor-tooltip-dropdown-item"
          @mousedown.prevent
          @click="editor.chain().focus().setHeading({ level }).run()"
        >
          {{ $t(`h${level}`) }}
        </div>
        <div
          v-close-popover
          :class="{
            'is-active': editor.isActive('paragraph', { typography: null })
          }"
          class="texteditor-tooltip-dropdown-item"
          @mousedown.prevent
          @click="
            editor.chain().focus().setParagraph({ typography: null }).run()
          "
        >
          {{ $t('paragraph') }}
        </div>
        <div
          v-if="editor.commands.toggleBlockquote"
          v-close-popover
          :class="{ 'is-active': editor.isActive('blockquote') }"
          class="texteditor-tooltip-dropdown-item"
          @mousedown.prevent
          @click="editor.chain().focus().toggleBlockquote().run()"
        >
          {{ $t('blockquote') }}
        </div>
      </div>
    </template>
  </ManualPopover>
</template>

<script>
import ManualPopover from '@cling/components/ui/ManualPopover'

export default {
  name: 'TextEditorFontStyleMenu',
  components: {
    ManualPopover
  },
  i18nOptions: {
    namespaces: 'TextEditorFontStyleMenu',
    messages: {
      en: {
        fontStyle: 'Font style',
        title: 'Title',
        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
        blockquote: 'Quote',
        paragraph: 'Text'
      },
      sv: {
        fontStyle: 'Typsnitt',
        title: 'Titel',
        h1: 'Rubrik 1',
        h2: 'Rubrik 2',
        h3: 'Rubrik 3',
        blockquote: 'Citat',
        paragraph: 'Text'
      }
    }
  },
  props: {
    editor: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div>
    <draggable
      ref="draggable"
      :value="value"
      v-bind="{
        animation: 200,
        group: 'value',
        disabled: false,
        forceFallback: false,
        filter: '.is-transparent-dark',
        ghostClass: 'sortable-ghost',
        ghost: 'sortable-ghost'
      }"
      :class="{
        'recently-added': recentlyAdded
      }"
      class="checklist-container"
      @input="$emit('input', $event)"
      @start="isDragging = true"
      @end="isDragging = false"
    >
      <transition-group :name="animationName" tag="div" type="transition">
        <div v-for="(item, i) in value" :key="item._id" class="checklist-row">
          <CCheckbox
            :value="item.isChecked"
            :type="tag || 'secondary'"
            size="medium"
            style="padding-top: 0.65em"
            @input="
              item.text
                ? $emit(
                    'input',
                    immutable(value).set({ ...item, isChecked: $event }, i)
                  )
                : null
            "
          />
          <CInput
            ref="input"
            :value="item.text"
            :custom-class="'checklist-input'"
            type="textarea"
            auto-resize
            placeholder="Skriv något"
            size="medium"
            expanded
            reset
            style="width: 100%"
            @input="
              $emit('input', immutable(value).set({ ...item, text: $event }, i))
            "
            @keydown.native.enter.prevent
            @keyup.native.enter="
              addOnEnter
                ? item.text
                  ? onChecklistAdd()
                  : null
                : $event.target.blur()
            "
            @blur="
              addOnEnter && item.text
                ? onChecklistAdd
                : item.text
                  ? (addOnEnter = false)
                  : onRemove(i)
            "
          />
          <div class="checklist-control" style="padding-top: 3px">
            <CButton
              icon="x"
              circle
              type="none"
              pattern="tertiary"
              @click="onRemove(i)"
            />
          </div>
        </div>
      </transition-group>
    </draggable>
    <CButton
      :icon-props="{
        strokeWidth: 3,
        class: `has-text-${tag || 'secondary'}`
      }"
      type="text"
      pattern="tertiary"
      icon-left="plus"
      class="is-left"
      style="margin: 0.5em 0 1em 0; width: 100%"
      @click="onChecklistAdd"
    >
      {{ $t('add', { thing: $t('checklist') }) }}
    </CButton>
  </div>
</template>

<script>
import CCheckbox from '@cling/components/ui/Checkbox'
import { immutable } from '@cling/utils'

import draggable from 'vuedraggable'

export default {
  name: 'ProjectTaskChecklist',
  components: {
    draggable,
    CCheckbox
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    tag: {
      type: String,
      default: null
    },
    getDefaultItem: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      recentlyAdded: false,
      addOnEnter: false
    }
  },
  computed: {
    animationName() {
      if (this.disableAnimation) return null
      if (this.recentlyAdded) return 'add-list'
      return this.isDragging ? 'flip-list' : null
    }
  },
  methods: {
    immutable,
    onDragStart(e) {
      this.isDragging = true
      this.hideIndex = e.oldIndex
    },
    onDragEnd(e) {
      e.originalEvent.preventDefault()
      e.originalEvent.stopPropagation()
      this.isDragging = false
      this.hideIndex = null
    },
    toggleClass(value, timeout = 300) {
      this[value] = true
      setTimeout(() => {
        this[value] = false
      }, timeout)
    },
    onChecklistAdd() {
      this.toggleClass('recentlyAdded')
      if (this.value.length === 0 || this.value[this.value.length - 1].text) {
        this.$emit(
          'input',
          this.immutable(this.value).add(this.getDefaultItem())
        )
      }
      this.$nextTick(() => {
        this.$refs.input[this.value.length - 1].focus()
        this.addOnEnter = true
      })
    },
    onRemove(i) {
      this.toggleClass('recentlyAdded')
      this.$emit('input', immutable(this.value).remove(i))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.checklist {
  &-container {
    padding-top: 1em;
  }
  &-row {
    padding: 0 0.5em;
    display: flex;
    border-radius: 0.5em;
    align-items: flex-start;
    cursor: pointer;
    background-color: $white;
    .checklist-control {
      visibility: hidden;
      pointer-events: none;
    }
    &:hover {
      background-color: hsl(var(--gray-color-100) / 1);
      .checklist-control {
        visibility: initial;
        pointer-events: initial;
      }
    }
  }
  &-row ::v-deep .checklist-input {
    user-select: none;
    min-height: initial !important;
    height: 1px;
    padding: 0.5em !important;
    cursor: pointer;
    &:focus {
      user-select: initial;
      cursor: text;
    }
  }
}

.add-list {
  &-move {
    transition: 110ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: opacity, transform;
  }
  &-enter-active {
    transition: 110ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    transition-property: opacity, transform;
  }
  &-enter {
    opacity: 0;
    transform: translateY(-1em) scale(0.8, 0.8);
  }
  &-enter-to {
    opacity: 1;
    transform: translateY(0) scale(1, 1);
  }
  &-leave-active {
    position: absolute;
    opacity: 0;
  }
}

.checklist-container {
  ::v-deep .sortable-drag {
    opacity: 1;
    box-shadow: none;
  }
  ::v-deep .no-move {
    transition: none;
  }
  ::v-deep .flip-list-move {
    transition: none;
    /* transition: 400ms; */
  }
  ::v-deep .sortable-chosen {
    cursor: grabbing;
  }
  ::v-deep .is-dragged,
  ::v-deep .sortable-ghost {
    border: 2px dashed hsl(0, 0%, 80%) !important;
    background-color: transparent !important;
    * {
      visibility: hidden;
    }
  }
}
</style>

export const actionTypes = {
  DO_LOAD_ALL_SETTINGS: 'DO_LOAD_ALL_SETTINGS',
  LOAD_ALL_SETTINGS: 'LOAD_ALL_SETTINGS',
  DO_LOAD_SETTINGS_COMPANY: 'DO_LOAD_SETTINGS_COMPANY',
  DO_LOAD_SETTINGS_COMPANYUSER: 'DO_LOAD_SETTINGS_COMPANYUSER',
  LOAD_SETTINGS_COMPANY: 'LOAD_SETTINGS_COMPANY',
  LOAD_SETTINGS_COMPANYUSER: 'LOAD_SETTINGS_COMPANYUSER',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  DO_UPDATE_SETTINGS: 'DO_UPDATE_SETTINGS',
  FORM_EDIT_COMPANY_SETTINGS: 'FORM_EDIT_COMPANY_SETTINGS',
  FORM_SUBMIT_COMPANY_SETTINGS: 'FORM_SUBMIT_COMPANY_SETTINGS',
  FORM_EDIT_COMPANY_USER_SETTINGS: 'FORM_EDIT_COMPANY_USER_SETTINGS',
  FORM_SUBMIT_COMPANY_USER_SETTINGS: 'FORM_SUBMIT_COMPANY_USER_SETTINGS'
}

export const mutationTypes = {
  SET_SETTINGS: 'SET_SETTINGS',
  CLEAR_SETTINGS: 'CLEAR_SETTINGS',
  SET_SETTINGS_FETCHING: 'SET_SETTINGS_FETCHING',
  SET_SETTINGS_POSTING: 'SET_SETTINGS_POSTING'
}

<template>
  <div class="rounded-xl bg-white p-8">
    <div
      class="mb-6 text-lg font-semibold"
      v-text="$t('_common:send', { thing: $t('_common:reminder') })"
    />
    <div
      v-for="(client, i) in clientsToSend"
      :key="i"
      class="mb-3 rounded-lg border border-gray-200 p-2"
    >
      <div class="mb-2 pl-1">
        <div class="text-sm font-medium">
          {{ client.name || client.companyName }}
        </div>
        <div
          v-if="!!client.answer"
          class="text-xs text-gray-500"
          v-text="$t('alreadySigned').capitalize()"
        />
        <div
          v-else-if="client.documentRole === 'recipient'"
          class="text-xs text-gray-500"
          v-text="$t('cannotSign').capitalize()"
        />
      </div>
      <div class="flex">
        <div
          class="mr-2 flex items-center rounded-md border border-gray-200 bg-gray-100 py-1.5 pl-1.5"
        >
          <CCheckbox
            v-tooltip="{
              ...(!canSendType('sms', client) && {
                content: $t('_common:missing', {
                  thing: $t('_common:phoneNumber')
                })
              })
            }"
            :value="isTypeActive('sms', client)"
            size="tiny"
            :disabled="!feature('sms') || !canSendType('sms', client)"
            class="mr-2 font-medium"
            @input="setDeliveryMethod('sms', i)"
          >
            {{ $t('_common:sms') }}
          </CCheckbox>
        </div>
        <div
          class="flex items-center rounded-md border border-gray-200 bg-gray-100 py-1.5 pl-1.5 pr-2.5"
        >
          <CCheckbox
            v-tooltip="{
              ...(!canSendType('email', client) && {
                content: $t('_common:missing', { thing: $t('_common:email') })
              })
            }"
            :value="isTypeActive('email', client)"
            size="tiny"
            :disabled="!canSendType('email', client)"
            class="font-medium"
            @input="setDeliveryMethod('email', i)"
          >
            {{ $t('_common:email').capitalize() }}
          </CCheckbox>
        </div>
      </div>
    </div>
    <CButton
      type="secondary"
      size="small"
      taller
      icon="send"
      wide
      class="mt-8 font-semibold"
      @click="submit"
    >
      {{ $t('_common:send', { thing: $t('_common:reminder') }) }}
    </CButton>
  </div>
</template>

<script>
import CCheckbox from '@cling/components/ui/Checkbox'
import { global } from '@cling/store/action-types'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DocumentSendReminderModal',
  i18nOptions: {
    namespaces: 'documentSendReminderModal',
    messages: {
      en: {
        alreadySigned: 'already signed',
        cannotSign: "can't sign"
      },
      sv: {
        alreadySigned: 'har signerat',
        cannotSign: 'kan ej signera'
      }
    }
  },
  components: {
    CCheckbox
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      clientsToSend: []
    }
  },
  computed: {
    ...mapGetters({
      feature: 'application/feature'
    })
  },
  created() {
    const { events = [], clients = [] } = this.document || {}

    const sentClientIds = events.reduce(
      (res, { code, data }) =>
        code === 'sent' && data && data.clients && data.clients.length
          ? [...res, ...data.clients.map(({ id }) => id)]
          : res,
      []
    )

    // only allow sending reminders to clients which has received it in the first place
    this.clientsToSend = clients.filter(({ id }) => sentClientIds.includes(id))

    // If SMS feature is disabled, make sure to disable any input data
    if (!this.feature('sms')) {
      this.clientsToSend.forEach((client, index) => {
        if (this.isTypeActive('sms', client))
          this.setDeliveryMethod('sms', index)
      })
    }

    // disable as default clients which has already answered or has role 'recipient'
    this.clientsToSend.forEach((client, index) => {
      if (!!client.answer || client.documentRole === 'recipient') {
        ;['sms', 'email'].forEach(key => {
          if (this.isTypeActive(key, client)) this.setDeliveryMethod(key, index)
        })
      }
    })
  },
  methods: {
    ...mapActions({
      sendDocumentReminder: global.SEND_REMINDER_DOCUMENT2
    }),
    async submit() {
      await this.sendDocumentReminder({
        id: this.document.id,
        body: {
          clients: this.clientsToSend
        }
      })
      this.$emit('close')
    },
    canSendType(type, client) {
      const key = type === 'sms' ? 'cellphone' : 'email'
      return !!client[key]
    },
    isTypeActive(type, client) {
      return (
        client.deliveryTypes.includes(type) && this.canSendType(type, client)
      )
    },
    setDeliveryMethod(type, index) {
      const typeIndex = this.clientsToSend[index].deliveryTypes.findIndex(
        x => x === type
      )
      if (typeIndex > -1)
        this.clientsToSend[index].deliveryTypes.splice(typeIndex, 1)
      else this.clientsToSend[index].deliveryTypes.push(type)
    }
  }
}
</script>

<script>
export default {
  name: 'CFieldBody',
  props: {
    message: {
      type: String,
      default: ''
    },
    type: {
      type: [String, Object],
      default: ''
    }
  },
  render(createElement) {
    return createElement(
      'div',
      { attrs: { class: 'field-body' } },
      this.$slots.default.map(element => {
        // skip returns and comments
        if (!element.tag) {
          return element
        }
        if (this.message) {
          return createElement(
            'c-field',
            { attrs: { message: this.message, type: this.type } },
            [element]
          )
        }
        return createElement('c-field', { attrs: { type: this.type } }, [
          element
        ])
      })
    )
  }
}
</script>

import Spinner from '@cling/components/ui/Spinner'
import rollbar from '@cling/services/error/rollbar'

import VTooltip from 'v-tooltip'
import VueJsModal from 'vue-js-modal'

export default {
  install(Vue) {
    // Third party plugins
    Vue.rollbar = rollbar
    Vue.prototype.$rollbar = Vue.rollbar

    Vue.use(VueJsModal, {
      dynamic: true,
      injectModalsContainer: true,
      dynamicDefaults: {
        transition: 'no-animation'
      }
    })

    Vue.prototype.$modal.getOpenInstances = () => {
      const { _dynamicContainer: modalsContainer } =
        VueJsModal.rootInstance || {}

      if (!modalsContainer || modalsContainer.modals.length === 0) return []

      const { modals } = modalsContainer
      return modals
    }
    Vue.use(VTooltip, {
      defaultTrigger: 'hover click',
      disposeTimeout: 100,
      defaultDelay: 300,
      defaultHtml: false // Dont trust data by default, enable html: true on the specific tooltip if needed
    })

    // Third party components
    Vue.component('Spinner', Spinner)
  }
}

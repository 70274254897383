import {
  actionTypes as userActions,
  mutationTypes as userMutations
} from './user/constants'

export const actionTypes = {
  ...userActions,
  PUSH_MODAL: 'PUSH_MODAL',
  POP_MODAL: 'POP_MODAL',
  SET_CAN_CLOSE_MODAL: 'SET_CAN_CLOSE_MODAL',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  UPDATE_VIEW_SETTINGS: 'UPDATE_VIEW_SETTINGS',
  RESET_VIEW_SETTINGS: 'RESET_VIEW_SETTINGS',
  SHOW_ERROR: 'SHOW_ERROR',
  RESET_ERROR: 'RESET_ERROR'
}

export const mutationTypes = {
  ...userMutations,
  SET_MODALS: 'SET_MODALS',
  SET_CAN_CLOSE_MODAL: 'SET_CAN_CLOSE_MODAL',
  SET_VIEW_SETTINGS: 'SET_VIEW_SETTINGS',
  RESET_VIEW_SETTINGS: 'RESET_VIEW_SETTINGS',
  SET_ERROR: 'SET_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  SET_SOCKET_ENABLED: 'SET_SOCKET_ENABLED',
  SET_SOCKET_CONNECTED: 'SET_SOCKET_CONNECTED'
}

import {
  getCompanyReferences,
  getCompanyReference,
  postCompanyReference,
  putCompanyReference,
  deleteCompanyReference,
  patchCompanyReference
} from '@cling/api'
import lang from '@cling/language'
import { global } from '@cling/store/action-types'
import globalMutationTypes from '@cling/store/mutation-types'
import { companyReferences as companyReferenceListSchema } from '@cling/store/utils/schema'
import uniqid from 'uniqid'

import { actionTypes, mutationTypes } from './constants'
import { SET_AS_NORMALIZED_DATA } from '../../actions/constants'

const moduleName = 'companyReferences'

const { FORM_RESET_OLD, DELETE_COMPANY_REFERENCE_ITEM } = global

const {
  LOAD_COMPANY_REFERENCES,
  LOAD_COMPANY_REFERENCE,
  CREATE_COMPANY_REFERENCE,
  UPDATE_COMPANY_REFERENCE,
  DELETE_COMPANY_REFERENCE,
  PATCH_COMPANY_REFERENCE,
  FORM_NEW_COMPANY_REFERENCE,
  FORM_EDIT_COMPANY_REFERENCE,
  FORM_SUBMIT_COMPANY_REFERENCE
} = actionTypes

const { SET_COMPANY_REFERENCE_FETCHING, SET_COMPANY_REFERENCE_POSTING } =
  mutationTypes

export default {
  /**
   * @name LOAD_COMPANY_REFERENCES
   *  Load all companyReferences
   * @param {Object} Vuex object
   */
  async [LOAD_COMPANY_REFERENCES]({ commit }) {
    try {
      commit(SET_COMPANY_REFERENCE_FETCHING, true)
      const { data: companyReferences } = await getCompanyReferences()
      const { CLEAR_COMPANY_REFERENCE_ITEMS } =
        globalMutationTypes.companyReferenceItems

      this.commit(`companyReferenceItems/${CLEAR_COMPANY_REFERENCE_ITEMS}`)

      await this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: companyReferences,
        schema: companyReferenceListSchema
      })
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        fallbackCode: 'companyReference.get',
        action: `${moduleName}/${LOAD_COMPANY_REFERENCES}`
      })
    } finally {
      commit(SET_COMPANY_REFERENCE_FETCHING, false)
    }
  },

  /**
   * @name LOAD_COMPANY_REFERENCE
   *  Load one company reference by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReference
   */
  async [LOAD_COMPANY_REFERENCE]({ commit }, { id }) {
    try {
      commit(SET_COMPANY_REFERENCE_FETCHING, true)
      const { data: companyReference } = await getCompanyReference(id)

      await this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: [companyReference],
        schema: companyReferenceListSchema
      })
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        objectId: id,
        fallbackCode: 'companyReference.get',
        action: `${moduleName}/${LOAD_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_COMPANY_REFERENCE_FETCHING, false)
    }
  },

  /**
   * @name CREATE_COMPANY_REFERENCE
   * Create one
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Object} object.body CompanyReference body
   * @returns {Promise<Number>} Promise that resolves with new companyReference id
   */
  async [CREATE_COMPANY_REFERENCE]({ commit }, { body }) {
    try {
      commit(SET_COMPANY_REFERENCE_POSTING, true)
      const { data: companyReference } = await postCompanyReference(body)
      await this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: [companyReference],
        schema: companyReferenceListSchema
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('createdThing', { thing: lang.t('reference') })
      })
      return companyReference.id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        fallbackCode: 'companyReference.post',
        action: `${moduleName}/${CREATE_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_COMPANY_REFERENCE_POSTING, false)
    }
  },

  /**
   * @name UPDATE_COMPANY_REFERENCE
   *  Update one companyReference by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReference
   * @param {Object} object.body CompanyReference data
   * @returns {Promise<Number>} Promise that resolves with companyReference id or false
   */
  async [UPDATE_COMPANY_REFERENCE]({ commit, dispatch }, { id, body }) {
    try {
      commit(SET_COMPANY_REFERENCE_POSTING, true)
      await putCompanyReference(id, body)
      await dispatch(LOAD_COMPANY_REFERENCE, {
        id
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('updatedThing', { thing: lang.t('reference') })
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        objectId: id,
        fallbackCode: 'companyReference.put',
        action: `${moduleName}/${UPDATE_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_COMPANY_REFERENCE_POSTING, false)
    }
  },

  /**
   * @name DELETE_COMPANY_REFERENCE
   * Destroy one companyReference by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReference
   * @returns {Promise<Number>} Promise that resolves with companyReference id or false
   */
  async [DELETE_COMPANY_REFERENCE]({ commit }, { id }) {
    try {
      commit(SET_COMPANY_REFERENCE_POSTING, true)
      await deleteCompanyReference(id)
      commit(mutationTypes.DELETE_COMPANY_REFERENCE, id)
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', { thing: lang.t('reference') }),
        actions: {
          undo: () => {
            this.dispatch(`${moduleName}/${PATCH_COMPANY_REFERENCE}`, { id })
          }
        }
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        objectId: id,
        fallbackCode: 'companyReference.delete',
        action: `${moduleName}/${DELETE_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_COMPANY_REFERENCE_POSTING, false)
    }
  },

  /**
   * @name PATCH_COMPANY_REFERENCE
   * Patch one companyReference by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReference
   * @returns {Promise<Number>} Promise that resolves with companyReference id or false
   */
  async [PATCH_COMPANY_REFERENCE]({ commit, dispatch }, { id }) {
    try {
      commit(SET_COMPANY_REFERENCE_POSTING, true)
      await patchCompanyReference(id)
      await dispatch(LOAD_COMPANY_REFERENCE, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        objectId: id,
        fallbackCode: 'companyReference.patch',
        action: `${moduleName}/${PATCH_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_COMPANY_REFERENCE_POSTING, false)
    }
  },
  /**
   * @name FORM_NEW_COMPANY_REFERENCE
   *  Prepare new form
   * @param {Object} Vuex object
   */
  async [FORM_NEW_COMPANY_REFERENCE]({ commit }) {
    try {
      const formData = {
        name: '',
        description: ''
      }
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'companyReference', formData },
        { root: true }
      )
      // Clear existing items form
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'companyReferenceItems', formData: [] },
        { root: true }
      )
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        action: `${moduleName}/${FORM_NEW_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },

  /**
   * @name FORM_EDIT_COMPANY_REFERENCE
   *  Prepare edit form
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the companyReference
   */
  async [FORM_EDIT_COMPANY_REFERENCE](
    { commit, state, rootState, dispatch },
    { id }
  ) {
    try {
      if (!state.data[id]) {
        // Did not find the reference in the store, fetch it first
        await dispatch(LOAD_COMPANY_REFERENCE, { id })
      }
      const formData = state.data[id]
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'companyReference', formData },
        { root: true }
      )

      // Also prepare related companyReferenceItems
      // Clear existing items from form
      const { CompanyReferenceItems: companyReferenceItems } = formData
      // Array with item objects, sorted by position
      const referenceItemsList = companyReferenceItems
        .map(itemId => {
          const item = Object.assign(
            {},
            rootState.companyReferenceItems.data[itemId]
          )
          if (!item) {
            return null
          }
          item.uniqueId = uniqid()
          return item
        })
        .sort((a, b) => {
          if (a.position < b.position) {
            return -1
          } else if (a.position > b.position) {
            return 1
          }
          return 0
        })
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'companyReferenceItems', formData: referenceItemsList },
        { root: true }
      )
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        action: `${moduleName}/${FORM_EDIT_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
      return false
    }
  },

  /**
   * @name FORM_SUBMIT_COMPANY_REFERENCE
   *  Submit form
   * @param {Object} Vuex object
   */
  async [FORM_SUBMIT_COMPANY_REFERENCE]({ rootGetters, dispatch }) {
    try {
      const companyReferenceData =
        rootGetters['forms/getFormByKey']('companyReference')
      let { id } = companyReferenceData
      if (id) {
        await dispatch(UPDATE_COMPANY_REFERENCE, {
          id: companyReferenceData.id,
          body: companyReferenceData
        })
      } else {
        id = await dispatch(CREATE_COMPANY_REFERENCE, {
          body: companyReferenceData
        })
      }

      // Remove any companyReferenceItems that no longer should exist for this reference
      // Valid items
      const validCompanyReferenceItemIds = rootGetters['forms/getFormByKey'](
        'companyReferenceItems'
      ).map(item => item.id)
      // Stored items
      const storedItemIds =
        rootGetters['companyReferenceItems/itemIdsWithReferenceId'](id)
      await Promise.all(
        storedItemIds.map(async storedItemId => {
          if (!validCompanyReferenceItemIds.includes(storedItemId)) {
            await this.dispatch(
              DELETE_COMPANY_REFERENCE_ITEM,
              {
                id: storedItemId
              },
              {
                root: true
              }
            )
          }
          return Promise.resolve(id)
        })
      )

      // Reload reference as related items might have been deleted
      await dispatch(LOAD_COMPANY_REFERENCE, {
        id
      })

      await this.dispatch(FORM_RESET_OLD, 'companyReference')

      return id
    } catch (err) {
      this.handleError(err, {
        object: 'companyReference',
        action: `${moduleName}/${FORM_SUBMIT_COMPANY_REFERENCE}`,
        actionPayload: arguments[1]
      })
      return false
    }
  }
}

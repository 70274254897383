import { mutationTypes } from '../constants'

const { SET_USER_LOCATION, SET_USER } = mutationTypes

export default {
  [SET_USER_LOCATION](state, newPosition) {
    state.user.position = newPosition
  },

  [SET_USER](state, user) {
    Object.keys(user).forEach(key => {
      state.user[key] = user[key]
    })
  }
}

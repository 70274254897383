<template>
  <component :is="tag">
    <slot />
  </component>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'

const defaultOptions = {
  property: ['height', 'width'],
  transition:
    'height 150ms cubic-bezier(0.175, 0.885, 0.32, 1), width .25s ease-out, transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1)'
}

export default {
  name: 'Animation',
  mixins: [smoothReflow],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.$smoothReflow({ ...defaultOptions, ...this.options })
  }
}
</script>

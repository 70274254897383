<template>
  <div
    :class="className"
    class="c-badge_status inline-flex h-6 items-center rounded-full pl-1 pr-2 text-xs font-medium leading-none"
  >
    <CIcon v-if="icon" :type="icon" size="16" class="status-icon mr-1" />
    <span>{{ text }}</span>
  </div>
</template>
<script>
import lang from '@cling/language'
import get from 'lodash/get'

const statuses = {
  new: {
    statusClass: 'draft',
    icon: 'statusDraft'
  },
  draft: {
    statusClass: 'draft',
    icon: 'statusDraft'
  },
  scheduled: {
    statusClass: 'scheduled',
    icon: 'statusScheduled'
  },
  sent: {
    statusClass: 'sent',
    icon: 'statusSent'
  },
  denied: {
    statusClass: 'denied',
    icon: 'statusDenied'
  },
  accepted: {
    statusClass: 'accepted',
    icon: 'statusAccepted'
  },
  signed: {
    statusClass: 'accepted',
    icon: 'statusAccepted'
  },
  semiSigned: {
    statusClass: 'accepted',
    icon: 'statusSemiSigned'
  },
  expired: {
    statusClass: 'expired',
    icon: 'statusExpired'
  },
  voided: {
    statusClass: 'voided',
    icon: 'statusVoided'
  },
  active: {
    statusClass: 'active',
    icon: 'statusActive'
  },
  completed: {
    statusClass: 'completed',
    icon: 'statusCompleted'
  },
  archived: {
    statusClass: 'archived',
    icon: 'statusArchive'
  }
}

export default {
  props: {
    status: {
      type: String,
      default: ''
    },
    split: {
      type: String,
      default: ''
    }
  },
  computed: {
    text() {
      const statusText = this.status
        ? lang.t(`_common:status.${this.status}`)
        : ''
      return statusText
    },
    className() {
      const { statusClass = '' } = statuses[this.status] || {}
      return statusClass
    },
    icon() {
      return get(statuses, `${this.status}.icon`)
    }
  }
}
</script>
<style lang="scss">
@import '@cling/styles/main.scss';

.c-badge_status {
  &.draft {
    @apply border border-gray-200 bg-gray-100 text-gray-600/90;
    .status-icon {
      @apply text-gray-500;
    }
  }
  &.sent,
  &.scheduled {
    @apply border border-orange-200/70 bg-orange-50 text-orange-800/80;
    .status-icon {
      @apply text-orange-600/60;
    }
  }
  &.denied {
    @apply border border-red-100 bg-red-50 text-red-700/80;
    .status-icon {
      @apply text-red-600/70;
    }
  }
  &.accepted {
    @apply border border-green-600/10 bg-green-600/10 text-green-700;
    .status-icon {
      @apply text-green-600/80;
    }
  }
  &.expired {
    @apply border border-slate-500/10 bg-slate-500/10 text-slate-600;
    .status-icon {
      @apply text-slate-500/80;
    }
  }
  &.voided {
    @apply border border-pink-100 bg-pink-50 text-pink-700/80;
    .status-icon {
      @apply text-pink-600/70;
    }
  }
  &.active {
    @apply border border-purple-100 bg-purple-50 text-purple-900/80;
    .status-icon {
      @apply text-purple-400;
    }
  }
  &.archived {
    @apply border border-gray-200 bg-gray-100 text-gray-600;
    .status-icon {
      @apply text-gray-400;
    }
  }
  &.completed {
    @apply border border-blue-100 bg-blue-50 text-blue-800/80;
    .status-icon {
      @apply text-blue-400;
    }
  }
}
</style>

import Vue from 'vue'

import { mutationTypes } from './constants'

export default {
  // projectFiles: Array with objects
  [mutationTypes.SET_PROJECT_FILES](state, { data = [] }) {
    if (typeof data !== 'object') {
      throw Error('Data must be a projectFile or an array of projectFiles!')
    }
    const items = Array.isArray(data) ? data : [data]

    items.forEach(projectFile => {
      const { ProjectId: projectId } = projectFile
      if (!projectId) return false
      if (!state.data[projectId]) return false
      // Does the ProjectFiles array exist on project?
      let newProjectFiles = []
      if (state.data[projectId].ProjectFiles) {
        newProjectFiles = state.data[projectId].ProjectFiles
      } else {
        Vue.set(state.data[projectId], 'ProjectFiles', newProjectFiles)
      }
      // Does the projectFile already exist?
      const index = newProjectFiles.findIndex(e => e.id === projectFile.id)
      if (index !== -1) {
        newProjectFiles[index] = Object.assign(
          newProjectFiles[index],
          projectFile
        )
      } else {
        newProjectFiles.push(projectFile)
      }
      return true
    })
  },
  // Delete one
  [mutationTypes.DELETE_PROJECT_FILE](state, { projectId, id }) {
    if (!projectId) return false
    if (!state.data[projectId]) return false
    const projectFiles = state.data[projectId].ProjectFiles
    if (projectFiles && Array.isArray(projectFiles)) {
      const index = projectFiles.findIndex(e => e.id === id)
      if (index !== -1) {
        projectFiles.splice(index, 1)
      }
    }
    return true
  }
}

<template>
  <div
    v-if="group"
    v-tooltip="namesOfGroup && { content: namesOfGroup, offset: 5 }"
    :style="[staticStyle, groupStyle]"
    :class="[staticClass, 'c-avatar']"
    @click="emit('click')"
  >
    <span style="margin-left: -0.2em">+{{ sizeOfGroup }}</span>
  </div>
  <div
    v-else
    v-tooltip="tooltipContent"
    :style="[staticStyle, avatarStyle]"
    :class="[staticClass, 'c-avatar']"
    @click="emit('click')"
  >
    <div v-if="image" :style="imageStyle" class="c-avatar-image" />
    <div
      v-else
      :style="{
        fontSize: `${Math.min(Number(size) / 28, 1.2)}rem`,
        lineHeight: 1
      }"
    >
      {{ initial(name || email) }}
    </div>
    <div
      v-if="badge"
      :style="{
        left: `${0}px`,
        bottom: `${0}px`
      }"
      class="c-avatar-badge"
    />
  </div>
</template>

<script setup lang="ts">
import config from '@cling/config'
import lang from '@cling/language'

import { computed, useAttrs } from 'vue'

type Props = {
  name?: string
  image?: string
  email?: string
  size: number | string
  chained?: boolean
  group?: boolean
  sizeOfGroup?: number
  namesOfGroup?: string
  showTooltip?: boolean
  badge?: boolean
  custom?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  image: '',
  email: '',
  size: 30,
  chained: false,
  group: false,
  sizeOfGroup: 0,
  namesOfGroup: '',
  showTooltip: true,
  badge: false,
  custom: false
})

const attrs = useAttrs()
const emit = defineEmits(['click'])

const initial = (a: string): string => (a ? a[0].toUpperCase() : '?')

const stringToHslColor = (str: string) => {
  const s = 60
  const l = 92
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const h = hash % 360
  return {
    background: `hsl(${h}, ${s}%, ${l}%)`,
    color: `hsl(${h}, ${s * 0.5}%, ${l - 40}%)`
  }
}

const staticStyle = computed(() => attrs.style)
const staticClass = computed(() => attrs.class)

const groupStyle = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`
}))

const avatarStyle = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
  backgroundColor:
    !props.custom && stringToHslColor(props.name || props.email).background,
  color: !props.custom && stringToHslColor(props.name || props.email).color
}))

const apiUrl = config.api.baseUrl

const imageStyle = computed(() => ({
  backgroundImage: `url(${apiUrl}/file/${props.image}/download)`
}))

const tooltipContent = computed(() =>
  props.showTooltip
    ? {
        content:
          props.name || props.email || lang.t('components:base.avatar.tooltip'),
        offset: 5
      }
    : null
)
</script>

<style lang="scss">
@import '@cling/styles/main.scss';

.c-avatar {
  @include fontConfig();
  width: 30px;
  height: 30px;
  font-size: calc(1rem - 1px);
  border-radius: 50%;
  background-color: lighten($menuColor, 80%);
  color: lighten($menuColor, 45%);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: default;
  box-shadow: 0 0 0 2px $white;
  margin-right: -2px;
  position: relative;
  &-image {
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  &-badge {
    background: $cobalt;
    box-shadow: 0 0 0 2px $white;
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 50%;
  }
}
</style>

export const actionTypes = {
  DO_LOAD_PUBLIC_DOCUMENT: 'DO_LOAD_PUBLIC_DOCUMENT',
  DO_LOAD_PUBLIC_DOCUMENTS: 'DO_LOAD_PUBLIC_DOCUMENTS',
  PUT_PUBLIC_DOCUMENT: 'PUT_PUBLIC_DOCUMENT',
  PUT_CURRENT_PUBLIC_DOCUMENT: 'PUT_CURRENT_PUBLIC_DOCUMENT',
  LOAD_PUBLIC_DOCUMENT: 'LOAD_PUBLIC_DOCUMENT',
  LOAD_PUBLIC_DOCUMENTS: 'LOAD_PUBLIC_DOCUMENTS',
  ANSWER_PUBLIC_DOCUMENT: 'ANSWER_PUBLIC_DOCUMENT',
  ANSWER_CURRENT_PUBLIC_DOCUMENT: 'ANSWER_CURRENT_PUBLIC_DOCUMENT',
  DO_SET_CURRENT_DOCUMENT: 'DO_SET_CURRENT_DOCUMENT',
  SET_CURRENT_PUBLIC_DOCUMENT_VALUE: 'SET_CURRENT_PUBLIC_DOCUMENT_VALUE',
  RELOAD_CURRENT_DOCUMENT: 'RELOAD_CURRENT_DOCUMENT',
  LOAD_PUBLIC_COMPANY: 'LOAD_PUBLIC_COMPANY',
  LOAD_CLIENT_DOCUMENTS: 'LOAD_CLIENT_DOCUMENTS',
  REQUEST_MAGIC_LINK: 'REQUEST_MAGIC_LINK'
}

export const mutationTypes = {
  SET_PUBLIC_DOCUMENTS: 'SET_PUBLIC_DOCUMENTS',
  SET_PUBLIC_DOCUMENTS_FETCHING: 'SET_PUBLIC_DOCUMENTS_FETCHING',
  SET_PUBLIC_DOCUMENTS_POSTING: 'SET_PUBLIC_DOCUMENTS_POSTING',
  SET_CURRENT_DOCUMENT: 'SET_CURRENT_DOCUMENT',
  SET_CURRENT_DOCUMENT_VALUE: 'SET_CURRENT_DOCUMENT_VALUE',
  SET_PUBLIC_COMPANIES: 'SET_PUBLIC_COMPANIES',
  SET_VIEWER: 'SET_VIEWER'
}

export default {
  actionTypes,
  mutationTypes
}

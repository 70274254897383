export const actionTypes = {
  LOAD_UNIT_TYPES: 'LOAD_UNIT_TYPES',
  LOAD_UNIT_TYPE: 'LOAD_UNIT_TYPE',
  CREATE_UNIT_TYPE: 'CREATE_UNIT_TYPE',
  UPDATE_UNIT_TYPE: 'UPDATE_UNIT_TYPE',
  DELETE_UNIT_TYPE: 'DELETE_UNIT_TYPE'
}

export const mutationTypes = {
  SET_UNIT_TYPES: 'SET_UNIT_TYPES',
  DELETE_UNIT_TYPE: 'DELETE_UNIT_TYPE',
  SET_UNIT_TYPE_FETCHING: 'SET_UNIT_TYPE_FETCHING',
  SET_UNIT_TYPE_POSTING: 'SET_UNIT_TYPE_POSTING'
}

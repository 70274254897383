<template>
  <component :is="transformed" />
</template>

<script>
export default {
  name: 'DynamicLink',
  props: {
    template: {
      type: String,
      required: true
    }
  },
  computed: {
    transformed() {
      return { template: this.template }
    }
  }
}
</script>

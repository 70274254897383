import actions from './actions'
import _common from './common'
import components from './components'
import errors from './errors'
import iso from './iso'
import router from './router'
import views from './views'

export default {
  _common,
  errors,
  views,
  components,
  actions,
  router,
  iso
}

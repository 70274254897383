import {
  deleteArticle,
  getAllArticles,
  getArticle,
  patchArticle,
  postArticle,
  putArticle,
  searchArticle
} from '@cling/api'
import lang from '@cling/language'

import globalMutationTypes from '@cling/store/mutation-types'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'articles'

const {
  LOAD_ARTICLES,
  LOAD_ARTICLE,
  SET_ARTICLES,
  UPDATE_ARTICLE,
  DO_CREATE_ARTICLE,
  DELETE_ARTICLE,
  PATCH_ARTICLE,
  SEARCH_ARTICLE,
  FORM_NEW_ARTICLE,
  FORM_EDIT_ARTICLE,
  FORM_SUBMIT_ARTICLE
} = actionTypes

const { SET_ARTICLES_FETCHING, SET_ARTICLES_POSTING } = mutationTypes

export default {
  /**
   * @name LOAD_ARTICLES
   *  Load all articles into store
   */
  async [LOAD_ARTICLES]({ commit }, params = {}) {
    try {
      commit(SET_ARTICLES_FETCHING, true)
      const { data } = await getAllArticles(params)
      commit(globalMutationTypes.SET_ARTICLES, { data })
    } catch (err) {
      this.handleError(err, {
        object: 'article',
        fallbackCode: 'article.get',
        action: `${moduleName}/${LOAD_ARTICLES}`
      })
    } finally {
      commit(SET_ARTICLES_FETCHING, false)
    }
  },
  /**
   * @name LOAD_ARTICLE
   *  Load one article by id into store
   * @param {Number} id Numeric id of the article
   */
  async [LOAD_ARTICLE]({ commit }, { id }) {
    try {
      commit(SET_ARTICLES_FETCHING, true)
      const { data } = await getArticle(id)
      commit(globalMutationTypes.SET_ARTICLES, { data })
    } catch (err) {
      this.handleError(err, {
        object: 'article',
        objectId: id,
        fallbackCode: 'article.get',
        action: `${moduleName}/${LOAD_ARTICLE}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_ARTICLES_FETCHING, false)
    }
  },
  /**
   * @name UPDATE_ARTICLE
   *  Update one article by id
   * @param {Number} id Numeric id of the article
   * @param {Object} body Article data
   * @returns {Promise<Number>} Promise that resolves with article id or null
   */
  async [UPDATE_ARTICLE]({ dispatch, commit }, { id, body }) {
    try {
      commit(SET_ARTICLES_POSTING, true)
      await putArticle(id, body)
      await dispatch(LOAD_ARTICLE, {
        id
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('updatedThing', { thing: lang.t('article') })
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'article',
        objectId: id,
        fallbackCode: 'article.put',
        action: `${moduleName}/${UPDATE_ARTICLE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_ARTICLES_POSTING, false)
    }
  },
  /**
   * @name DO_CREATE_ARTICLE
   * Create one article
   * @param {Object} body Article data
   * @returns {Promise<Number>} Promise that resolves with article id
   */
  async [DO_CREATE_ARTICLE]({ commit }, { body, showMessage = true }) {
    try {
      commit(SET_ARTICLES_POSTING, true)
      const { data } = await postArticle(body)
      await commit(SET_ARTICLES, { data })
      if (showMessage) {
        this.dispatch('application/SHOW_MESSAGE', {
          type: 'success',
          message: lang.t('createdThing', { thing: lang.t('article') })
        })
      }
      return data.id
    } finally {
      commit(SET_ARTICLES_POSTING, false)
    }
  },
  /**
   * @name DELETE_ARTICLE
   * Destroy one article by id
   * @param {Number} id Numeric id of the article
   * @returns {Promise<Number>} Promise that resolves with article id or null
   */
  async [DELETE_ARTICLE]({ commit }, { id }) {
    try {
      commit(SET_ARTICLES_POSTING, true)
      await deleteArticle(id)
      commit(globalMutationTypes.DELETE_ARTICLE, id)
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', { thing: lang.t('article') }),
        actions: {
          undo: () => {
            this.dispatch(`articles/${PATCH_ARTICLE}`, { id })
          }
        }
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'article',
        objectId: id,
        fallbackCode: 'article.delete',
        action: `${moduleName}/${DELETE_ARTICLE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_ARTICLES_POSTING, false)
    }
  },
  /**
   * @name PATCH_ARTICLE
   * Patch one article by id
   * @param {Number} id Numeric id of the article
   * @returns {Promise<Number>} Promise that resolves with article id or null
   */
  async [PATCH_ARTICLE]({ dispatch, commit }, { id }) {
    try {
      commit(SET_ARTICLES_POSTING, true)
      await patchArticle(id)
      await dispatch(LOAD_ARTICLE, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'article',
        objectId: id,
        fallbackCode: 'article.patch',
        action: `${moduleName}/${PATCH_ARTICLE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_ARTICLES_POSTING, false)
    }
  },
  /**
   * @name SEARCH_ARTICLE
   * Search articles
   * @param {String} query Querystring to search for
   * @returns {[Object]} Array with article-objects directly from API
   */
  async [SEARCH_ARTICLE](_, { query }) {
    try {
      if (!query || query.length <= 0) {
        return []
      }
      const { data } = await searchArticle(query)
      return data
    } catch (err) {
      this.handleError(err, {
        object: 'article',
        fallbackCode: 'article.search',
        action: `${moduleName}/${SEARCH_ARTICLE}`,
        actionPayload: arguments[1]
      })
      return []
    }
  },
  /**
   * @name FORM_NEW_ARTICLE
   *  Prepare new article form
   */
  [FORM_NEW_ARTICLE]({ commit, rootGetters }) {
    const formData = rootGetters['articles/getNewArticle']()
    return commit(
      `forms/${globalMutationTypes.SET_FORM}`,
      { key: 'article', formData },
      { root: true }
    )
  },
  /**
   * @name FORM_EDIT_ARTICLE
   *  Prepare edit article form
   * @param {Number} id Numeric id of the article
   */
  [FORM_EDIT_ARTICLE]({ commit, getters }, { id }) {
    const articleData = getters.articleById(id)
    return commit(
      `forms/${globalMutationTypes.SET_FORM}`,
      { key: 'article', formData: articleData },
      { root: true }
    )
  },
  /**
   * @name FORM_SUBMIT_ARTICLE
   *  Submit article form
   * @returns {Promise<Number>} Promise that resolves with the article id or null
   */
  async [FORM_SUBMIT_ARTICLE]({ rootGetters, dispatch }) {
    try {
      const articleData = rootGetters['forms/getFormByKey']('article')
      let { id } = articleData
      if (id) {
        await dispatch(UPDATE_ARTICLE, {
          id: articleData.id,
          body: articleData
        })
      } else {
        id = await dispatch(DO_CREATE_ARTICLE, {
          body: articleData
        })
      }
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'article',
        fallbackCode: 'article.submit',
        action: `${moduleName}/${FORM_SUBMIT_ARTICLE}`
      })
      return null
    }
  }
}

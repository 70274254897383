import { getDisabledThirdParties } from '@cling/services/disabledServices'
import logger from '@cling/services/logger'

// This class helps to track important event that is communicated to external partners needed

const validEvents = [
  'pageView',
  'loadUser',
  'register',
  'setSector',
  'purchase',
  'viewer',
  'documentViewed'
]

/**
 * Function to call fbPixel
 */
function fbPixel(...args) {
  if (!window.fbq) return
  if (getDisabledThirdParties().includes('facebook')) return
  window.fbq(...args)
}

function _piwik(...args) {
  if (!window._paq) return
  window._paq.push(args)
}

/**
 * Install fb pixel
 * @param {String} fbKey
 * @returns {Boolean} Returns true if it was installed (or is installed), otherwise false
 */
function installFbPixel(fbKey) {
  if (getDisabledThirdParties().includes('facebook')) return false
  if (!document.getElementById('fbPixel')) {
    const elem = document.createElement('script')
    elem.type = 'text/javascript'
    elem.setAttribute('id', 'fbPixel')
    elem.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${fbKey}');
      fbq('track', 'PageView');
    `
    // We do not add the img as fallback, as it would only be added when js is available
    document.head.appendChild(elem)
  }

  if (window.fbq) window.fbq('consent', 'grant') // Grant permission (as it might have been revoked before)
  return true
}

class EventTracker {
  _store = null // reference to store
  _hasInstalledFbUserPixel = false // fbPixel script for user has been loaded
  _fbUserPixel = null // FB Pixel ID

  /**
   * Initialize eventTracker instance with ids and store
   * @param {Object} object
   * @param {String} object.fbUserPixel FB Pixel ID for companyUsers
   * @param {Object} object.store Vuex store
   */
  init({ store, fbUserPixel = null } = {}) {
    this._store = store
    this._fbUserPixel = fbUserPixel
  }

  get fbUserPixel() {
    return this._fbUserPixel
  }

  isSuperUser() {
    const { getters } = this._store || {}
    if (!getters) {
      throw new Error('Could not access store getters')
    }
    const isSuperUser = getters['application/isSuperUser']()
    return isSuperUser
  }

  /**
   * Get authenticated companyuser
   * @returns {Object|null} CompanyUser
   */
  _getCompanyUser() {
    const { getters } = this._store || {}
    if (!getters) {
      throw new Error('Could not access store getters')
    }
    const companyUser = getters['application/user']
    if (!companyUser) {
      return null
    }
    return companyUser
  }

  /**
   * Install FB Pixel script
   * Will inject fbPixel js into document
   */
  installFbUserPixel() {
    if (
      !getDisabledThirdParties().includes('facebook') &&
      !this._hasInstalledFbUserPixel &&
      this.fbUserPixel
    ) {
      if (installFbPixel(this.fbUserPixel)) this._hasInstalledFbUserPixel = true
    }
  }

  /**
   * Uninstall FB Pixel script
   */
  uninstallFbUserPixel() {
    if (window.fbq) window.fbq('consent', 'revoke') // Revoke permission for FB to track any data, otherwise any loaded source would still track
    if (document.getElementById('fbPixel'))
      document.getElementById('fbPixel').remove()

      // Try to remove any script that was injected during install
      // We must spread as getElementsByTagName does not return a normal array
    ;[...document.getElementsByTagName('script')]
      .filter(s => s.src && s.src.startsWith('https://connect.facebook.net'))
      .forEach(s => s.remove())

    this._hasInstalledFbUserPixel = false
  }

  /**
   * Method to track a event
   *
   * @param {String} event The event name, must be defined in validEvents
   * @param {Object} obj Optional data object
   */
  trackEvent(event, data = {}) {
    const { sector = null } = data
    const companyUser = this._getCompanyUser()

    if (!validEvents.includes(event))
      throw new Error(`Event: '${event}' is not a valid event`)
    if (this.isSuperUser()) {
      logger.debug('EventTracker: isSuperUser, do not track event.')
      return
    }

    if (event === 'pageView') {
      fbPixel('track', 'PageView')
    } else if (event === 'loadUser') {
      if (!companyUser)
        throw new Error(`Event '${event}' called without companyUser`)
      fbPixel('init', `${this.fbUserPixel}`, { uid: `${companyUser.id}` })
    } else if (event === 'register') {
      fbPixel('track', 'CompleteRegistration')
    } else if (event === 'setSector') {
      if (!sector) throw new Error(`Event '${event}' called without sector`)
      fbPixel('trackCustom', 'SetSector', { sector })
    } else if (event === 'purchase') {
      const { currency, amount, accountType } = data

      fbPixel('track', 'Purchase', {
        currency,
        value: amount / 100,
        content_name: accountType
      })
      _piwik('trackEvent', 'conversion', 'purchase', undefined, amount / 100)
    } else if (event === 'viewer') {
      fbPixel('trackCustom', 'viewer', data)
    } else if (event === 'documentViewed') {
      fbPixel('trackCustom', 'documentViewed', data)
    }
  }
}

const Instance = new EventTracker()

// Export method so other can call on syncUserData
export const { trackEvent } = Instance
export default Instance

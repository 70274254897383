export default {
  settings: {
    company: 'Company',
    user: 'My Profile',
    document: 'Document',
    members: 'Members',
    membersForm: 'Member',
    notifications: 'Notifications',
    integrations: 'Integrations',
    billing: 'Billing',
    language: 'Language',
    sms: 'SMS sender name',
    emailDomain: 'E-mail domain',
    subdomain: 'Subdomain',
    websitesSocialMedia: 'Social profiles',
    certificates: 'Certificates',
    theme: 'Theme',
    companyPresentation: 'Presentation',
    tabPhotos: 'Settings - Photos',
    photosVideos: 'My Photos',
    templates: 'Settings - Templates',
    templateEditor: 'Snippets',
    termForm: 'Edit - Terms',
    termList: 'My Terms'
  }
}

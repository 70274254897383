<template>
  <CField
    :label="label"
    :custom-class="customClass"
    :type="type"
    :message="firstErrorMessage"
    v-bind="{ ...$attrs, ...$props }"
  >
    <slot />
  </CField>
</template>
<script>
import CField from '@cling/components/ui/Field'
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  name: 'CFormField',
  components: {
    CField
  },
  extends: singleErrorExtractorMixin,
  computed: {
    type() {
      return this.hasErrors ? 'is-danger' : this.isValid ? 'is-success' : null
    },
    customClass() {
      return this.hasErrors
        ? 'text-red-500'
        : this.isValid
          ? 'text-green-500'
          : null
    }
  }
}
</script>

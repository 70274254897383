import { mutationTypes as application } from './modules/application/constants'
import { mutationTypes as certificates } from './modules/certificates/constants'
import { mutationTypes as companies } from './modules/companies/constants'
import { mutationTypes as companyReferenceItems } from './modules/companyReferenceItems/constants'
import { mutationTypes as companyReferences } from './modules/companyReferences/constants'
import { mutationTypes as documents2 } from './modules/documents2/constants'
import { mutationTypes as documentTerms } from './modules/documentTerms/constants'
import { mutationTypes as files } from './modules/files/constants'
import { mutationTypes as forms } from './modules/forms/constants'
import { mutationTypes as notifications } from './modules/notifications/constants'
import { mutationTypes as projects } from './modules/projects/constants'
import { mutationTypes as publicDocuments } from './modules/publicDocuments/constants'
import { mutationTypes as settings } from './modules/settings/constants'
import { mutationTypes as templates } from './modules/templates/constants'
import { mutationTypes as unitTypes } from './modules/unitTypes/constants'

// Description of all available mutation types
export default {
  application,
  projects,
  forms,
  files,
  settings,
  certificates,
  companies,
  companyReferences,
  companyReferenceItems,
  documents2,
  templates,
  publicDocuments,
  documentTerms,
  notifications,
  unitTypes,
  CLEAR_FORM: 'CLEAR_FORM',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  DELETE_COMPANY_USER: 'DELETE_COMPANY_USER',
  DELETE_CONTRACT: 'DELETE_CONTRACT',
  DELETE_ENDCUSTOMER: 'DELETE_ENDCUSTOMER',
  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_FILE: 'DELETE_PROJECT_FILE',
  DELETE_TEMPLATE_MESSAGE: 'DELETE_TEMPLATE_MESSAGE',
  SET_ARTICLES: 'SET_ARTICLES',
  SET_ATAS: 'SET_ATAS',
  SET_COMPANY_USERS: 'SET_COMPANY_USERS',
  SET_CONTRACTS: 'SET_CONTRACTS',
  SET_ENDCUSTOMERS: 'SET_ENDCUSTOMERS',
  SET_FORM: 'SET_FORM',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_OFFERS: 'SET_OFFERS',
  SET_PROJECTS: 'SET_PROJECTS',
  SET_PROJECT_FILES: 'SET_PROJECT_FILES',
  UPDATE_COMPANY: 'UPDATE_COMPANY'
}

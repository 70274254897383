import { getProjectNote, postProjectNote, putProjectNote } from '@cling/api'
import lang from '@cling/language'
import { mutationTypes as formMutationTypes } from '@cling/store/modules/forms/constants'

import { actionTypes, mutationTypes } from '../constants'

const moduleName = 'projects'

const {
  LOAD_PROJECT_NOTE,
  DO_CREATE_PROJECT_NOTE,
  DO_UPDATE_PROJECT_NOTE,
  FORM_NEW_PROJECT_NOTE,
  FORM_EDIT_PROJECT_NOTE,
  FORM_SUBMIT_PROJECT_NOTE
} = actionTypes

const {
  SET_PROJECT_NOTES,
  SET_PROJECT_NOTES_FETCHING,
  SET_PROJECT_NOTES_POSTING
} = mutationTypes

const { SET_FORM } = formMutationTypes

const actions = {
  /**
   * @name LOAD_PROJECT_NOTE
   * Load one project notes by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   */
  async [LOAD_PROJECT_NOTE]({ commit }, { id }) {
    try {
      commit(SET_PROJECT_NOTES_FETCHING, true)
      if (!id) {
        return
      }
      const { data: projectNote } = await getProjectNote(id)
      if (!projectNote) {
        return
      }
      commit(SET_PROJECT_NOTES, {
        projectNotes: [projectNote]
      })
    } catch (err) {
      this.handleError(err, {
        object: 'projectNote',
        objectId: id,
        fallbackCode: 'projectNote.get',
        action: `${moduleName}/${LOAD_PROJECT_NOTE}`,
        actionPayload: arguments[1]
      })
    } finally {
      commit(SET_PROJECT_NOTES_FETCHING, false)
    }
  },
  /**
   * @name DO_CREATE_PROJECT_NOTE
   * Create a new projectNote
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.body Project note data
   * @returns {Promise<Number>} Promise that resolves with project note id
   */
  async [DO_CREATE_PROJECT_NOTE]({ commit }, { body }) {
    try {
      commit(SET_PROJECT_NOTES_POSTING, true)

      const { data: projectNote } = await postProjectNote(body)
      commit(SET_PROJECT_NOTES, {
        projectNotes: [projectNote]
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('createdThing', { thing: lang.t('note') })
      })
      return projectNote.id
    } finally {
      commit(SET_PROJECT_NOTES_POSTING, false)
    }
  },
  /**
   * @name DO_UPDATE_PROJECT_NOTE
   * Update a projectNote with id and body
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the projectNote
   * @param {Object} object.body projectNote data
   * @returns {Promise<Number>} Promise that resolves with projectNote id
   */
  async [DO_UPDATE_PROJECT_NOTE]({ commit, dispatch }, { id, body }) {
    try {
      commit(SET_PROJECT_NOTES_POSTING, true)

      await putProjectNote(id, body)
      await dispatch(LOAD_PROJECT_NOTE, {
        id
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('updatedThing', { thing: lang.t('note') })
      })
      return id
    } finally {
      commit(SET_PROJECT_NOTES_POSTING, false)
    }
  },
  /**
   * @name FORM_NEW_PROJECT_NOTE
   * Prepare create new
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.projectId Optional numeric id of the project
   */
  [FORM_NEW_PROJECT_NOTE]({ commit }, { projectId = null }) {
    const formData = {
      ProjectId: projectId,
      text: ''
    }
    commit(
      `forms/${SET_FORM}`,
      { key: 'projectNote', formData },
      { root: true }
    )
  },
  /**
   * @name FORM_EDIT_PROJECT_NOTE
   * Prepare edit a projectNote
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.projectId Numeric id of the project to edit associated projectNote for
   */
  async [FORM_EDIT_PROJECT_NOTE]({ commit, state }, { projectId }) {
    try {
      const formData = state.data[projectId].ProjectNote
      commit(
        `forms/${SET_FORM}`,
        { key: 'projectNote', formData },
        { root: true }
      )
    } catch (err) {
      this.handleError(err, {
        object: 'projectNote',
        fallbackCode: 'projectNote.edit',
        action: `${moduleName}/${FORM_EDIT_PROJECT_NOTE}`,
        actionPayload: arguments[1]
      })
    }
  },
  /**
   * @name FORM_SUBMIT_PROJECT_NOTE
   * Submit form
   * @param {Object} Vuex object
   * @returns {Promise<Number>} Number with the projectNote id or null
   */
  async [FORM_SUBMIT_PROJECT_NOTE]({ rootGetters, dispatch }) {
    try {
      const projectNoteData = rootGetters['forms/getFormByKey']('projectNote')
      let { id } = projectNoteData
      if (id) {
        await dispatch(DO_UPDATE_PROJECT_NOTE, {
          id: projectNoteData.id,
          body: projectNoteData
        })
      } else {
        id = await dispatch(DO_CREATE_PROJECT_NOTE, {
          body: projectNoteData
        })
      }
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'projectNote',
        fallbackCode: 'projectNote.submit',
        action: `${moduleName}/${FORM_SUBMIT_PROJECT_NOTE}`
      })
      return null
    }
  }
}

export default actions

<script>
import elementResizeDetectorMaker from 'element-resize-detector'

if (!window.elementResizeDetectorMaker) {
  window.elementResizeDetectorMaker = elementResizeDetectorMaker({
    strategy: 'scroll'
  })
}

const getDeviceType = width => {
  if (width <= 640) return 'mobile'
  if (width <= 1024) return 'tablet'
  return 'desktop'
}

export default {
  provide() {
    if (!this.provideDevice) return

    return { containerType: this.containerType }
  },
  props: {
    provideDevice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: null,
      height: null,
      containerType: {
        device: 'desktop'
      }
    }
  },
  created() {
    if (this.provideDevice)
      this.$watch('width', v => {
        this.containerType.device = getDeviceType(v)
      })
    // Used as an approximate before there's an value
    this.width = window.innerWidth
    this.height = window.innerHeight
  },
  mounted() {
    window.elementResizeDetectorMaker.listenTo(this.$el, el => {
      this.width = el.offsetWidth
      this.height = el.offsetHeight
      this.$emit('width', el.offsetWidth)
      this.$emit('height', el.offsetHeight)
      this.$emit('resize')
    })
    this.$nextTick(() => {
      this.width = this.$el.offsetWidth
      this.height = this.$el.offsetHeight
    })
  },
  beforeDestroy() {
    window.elementResizeDetectorMaker.uninstall(this.$el)
  },
  render() {
    return this.$scopedSlots.default({
      width: this.width,
      height: this.height
    })
  }
}
</script>
<style>
/* Stylesheet needs to be defined so that the widget build can snap it up */
/* Created by the element-resize-detector library. */
.erd_scroll_detection_container > div::-webkit-scrollbar {
  display: none;
}

.erd_scroll_detection_container_animation_active {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-name: erd_scroll_detection_container_animation;
  animation-name: erd_scroll_detection_container_animation;
}
@-webkit-keyframes erd_scroll_detection_container_animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes erd_scroll_detection_container_animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

/**
 * Set cookie
 * @param {String} name Name of the cookie
 * @param {String} value Cookie value as a string
 * @param {Integer} days Optional cookie expiration time in days, defaults to 90
 */
export function setCookie(name, value, days = 90) {
  if (typeof name !== 'string') throw new Error('Param name must be a string')
  if (typeof value !== 'string') throw new Error('Param value must be a string')
  if (typeof days !== 'number') throw new Error('Param days must be a number')

  try {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`
  } catch (err) {
    // Nothing
  }
}

/**
 * Get a cookie by name
 * @param {String} name Name of the cookie
 * @returns {String|null}
 */
export function getCookie(name) {
  if (typeof name !== 'string') throw new Error('Param name must be a string')
  try {
    const nameEQ = `${name}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
  } catch (err) {
    // Nothing
  }
  return null
}

/**
 * Remove a cookie by name
 * @param {String} name Name of the cookie
 */
export function removeCookie(name) {
  if (typeof name !== 'string') throw new Error('Param name must be a string')
  try {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  } catch (err) {
    // Nothing
  }
}

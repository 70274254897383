export default {
  data: {}, // id as key
  ids: [], // array of ids in projects
  projectPublicIdToId: {}, // object with publicId -> id
  isFetching: false,
  isPosting: false,
  canFetchMore: true, // bool if there are more that can be fetched
  count: {}, // object with total project count for each status
  searchWord: '',
  isFetchingProjectNotes: false,
  isPostingProjectNotes: false
}

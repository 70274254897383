import Feature from '@cling/services/features'

export default {
  allCompanySettings: state => state.company,
  getCompanySetting: state => key => {
    const value = state.company[key]
    if (typeof value !== 'undefined') {
      return value
    }
    return null
  },
  getCompanyUserSetting: state => key => {
    const value = state.companyUser[key]
    if (typeof value !== 'undefined') {
      return value
    }
    return null
  },
  isPosting: state => state.isPosting,
  isFetching: state => state.isFetching,
  allowHidePrice: (state, getters) =>
    getters.getCompanySetting('work_allowHidePrice'),
  hidePriceOnNewlyAddedArticles: (state, getters) =>
    getters.allowHidePrice &&
    getters.getCompanySetting('work_default_hidePrice'),
  allowDocumentCustomSender: (state, getters) =>
    getters.getCompanySetting('allowProjectCustomSender'),
  allowHideDocArticlePriceInput: (state, getters) =>
    getters.getCompanySetting('allowHideDocArticlePriceInput'),
  defaultHideDocArticlePriceInput: (state, getters) =>
    getters.getCompanySetting('hideDocArticlePriceInput'),
  defaultVatRate: (state, getters) =>
    getters.getCompanySetting('defaultVatRate'),
  defaultCurrency: (state, getters) =>
    getters.getCompanyUserSetting('defaultCurrency'),
  documentSenderSign: (state, getters) =>
    getters.getCompanyUserSetting('documentSenderSign'),
  showOverviewOnboardingOverlay: (state, getters) =>
    getters.getCompanyUserSetting('showOverviewOnboardingOverlay'),
  reminderSettings: state => {
    const obj = state.company || {}
    return {
      notAnswered: obj.defaultReminderRemindDays,
      notViewed: obj.defaultReminderViewedDays,
      expires: obj.defaultReminderExpireDays
    }
  },
  defaultDocumentTheme: state => {
    const {
      documentThemeBackgroundFilter: backgroundFilter,
      documentThemePriceBackground: priceSummaryBackground,
      documentThemePrimaryColor: headingColor,
      offerBrandingBackgroundUrl: background
    } = state.company || {}
    return {
      ...(backgroundFilter &&
        backgroundFilter !== 'hsla(239,71%,89%,0)' && { backgroundFilter }),
      ...(priceSummaryBackground &&
        priceSummaryBackground !== 'hsla(215, 14%, 34%, 1)' && {
          priceSummaryBackground
        }),
      ...(headingColor &&
        headingColor !== 'hsla(215, 14%, 34%, 1)' && { headingColor }),
      ...(headingColor &&
        headingColor !== 'hsla(215, 14%, 34%, 1)' && { headingColor }),
      ...(background && { background })
    }
  },
  isAuthenticatedCRM: (state, getters) => crm => {
    const appToSettingsKey = {
      hubspot: 'hubspotNativeTokens',
      pipedrive: 'pipedriveNativeTokens'
    }
    return !!getters.getCompanyUserSetting(appToSettingsKey[crm])
  },
  // Not including Upsales due to limited support
  allAuthenticatedCRMs: (state, getters) =>
    ['hubspot', 'pipedrive'].filter(crm => !!getters.isAuthenticatedCRM(crm)),
  isFortnoxInstalled: (state, getters) =>
    !!getters.getCompanySetting('fortnoxTokens') &&
    Feature.checkFeature('fortnox'),
  stripeCustomDealToken: (state, getters) =>
    getters.getCompanySetting('stripeCustomDealToken')
}

import { getShortLink } from '@cling/api'

const route = {
  beforeEnter: async (to, from, next) => {
    try {
      const { params } = to
      const { publicId } = params

      const { data: link } = await getShortLink(publicId)
      window.location = link // redirect
      return next(false)
    } catch (err) {
      return next({ name: 'notFound' })
    }
  }
}

export default route

import { actionTypes as articleActions } from './articles/constants'

import {
  actionTypes as document2Actions,
  mutationTypes as document2Mutations
} from './document2/constants'

export const actionTypes = {
  ...articleActions,
  ...document2Actions,
  LOAD_DOCUMENT_FORM: 'LOAD_DOCUMENT_FORM',
  LOAD_UPGRADE_DOCUMENT_FORM: 'LOAD_UPGRADE_DOCUMENT_FORM',
  LOAD_ATA_DOCUMENT_FORM: 'LOAD_ATA_DOCUMENT_FORM',
  LOAD_DUPLICATE_DOCUMENT_FORM: 'LOAD_DUPLICATE_DOCUMENT_FORM',
  LOAD_DEMO_DOCUMENT_FORM: 'LOAD_DEMO_DOCUMENT_FORM',
  SAVE_DOCUMENT_FORM: 'SAVE_DOCUMENT_FORM',
  SAVE_DOCUMENT_FORM_AND_SEND: 'SAVE_DOCUMENT_FORM_AND_SEND',
  DO_SAVE_DOCUMENT: 'DO_SAVE_DOCUMENT',
  SUBMIT_DOCUMENT_AND_SEND: 'SUBMIT_DOCUMENT_AND_SEND',
  FORM_DOCUMENT_SET_CLIENT: 'FORM_DOCUMENT_SET_CLIENT',
  FORM_DOCUMENT_SUBMIT_CLIENT: 'FORM_DOCUMENT_SUBMIT_CLIENT',
  FORM_RESET_OLD: 'FORM_RESET_OLD',
  FORM_CLEAR_FORM: 'FORM_CLEAR_FORM',
  SET_FORM: 'SET_FORM',
  SET_FORM_VALUE: 'SET_FORM_VALUE',
  REMOVE_FORM_VALUE: 'REMOVE_FORM_VALUE',
  REMOVE_FORM_KEY_VALUE: 'REMOVE_FORM_KEY_VALUE'
}

export const mutationTypes = {
  ...document2Mutations,
  SET_FORM: 'SET_FORM',
  CLEAR_FORM: 'CLEAR_FORM',
  SET_FORM_VALUE: 'SET_FORM_VALUE',
  REMOVE_FORM_VALUE: 'REMOVE_FORM_VALUE',
  REMOVE_FORM_KEY_VALUE: 'REMOVE_FORM_KEY_VALUE'
}

// import { getAllDocumentTerms, getDocumentTerm, postDocumentTerm, putDocumentTerm, deleteDocumentTerm, patchDocumentTerm } from '@cling/api';
import { documentTerm } from '@cling/api'
import lang from '@cling/language'
import { global } from '@cling/store/action-types'
import globalMutationTypes from '@cling/store/mutation-types'

import { actionTypes, mutationTypes } from './constants'

import { SET_AS_NORMALIZED_DATA } from '../../actions/constants'

const moduleName = 'documentTerms'

const { FORM_RESET_OLD } = global

const {
  LOAD_DOCUMENT_TERMS,
  LOAD_DOCUMENT_TERM,
  CREATE_DOCUMENT_TERM,
  UPDATE_DOCUMENT_TERM,
  DELETE_DOCUMENT_TERM,
  PATCH_DOCUMENT_TERM,
  FORM_NEW_DOCUMENT_TERM,
  FORM_EDIT_DOCUMENT_TERM,
  FORM_SUBMIT_DOCUMENT_TERM
} = actionTypes

const { SET_DOCUMENT_TERMS_POSTING, SET_DOCUMENT_TERMS_FETCHING } =
  mutationTypes

export default {
  /**
   * @name LOAD_DOCUMENT_TERMS
   *  Load all documentTerms into store
   * @param {Object} Vuex object
   * @returns {Promise<Boolean>} Resolves with true if successfully, otherwise false
   */
  async [LOAD_DOCUMENT_TERMS]({ commit }) {
    try {
      commit(SET_DOCUMENT_TERMS_FETCHING, true)

      const { data } = await documentTerm.get()

      await this.dispatch(SET_AS_NORMALIZED_DATA, {
        data,
        schema: 'documentTerms'
      })

      return true
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        code: 'documentTerm.getAll',
        action: `${moduleName}/${LOAD_DOCUMENT_TERMS}`
      })
      return false
    } finally {
      commit(SET_DOCUMENT_TERMS_FETCHING, false)
    }
  },

  /**
   * @name LOAD_DOCUMENT_TERM
   *  Load one documentTerm by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id of the documentTerm
   * @returns {Promise<Boolean>} Resolves with true if successfully, otherwise false
   */
  async [LOAD_DOCUMENT_TERM]({ commit }, { id }) {
    try {
      if (typeof id === 'undefined') throw new Error('Missing param id')
      commit(SET_DOCUMENT_TERMS_FETCHING, true)
      const { data } = await documentTerm.get(id)

      await this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: [data],
        schema: 'documentTerms'
      })

      return true
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        objectId: id,
        code: 'documentTerm.get',
        action: `${moduleName}/${LOAD_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_DOCUMENT_TERMS_FETCHING, false)
    }
  },

  /**
   * @name CREATE_DOCUMENT_TERM
   *  Create one
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Object} object.body Body of the new documentTerm
   * @returns {Promise<Number|null>} Numeric id of the documentTerm or null
   */
  async [CREATE_DOCUMENT_TERM]({ commit }, { body }) {
    try {
      commit(SET_DOCUMENT_TERMS_POSTING, true)

      const { data } = await documentTerm.post(body)

      await this.dispatch(SET_AS_NORMALIZED_DATA, {
        data: [data],
        schema: 'documentTerms'
      })

      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('createdThing', {
          thing: lang.t('terms.conditionTerm')
        })
      })
      return data.id
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        code: 'documentTerm.post',
        action: `${moduleName}/${CREATE_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_DOCUMENT_TERMS_POSTING, false)
    }
  },

  /**
   * @name UPDATE_DOCUMENT_TERM
   *  Update one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @param {Object} object.body data
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [UPDATE_DOCUMENT_TERM]({ dispatch, commit }, { id, body }) {
    try {
      commit(SET_DOCUMENT_TERMS_POSTING, true)
      await documentTerm.put(id, body)
      await dispatch(actionTypes.LOAD_DOCUMENT_TERM, {
        id
      })
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('updatedThing', {
          thing: lang.t('terms.conditionTerm')
        })
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        objectId: id,
        code: 'documentTerm.put',
        action: `${moduleName}/${UPDATE_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_DOCUMENT_TERMS_POSTING, false)
    }
  },

  /**
   * @name DELETE_DOCUMENT_TERM
   * Destroy one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [DELETE_DOCUMENT_TERM]({ commit }, { id }) {
    try {
      commit(SET_DOCUMENT_TERMS_POSTING, true)
      await documentTerm.delete(id)
      commit(mutationTypes.DELETE_DOCUMENT_TERM, id)
      this.dispatch('application/SHOW_MESSAGE', {
        type: 'success',
        message: lang.t('removedThing', {
          thing: lang.t('terms.conditionTerm')
        }),
        actions: {
          undo: () => {
            this.dispatch(`documentTerms/${PATCH_DOCUMENT_TERM}`, { id })
          }
        }
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        objectId: id,
        code: 'documentTerm.delete',
        action: `${moduleName}/${DELETE_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_DOCUMENT_TERMS_POSTING, false)
    }
  },

  /**
   * @name PATCH_DOCUMENT_TERM
   * Patch one by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [PATCH_DOCUMENT_TERM]({ dispatch, commit }, { id }) {
    try {
      commit(SET_DOCUMENT_TERMS_POSTING, true)
      await documentTerm.patch(id)
      await dispatch(LOAD_DOCUMENT_TERM, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        objectId: id,
        code: 'documentTerm.patch',
        action: `${moduleName}/${PATCH_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_DOCUMENT_TERMS_POSTING, false)
    }
  },

  /**
   * @name FORM_NEW_DOCUMENT_TERM
   *  Prepare new form
   * @param {Object} Vuex object
   */
  async [FORM_NEW_DOCUMENT_TERM]({ commit }) {
    try {
      const formData = {
        id: null,
        name: '',
        type: 'text',
        text: '',
        href: null,
        version: 1,
        clientType: 'all',
        documentType: 'all',
        isDefault: false,
        isLocked: false,
        FileId: null
      }
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'documentTerm', formData },
        { root: true }
      )
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        code: 'documentTerm.new',
        action: `${moduleName}/${FORM_NEW_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
    }
  },

  /**
   * @name FORM_EDIT_DOCUMENT_TERM
   *  Prepare edit form
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number} object.id Numeric id
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [FORM_EDIT_DOCUMENT_TERM]({ commit, state }, { id }) {
    try {
      const documentTermData = state.data[id]
      commit(
        `forms/${globalMutationTypes.SET_FORM}`,
        { key: 'documentTerm', formData: documentTermData },
        { root: true }
      )
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        objectId: id,
        code: 'documentTerm.edit',
        action: `${moduleName}/${FORM_EDIT_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
      return null
    }
  },

  /**
   * @name FORM_SUBMIT_DOCUMENT_TERM
   *  Submit form
   * @param {Object} Vuex object
   * @returns {Promise<Number>} Promise that resolves with id or null
   */
  async [FORM_SUBMIT_DOCUMENT_TERM]({ rootGetters, dispatch }) {
    try {
      const documentTermData = rootGetters['forms/getFormByKey']('documentTerm')
      let { id } = documentTermData
      if (id) {
        await dispatch(actionTypes.UPDATE_DOCUMENT_TERM, {
          id: documentTermData.id,
          body: documentTermData
        })
      } else {
        id = await dispatch(actionTypes.CREATE_DOCUMENT_TERM, {
          body: documentTermData
        })
      }
      // Update in store
      dispatch(actionTypes.LOAD_DOCUMENT_TERM, {
        id
      })
      await this.dispatch(FORM_RESET_OLD, 'documentTerm')
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'documentTerm',
        code: 'documentTerm.submit',
        action: `${moduleName}/${FORM_SUBMIT_DOCUMENT_TERM}`,
        actionPayload: arguments[1]
      })
      return null
    }
  }
}

// date-fns v2.14.0
// supported locales: https://github.com/date-fns/date-fns/blob/v2.14.0/src/locale/index.js

export default [
  'af',
  'ar-DZ',
  'ar-MA',
  'ar-SA',
  'az',
  'be',
  'bg',
  'bn',
  'ca',
  'cs',
  'cy',
  'da',
  'de',
  'el',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-US',
  'eo',
  'es',
  'et',
  'eu',
  'fa-IR',
  'fi',
  'fr',
  'fr-CA',
  'gl',
  'gu',
  'he',
  'hi',
  'hr',
  'hu',
  'hy',
  'id',
  'is',
  'it',
  'ja',
  'ka',
  'kk',
  'kn',
  'ko',
  'lt',
  'lv',
  'mk',
  'ms',
  'mt',
  'nb',
  'nl',
  'nn',
  'pl',
  'pt',
  'pt-BR',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr',
  'sr-Latn',
  'sv',
  'ta',
  'te',
  'th',
  'tr',
  'ug',
  'uk',
  'uz',
  'vi',
  'zh-CN',
  'zh-TW'
]

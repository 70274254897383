import {
  ForbiddenError,
  GeneralError,
  NetworkError,
  NotFoundError,
  ValidationError
} from '@cling/services/error/types'
import eventBus from '@cling/services/eventBus'
import { addNestedUniqueIds, removeNestedUniqueIds } from '@cling/utils'
import webStorage from '@cling/utils/webStorage'

import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'

// Custom parser for params to handle params with same keys as ?value=1&value=2 instead of
// ?value[]=1&value[]=2
// Source: https://github.com/axios/axios/issues/604
const parseParams = params => {
  const keys = Object.keys(params)
  let options = ''

  keys.forEach(key => {
    const isParamTypeObject = typeof params[key] === 'object'
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach(element => {
        options += `${key}=${element}&`
      })
    }
  })

  return options ? options.slice(0, -1) : options
}

// Add request callback to que
const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb)
}

const onRefreshed = token => {
  refreshSubscribers.map(cb => cb(token))
}
function userShouldBeLoggedOut(error = '') {
  // eslint-disable-next-line no-console
  console.error('User should be logged out!!')
  webStorage.removeItem('token')
  webStorage.removeItem('tokenExpires')
  webStorage.removeItem('refreshToken')
  // eslint-disable-next-line no-undef
  if (__APP_TYPE__ !== 'widget') window.location.reload()
  else eventBus.trigger('user:unauthorized', { error })
}

// Set up helpers to refresh token
let isRefreshingToken = false // Bool to pause requests if refreshing
let refreshSubscribers = [] // Que to store any request that must wait

// Request interceptor to renew the token before any request if it has expired
const interceptRenewalsOfTokens = (instance, { renewToken }) => {
  instance.interceptors.request.use(
    config => {
      const originalRequest = config
      // If the request is to authenticate or is public route, return original
      if (
        originalRequest.url.includes('/auth') ||
        originalRequest.url.startsWith('/public/')
      ) {
        return originalRequest
      }

      const token = originalRequest.headers['X-TOKEN']
      // If no token is defined on original request, return original
      if (!token) {
        return originalRequest
      }
      const tokenExpires = webStorage.getItem('tokenExpires')
      if (token && tokenExpires) {
        const d1 = new Date()
        d1.setSeconds(d1.getSeconds() + 30) // 30 seconds grace period

        const d2 = new Date(tokenExpires)
        if (d1 > d2) {
          renewToken()

          const retryOrigReq = new Promise(resolve => {
            subscribeTokenRefresh(newToken => {
              // replace the expired token and retry
              originalRequest.headers['X-TOKEN'] = newToken
              resolve(originalRequest)
            })
          })
          return retryOrigReq
        }
      }
      return originalRequest
    },
    error =>
      // Do something with request error
      Promise.reject(error)
  )
}

// Interceptor to handle expired tokens when request failed
const interceptExpiredTokens = (instance, { renewToken }) => {
  instance.interceptors.response.use(
    response => response,
    error => {
      // If no error or no response at all (no connection), let next interceptor handle it
      if (!error || !error.response) {
        return Promise.reject(error)
      }
      const { config, response } = error
      const { status, data } = response
      const { errorCode } = data
      const originalRequest = config

      if (originalRequest.url.startsWith('/public/')) {
        return Promise.reject(error)
      }

      function isExpiredToken() {
        if (errorCode === 8001) return true
        const tokenExpires = webStorage.getItem('tokenExpires')
        if (tokenExpires) {
          const d1 = new Date()
          const d2 = new Date(tokenExpires)
          return d1 > d2
        }
        return false
      }

      // Unauthorized due to expired token
      if (
        status === 401 &&
        originalRequest.headers['X-TOKEN'] &&
        isExpiredToken() &&
        !isRefreshingToken
      ) {
        renewToken()

        const retryOrigReq = new Promise(resolve => {
          subscribeTokenRefresh(token => {
            // replace the expired token and retry
            originalRequest.headers['X-TOKEN'] = token
            resolve(axios(originalRequest))
          })
        })
        return retryOrigReq
        // If user got 401 and no ErrorCode then the token is really expired and cannot be reused
      } else if (status === 401 && typeof errorCode === 'undefined') {
        // If user is trying to authenticate, return the error
        if (
          originalRequest.url.includes('auth/companyUser') &&
          !originalRequest.headers['X-TOKEN']
        ) {
          // Dont do anything if we are currently logging in
          // We want to be able to display messages to the user
          return Promise.reject(error)
          // If the user is trying to login with an actionLink, return the error
        } else if (
          originalRequest.url.includes('auth/actionlink') &&
          !originalRequest.headers['X-TOKEN']
        ) {
          // Dont do anything if we are currently logging in
          // We want to be able to display messages to the user
          return Promise.reject(error)
        }
        userShouldBeLoggedOut(error?.message)
      }
      return Promise.reject(error)
    }
  )
}

const addUniqueIdsOnResponse = res => {
  addNestedUniqueIds(res.data)
  return res
}

// Interceptor to handle errors
const interceptErrors = instance => {
  instance.interceptors.response.use(addUniqueIdsOnResponse, error => {
    const { response, config } = error
    if (!response) {
      throw new NetworkError(error)
    }

    // check for errorHandle config
    if (
      Object.prototype.hasOwnProperty.call(config, 'errorHandle') &&
      config.errorHandle === false
    ) {
      return Promise.reject(error)
    }

    const { status } = response
    // Throw correct type of error depending on status and optional errorCode
    switch (status) {
      case 404: {
        throw new NotFoundError(error)
      }
      case 401: {
        throw new ForbiddenError(error)
      }
      case 412: {
        throw new ValidationError(error)
      }
      default: {
        throw new GeneralError(error)
      }
    }
  })
}

// If a POST request uses the __tempId key,
// make sure to also re-add the __tempId when retrieving the response
const interceptResponseTempIds = instance => {
  instance.interceptors.response.use(
    response => {
      if (
        response.config?.url?.includes('document') &&
        response.config.method === 'post'
      ) {
        const requestData = JSON.parse(response.config.data)
        if (requestData.__tempId) {
          response.data.__tempId = requestData.__tempId
        }
      }
      return response
    },
    error => Promise.reject(error)
  )
}

const interceptCleanUniqueIds = instance => {
  instance.interceptors.request.use(
    config => {
      if (
        ['post', 'put'].includes(config.method) &&
        config.data &&
        !(config.data instanceof FormData)
      ) {
        const dataCopy = cloneDeep(config.data)
        removeNestedUniqueIds(dataCopy)
        config.data = dataCopy
      }
      return config
    },
    error => Promise.reject(error)
  )
}

export const generateInstance = ({ baseUrl, withCredentials, cache }) => {
  // New instance of axios to use with clingapi
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 20000,
    adapter: cache, // allows caching of some requests
    withCredentials, // allow API to store cookie
    paramsSerializer: params => parseParams(params)
  })

  // eslint-disable-next-line no-undef
  instance.defaults.headers['X-CLIENT'] = __APP_TYPE__
  // eslint-disable-next-line no-undef
  instance.defaults.headers['X-CLIENT-VERSION'] = __APP_VERSION__

  // Function to send a request to cling api
  const sendRequest = async (method, url, data = null, config = {}) => {
    const isPublicRoute = url.startsWith('/public/') // Dont append token on public routes
    const token = webStorage.getItem('token')
    if (token && !isPublicRoute) {
      instance.defaults.headers['X-TOKEN'] = token
    }
    switch (method) {
      case 'get': {
        return instance.get(url, config)
      }
      case 'post': {
        return instance.post(url, data, config)
      }
      case 'put': {
        return instance.put(url, data, config)
      }
      case 'delete': {
        return instance.delete(url, config)
      }
      case 'patch': {
        return instance.patch(url, data, config)
      }
      default: {
        throw new Error(
          `SendRequest failed as method: '${method}' is not implemented`
        )
      }
    }
  }

  // Wrappers to send request to api
  const get = (url, config = {}) => sendRequest('get', url, null, config)
  const put = (url, data = {}, config = {}) =>
    sendRequest('put', url, data, config)
  const post = (url, data, config = {}) =>
    sendRequest('post', url, data, config)
  const del = (url, config = {}) => sendRequest('delete', url, null, config)
  const patch = (url, config = {}) => sendRequest('patch', url, null, config)

  // Function to refresh the token
  const renewToken = async () => {
    if (isRefreshingToken) return
    try {
      isRefreshingToken = true
      const refreshToken = webStorage.getItem('refreshToken')
      const result = await post('/auth/companyUser', { refreshToken })
      if (!result.data || !result.data.token) {
        throw new Error('No token was defined in result')
      }
      const { token, tokenExpires, refreshToken: newRefreshToken } = result.data
      webStorage.setItem('token', token)
      webStorage.setItem('tokenExpires', tokenExpires)
      if (newRefreshToken) webStorage.setItem('refreshToken', newRefreshToken)

      isRefreshingToken = false

      onRefreshed(token)
      refreshSubscribers = [] // clear queue
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return userShouldBeLoggedOut(err?.message)
    } finally {
      isRefreshingToken = false
    }
  }

  interceptRenewalsOfTokens(instance, { renewToken })
  interceptExpiredTokens(instance, { renewToken })
  addUniqueIdsOnResponse(instance)
  interceptErrors(instance)
  interceptCleanUniqueIds(instance)
  interceptResponseTempIds(instance)

  return {
    instance,
    get,
    put,
    post,
    del,
    patch,
    renewToken
  }
}

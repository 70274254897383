<template>
  <transition name="fade-in">
    <div
      v-if="showQueryVideo"
      class="v-overlay font-inter"
      :style="{
        backgroundColor: showOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.7)'
      }"
      @click.self="() => (showQueryVideo = false)"
    >
      <div
        style="
          padding: 2rem;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          pointer-events: none;
        "
      >
        <div class="close-btn" style="pointer-events: auto">
          <CIcon type="x" size="34" />
        </div>
      </div>
      <div class="c-flex-row c-align-middle column justify-center">
        <div style="width: 1280px; max-width: 90vw">
          <div id="vimeo-player"></div>
        </div>
        <div
          v-if="showOverlay"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
            color: white;
          "
          class="c-flex-row c-align-middle c-align-center column text-center"
          @click.exact="popCloseBtn"
        >
          <div
            style="
              font-size: calc(18px + 0.5vw);
              font-weight: 700;
              pointer-events: none;
            "
          >
            <div>{{ title }}</div>
          </div>
          <div
            style="
              font-size: calc(14px + 0.35vw);
              opacity: 0.6;
              padding-top: 0.25em;
              pointer-events: none;
            "
          >
            {{ duration }}
          </div>
          <div
            class="c-flex-row c-align-middle c-align-center column"
            style="margin-top: 1rem; pointer-events: none"
          >
            <CButton
              icon="play"
              type="secondary"
              size="large"
              :loading="loading"
              :icon-props="{
                style: 'transform: translateX(2px)',
                fill: 'white'
              }"
              circle
              style="margin: 0 0 3rem 0; pointer-events: auto"
              @click="onPlay"
            />
            <CButton
              ref="closeBtn"
              type="text"
              size="normal"
              style="opacity: 0.75; pointer-events: auto; transition: 80ms"
              @click="() => (showQueryVideo = false)"
              v-text="$t('_common:close')"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VimeoPlayer from '@vimeo/player'
import { mapState } from 'vuex'

function secondsToTime(e) {
  const m = Math.floor((e % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const s = Math.floor(e % 60)
    .toString()
    .padStart(2, '0')

  return `${m}:${s}`
}

export default {
  name: 'QueryVideo',
  data() {
    return {
      showQueryVideo: false,
      showOverlay: true,
      title: '\u00a0', // non-breaking-space
      duration: '\u00a0', // non-breaking-space
      player: null,
      loading: false
    }
  },
  computed: {
    ...mapState('route', {
      videoId: state => state.query.v
    })
  },
  watch: {
    videoId: {
      immediate: true,
      handler(val) {
        if (!val) return
        if (!this.videoId) return

        // Remove query from url to allow reload without resetting the doc form
        const query = Object.assign({}, this.$route.query)
        delete query.v
        this.$router.replace({ ...this.$route, query })

        this.showQueryVideo = true

        setTimeout(() => {
          this.initPlayer(val)
        }, 0)
      }
    }
  },
  methods: {
    async initPlayer(id) {
      const options = {
        id: id,
        width: '100%',
        portrait: 0,
        byline: 0,
        title: 0
      }
      try {
        this.loading = true
        this.player = new VimeoPlayer('vimeo-player', options)
        this.title = await this.player.getVideoTitle()
        this.duration = secondsToTime(await this.player.getDuration())
      } catch (error) {
        this.showQueryVideo = false
      } finally {
        this.loading = false
      }
    },
    onPlay() {
      if (this.player) this.player.play()
      this.showOverlay = false
    },
    popCloseBtn() {
      if (!this.$refs.closeBtn) return
      this.$refs.closeBtn.$el.style.transform = 'scale(1.3)'

      setTimeout(() => {
        if (!this.$refs.closeBtn) return
        this.$refs.closeBtn.$el.style.transform = 'scale(1)'
      }, 200)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.v-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.8);
  z-index: 9999;
  overflow: auto;
}

.close-btn {
  color: white;
  transition: 100ms;
  border-radius: 0.5em;
  cursor: pointer;
  padding: 0.5em;
  &:hover {
    background-color: hsla(0, 0%, 100%, 0.1);
  }
}

#vimeo-player {
  padding: 56.25% 0 0 0;
  position: relative;

  ::v-deep iframe,
  ::v-deep object,
  ::v-deep embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div>
    <CDropdown
      :mobile-label="
        isFortnoxExport
          ? $t('mobileLabel')
          : $t('_common:download', { thing: 'PDF' })
      "
      expanded
    >
      <template #trigger="{ active }">
        <div
          @click="
            isFortnoxExport || documents.length > 1 ? null : onPrintSolo($event)
          "
        >
          <CButton
            :class="{ 'is-left': !compact }"
            :style="!compact && { paddingLeft: '1.25em' }"
            :dropdown-icon="isFortnoxExport ? !compact : documents.length > 1"
            type="secondary"
            expanded
            raised
          >
            <CIcon
              v-if="!compact"
              :type="isFortnoxExport ? 'dollar' : 'print'"
              size="16"
              style="padding-right: 1em"
            />
            <template v-if="isFortnoxExport">
              {{ active ? $t('active') : $t('inActive') }}
            </template>
            <template v-else>
              {{
                active
                  ? $t('printActive')
                  : $t('_common:download', { thing: 'PDF' })
              }}
            </template>
          </CButton>
        </div>
      </template>
      <template v-if="isFortnoxExport">
        <CDropdownItem
          v-for="(doc, i) in documents"
          :key="i"
          :tooltip="$t('connectedTooltip')"
          icon-left="paper"
          @click="() => exportFortnox(doc)"
        >
          {{ truncate(doc.name, 15) }}
        </CDropdownItem>
      </template>
      <template v-else>
        <CDropdownItem
          v-for="(doc, i) in documents"
          :key="i"
          :disabled="!doc.readPrices"
          icon-left="paper"
          @click="onPrint(doc)"
        >
          {{ truncate(doc.name, 15) }}
        </CDropdownItem>
      </template>
    </CDropdown>
  </div>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import { truncate } from '@cling/utils'

import { mapGetters } from 'vuex'

import DocumentExportFortnoxModal from '@/components/document2/DocumentExportFortnoxModal.vue'
import DocumentPrintModal from '@/components/document2/DocumentPrintModal.vue'

export default {
  i18nOptions: {
    namespaces: 'views',
    keyPrefix: 'account.project.secondaryDropdown'
  },
  name: 'ProjectSecondaryDropdown',
  components: {
    CDropdown,
    CDropdownItem
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    documents: {
      type: Array,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isFortnoxInstalled: 'settings/isFortnoxInstalled'
    }),
    // Fortnox export
    isFortnoxExport() {
      return this.$can('fortnox', 'export') && this.isFortnoxInstalled
    }
  },
  methods: {
    truncate,
    onPrint(document) {
      if (!document.can('readPrice')) return
      this.$modal.show(DocumentPrintModal, { document })
    },
    onPrintSolo(e) {
      e.preventDefault()
      e.stopPropagation()
      this.onPrint(this.documents[0])
    },
    exportFortnox(doc) {
      this.$modal.show(DocumentExportFortnoxModal, { document: doc })
    }
  }
}
</script>

import { sortCompareFunction } from '@cling/utils'

export default {
  unreadNotificationIdList: state =>
    state.ids
      .filter(id => {
        if (state.data[id].isRead === false) {
          return true
        }
        return false
      })
      .sort((a, b) =>
        sortCompareFunction(state.data[b].createdAt, state.data[a].createdAt)
      ),
  allNotificationIdList: state =>
    state.ids.sort((a, b) =>
      sortCompareFunction(state.data[b].createdAt, state.data[a].createdAt)
    ),
  isFetching: state => state.isFetching,
  isPosting: state => state.isPosting,
  lastFetchedAt: state => state.lastFetchedAt
}

import webStorage from '@cling/utils/webStorage'

export default {
  endCustomerById: state => id => state.data[id],
  isPosting: state => state.isPosting,
  isFetching: state => state.isFetching,
  idList: state => state.ids,
  clients: state => state.data,
  defaultClientType: (state, getters, rootState, rootGetters) => () => {
    // Return function as no reactivity to state
    // Get from localStorage
    const webStorageSetting = webStorage.getItem('defaultClientType')
    if (
      webStorageSetting &&
      ['individual', 'company'].includes(webStorageSetting)
    ) {
      return webStorageSetting
    }

    // Get from company settings
    return (
      rootGetters['settings/getCompanySetting']('defaultClientType') ||
      'company'
    )
  }
}

import { getDefault } from '@cling/utils/jsonSchema'

import get from 'lodash/get'

/**
 * Class to represent a document template
 * Every template has a validationSchema that describes and validates the data,
 * the views array describes how to view / edit the data.
 */
class Template {
  /**
   * Template constructor
   * @param {Object} obj
   * @param {Object} obj.data The template data
   * @param {Object} obj.data.validationSchema JSON Schema describing the template data
   * @param {Object[]} obj.data.views Array of different view schemas
   */
  constructor(data) {
    if (!data) throw new Error('Missing param data')
    const { validationSchema, views } = data

    if (!validationSchema)
      throw new Error('Missing param validationSchema in template data')
    if (!views) throw new Error('Missing param views in template data')

    this._data = data
  }

  get data() {
    return this._data
  }
  get id() {
    return this.data.id || null
  }
  get companyId() {
    return this.data.companyId || null
  }
  get name() {
    return this.data.name || null
  }
  get isNew() {
    return !this.id
  }
  get validationSchema() {
    return this.data.validationSchema
  }
  get views() {
    return this.data.views || {}
  }
  get language() {
    return this.data.language
  }
  get theme() {
    return (
      get(
        this,
        '_data.validationSchema.properties.data.properties.theme.default',
        {}
      ) || {}
    )
  }
  get thumbnailUrl() {
    return this.data.thumbnailUrl
  }

  // virtual properties
  get doConsumeDocumentQuota() {
    return typeof this.data.doConsumeDocumentQuota === 'boolean'
      ? this.data.doConsumeDocumentQuota
      : true
  }

  get onModify() {
    return this.data.onModify || 'update'
  }

  get defaultMedia() {
    if (this.thumbnailUrl)
      return { type: 'image', resourceUrl: this.thumbnailUrl }

    const { defaultData } = this
    const mediaNodes = (get(this, '_data.views.read.nodes') || []).filter(
      node => ['header', 'coverBlock'].includes(node.itemType)
    )

    let media = null
    for (const node of mediaNodes) {
      if (node) {
        if (media) break

        let dataKey = node.value[node.itemType]

        if (!dataKey) continue

        if (node.itemType === 'header') {
          dataKey += '.items'
          const item = (get(defaultData, dataKey) || []).find(
            ({ type }) => type === 'media'
          )
          if (item && item.media) ({ media } = item)
        } else if (node.itemType === 'coverBlock') {
          const { type, cover } = get(defaultData, dataKey) || {}
          // Fixes media type for old & new type location for coverBlock
          if (cover) media = { ...(type && { type }), ...cover }
        }
      }
    }

    if (!media) {
      // Fallback for old styled documents with legacy header and background in doc.data.theme
      const resourceUrl = get(this, 'theme.background')
      if (resourceUrl) media = { type: 'image', resourceUrl }
    }

    return media
  }

  get reminders() {
    const defaultReminders = this.getDefaultData({
      path: 'properties.data.properties.reminders'
    })
    if (defaultReminders && Array.isArray(defaultReminders))
      return defaultReminders
    return []
  }

  /**
   * Get default data from the template
   * @returns {Object} Object with default data, if any is provided in the validationSchema
   */
  get defaultData() {
    return this.getDefaultData()
  }

  get categoryTags() {
    return get(this.defaultData, 'tags', []).reduce(
      (r, i) => [...r, i.name],
      []
    )
  }
  get type() {
    return this.categoryTags[0]
  }

  /**
   * Get default data function from the template
   * @param {Object} obj Optional
   * @param {String} obj.path Optional path to get default data
   * @returns {Object} Object with default data, if any is provided in the validationSchema
   */
  getDefaultData({ path = '' } = {}) {
    let schema = this.validationSchema
    if (path) schema = path.split('.').reduce((res, prop) => res[prop], schema)
    return getDefault(schema) || {}
  }
}

export default Template

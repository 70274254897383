export function loadOptions(i18nOptions) {
  const { namespaces, messages } = i18nOptions

  if (!namespaces) throw new Error('useMessages: namespaces is required')
  if (!messages) throw new Error('useMessages: messages is required')

  this.i18next.loadNamespaces(namespaces)

  Object.keys(messages).forEach(lang => {
    this.i18next.addResourceBundle(
      lang,
      namespaces,
      { ...messages[lang] },
      true, // deep
      false // overwrite
    )
  })
}

export const actionTypes = {
  LOAD_FILE: 'LOAD_FILE',
  CREATE_FILE: 'CREATE_FILE',
  UPDATE_FILE: 'UPDATE_FILE',
  DELETE_FILE: 'DELETE_FILE',
  PATCH_FILE: 'PATCH_FILE',
  FORM_NEW_FILE: 'FORM_NEW_FILE',
  FORM_EDIT_FILE: 'FORM_EDIT_FILE',
  FORM_SUBMIT_FILE: 'FORM_SUBMIT_FILE'
}

export const mutationTypes = {
  UPDATE_MANY_FILES: 'UPDATE_MANY_FILES',
  DELETE_ONE_FILE: 'DELETE_ONE_FILE',
  CLEAR_FILES: 'CLEAR_FILES',
  SET_FILES_FETCHING: 'SET_FILES_FETCHING',
  SET_FILES_POSTING: 'SET_FILES_POSTING'
}

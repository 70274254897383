export default {
  // Active messages
  modals: [],
  canCloseModal: true,
  // How input prices handle vat
  priceInputVat: 'exVat',
  hideDocArticlePriceInput: false, // if input price should be visible on documents
  // Information on logged in user
  user: {
    position: null,
    isLoggingIn: false,
    isRegistering: false,
    isResettingPassword: false,
    userId: null,
    companyId: null
  },

  error: null,

  socketEnabled: true,
  isSocketConnected: false,

  // Default view settings
  viewSettings: {
    app: {
      isFullPageWindow: true // Used views intended for iFrames / external windows
    },
    nav: {
      title: null,
      left: null
    },
    projectList: {
      quickViewProjectId: null // ProjectId for current quick preview
    },
    login: {
      error: null
    },
    register: {
      error: null
    },
    search: {
      show: false
    },
    newDocument: {
      showOverlayLoader: false
    }
  }
}

<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="flex w-full flex-col" style="height: 100%">
    <div class="image-modal-overlay" />
    <div class="image-modal-nav" style="z-index: 2">
      <div class="truncate-text" style="align-items: center; display: flex">
        <div class="truncate-text">{{ list[currentIndex].name }}</div>
      </div>
      <div style="display: flex">
        <div
          v-tooltip="$t('show', { thing: $t('previous') })"
          :class="{ disabled: !list[currentIndex - 1] }"
          class="image-modal-nav-button"
          @click="onGo(-1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M19 12H6M12 5l-7 7 7 7" />
          </svg>
        </div>
        <div
          v-tooltip="$t('show', { thing: $t('next') })"
          :class="{ disabled: !list[currentIndex + 1] }"
          class="image-modal-nav-button"
          @click="onGo(1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M5 12h13M12 5l7 7-7 7" />
          </svg>
        </div>
        <a
          v-tooltip="$t('download')"
          :href="list[currentIndex].url"
          target="_blank"
          download
          class="image-modal-nav-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"
            />
          </svg>
        </a>
        <div
          v-tooltip="$t('close')"
          class="image-modal-nav-button"
          @click="$emit('close')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
      </div>
    </div>
    <div
      class="content-container flex w-full items-center justify-center"
      style="position: relative"
      @click="$emit('close')"
    >
      <Spinner
        v-if="isLoading"
        size="50px"
        style="
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        "
      />
      <div v-if="isPdf" class="pdf-root">
        <VuePdf
          :src="list[currentIndex].url"
          class="pdf-page"
          @rendered="
            () => {
              isLoading = false
            }
          "
          @click.native.stop
        />
      </div>
      <img
        v-else-if="!isLoading"
        :src="list[currentIndex].url"
        class="modal-image"
        @load="isLoading = false"
      />
    </div>
  </div>
</template>

<script>
import VuePdf from '@cling/components/PdfViewer'
import Spinner from '@cling/components/ui/Spinner'

export default {
  name: 'FilesPreviewModal',
  components: {
    Spinner,
    VuePdf
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      currentIndex: this.index
    }
  },
  computed: {
    isPdf() {
      return this.list[this.currentIndex].mime === 'application/pdf'
    }
  },
  mounted() {
    window.addEventListener('keyup', this.handleKey, { capture: true })
    // If the image is still loading, force it to show
    // This way it's being progressivly shown that its being loaded
    this.toggleIsLoading()
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKey, { capture: true })
  },
  methods: {
    onGo(deltaDirection) {
      const newIndex = this.currentIndex + deltaDirection
      if (!this.list[newIndex]) return
      this.currentIndex = newIndex
      this.toggleIsLoading()
    },
    toggleIsLoading() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    handleKey(e) {
      e.stopPropagation()
      if (parseInt(e.keyCode, 10) === 37) this.onGo(-1)
      if (parseInt(e.keyCode, 10) === 39) this.onGo(1)
      const key = e.key || e.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 27) this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';
.image-modal-overlay {
  @include fontConfig();
  background-color: hsla(220, 10%, 20%, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}

.image-modal-nav {
  background-color: hsla(220, 10%, 20%, 0.8);
  border-bottom: 1px solid rgba($white, 0.1);
  color: $white;
  font-size: calc(calc(1 * var(--rem)) + 2px);
  font-weight: 600;
  padding: calc(0.5 * var(--rem)) calc(1 * var(--rem)) calc(0.5 * var(--rem))
    calc(2 * var(--rem));
  display: flex;
  justify-content: space-between;
}

.image-modal-nav-button {
  cursor: pointer;
  width: calc(3 * var(--rem));
  height: calc(3 * var(--rem));
  border-radius: 0.5em;
  color: rgba($white, 0.6);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  + #{&} {
    margin-left: 3px;
  }
  &:hover {
    color: $white;
    background-color: hsla(220, 10%, 20%, 100%);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
  }
}

img.modal-image {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
}

.content-container {
  z-index: 2;
  flex: 1 0 auto;
  overflow: hidden;
  height: 0; // Flex hack for filling remaining height
}

.pdf-root {
  position: relative;
  height: 100%;
  overflow: scroll;
  padding: calc(2 * var(--rem));
  width: 100%;
  & .pdf-page {
    width: 95%;
    max-width: 750px;
    margin: 0 auto;
    & ::v-deep > div {
      margin: calc(1.5 * var(--rem)) auto;
      canvas {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}
</style>
